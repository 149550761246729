import { Size } from '@ff-it/ui';
import { getMonth, lastDayOfYear, format, parseISO } from 'date-fns';
import { FormGroup, useIsDisabled, fieldSubscription } from '@ff-it/form';
import { DatePicker } from '@ff-it/ui';
import { useField } from 'react-final-form';
import { forwardRef, MouseEvent, ReactElement, ReactNode, useId } from 'react';

interface MonthDayFieldProps {
  name: string;
  id?: string;
  label?: ReactNode;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  size?: Size;
  help?: ReactNode;
  placeholder?: string;
  // validate?: FieldValidator<V>;
}

interface ControlButtonProps {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  value?: string;
  placeholder?: string;
}
const ControlButton = forwardRef<HTMLButtonElement, ControlButtonProps>(
  ({ value, onClick, placeholder }: ControlButtonProps, ref) => {
    return (
      <button ref={ref} onClick={onClick} type="button" className="form-control text-left">
        {value || placeholder}
      </button>
    );
  },
);

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const renderMonthSelectHeader = ({
  date,
  changeMonth,
}: {
  date: Date;
  changeMonth(month: number): void;
}): ReactNode => {
  return (
    <div
      style={{
        marginLeft: 5,
        marginRight: 5,
      }}
    >
      <select
        className="form-control"
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

// leap year
const year = 2020;

const startOfYear = new Date(year, 0, 1);

const parseDate = (value: any): any => {
  // loose year
  //  MM-dd
  if (value) {
    return format(value, 'MM-dd');
  }
  return null;
};

export const formatDate = (value: any): any => {
  // add year
  return value ? parseISO(`${year}-${value}`) : null;
};

const props = {
  minDate: startOfYear,
  maxDate: lastDayOfYear(startOfYear),
};

export function MonthDayField({
  name,
  className,
  label,
  help,
  required,
  placeholder = 'Select day',
  size,
  ...rest
}: MonthDayFieldProps): ReactElement {
  const _id = useId();
  const id = rest.id || _id;

  const disabled = useIsDisabled(rest.disabled);

  const {
    input: { value, onChange },
    meta,
  } = useField<Date, any>(name, {
    format: formatDate,
    parse: parseDate,
    subscription: fieldSubscription,
    validate: undefined,
  });

  return (
    <FormGroup className={className} label={label} id={rest.id} help={help} meta={meta} required={required}>
      <div className="input-group">
        <DatePicker
          id={id}
          disabled={disabled}
          selected={value}
          onChange={onChange}
          customInput={<ControlButton />}
          renderCustomHeader={renderMonthSelectHeader}
          placeholder={placeholder}
          dateFormat="MMMM do."
          size={size}
          openToDate={value || props.minDate}
          {...props}
        />
        {!required && value && (
          <button className="px-2 close" type="button" onClick={() => onChange(null)}>
            ×
          </button>
        )}
      </div>
    </FormGroup>
  );
}
