import { Action, BlockWidget, DialogForm, useOptions } from 'components';
import { ReactElement } from 'react';
import { PlanController } from '../../usePlanController';
import { PlanState } from '../../types';
import { openModal } from '@ff-it/ui';
import { FileField, SelectField } from '@ff-it/form';
import { ActivityType } from 'types';
import { BlockDetails } from 'modules/campaign/block/types';
import { required } from 'utilities';

type CodeImportProps = {
  type: ActivityType;
  id: number;
};

function ImportableBlockField({ id, type }: CodeImportProps): ReactElement {
  const loadOptions = useOptions<BlockDetails>('campaign/blocks/', {
    type,
    exclude: id,
  });

  return (
    <SelectField<any>
      autoFocus
      loadOptions={loadOptions}
      getOptionLabel={({ code, title }) => `${title} ${code}`}
      formatOptionLabel={BlockWidget}
      name="block"
      placeholder="Import code..."
      required
    />
  );
}

export type UseImportExportActionsArgs = {
  hasLockedRows: boolean;
  controller: PlanController;
  planState: PlanState;
};

export function useImportExportActions({
  controller,
  planState,
  hasLockedRows,
}: UseImportExportActionsArgs): (Action | '-')[] {
  let sep = false;

  const actions: (Action | '-')[] = [
    {
      action: 'plan-export',
      button: { children: 'Export plan', onClick: () => controller.planExport(planState) },
    },
    {
      action: 'row-export',
      button: { children: 'Export rows', onClick: controller.rowExport },
    },
  ];

  if (controller.strategy.canExportMediaReservation) {
    actions.push({
      action: 'media-reservation-export',
      button: { children: 'Export media reservation', onClick: () => controller.mediaReservationExport(planState) },
    });
  }

  if (planState.planning) {
    if (!sep) {
      actions.unshift('-');
      sep = true;
    }
    // appends rows to exsting plan
    actions.unshift({
      action: 'code-import',
      button: {
        children: 'Import rows (code)',
        onClick: () =>
          openModal((props) => (
            <DialogForm
              {...props}
              dialogHeader="Import rows"
              initialValues={{ block: null }}
              submitHandler={controller.codeImport}
            >
              <ImportableBlockField id={planState.id} type={planState.type} />
            </DialogForm>
          )),
      },
    });
    if (!hasLockedRows) {
      // destructive
      actions.unshift({
        action: 'plan-import',
        button: {
          children: 'Import plan',
          onClick: () => {
            // can't disctory linked rows (REPLAN)
            if (
              planState.rows.length !== 0 &&
              !window.confirm('This is going to rewrite rows. All changes will be lost. Are you sure?')
            ) {
              return;
            }

            openModal((props) => (
              <DialogForm {...props} dialogHeader="Import plan" submitHandler={controller.planImport}>
                <FileField name="file" label="File" required validate={required} />
              </DialogForm>
            ));
          },
        },
      });
    }
  }
  return actions;
}
