import { ReactElement, useMemo } from 'react';
import { Entity } from 'types';
import { useEntity, useModel } from '../../scenes/context';
import { NavLinkProps } from 'react-router-dom';
import { Perm, useCheck } from 'core/permissions';
import { TabNav } from '../SubNav';

interface NavItem<T> extends NavLinkProps {
  perm?: Perm;
  visible?: (item: T) => boolean;
}

interface EntityNavProps<T extends Entity> {
  nav: NavItem<T>[];
}

export function EntityNav<T extends Entity>({ nav: entityNav }: EntityNavProps<T>): ReactElement {
  const check = useCheck();
  const { entityTitle } = useModel();
  const { item } = useEntity();
  const title = entityTitle(item);

  const nav = useMemo(() => {
    return entityNav.filter(({ visible, perm }) => {
      if (visible && !visible(item)) {
        return false;
      }
      if (perm && !check(perm)) {
        return false;
      }
      return true;
    });
  }, [entityNav, item, check]);
  return (
    <div className="container-fluid header">
      <div className="d-flex flex-row align-items-center">
        <div className="mb-2">
          <h2>{title}</h2>
        </div>
      </div>
      <TabNav nav={nav} />
    </div>
  );
}
