import { Row as InnerRow, RenderRowProps } from 'components/DataGrid';
import { ReactElement } from 'react';
import clsx from 'clsx';
import { useDropTarget } from './hooks/useDragAndDrop';
import { RowExpansionProvider } from './hooks';
import { GridSummaryRow, RowOrGroup } from './types';

function Row({ className, ...props }: RenderRowProps<RowOrGroup, GridSummaryRow>): ReactElement {
  const row = props.row;
  const [rowRef, moveType] = useDropTarget(props.row);

  return (
    <InnerRow
      {...props}
      data-rowkind={row._isGroup ? 'group' : row.kind}
      className={clsx(className, moveType && `move-${moveType}`)}
      // Hack empty groups are no selectable
      isRowSelected={row._isGroup && row.empty ? false : props.isRowSelected}
      aria-selected={row._isGroup && row.empty ? false : props['aria-selected']}
      ref={rowRef}
    />
  );
}

export const renderRowOrGroup = (
  key: React.Key,
  isExpanded: boolean | null,
  props: RenderRowProps<RowOrGroup, GridSummaryRow>,
): ReactElement => {
  if (isExpanded !== null) {
    return (
      <RowExpansionProvider key={key} value={isExpanded}>
        <Row {...props} data-testid={key as string} />
      </RowExpansionProvider>
    );
  }
  return <Row key={key} {...props} data-testid={key as string} />;
};
