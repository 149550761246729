import { WEBDevice, webDevices, MaterialType, materialTypes, Transparency, transparencies } from 'modules/core/types';
import {
  Unit,
  genericUnits,
  RTBUnits,
  ProductKind,
  productKinds,
  ActivityType,
  activityTypes,
  Application,
  applications,
  ActivityKind,
} from 'types';

export type Option<V = string> = {
  label: string;
  value: V;
};

export type Options<V = string> = Array<Option<V>>;

const capitalize = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1).toLocaleLowerCase();

export function createOptions<V extends string>(
  values: readonly V[],
  labelMap: Partial<Record<V, string>> = {},
  valueToLabel: (v: V) => string = capitalize,
): Options<V> {
  return values.map((value) => {
    let label: string | undefined = labelMap[value];
    if (!label) {
      label = valueToLabel(value);
    }
    return {
      label,
      value,
    };
  });
}

export function optionLabel<V extends string>(options: Options<V>, value: V): string {
  return options.find((o) => o.value === value)?.label || value;
}

// FIXME: units are mostly abbreviations
export const genericUnitOptions = createOptions<Unit>(genericUnits, undefined, (v) => v);
export const rtbUnitOptions = createOptions<Unit>([...genericUnits, ...RTBUnits], undefined, (v) => v);

export function getUnitOptions(kind: ProductKind, type: ActivityType): Options<Unit> {
  if ((kind === 'MEDIA' && type === 'RTB') || type === 'WEB') {
    return rtbUnitOptions;
  }
  return genericUnitOptions;
}

export const productKindOptions = createOptions<ProductKind>(productKinds);
export const activityKindOptions = createOptions<ActivityKind>(['MEDIA', 'SERVICE']);

export const activityTypeOptions = createOptions<ActivityType>(activityTypes, {
  RTB: 'RTB',
  TV: 'TV',
  BTL: 'BTL',
  SEO: 'SEO',
  OOH: 'OOH',
});

export const invoiceTemplateOptions = createOptions(
  [
    'ENGLISH_DEFAULT',
    'ESTONIAN_DEFAULT',
    'ESTONIAN_ENGLISH',
    'LATVIAN_DEFAULT',
    'LATVIAN_ENGLISH',
    'LITHUANIAN_DEFAULT',
    'LITHUANIAN_ENGLISH',
  ],
  {
    ENGLISH_DEFAULT: 'English',
    ESTONIAN_DEFAULT: 'Estonian',
    ESTONIAN_ENGLISH: 'Estonian / English',
    LATVIAN_DEFAULT: 'Latvian',
    LATVIAN_ENGLISH: 'Latvian / English',
    LITHUANIAN_DEFAULT: 'Lithuanian',
    LITHUANIAN_ENGLISH: 'Lithuanian / English',
  },
);

export const acceptanceActTemplateOptions = createOptions(
  ['ESTONIAN_DEFAULT', 'LATVIAN_DEFAULT', 'LITHUANIAN_DEFAULT'],
  {
    ESTONIAN_DEFAULT: 'Estonian',
    LATVIAN_DEFAULT: 'Latvian',
    LITHUANIAN_DEFAULT: 'Lithuanian',
  },
);

export const materialTypeOptions = createOptions<MaterialType>(materialTypes, { HTML5: 'HTML5' });

export const webDeviceOptions = createOptions<WEBDevice>(webDevices, {
  TV: 'TV',
  CROSS_DEVICE: 'Cross device',
});
export const applicationOptions = createOptions<Application>(applications);
export const transparencyOptions = createOptions<Transparency>(transparencies);
