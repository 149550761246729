import { ReactElement } from 'react';
import Big from 'big.js';
import { Col, DialogBody, DialogClose, DialogFooter, DialogHeader, Row } from '@ff-it/ui';
import { DecimalField, Form, Submit, useSubmitHandler } from '@ff-it/form';
import { composeValidators, parseBig, positive, required } from 'utilities';
import { ApplicationResponse, LinkCandidate } from 'modules/applicator/types';
import { InvoiceWidget } from 'modules/invoicing/common/InvoiceWidget';

type ApplyFormProps = {
  url: string;
  balance: Big;
  onSubmit: (data: ApplicationResponse) => void;
  candidate: LinkCandidate;
};

export function ApplyForm({
  url,
  balance,
  candidate: { destination, amount: candidateBalance },
  onSubmit: onSuccess,
}: ApplyFormProps): ReactElement {
  const submitHandler = useSubmitHandler({ url, method: 'POST' }, { onSuccess });

  const validate = composeValidators(required, positive, (value: any) =>
    parseBig(value).gt(balance) ? `Has to be less or equeal to ${balance.toFixed()}` : undefined,
  );

  return (
    <Form initialValues={{ amount: candidateBalance, destination: destination.id }} onSubmit={submitHandler}>
      <DialogHeader title="Add invoice" />
      <DialogBody>
        <Row>
          <Col>
            <InvoiceWidget invoice={destination} />
          </Col>
          <Col paddingX="lg">
            <div className="form-control form-control-plaintext font-weight-bold">{candidateBalance}</div>
          </Col>
          <Col>
            <DecimalField name="amount" className="mb-0 text-right" required validate={validate} />
          </Col>
        </Row>
      </DialogBody>
      <DialogFooter>
        <Submit />
        <DialogClose className="ml-auto" />
      </DialogFooter>
    </Form>
  );
}
