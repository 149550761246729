import { ReactElement } from 'react';
import { Link, Navigate, useRoutes } from 'react-router-dom';
import { useEntity, Icon, SubNavItem, TabNav, withHasPerm, IndexContainer } from 'components';
import { columns } from 'modules/invoicing/expenses';
import permissions from 'modules/invoicing/expenses/permissions';
import type { CampaignDetails } from 'modules/campaign/campaign/types';
import { InvoiceFooter } from '../InvoiceFooter';
import { Button } from '@ff-it/ui';
import { useSessionScope } from 'services';
import { serializeQs } from 'components/ControlledTable/useQueryState';

const cols = [
  ...columns.slice(0, -1),
  {
    content: 'Bound',
    key: 'campaign_bound',
    width: 120,
    className: 'text-right',
    headerClassName: 'text-right',
  },
  ...columns.slice(-1),
];

function LinkExpenses({ campaign }: { campaign: CampaignDetails }): ReactElement | null {
  const { slug } = useSessionScope();

  if (campaign.sums.expense_state === 'FULL') {
    return null;
  }
  return (
    <Button
      variant="outline-primary"
      component={Link}
      to={`/${slug}/invoices/expense${serializeQs({
        pageIndex: 1,
        pageSize: 15,
        sort: undefined,
        filter: {
          client: campaign.client.id.toString(),
          sums__state: ['NONE', 'PARTIAL'],
        },
      })}`}
      size="sm"
    >
      Link expenses <Icon icon="link" className="ml-1" />
    </Button>
  );
}

const nav: SubNavItem[] = [
  {
    to: 'campaign',
    children: 'Campaign',
  },
  {
    to: 'cvb',
    children: 'CVB',
  },
];

export const Expense = withHasPerm(function Expense(): ReactElement {
  const { item } = useEntity<CampaignDetails>();

  const routes = useRoutes([
    {
      path: 'campaign',
      element: (
        <IndexContainer
          url={`invoicing/expense/`}
          filterFields={null}
          columns={cols}
          pageSize={Number.POSITIVE_INFINITY}
          queryParams={{
            campaign: item.id,
            source: 'CAMPAIGN',
          }}
          after={<InvoiceFooter campaingTotal={item.sums.income_total} />}
          title="Net invoices"
          toolbar={<LinkExpenses campaign={item} />}
        />
      ),
    },
    {
      path: 'cvb',
      element: (
        <IndexContainer
          url={`invoicing/expense/`}
          columns={cols}
          filterFields={null}
          pageSize={Number.POSITIVE_INFINITY}
          queryParams={{
            campaign: item.id,
            source: 'CVB',
          }}
          after={<InvoiceFooter />}
          title="Bonus invoices"
        />
      ),
    },
    {
      index: true,
      element: <Navigate to="campaign" replace />,
    },
  ]);

  return (
    <>
      <div className="container-fluid">
        <TabNav nav={nav} />
      </div>
      {routes}
    </>
  );
}, permissions.view);
