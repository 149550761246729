import { TableInlineColumn, TableInlines } from 'components';
import { Card, CardBody } from 'components/Card';
import { ReactElement } from 'react';
import { ActivityType } from 'types';
import { ServiceRate } from './types';
import { DecimalField, TextField } from '@ff-it/form';
import { CategoryField } from 'modules/supplier/category/MediaCategoryField';
import { SupplierField } from 'modules/supplier/supplier/components';
import { Box } from '@ff-it/ui';
import { required } from 'utilities';

const initialValues = {
  target_supplier: {
    provider: null,
    activity: null,
    product: null,
  },
  target_category: null,
  client_price: null,
  terms: '',
};

export function SeviceRates({ type }: { type: ActivityType }): ReactElement {
  const columns: TableInlineColumn<ServiceRate>[] = [
    {
      key: 'client_price',
      content: 'Client price',
      render: ({ name }) => {
        return <DecimalField name={`${name}.client_price`} className="mb-0" required validate={required} />;
      },
      width: '120px',
    },

    {
      key: 'target_category',
      content: 'Target category',
      render: ({ name }) => <CategoryField name={`${name}.target_category`} className="mb-0" />,
    },
    {
      key: 'target_supplier',
      content: 'Target supplier fields',
      render: ({ name }) => (
        <SupplierField name={`${name}.target_supplier`} className="mb-0" type={type} kind="SERVICE" incomplete />
      ),
    },
    {
      key: 'terms',
      content: 'Comment',
      render: ({ name }) => <TextField multiline autosize name={`${name}.terms`} className="mb-0" />,
    },
  ];

  return (
    <Card
      title={
        <Box display="flex" alignItems="center">
          Service rates
          <Box marginLeft="auto" fontWeight="regular" fontSize="xs">
            Set client price when target conditions are met.
          </Box>
        </Box>
      }
      testId="service-rates"
    >
      <CardBody>
        <TableInlines name="service_rates" initialValues={initialValues} columns={columns} />
      </CardBody>
    </Card>
  );
}
