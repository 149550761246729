import type { InvoiceRow } from 'modules/invoicing/income/types';
import type { LinkCandidate } from '../types';
import { initialRow } from 'modules/invoicing/income/initialValues';
import { formatDate, parseBig } from 'utilities';

export function createCompensationRow({ destination, amount }: LinkCandidate): InvoiceRow & { __NEW__: number } {
  const title =
    destination.date_from && destination.date_to
      ? `Compensation for ${formatDate(destination.date_from)} - ${formatDate(destination.date_to)}`
      : `Compensation for NR: ${destination.number}`;
  return {
    ...initialRow,
    kind: 'COMPENSATION',
    compensated_to: {
      destination: destination,
    },
    title,
    unit_price: parseBig(amount).mul(-1).toFixed(),
    __NEW__: Date.now(),
  };
}
