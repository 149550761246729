import { memo, ReactElement } from 'react';
import { ActivityField, AgencyActivityTypesField, Fieldset, RemoveButton } from 'components';
import { RateFields } from 'components/company';
import { BonusForm, Rows, RowProps } from 'modules/guarantee/common/bonus';
import permissions from 'modules/guarantee/avb/permissions';
import { TextField, useIsDisabled } from '@ff-it/form';
import { CompanyField } from 'modules/core/company/components';
import { DepartmentField } from 'modules/supplier/department/DepartmentField';

const props = {
  size: 'sm' as const,
  placeholder: 'All',
};

export const Row = memo(function Row({ name, remove, index }: RowProps): ReactElement {
  const disabled = useIsDisabled();

  return (
    <tr>
      <td className="provider">
        <CompanyField name={`${name}.provider`} {...props} includeBranches={true} />
      </td>
      <td className="type">
        <AgencyActivityTypesField name={`${name}.type`} {...props} />
      </td>
      <td className="activity">
        <ActivityField name={`${name}.activity`} {...props} />
      </td>

      <td className="department">
        <DepartmentField name={`${name}.department`} {...props} />
      </td>
      <td className="client">
        <CompanyField name={`${name}.client`} {...props} includeBranches={true} />
      </td>
      <td className="payer">
        <CompanyField name={`${name}.payer`} {...props} includeBranches={true} />
      </td>
      <RateFields name={name} />
      <td className="tools">
        <RemoveButton onClick={() => remove(index)} disabled={disabled} />
      </td>
    </tr>
  );
});

export function Form(): ReactElement {
  return (
    <BonusForm sensitive={permissions.sensitive_info}>
      <Fieldset title="Signature person">
        <div className="form-row">
          <TextField name="signature_first_name" label="First name" className="col-md-6" />
          <TextField name="signature_last_name" label="Last name" className="col-md-6" />
        </div>
      </Fieldset>
      <table className="table-rates">
        <thead>
          <tr>
            <th className="provider">Provider</th>
            <th className="type">Type</th>
            <th className="activity">Activity</th>
            <th className="department">Department</th>
            <th className="client">Client</th>
            <th className="payer">Payer</th>
            <th className="rate">Current</th>
            <th className="rate">Target</th>
            <th className="rate">Final</th>
            <th className="tools" />
          </tr>
        </thead>
        <Rows Row={Row} />
      </table>
    </BonusForm>
  );
}
