import { NavLinkProps } from 'react-router-dom';
import { useHasPerms } from 'core/permissions';
import { isLinkable } from 'modules/linker/isLinkable';
import { isApplicable } from 'modules/applicator/isApplicable';
import type { Invoice } from '../types';
import { IsLinkedStateBadge } from './IsLinkedStateBadge';
import { DocumentApplicationStateBadge } from './DocumentApplicationStateBadge';
import { LinkApplicationStateBadge } from './LinkApplicationStateBadge';

export function useNavLinks(invoice: Invoice, permissions: any): NavLinkProps[] {
  const [canLink, canChange] = useHasPerms(permissions.link, permissions.change);
  const nav: NavLinkProps[] = [
    {
      to: '',
      end: true,
      children: 'Update',
    },
  ];

  if (canLink && isLinkable(invoice)) {
    nav.push({
      to: 'link',
      children: (
        <>
          Link campaigns
          <span className="ml-2">
            <IsLinkedStateBadge linked_at={invoice.linked_at} link_state={invoice.sums.link_state} />
            <LinkApplicationStateBadge direction={invoice.direction} link_state={invoice.sums.link_state} />
          </span>
        </>
      ),
    });
  }
  const applicationType = isApplicable(invoice);
  if (canChange && applicationType) {
    nav.push({
      to: 'application',
      children: (
        <>
          Apply {applicationType} <DocumentApplicationStateBadge type={applicationType} invoice={invoice} />
        </>
      ),
    });
  }

  nav.push({
    to: 'history',
    children: 'History',
  });

  return nav;
}
