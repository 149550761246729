import { Form, Filter, columns, initialValues, Details } from 'modules/guarantee';
import {
  CreateScene,
  DeleteScene,
  DetailsScene,
  EntityNav,
  EntityScene,
  ModelScene,
  IndexScene,
  UpdateScene,
} from 'components';
import type { Guarantee } from 'modules/guarantee/types';
import { clientPermissions } from './permissions';
import { NewListExport } from 'components/ListActions';
import { copyGuarantee } from 'modules/guarantee/util';

const model = {
  endpoint: 'guarantee/local/client/',
  title: 'Client guarantee',
  pluralTitle: 'Client guarantees',
  entityTitle: (g: Guarantee) => g.number,
  permissions: clientPermissions,
};

export const route = {
  path: 'client',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene
          columns={columns}
          toolbar={<NewListExport />}
          storageKey="guarantee_client"
          filterFields={<Filter />}
        />
      ),
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form global={false} />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <EntityNav
            nav={[
              {
                to: '',
                end: true,
                children: 'Details',
              },
              {
                to: 'update',
                children: 'Update',
                perm: model.permissions?.change,
              },
            ]}
          />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: (
            <DetailsScene heading={null}>
              <Details global={false} />
            </DetailsScene>
          ),
        },
        {
          path: 'update',
          element: (
            <UpdateScene heading={null} createPath="../../create" deletePath="../delete" copy={copyGuarantee}>
              <Form global={false} />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};
