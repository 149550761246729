import { Breadcrumb, Page, useEntity } from 'components';
import { ReactElement, Suspense } from 'react';
import { Alert, Box, Spinner } from '@ff-it/ui';
import { ApplicationContainer } from './ApplicationContainer';
import type { Invoice } from 'modules/invoicing/types';
import { isApplicable } from '../isApplicable';

export function Applicator(): ReactElement {
  const ctx = useEntity<Invoice>();
  const type = isApplicable(ctx.item);
  if (!type) {
    // happens when we remove last link for invoice that had no demand to begin with
    return (
      <Page className="scene flex-grow-1 d-flex flex-column" narrow>
        <Alert>Nothing to apply</Alert>
      </Page>
    );
  }

  return (
    <Page className="scene flex-grow-1 d-flex flex-column" narrow>
      <Breadcrumb to={null}>Application</Breadcrumb>
      <Suspense
        fallback={
          <Box paddingY="xxl" marginX="auto">
            <Spinner color="primary" />
          </Box>
        }
      >
        <ApplicationContainer ctx={ctx} type={type} />
      </Suspense>
    </Page>
  );
}
