import { ButtonVariant } from '@ff-it/ui';
import { EntityAction, ResolvedEntityAction, createConfirmDialog } from 'components';
import type { Expense } from './types';

export const actions: EntityAction<Expense>[] = [
  {
    action: 'confirm',
    button: {
      children: 'Confirm',
      icon: 'check',
      variant: 'outline-success',
    },
    visible: ({ state }) => state === 'DRAFT',
  },
  {
    action: 'draft',
    button: {
      children: 'Draft',
      icon: 'ban',
      variant: 'outline-secondary',
    },
    requestDialog: createConfirmDialog(),
    visible: ({ state }) => state === 'CANCELED',
  },
  {
    action: 'cancel',
    button: {
      children: 'Cancel',
      icon: 'ban',
      variant: 'outline-danger',
    },
    requestDialog: createConfirmDialog(),
    visible: ({ state, linked_at, booking_state }) => state === 'CONFIRMED' && !linked_at && booking_state === 'NONE',
  },
  {
    action: 'mark_linked',
    button: {
      children: 'Mark linked',
      variant: 'outline-success',
      icon: 'link',
    },
    visible: ({ state, type, linked_at }) => state === 'CONFIRMED' && type === 'INVOICE' && !linked_at,
  },
  {
    action: 'mark_not_linked',
    button: {
      children: 'Mark not linked',
      variant: 'outline-danger',
      icon: 'link-slash',
    },
    visible: ({ state, type, linked_at }) => state === 'CONFIRMED' && type === 'INVOICE' && !!linked_at,
  },
];

export const bookingActions: EntityAction<Expense>[] = [
  {
    action: 'book_create',
    permKey: 'book_expense',
    button: {
      children: 'Create',
      icon: 'book-open',
      variant: 'outline-secondary',
      size: 'sm',
    },
    visible: ({ state, booking_state }) => state !== 'CANCELED' && booking_state === 'NONE',
  },
  ({ item: { booking_state } }): ResolvedEntityAction<Expense> => ({
    action: 'book_sync',
    permKey: 'book_expense',
    button: {
      children: 'Sync',
      icon: 'book-open',
      size: 'sm',
      variant: {
        CREATED: 'outline-warning' as ButtonVariant,
        BOOKED: 'text-success' as ButtonVariant,
        NONE: undefined,
      }?.[booking_state],
    },
    visible: ({ state, booking_state }) => state !== 'CANCELED' && booking_state !== 'NONE',
  }),
];
