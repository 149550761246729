import { ReactElement } from 'react';
import { Box, Popover } from '@ff-it/ui';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import cx from 'clsx';
import Big from 'big.js';
import { group, variants, popover, invoiceRatio, number, meta } from './style.css';
import { fmt, parseBig } from 'utilities';
import { InvoiceList } from './InvoiceList';

import { Invoice, Invoices } from '../types';

type InvoiceGroupProps = {
  progressRatio: number;
  invoices: Invoices;
  variant: 'income' | 'expense';
  onInvoiceSelect?: (invoice: Invoice) => void;
};

const placmentOptions: any = {
  alignment: 'start',
};

const sizeOptions: any = {
  apply({ availableHeight, elements }: any) {
    Object.assign(elements.floating.style, {
      maxHeight: `${availableHeight}px`,
    });
  },
  padding: 8,
};

export function InvoiceGroup({ invoices, variant, progressRatio, onInvoiceSelect }: InvoiceGroupProps): ReactElement {
  const total = invoices.reduce((acc, invoice) => {
    return acc.plus(parseBig(invoice.amount));
  }, Big(0));

  return (
    <Popover
      interaction="hover"
      sizeOptions={sizeOptions}
      placement="auto"
      placementOptions={placmentOptions}
      content={
        <Box className={popover}>
          <InvoiceList variant={variant} invoices={invoices} onInvoiceSelect={onInvoiceSelect} />
        </Box>
      }
    >
      <Box
        className={cx(group, variants[variant])}
        style={assignInlineVars({ [invoiceRatio]: progressRatio.toString() })}
      >
        <Box className={number}>{invoices.length} invoices</Box>
        <Box className={meta} data-testid="group-amount">
          {fmt(total)}
        </Box>
      </Box>
    </Popover>
  );
}
