import { ReactElement } from 'react';
import { Link, useRoutes } from 'react-router-dom';
import type { Ticket } from 'modules/support/types';
import { ModelScene, Badge, EntityScene, DetailsScene, IndexScene } from 'components';
import { SelectField } from '@ff-it/form';
import { never } from 'core/permissions';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ViewTicket } from './View';
import { TableColumns } from '@ff-it/ui';

const cols: TableColumns<Ticket> = [
  {
    content: 'Ticket',
    key: 'number',
    render: ({ id, title }: Ticket) => (
      <>
        <strong className="mr-2">#{id}</strong>
        <Link to={`${id}`}>{title}</Link>
      </>
    ),
    sortable: true,
    width: 350,
  },
  {
    content: 'Agency',
    key: 'enterprise.company.title',
  },

  {
    content: 'Created',
    key: 'created_at',
    render: ({ created_at }: Ticket) => formatDistanceToNow(parseISO(created_at), { addSuffix: true }),
    width: 120,
  },
  {
    content: 'Author',
    key: 'author',
    render: ({ author }: Ticket) => author.email,
  },
  {
    content: 'Assignee',
    key: 'assignee',
    render: ({ assignee }: Ticket) => assignee?.email,
  },
  {
    content: 'State',
    width: 80,
    key: 'state',
    sortable: true,
    render: ({ state }: Ticket) => <Badge state={state} />,
  },
];

const model = {
  endpoint: 'support/tickets/',
  title: 'Ticket',
  pluralTitle: 'Tickets',
  entityTitle: (e: Ticket) => `#${e.id}`,
  //   permissions,
};

const stateOptions = [
  { value: 'OPEN', label: 'Open' },
  { value: 'CLOSED', label: 'Closed' },
  { value: 'CANCELED', label: 'Canceled' },
];

export function Tickets(): ReactElement {
  const routes = useRoutes([
    {
      index: true,
      element: (
        <IndexScene
          columns={cols}
          canCreate={never}
          storageKey="dashboard_tickets"
          filterFields={
            <SelectField name="state" simple size="sm" placeholder="State" options={stateOptions} isClearable={false} />
          }
        />
      ),
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <DetailsScene heading={null}>
              <ViewTicket />
            </DetailsScene>
          ),
        },
      ],
    },
  ]);
  return <ModelScene {...model}>{routes}</ModelScene>;
}
