import cx from 'clsx';
import type { CSSProperties, ReactElement, ReactNode } from 'react';
import { Box } from '@ff-it/ui';
import { row, cell, acell, rcell, pcell, ccell, lcell } from './style.css';

type RowContainerProps = {
  testId?: string;
  className?: string;
  style?: CSSProperties;
  rowLink?: ReactNode;
  client?: ReactNode;
  product?: ReactNode;
  provider?: ReactNode;
  block?: ReactNode;
  // source
  period?: ReactNode;
  source?: ReactNode;
  rate?: ReactNode;
  invoiceable?: ReactNode;
  invoiced?: ReactNode;
  balance?: ReactNode;
  balanceControl?: ReactNode;
  linked?: ReactNode;
  linkedControl?: ReactNode;
  manualControl?: ReactNode;
};

export function RowContainer({
  testId,
  className,
  style,
  rowLink,
  client,
  product,
  provider,
  block,
  period,
  source,
  rate,
  invoiceable,
  invoiced,
  balance,
  balanceControl,
  linked,
  linkedControl,
  manualControl,
}: RowContainerProps): ReactElement {
  return (
    <Box className={cx(row, className)} style={style} data-testid={testId}>
      <Box className={lcell} textAlign="center">
        {rowLink}
      </Box>
      <Box className={cell}>{client}</Box>
      <Box className={cell}>{product}</Box>
      <Box className={cell}>{provider}</Box>
      <Box className={cell}>{block}</Box>
      <Box className={pcell}>{period}</Box>
      <Box className={acell}>{source}</Box>
      <Box className={rcell}>{rate}</Box>
      <Box className={acell}>{invoiceable}</Box>
      <Box className={acell}>{invoiced}</Box>
      <Box className={acell}>{balance}</Box>
      <Box className={ccell}>{balanceControl}</Box>
      <Box className={acell}>{linked}</Box>
      <Box className={ccell}>{linkedControl}</Box>
      <Box className={ccell}>{manualControl}</Box>
    </Box>
  );
}
