import { addDays, min, max, parseISO, formatISO } from 'date-fns';
import type { PlanRow } from 'modules/campaign/row';
import type {
  Segment,
  SegmentHandle,
  Interval,
  PositionError,
  Bounds,
  SegmentSelectionStateType,
  Calendar,
} from './types';

export function calculateBounds<T extends { width: number }>(
  columns: T[],
): Array<
  T & {
    left: number;
    right: number;
  }
> {
  let curLeft = 0;
  return columns.map((col) => {
    const left = curLeft;
    curLeft += col.width + 1;

    return {
      ...col,
      left,
      right: left + col.width,
    };
  });
}

export function calcRowSegments(
  positions: PlanRow['positions'],
  intervalToBounds: Calendar['intervalToBounds'],
): Segment[] {
  return positions.map((pos) => {
    const { date_from, date_to } = pos;
    const start = parseISO(date_from);
    const end = parseISO(date_to);

    return { ...pos, start, end, ...intervalToBounds({ start, end }) };
  });
}

export function getSegmentMouseHit(
  mouseX: number,
  rowSegments: Segment[],
  mouseY: number | null = null,
): [segment: Segment, handle: SegmentHandle] | null {
  if (mouseY !== null && (mouseY < 9 || mouseY > 36)) {
    return null;
  }
  if (rowSegments.length > 0) {
    const segment = rowSegments.find((segment) => segment.left <= mouseX && segment.right >= mouseX);
    if (segment) {
      const handle = segment.left + 5 > mouseX ? 'left' : segment.right - 5 < mouseX ? 'right' : 'middle';
      return [segment, handle];
    }
  }
  return null;
}

export function shiftInterval({ start, end }: Interval, handle: SegmentHandle, delta: number | null): Interval {
  if (delta) {
    switch (handle) {
      case 'middle':
        start = addDays(start, delta);
        end = addDays(end, delta);
        break;
      case 'left':
        start = min([addDays(start, delta), end]);
        break;
      case 'right':
        end = max([addDays(end, delta), start]);
        break;
    }
  }
  return {
    start,
    end,
  };
}

export function getPositionError(segments: Segment[], interval: Interval, excludeId?: number): PositionError | null {
  let { start, end } = interval;
  if (segments.length > 0) {
    // @IMPORTANT asserts segments are ordered
    for (let i = 0; i < segments.length; i++) {
      const { id, start: segmentStart, end: segmentEnd } = segments[i];
      if (id === excludeId) {
        continue;
      }

      if (segmentStart < start) {
        start = segmentStart;
      }
      if (segmentEnd > end) {
        end = segmentEnd;
      }

      if (segmentEnd < interval.start) {
        continue;
      }
      if (segmentStart > interval.end) {
        break;
      }
      return 'overlap';
    }
  }

  return null;
}

export function calcRowSelection(
  positions: PlanRow['positions'],
  rowSelection: SegmentSelectionStateType,
  intervalToBounds: Calendar['intervalToBounds'],
):
  | ({
      id: number;
      quantity: string;
      date_from: string;
      date_to: string;
      error: PositionError | null;
    } & Interval &
      Bounds)
  | undefined {
  // invariant(rowSelection.rowId == row.id);

  const position = positions.find((i) => i.id === rowSelection.segmentId);
  if (!position) {
    return;
  }

  const { id, quantity, date_from, date_to } = position;
  const { handle, delta, error } = rowSelection;

  const interval = shiftInterval(
    {
      start: parseISO(date_from),
      end: parseISO(date_to),
    },
    handle,
    delta,
  );

  return {
    id,
    quantity,
    date_from: formatISO(interval.start, { representation: 'date' }),
    date_to: formatISO(interval.end, { representation: 'date' }),
    ...interval,
    ...intervalToBounds(interval),
    error,
  };
}
