import { ReactElement, ReactNode } from 'react';
import type { Bound, EntityMap } from '../types';
import { AddButton, EnterpriseLink, Icon, RemoveButton, useEntity } from 'components';
import { useForm } from 'react-final-form';
import { fmt } from 'utilities';
import { SupplierTitle } from 'modules/supplier/supplier/components';
import { RowContainer } from './RowContainer';
import { PeriodState } from '../PeriodState';
import { AmountInput } from './AmountInput';
import Big from 'big.js';
import { Linkable } from './types';
import { calcSummaryState } from './calcSummaryState';

function linkableSupplier({ product_id, provider_id, activity_id }: Linkable, entities: EntityMap): ReactNode {
  const product = entities.product[product_id];
  const provider = entities.company[provider_id];
  const activity = entities.activity[activity_id];
  return <SupplierTitle product={product} provider={provider} activity={activity} />;
}

export type RowProps = {
  linkable: Linkable;
  entities: EntityMap;
  className?: string;
  disabled: boolean;
};

function LinkButton({ id, balance }: { id: string; balance: Big }): ReactElement {
  const { item } = useEntity();
  const form = useForm();

  return (
    <AddButton
      testId={`link-${id}`}
      onClick={() => {
        // If something is left in document balance and it's less than row balnce use that
        // This is handy when we link last remainder or only partial row
        const summary = calcSummaryState(item, (form.getFieldState('bound')?.value || {}) as Bound).balance;
        const amount = summary.gt(0) && summary.lt(balance) ? summary.toFixed() : balance.toFixed();
        form.change(`bound._${id}`, amount);
      }}
    />
  );
}

export function CandidateRow({ linkable, entities, className, disabled }: RowProps): ReactElement {
  const { id, campaign_id, block_id, invoiceable, invoiced, balance, state } = linkable;

  return (
    <RowContainer
      className={className}
      rowLink={
        <EnterpriseLink to={`/planning/campaigns/${campaign_id}/planning/${block_id}?rowId=${id}`}>
          <Icon size="sm" icon="link" />
        </EnterpriseLink>
      }
      supplier={linkableSupplier(linkable, entities)}
      period={<PeriodState {...state} />}
      invoiceable={fmt(invoiceable)}
      invoiced={fmt(invoiced)}
      balance={fmt(balance)}
      control={disabled ? undefined : <LinkButton id={id} balance={balance} />}
    />
  );
}

export function LinkedRow({ linkable, entities, className, disabled }: RowProps): ReactElement {
  const { id, campaign_id, block_id, invoiceable, invoiced, balance, state } = linkable;

  const form = useForm();
  const name = `bound._${id}`;

  return (
    <RowContainer
      className={className}
      rowLink={
        <EnterpriseLink to={`/planning/campaigns/${campaign_id}/planning/${block_id}?rowId=${id}`}>
          <Icon size="sm" icon="link" />
        </EnterpriseLink>
      }
      supplier={linkableSupplier(linkable, entities)}
      period={<PeriodState {...state} />}
      invoiceable={fmt(invoiceable)}
      invoiced={disabled ? fmt(invoiced) : <AmountInput name={name} />}
      balance={fmt(balance)}
      control={
        disabled ? undefined : (
          <RemoveButton
            testId={`unlink-${id}`}
            onClick={() => {
              form.change(name, undefined);
            }}
          />
        )
      }
    />
  );
}
