import { FilterState, filterRows } from '../filter';
import { BonusLinkable, LinkerContext } from '../types';
import { Linkable, LinkerState } from './types';
import { useBound } from '../useBound';
import { useMemo } from 'react';
import { parseLinkable } from '../parseLinkable';
import { calcSummaryState } from './calcSummaryState';
import { parseBig } from 'utilities';
import { Invoice } from 'modules/invoicing/types';

const isBoundOrRemainder = (i: Linkable): boolean => i.bound !== null || !i.balance.eq(0);

const isBound = (i: Linkable): boolean => i.bound !== null;

export function useLinkerState(
  { linkable, entities }: LinkerContext,
  filterState: FilterState,
  disabled: boolean,
  invoice?: Invoice,
): LinkerState {
  const cond = disabled ? isBound : isBoundOrRemainder;
  const bound = useBound();

  const parsedLinkable: Linkable[] = useMemo(
    () =>
      (linkable as BonusLinkable[]).map((r) => {
        const row = parseLinkable(r);
        const key = `_${r.id}`;
        if (key in bound) {
          const rawAmount = bound[key];
          const amount = parseBig(rawAmount);
          return {
            ...row,
            bound: amount,
            balance: row.balance.minus(amount),
            hash: `${rawAmount}${row.balance.toFixed()}`,
          };
        } else {
          return {
            ...row,
            bound: null,
            hash: `${row.balance.toFixed()}`,
          };
        }
      }),
    [linkable, bound],
  );

  const rows = useMemo(
    () => filterRows(parsedLinkable, filterState, entities, cond),
    [parsedLinkable, filterState, entities, cond],
  );

  return {
    rows,
    summary: calcSummaryState(rows, bound, invoice),
    bound,
  };
}
