import { ReactElement } from 'react';
import type { LinkerContext } from './types';
import { InvoiceFields } from './InvoiceFields';
import { BonusLinkerContainer } from './BonusLinker';

export function AVBBuilder(props: LinkerContext & { url: string }): ReactElement {
  return (
    <BonusLinkerContainer direction="income" disabled={false} {...props}>
      <InvoiceFields hideServicePeriod />
    </BonusLinkerContainer>
  );
}
