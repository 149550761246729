import type { ReactElement, ReactNode } from 'react';
import { LinkerFields } from '../LinkerFields';
import { Grid } from './Grid';
import { BonusLinkerProps } from './types';
import { FilterForm, useFilterState } from '../filter';
import { HeaderRow } from './HeaderRow';
import { FilterRow } from './FilterRow';
import { Submit, useForm } from '@ff-it/form';
import { useLinkerState } from './useLinkerState';
import { LinkerSummary } from '../LinkerSummary';
import { Box } from '@ff-it/ui';
import { Export } from './Export';

export function BonusLinker(props: BonusLinkerProps): ReactElement {
  return <BonusLinkerContainer {...props} />;
}

export function BonusLinkerContainer({
  children,
  disabled = false,
  invoice,
  direction,
  url,
  source_period,
  ...props
}: BonusLinkerProps & {
  // invoice fields for builder
  children?: ReactNode;
}): ReactElement {
  const form = useForm();
  const [filterState, setFilterState] = useFilterState();
  const { rows, summary, bound } = useLinkerState(props, filterState, disabled, invoice);
  const unlink =
    !disabled && summary.hasBound
      ? () => {
          form.change('bound', {});
        }
      : undefined;

  const link =
    !disabled && summary.hasAvailable
      ? () => {
          form.change('bound', {
            ...bound,
            ...rows.reduce((acc: Record<string, string>, { id, bound, balance }) => {
              const key = `_${id}`;
              if (bound === null && !balance.eq(0)) {
                acc[key] = balance.toFixed();
              }
              return acc;
            }, {}),
          });
        }
      : undefined;
  return (
    <>
      {children}
      <div className="form-row">
        <div className="col-md-7 d-flex align-items-start">
          <LinkerFields className="flex-grow-1" source_period={source_period} />
          <Export url={url} />
          {!disabled && <Submit className="ml-3" size="sm" />}
        </div>
        {invoice && (
          <Box marginLeft="auto" className="pr-1">
            <LinkerSummary direction={direction} state={summary} marginBottom="xs" />
          </Box>
        )}
      </div>
      <HeaderRow />
      <FilterForm filter={filterState} setFilter={setFilterState} disabled={false}>
        <FilterRow entities={props.entities} summary={summary} unlink={unlink} link={link} />
      </FilterForm>
      <Grid rows={rows} entities={props.entities} disabled={disabled} />
    </>
  );
}
