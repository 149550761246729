import { lazy, ReactElement, useMemo } from 'react';
import { Alert, Table, Tbody, Td, Tfoot, Th, Thead, Tr } from '@ff-it/ui';
import { sepFormat } from 'utilities';
import { ResponseData, Value } from './types';
import { numMonths, monthNames } from './const';

const Chart = lazy(() => import('./Chart').then((module) => ({ default: module.Chart })));

interface ReportTableProps {
  data: ResponseData;
  prop: keyof Value;
  slugs: string[];
}

const header = (
  <Thead>
    <Tr>
      <Th textAlign="start" />
      {numMonths.map((month) => (
        <Th key={month}>{monthNames[month]}</Th>
      ))}
      <Th>Total</Th>
    </Tr>
  </Thead>
);

function ReportTable({ data, prop, slugs }: ReportTableProps): ReactElement {
  return (
    <Table textAlign="right" variant="dark" fixed size="sm" marginBottom="lg">
      {header}
      <Tbody>
        {slugs.map((slug) => {
          const { months, total } = data.facets[slug];
          return (
            <Tr key={slug}>
              <Td textAlign="start">{slug}</Td>
              {numMonths.map((month) => {
                const value = months[month]?.[prop] || null;
                return (
                  <Td key={month}>
                    {sepFormat(value)}
                    {prop === 'profit_ratio' && value && '%'}
                  </Td>
                );
              })}
              <Td className="font-weight-bold">
                {sepFormat(total[prop])}
                {prop === 'profit_ratio' && '%'}
              </Td>
            </Tr>
          );
        })}
      </Tbody>
      <Tfoot>
        <Tr>
          <Td />
          {numMonths.map((month) => {
            const value = data.months[month]?.[prop] || null;
            return (
              <Td key={month}>
                {sepFormat(value)}
                {prop === 'profit_ratio' && value && '%'}
              </Td>
            );
          })}
          <Td fontWeight="bold">
            {sepFormat(data.total[prop])}
            {prop === 'profit_ratio' && '%'}
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  );
}

export function ReportRenderer({ data }: Pick<ReportTableProps, 'data'>): ReactElement {
  // sort by turnover?
  const orderedFactes = useMemo(() => {
    const keys = Object.keys(data.facets);
    keys.sort(
      (a, b) => Number.parseFloat(data.facets[b].total.turnover) - Number.parseFloat(data.facets[a].total.turnover),
    );
    return keys;
  }, [data.facets]);

  if (Object.keys(data.facets).length === 0) {
    return <Alert>There is nothing here.</Alert>;
  }

  return (
    <>
      <Chart data={data} facets={orderedFactes} />
      <h4 title="Client price + Agency fee">Turnover</h4>
      <ReportTable data={data} prop="turnover" slugs={orderedFactes} />
      <h4 title="Revenue including commisions">Profit</h4>
      <ReportTable data={data} prop="profit" slugs={orderedFactes} />
      <h4 title="Profit / Turnover">Profit %</h4>
      <ReportTable data={data} prop="profit_ratio" slugs={orderedFactes} />
    </>
  );
}
