import { ReactElement } from 'react';
import { withHasPerm } from 'components';
import { Type } from './Type';
import permissions from './permissions';
import { LocalVisibilityReport, ReportContainer } from '../common';

export function LocalType(): ReactElement {
  return (
    <LocalVisibilityReport
      localPerm={permissions.local}
      managedPerm={permissions.local_managed}
      Component={Type}
      title="Types"
    />
  );
}

export const GlobalType = withHasPerm(
  () => (
    <ReportContainer title="Types">
      <Type visibility="GLOBAL" />
    </ReportContainer>
  ),
  permissions.global,
);
