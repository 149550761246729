import { ReactElement } from 'react';
import { useField, SelectField, useOnFieldChange, DecimalField, PeriodPickerField } from '@ff-it/form';
import { useDefaultOptions } from 'components';
import { fmt, formatDate, parseBig } from 'utilities';
import { CurrencyRate } from 'modules/invoicing/common/types';
import { CompanyField } from 'modules/core/company/components';

export function InvoiceFields(): ReactElement | null {
  const {
    input: { value },
  } = useField('type', { subscription: { value: true } });
  if (value === 'INVOICE') {
    return (
      <>
        <CompanyField name="clients" label="Clients" isMulti isClearable={false} includeBranches={true} />
        <PeriodPickerField name="finance_period" label="Finance period" help="Month sums to apply" part="month" />
      </>
    );
  }
  return null;
}

const getOptionLabel = (v: any): string => (v ? v.currency : v);
const getOptionValue = (v: any): string => (v ? v.id.toString() : v);

export function CurrencyRateField({ className, disabled }: { className?: string; disabled?: boolean }): ReactElement {
  const {
    input: { value, onChange },
  } = useField('alt_currency', { subscription: { value: true } });

  const manual = value && !value.date;

  const checkout_date = useOnFieldChange('checkout_date', (newValue) => {
    // // Alt rate is present but date mismatch
    if (!manual && value && value.date !== newValue) {
      onChange(null);
    }
  });

  const defaultOptions: any = useDefaultOptions('core/rates/', { date: checkout_date }, checkout_date);

  const isDisabled = disabled || !checkout_date || manual;
  let help = undefined;
  if (manual) {
    help = `${value.rate} manual`;
  } else if (value) {
    help = `${value.rate} @ ${formatDate(value.date)}`;
  } else if (!checkout_date) {
    help = 'Set invoice date for ECB rate';
  }
  const props = {
    className,
    disabled: isDisabled,
    help,
    label: 'Foreign currency',
    placeholder: 'EUR',
    getOptionLabel,
    getOptionValue,
  };

  return (
    <SelectField<CurrencyRate>
      name="alt_currency"
      isClearable
      options={checkout_date ? defaultOptions : []}
      {...props}
    />
  );
}

export function AltTotalsFields({ external, rate }: { external?: boolean; rate: string }): ReactElement {
  const parsedCurrencyRate = parseBig(rate);

  const {
    input: { value: alt_vat_amount },
  } = useField('alt_vat_amount', { subscription: { value: true } });

  const {
    input: { value: alt_total },
  } = useField('alt_total', { subscription: { value: true } });

  const vatAmount = alt_vat_amount ? parseBig(alt_vat_amount).mul(parsedCurrencyRate).round(2, 1) : undefined;

  const totalAmount = alt_total ? parseBig(alt_total).mul(parsedCurrencyRate).round(2, 1) : undefined;

  return (
    <div className="form-row">
      <DecimalField
        name="alt_vat_amount"
        label="Foreign VAT amount"
        className="col-md-4"
        required
        disabled={external}
        help={vatAmount ? <strong data-testid="native_vat_amount">{fmt(vatAmount)} €</strong> : undefined}
      />
      <DecimalField
        name="alt_total"
        label="Foreign Total"
        className="col-md-4"
        required
        disabled={external}
        help={totalAmount ? <strong data-testid="native_total_amount">{fmt(totalAmount)} €</strong> : undefined}
      />
    </div>
  );
}

export function TotalsFields({ external }: { external?: boolean }): ReactElement {
  const {
    input: { value: alt_currency },
  } = useField('alt_currency', { subscription: { value: true } });

  if (alt_currency) {
    return <AltTotalsFields external={external} rate={alt_currency.rate} />;
  }

  return (
    <div className="form-row">
      <DecimalField name="vat_amount" label="VAT amount" className="col-md-4" disabled={external} />
      <DecimalField name="total" label="Total" className="col-md-4" disabled={external} />
    </div>
  );
}
