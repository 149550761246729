import { MutableRefObject, RefObject, useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';
import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';

type Edge = 'top' | 'bottom';

type Draggable = {
  elementRef: RefObject<any>;
  handleRef: MutableRefObject<any>;
  closestEdge: Edge | null;
  isDragging: boolean;
};

export function useDraggable(index: number, isDisabled: boolean, instanceId: string): Draggable {
  const elementRef = useRef<HTMLElement>(null);
  const handleRef = useRef<Element | undefined>(undefined);

  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  useEffect(() => {
    invariant(elementRef.current);
    const element = elementRef.current;
    const data = { index, instanceId };
    return combine(
      draggable({
        element,
        dragHandle: handleRef.current,
        canDrag: () => !isDisabled,
        getInitialData: () => data,
        onGenerateDragPreview({ nativeSetDragImage }) {
          disableNativeDragPreview({ nativeSetDragImage });
        },
        onDragStart() {
          setIsDragging(true);
        },
        onDrop() {
          setIsDragging(false);
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          return source.data.instanceId === instanceId;
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            // move after, unless it's first element
            allowedEdges: index === 0 ? ['top', 'bottom'] : ['bottom'],
          });
        },
        onDrag({ self, source }) {
          const isSource = source.element === element;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data) as Edge;

          const sourceIndex = source.data.index;
          invariant(typeof sourceIndex === 'number');

          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === 'bottom') || (isItemAfterSource && closestEdge === 'top');

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      }),
    );
  }, [index, isDisabled, instanceId]);

  return {
    elementRef,
    handleRef,
    closestEdge,
    isDragging,
  };
}

type ItemData = { index: number };

export function useDragMonitor(
  move: (source: ItemData, target: ItemData, edge: Edge) => void,
  instanceId: string,
): void {
  // biome-ignore lint/correctness/useExhaustiveDependencies: move stable, run once
  useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        return source.data.instanceId === instanceId;
      },
      onDrop({ location, source }) {
        const target = location.current.dropTargets[0];
        if (!target) {
          return;
        }

        const sourceData = source.data as { index: number };
        const targetData = target.data as { index: number };
        const closestEdgeOfTarget = extractClosestEdge(targetData) as Edge;
        move(sourceData, targetData, closestEdgeOfTarget);
      },
    });
  }, []);
}
