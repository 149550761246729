import { createContext, useContext } from 'react';
import type { RowType, TableController } from './types';
import invariant from 'tiny-invariant';

export const TableContext = createContext<TableController<any> | undefined>(undefined);

export function useTable<T extends RowType>(): TableController<T> {
  const c = useContext(TableContext);
  invariant(c, 'ControlledTable missing');
  return c;
}
