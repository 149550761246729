import { ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import { NavLink, NavLinkProps } from 'react-router-dom';
import './SubNav.scss';
import { MenuItem, MenuList, MenuSeparator, Popover } from '@ff-it/ui';

type ButtonProps = {
  className?: string;
  children: ReactNode;
  onClick: () => void;
};

interface DropdownProps {
  className?: string;
  children: ReactNode;
  items: (ButtonProps | '-')[];
}

export type SubNavItem = NavLinkProps | DropdownProps;
interface NavTabProps {
  nav: SubNavItem[];
  className?: string;
  variant?: 'tabs' | 'pills';
  children?: ReactNode;
}

export function TabNav({ nav, className, variant = 'tabs', children }: NavTabProps): ReactElement {
  return (
    <ul className={cx(`nav nav-${variant}`, className)}>
      {nav.map(({ className, ...props }, idx) => {
        if ('items' in props) {
          const { children, items } = props;
          return (
            <li key={idx} className="nav-item dropdown">
              <Popover
                placement="bottom-start"
                content={
                  <MenuList>
                    {items.map((item, idx) => {
                      if (item === '-') {
                        return <MenuSeparator key={idx} />;
                      }
                      const { children: label, ...rest } = item;
                      return <MenuItem key={idx} {...rest} label={label} />;
                    })}
                  </MenuList>
                }
              >
                <a className={cx('nav-link dropdown-toggle')} href="#" role="button">
                  {children}
                </a>
              </Popover>
            </li>
          );
        }
        return (
          <li className="nav-item" key={idx}>
            <NavLink className={cx('nav-link', className)} {...props} />
          </li>
        );
      })}
      {children}
    </ul>
  );
}

interface SubNavProps extends NavTabProps {
  children?: ReactNode;
}

export function SubNav({ children, nav, className }: SubNavProps): ReactElement {
  return (
    <div className={cx('container-fluid mb-2 sub-nav', className)}>
      {children}

      {nav.length > 0 && <TabNav nav={nav} className="mr-auto" />}
    </div>
  );
}
