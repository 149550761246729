import { ReactElement } from 'react';
import type { ApplicationSummaryState } from './types';
import type { InvoiceDirection } from 'modules/invoicing/common/types';
import { SummaryContainer, SummaryField } from 'modules/invoicing/common';
import { InvoiceCheck } from 'modules/invoicing/common/components/InvoiceCheck';

type ApplicationSummaryProps = {
  direction: InvoiceDirection;
  state: ApplicationSummaryState;
};

export function ApplicationSummary({
  direction,
  state: { amount, applied, balance },
}: ApplicationSummaryProps): ReactElement {
  return (
    <SummaryContainer direction={direction} marginBottom="md" testId="application-summary">
      <SummaryField label="Amount" testId="amount">
        {amount.toFixed(2)}
      </SummaryField>
      <SummaryField label="Applied" testId="applied">
        {applied.mul(-1).toFixed(2)}
      </SummaryField>
      <SummaryField label="Balance" testId="balance" display="flex" alignItems="center">
        {balance.toFixed(2)}{' '}
        <InvoiceCheck direction={direction} marginLeft="sm" state={balance.eq(0) ? 'success' : 'warn'} />
      </SummaryField>
    </SummaryContainer>
  );
}
