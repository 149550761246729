import { ItemLoader, NotFound, UpdateContainer, useEntity, useModel } from 'components';
import { useHasPerm } from 'core/permissions';
import { TermsForm } from 'modules/client/agreement/TermsForm';
import { ClientAgreement } from 'modules/client/agreement/types';
import { ReactElement } from 'react';
import { useParams } from 'react-router';
import { ActivityType, activityTypes } from 'types';
import { mutate as globalMutate } from 'swr';
export function Terms(): ReactElement {
  const { type } = useParams<{ type: ActivityType }>();
  if (!type || !activityTypes.includes(type)) {
    return <NotFound />;
  }

  return <TermsContainer type={type} />;
}

function TermsContainer({ type }: { type: ActivityType }): ReactElement {
  const { permissions } = useModel();
  const { endpoint } = useEntity<ClientAgreement>();
  const hasUpdate = useHasPerm(permissions?.change);

  const url = `${endpoint}type_terms/${type}/`;

  return (
    <ItemLoader<any> url={url}>
      {({ mutate, item }) => (
        <UpdateContainer
          url={url}
          initialValues={item}
          updateItem={async (value) => {
            await mutate(value, { revalidate: false });
            // refetch agreement to update types
            await globalMutate({ method: 'GET', url: endpoint });
          }}
          method="POST"
          disabled={!hasUpdate}
        >
          <TermsForm type={type} />
        </UpdateContainer>
      )}
    </ItemLoader>
  );
}
