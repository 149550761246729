import { ReactElement } from 'react';
import { LoadingContainer } from '@ff-it/ui';
import { useEntity } from 'components';
import { BlockDetails } from 'modules/campaign/block/types';
import { CampaignDetails } from '../types';
import { CampaignOverview } from './CampaignOverview';
import useSWR from 'swr';

export function Overview(): ReactElement {
  const ctx = useEntity<CampaignDetails>();

  const {
    data: blocks,
    mutate,
    isLoading,
  } = useSWR<BlockDetails[]>({
    url: `${ctx.endpoint}blocks/`,
    method: 'GET',
  });

  return (
    <LoadingContainer loading={isLoading} className="flex-grow-1 flex-column d-flex">
      {blocks && (
        <CampaignOverview ctx={ctx} blocks={blocks} setBlocks={(v) => mutate(mutate, { revalidate: false })} />
      )}
    </LoadingContainer>
  );
}
