import { Link } from 'react-router-dom';
import { Progress, TableColumns } from '@ff-it/ui';
import type { Guarantee } from 'modules/guarantee/types';
import { dateTo } from 'components/columns';
import { sepFormat } from 'utilities';

function progressVariant(progress: number, value: number): 'danger' | 'warning' | 'success' {
  if (value > progress) {
    return 'success';
  }
  if (progress - value >= 10) {
    return 'danger';
  }
  return 'warning';
}

export const columns: TableColumns<Guarantee> = [
  {
    content: 'Number',
    key: 'number',
    width: 90,
    render: ({ id, number }) => <Link to={`${id}`}>{number}</Link>,
  },
  {
    content: 'Title',
    key: 'title',
  },
  {
    content: 'Type',
    key: 'type',
  },
  // dateFrom(),
  dateTo(),
  {
    content: 'Time left',
    key: 'execution.time_left',
    cellProps: { textAlign: 'end' },
    width: 120,
    sortable: true,
    render: ({ execution: { time_left } }: any) => `${time_left}%`,
  },
  {
    content: 'Target, €',
    cellProps: { textAlign: 'end' },
    key: 'execution.target',
    render: ({ execution: { target } }) => sepFormat(target),
    width: 160,
    sortable: true,
  },
  {
    content: 'Planned, €',
    key: 'execution.planned',
    render: ({ execution: { planned } }) => sepFormat(planned),
    cellProps: { textAlign: 'end' },
    width: 160,
    sortable: true,
  },
  {
    content: 'Approved',
    key: 'execution.approved_progress',
    width: 140,
    sortable: true,
    render: ({ execution: { time_progress, approved_progress } }: Guarantee) =>
      approved_progress ? (
        <Progress
          variant={progressVariant(Number.parseFloat(time_progress), Number.parseFloat(approved_progress))}
          value={Number.parseFloat(approved_progress)}
        />
      ) : null,
  },
  {
    content: 'Approved,  €',
    key: 'execution.approved',
    cellProps: { textAlign: 'end' },
    width: 160,
    sortable: true,
    render: ({ execution: { approved } }: Guarantee) => sepFormat(approved),
  },
];
