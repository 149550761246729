import { useCallback, useRef, useState, useEffect } from 'react';
import { useDebounce } from '@ff-it/ui';
import { RequestSuccess, RequestArgs } from '@ff-it/api';
import { OptionLoader, Page, RequestHandler, ResponseHandler, LoaderOptions } from './types';
import { checkActionError } from 'utilities';
import { api } from 'services';
export type { OptionLoader, ResponseHandler };

export const defaultRequestHandler: RequestHandler = (
  url: string,
  inputValue: string | undefined,
  filter?: Record<string, unknown>,
  suggestion?: boolean,
  pageSize = 20,
): RequestArgs => ({
  url,
  method: 'GET',
  queryParams: {
    [suggestion ? 'suggestion' : 'search']: inputValue,
    ...filter,
    page_size: pageSize,
  },
});

export const defaultResponseHandler: ResponseHandler = (result: RequestSuccess<Page<any>>): any[] =>
  result.data.results;

export function useOptionLoader<T, R = Page<T>>(
  url: string,
  filter?: Record<string, unknown>,
  options: LoaderOptions = {},
): OptionLoader<T> {
  const {
    requestHandler = defaultRequestHandler,
    responseHandler = defaultResponseHandler,
    suggestion,
    pageSize = 20,
  } = options;

  // biome-ignore lint/correctness/useExhaustiveDependencies: handlers should be stable
  return useCallback(
    (inputValue: string | undefined, cb: any) => {
      api.request<R, unknown>(requestHandler(url, inputValue, filter, suggestion, pageSize)).then((res) => {
        if (res.ok) {
          cb(responseHandler(res));
        } else {
          throw res.error;
        }
      });
    },
    [url, filter],
  );
}

export function useOptions<T, R = Page<T>>(
  url: string,
  filter?: Record<string, unknown>,
  options: LoaderOptions = {},
): OptionLoader<T> {
  return useDebounce(useOptionLoader<T, R>(url, filter, options), 300);
}

export function useDefaultOptions<T>(url: string | null, filter?: Record<string, unknown>, ...deps: any[]): T[] {
  const isMounted = useRef<boolean>(true);

  const [result, setResult] = useState<T[]>([]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: external deps
  const loadOptions = useCallback(async (url: string) => {
    // reset
    if (isMounted.current && result.length !== 0) {
      setResult([]);
    }
    const res = await api.get<Page<T>, unknown>(url, {
      queryParams: {
        page_size: 20,
        ...filter,
      },
    });
    if (isMounted.current) {
      if (res.ok) {
        setResult(res.data.results);
      } else {
        if (checkActionError(res)) {
          setResult([]);
        } else {
          setResult(() => {
            throw res.error;
          });
        }
      }
    }
  }, deps);

  useEffect(() => {
    isMounted.current = true;
    if (url) {
      loadOptions(url);
    }
    return () => {
      isMounted.current = false;
    };
  }, [loadOptions, url]);

  return result;
}
