import { Box } from '@ff-it/ui';
import { usePeriod } from '../usePeriod';
import { useScopedActivityTypeOptions } from '../useScopedActivityTypeOptions';
import { Revenue } from './Revenue';
import permissions from './permissions';
import { DatePartPickerField, withHasPerm } from 'components';

export const LocalRevenue = withHasPerm(() => {
  const types = useScopedActivityTypeOptions(false);
  const { date_from: minDate, date_to: maxDate } = usePeriod();
  return (
    <Revenue
      storageKey="reprot_revenue_local"
      types={types}
      url="reporting/revenue/local/"
      filterFields={
        <Box marginLeft="md" marginRight="lg" display="flex" alignItems="center" className="form-group">
          <Box component="label">Compare to:</Box>
          <DatePartPickerField part="year" name="cmp_year" minDate={minDate} maxDate={maxDate} className="ml-2 mb-0" />
        </Box>
      }
    />
  );
}, permissions.local);
