import { DecimalField, TextField } from '@ff-it/form';
import { DepartmentField } from 'modules/supplier/department/DepartmentField';
import React from 'react';
import { required } from 'utilities';

export function FactorForm({ update }: { update?: boolean }): React.ReactElement {
  return (
    <div>
      <DepartmentField
        name="department"
        label="Department"
        required
        validate={required}
        menuPortalTarget={document.body}
        disabled={update}
      />
      <div className="form-row">
        <TextField name="name" label="Name" required validate={required} className="col-9" />
        <DecimalField precision={2} name="rate" label="Rate" required validate={required} className="col-3" />
      </div>
      <TextField name="description" label="Description" multiline autosize />
    </div>
  );
}
