import { ComponentType, ReactElement, ReactNode } from 'react';
import { AddButton, AttachmentField, FormInlineError } from 'components';
import { TextField, DateRangePickerField, SelectField, useIsDisabled, useField } from '@ff-it/form';
import { agreementKindOptions, regularityOptions } from 'configuration';
import { useFieldArray } from 'react-final-form-arrays';
import { Perm, useHasPerm } from 'core/permissions';
import { CompanyField } from 'modules/core/company/components';
import { Bonus } from 'modules/guarantee/types';
import { fmtMonth } from 'utilities';

interface BonusFormProps {
  children?: ReactNode;
  sensitive: Perm;
}

export function BonusForm({ sensitive, children }: BonusFormProps): ReactElement {
  const canViewSensitive = useHasPerm(sensitive);
  const {
    input: { value: finance_period },
  } = useField<Bonus['finance_period']>('finance_period', { subscription: { value: true } });
  const periodHelp = finance_period ? `${fmtMonth(finance_period.start)} - ${fmtMonth(finance_period.end)}` : undefined;
  return (
    <div className="container px-0 mb-5 ml-0">
      <div className="form-row">
        <TextField name="title" label="Title" className="col-md-3" required />
        <CompanyField
          name="partner"
          label="Partner"
          className="col-md-3"
          required
          help="Payer"
          includeBranches={true}
        />
        <TextField name="number" label="Number" className="col-md-3" help="Leave blank to assign" />
        <DateRangePickerField
          label="Period"
          fromName="date_from"
          toName="date_to"
          className="col-md-3"
          required
          help={periodHelp}
        />
      </div>
      <div className="form-row">
        <SelectField
          label="Regularity"
          name="regularity"
          options={regularityOptions}
          simple
          required
          className="col-md-3"
        />
        <SelectField label="Kind" name="kind" options={agreementKindOptions} simple required className="col-md-3" />
      </div>
      {canViewSensitive && (
        <>
          <div className="form-row">
            <div className="col-md-6">
              <AttachmentField name="attachments" label="Attachments" />
            </div>
            <div className="col-md-6"></div>
          </div>

          <div className="form-row">
            <TextField
              name="terms"
              className="col-6"
              label="Terms and conditions"
              help="General terms and conditions"
              multiline
              autosize
              rows={2}
            />
            <TextField
              name="notes"
              className="col-6"
              label="Notes"
              help="Private notes for internal use"
              multiline
              autosize
              rows={2}
            />
          </div>
        </>
      )}

      <hr />
      <FormInlineError name="rates" />
      {children}
    </div>
  );
}

export interface RowProps {
  name: string;
  remove: (index: number) => void;
  disabled?: boolean;
  index: number;
}

// TODO
const emptyRow = {
  provider: null,
  type: null,
  activity: null,
  department: null,
  client: null,
  payer: null,
  current: null,
  target: null,
  final: null,
};

interface RowsProps {
  Row: ComponentType<RowProps>;
}

export function Rows({ Row }: RowsProps): ReactElement {
  const disabled = useIsDisabled();
  const { fields } = useFieldArray('rates', { subscription: {} });
  return (
    <tbody>
      {fields.map((name, index) => (
        <Row key={index} name={name} remove={fields.remove} index={index} />
      ))}
      <tr>
        <td colSpan={8}>
          <AddButton
            disabled={disabled}
            testId="add-inline"
            onClick={() => {
              fields.push(emptyRow);
            }}
          >
            add row
          </AddButton>
        </td>
        <td className="tools"></td>
      </tr>
    </tbody>
  );
}
