import { ReactElement } from 'react';
import { TextField } from '@ff-it/form';
import { MonthDayField } from 'components';

export function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="first_name" label="First name" className="col-3" required />
        <TextField name="last_name" label="Last name" className="col-3" required />
        <MonthDayField name="birth_day" label="Birthday" className="col-3" />
        <MonthDayField name="name_day" label="Nameday" className="col-3" />
      </div>

      <div className="form-row">
        <TextField name="email" label="Email" className="col-3" type="email" />
        <TextField name="mobile_phone" label="Mobile phone" className="col-3" />
        <TextField name="work_phone" label="Work phone" className="col-3" />
      </div>
      <div className="form-row">
        <TextField name="position" label="Position" className="col" multiline autosize />
        <TextField name="address" label="Address" className="col" multiline autosize />
      </div>
      <div className="form-row">
        <TextField name="notes" label="Notes" className="col" multiline autosize />
      </div>
    </div>
  );
}
