import { Check, checkPerm, all, any, Perm } from 'core/permissions';
import campaign from 'modules/campaign/campaign/permissions';
import { activityTypeOptions } from 'options';
import { AuthState } from 'services';

export function createViewLocal(local: Perm, localManaged: Perm): Check {
  return (sess: AuthState) => {
    return (
      // view local
      checkPerm(sess, local) ||
      // view local manage
      checkPerm(sess, all(localManaged, campaign.manage)) ||
      // view local type
      checkPerm(
        sess,
        all(localManaged, any(...activityTypeOptions.map(({ value }) => `campaign.manage_${value.toLowerCase()}`))),
      )
    );
  };
}
