import { createSelectField } from './createSelectField';
import type { LookupOp } from 'modules/guarantee/types';
import type { Option } from 'options';

const conditionOptions: Array<Option<LookupOp>> = [
  { label: 'AND (Include)', value: 'AND' },
  { label: 'NOT (Exclude)', value: 'NOT' },
];

export const LookupOperatorField = createSelectField<Option<LookupOp>>(conditionOptions, {
  simple: true,
  isClearable: false,
  label: 'Condition',
});
