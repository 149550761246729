import { Badge, Heading, useEntity } from 'components';
import type { ReactElement } from 'react';
import type { Ticket } from 'modules/support/types';

export function Header(): ReactElement {
  const {
    item: { id, state, title },
  } = useEntity<Ticket>();
  return (
    <Heading>
      <h1>
        <Badge state={state} />
        <span className="text-muted">#{id}</span>
        {title}
      </h1>

      <div className="btn-toolbar"></div>
    </Heading>
  );
}
