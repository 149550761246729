import avb from 'modules/guarantee/avb/permissions';
import cvb from 'modules/guarantee/cvb/permissions';
import { agencyPermissions, clientPermissions } from './permissions';

export const menu = {
  to: '/guarantee',
  label: 'Guarantees',
  icon: 'handshake' as const,
  children: [
    { to: '/guarantee/avb', label: 'AVB', visible: avb?.view },
    { to: '/guarantee/cvb', label: 'CVB', visible: cvb?.view },
    { to: '/guarantee/agency', label: 'Agency', visible: agencyPermissions?.view },
    { to: '/guarantee/client', label: 'Client', visible: clientPermissions?.view },
  ],
};
