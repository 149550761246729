import Big from 'big.js';
import { endOfMonth, formatISO, parseISO } from 'date-fns';
import invariant from 'tiny-invariant';
import type { Unit } from 'types';
import { parseBig } from 'utilities';
import type { Facet, Template, Selection, BuilderBlock, MonthAmount } from './types';
import { EntityMap, NetLinkable, LinkerContext } from '../types';
import { InvoiceRow } from 'modules/invoicing/income/types';

export function canExpandFee(facet: Facet): boolean {
  return ['BLOCK', 'DEPARTMENT', 'ACTIVITY'].includes(facet);
}

export function canAppendComment(facet: Facet): boolean {
  return facet === 'ROW';
}

export function canAltActivitName(facet: Facet): boolean {
  return ['ROW', 'ACTIVITY'].includes(facet);
}

export function canTransparentUnits(facet: Facet): boolean {
  return facet === 'ROW';
}

export function canAppendPONumbers(facet: Facet): boolean {
  return facet === 'BLOCK';
}

export function rowKey({ facet, expandFees }: Template, linkable: NetLinkable, entities: EntityMap): string {
  const activity = entities.activity[linkable.activity_id];
  if (expandFees && canExpandFee(facet) && activity.is_fee) {
    return `fee_${linkable.id}`;
  }

  switch (facet) {
    case 'BLOCK':
      return linkable.block_id.toString();

    case 'ROW':
      return linkable.id.toString();

    case 'DEPARTMENT': {
      const product = entities.product[linkable.product_id];
      return product.department.id.toString();
    }

    case 'ACTIVITY': {
      const block = entities.block[linkable.block_id];
      return `${block.type}_${linkable.activity_id}`;
    }

    default:
      throw new Error(`invalid facet ${facet}`);
  }
}

export function rowTitle(
  { facet, expandFees, appendComment, altActivityName, appendPONumbers }: Template,
  linkable: NetLinkable,
  context: EntityMap,
): string {
  const block = context.block[linkable.block_id];
  const activity = context.activity[linkable.activity_id];
  const product = context.product[linkable.product_id];
  const department = product.department;

  let parts: string[];

  const ativityName =
    altActivityName && canAltActivitName(facet) && activity.name_alternative
      ? activity.name_alternative
      : activity.name;

  if (expandFees && canExpandFee(facet) && activity.is_fee) {
    // same as row
    parts = [block.type, ativityName, department.name];
  } else {
    switch (facet) {
      case 'BLOCK':
        parts = [block.type];
        block.title && parts.push(block.title);
        parts.push(block.code);
        appendPONumbers && block.final_client_po_number && parts.push(block.final_client_po_number);
        break;

      case 'ROW':
        parts = [block.type, ativityName, department.name];
        if (appendComment && linkable.comment) {
          parts.push(linkable.comment);
        }
        break;

      case 'DEPARTMENT':
        parts = [department.name];
        break;

      case 'ACTIVITY':
        parts = [block.type, ativityName];
        break;

      default:
        throw new Error(`invalid facet ${facet}`);
    }
  }
  return parts.join(' - ');
}

type RowAmount = {
  title: string;
  unit: Unit;
  index: number;
  amounts: MonthAmount[];
};

function mapNaiveRow({ title, amounts }: RowAmount): InvoiceRow {
  const value = amounts.reduce((acc, v) => acc.plus(v.invoiceable), Big(0)).toFixed(2);
  return {
    title,
    unit: 'UNIT',
    quantity: '1',
    unit_price: value,
    kind: 'AMOUNT',
    compensated_to: null,
  };
}

function mapTransparentRow(rowAmount: RowAmount): InvoiceRow {
  const { title, unit, amounts } = rowAmount;
  let totalAmount = Big(0);
  let totalQuantity = Big(0);

  for (let i = 0; i < amounts.length; i++) {
    const { quantity, invoiceable, target } = amounts[i];
    // no quanity, or something invoiced
    if (!quantity || quantity === '0.00' || !invoiceable.eq(target)) {
      return mapNaiveRow(rowAmount);
    }
    totalAmount = totalAmount.plus(invoiceable);
    totalQuantity = totalQuantity.plus(parseBig(quantity));
  }

  // quantity = models.DecimalField(max_digits=20, decimal_places=4, default=1)
  // unit_price = models.DecimalField(max_digits=32, decimal_places=16)

  const rawUnitPrice = totalAmount.minus(Big('0.005')).div(totalQuantity);
  totalQuantity = totalQuantity.round(4, 1);

  let unitPrice = null;
  let precision = 2;
  while (precision <= 16) {
    const currentPrice = rawUnitPrice.round(precision, 1);
    if (currentPrice.mul(totalQuantity).round(2, 1).eq(totalAmount)) {
      unitPrice = currentPrice;
      break;
    }
    precision++;
  }
  if (!unitPrice) {
    return mapNaiveRow(rowAmount);
  }

  return {
    title,
    unit,
    quantity: totalQuantity.toFixed(),
    unit_price: unitPrice.toFixed(),
    kind: 'AMOUNT',
    compensated_to: null,
  };
}

export function buildInvoice(
  { linkable, entities }: LinkerContext,
  blocks: BuilderBlock[],
  selection: Selection,
  template: Template,
  currentRows: InvoiceRow[],
): {
  bound: Record<string, string>;
  rows: InvoiceRow[];
  date_from: string | null;
  date_to: string | null;
} {
  const seenBound: Map<string, Big> = new Map();

  const rowAmounts: Map<string, RowAmount> = new Map();

  let dateFrom: Date | null = null;
  let dateTo: Date | null = null;

  Object.keys(selection).forEach((blockId) => {
    const block = blocks.find((block) => block.id === blockId);
    selection[blockId as any].forEach((month) => {
      const start = parseISO(month);
      if (!dateFrom || dateFrom > start) {
        dateFrom = start;
      }
      const end = endOfMonth(start);
      if (!dateTo || dateTo < end) {
        dateTo = end;
      }
      const amounts = block?.months[month]?.amounts;

      amounts &&
        Object.keys(amounts).forEach((linkableKey: string) => {
          const rowIndex = linkable.findIndex((l) => l.id === linkableKey);
          invariant(rowIndex > -1, 'row not found');
          const likableRow = linkable[rowIndex];

          const monthAmount = amounts[linkableKey];
          const amountValue = monthAmount.invoiceable;

          // collect bound
          const bound = seenBound.get(linkableKey);
          seenBound.set(linkableKey, bound ? bound.plus(amountValue) : amountValue);

          // collect rows
          const invoiceKey = rowKey(template, likableRow, entities);

          const row = rowAmounts.get(invoiceKey);
          rowAmounts.set(
            invoiceKey,
            row
              ? {
                  ...row,
                  amounts: [...row.amounts, monthAmount],
                }
              : {
                  title: rowTitle(template, likableRow, entities),
                  index: rowIndex,
                  unit: likableRow.unit,
                  amounts: [monthAmount],
                },
          );
        });
    });
  });

  const bound = Array.from(seenBound).reduce((acc: any, [key, value]) => {
    acc[key] = value.toFixed(2);
    return acc;
  }, {});

  const transparent = canTransparentUnits(template.facet) && template.transparentUnits;

  const rows = Array.from(rowAmounts.values());
  rows.sort((a, b) => a.index - b.index);

  return {
    bound,
    rows: rows
      .map(transparent ? mapTransparentRow : mapNaiveRow)
      .concat(currentRows.filter((r) => r.kind === 'COMPENSATION')),
    date_from: dateFrom ? formatISO(dateFrom, { representation: 'date' }) : null,
    date_to: dateTo ? formatISO(dateTo, { representation: 'date' }) : null,
  };
}
