import type { ReactElement, ReactNode } from 'react';
import { Box, Sprinkles } from '@ff-it/ui';
import * as style from './style.css';
import { InvoiceDirection } from 'modules/invoicing/common/types';

type SummaryContainerProps = {
  direction: InvoiceDirection;
  children?: ReactNode;
  testId?: string;
} & Sprinkles;

export function SummaryContainer({ direction, children, testId, ...rest }: SummaryContainerProps): ReactElement {
  return (
    <Box className={style.container[direction]} data-testid={testId} {...rest}>
      {children}
    </Box>
  );
}

type SummaryFieldProps = {
  label: ReactNode;
  children: ReactNode;
  testId?: string;
} & Sprinkles;

export function SummaryField({ label, children, testId, ...rest }: SummaryFieldProps): ReactElement {
  return (
    <Box className={style.cell}>
      <Box className={style.label}>{label}</Box>
      <Box data-testid={testId} marginLeft="md" {...rest}>
        {children}
      </Box>
    </Box>
  );
}
