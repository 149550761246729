import { useField } from 'react-final-form';

export function useFieldValue<V = any>(
  name: string,
  config?: {
    allowNull?: boolean;
  },
): V {
  const {
    input: { value },
  } = useField<V>(name, { subscription: { value: true }, ...config });

  return value;
}
