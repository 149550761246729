import cx from 'clsx';
import { type ReactElement } from 'react';
import { TextField, PeriodPickerField } from '@ff-it/form';
import type { Period } from 'types';
import { fmtMonth } from 'utilities';

type LinkerFieldsProps = {
  className?: string;
  source_period?: Period | null;
};

function PeriodHelp({ start, end }: Period): ReactElement {
  return (
    <span>
      Agreement: {fmtMonth(start)} - {fmtMonth(end)}
    </span>
  );
}

export function LinkerFields({ className, source_period }: LinkerFieldsProps): ReactElement {
  const help = source_period ? <PeriodHelp {...source_period} /> : undefined;
  return (
    <div className={cx('form-row', className)}>
      <PeriodPickerField
        name="finance_period"
        placeholder="Finance period"
        className="col-md-4 mb-0"
        part="month"
        size="sm"
        help={help}
      />
      <TextField
        name="link_notes"
        placeholder="Link notes for internal use. Wont be visibile in invoice."
        className="col-md-8"
        size="sm"
        autosize
        multiline
      />
    </div>
  );
}
