import type { ReactElement } from 'react';
import { DecimalField, SelectField, TextField, useField } from '@ff-it/form';
import { FormInlineAdd, TableInlineColumn, TableInlines } from 'components';
import { TemplateNotice } from 'modules/invoicing/common';
import { Sidebar } from 'modules/invoicing/common/Sidebar';
import type { Income } from 'modules/invoicing/income/types';
import { initialRow } from 'modules/invoicing/income/initialValues';
import { Fields } from './Fields';
import { CreditSource } from './CreditSource';
import { ImplicitCompensation } from 'modules/applicator/ImplicitCompensation';
import { Box, Col, Row } from '@ff-it/ui';
import { controlCol, sumCol } from 'styles/style.css';
import { InvoiceRowKindIcon } from 'modules/invoicing/income/InvoiceRowKindIcon';
import { InvoiceWidget } from 'modules/invoicing/common/InvoiceWidget';
import { rtbUnitOptions } from 'options';
import { fmt } from 'utilities';
import { getRowTotal } from 'modules/invoicing/income/calculate';
import Totals from './Totals';

interface FormProps {
  update?: boolean;
}

const columns: TableInlineColumn[] = [
  {
    key: 'kind',
    cellProps: {
      className: controlCol,
    },
    render: ({ value: { kind } }) => <InvoiceRowKindIcon kind={kind} />,
  },
  {
    key: 'title',
    content: 'Title',
    render: ({ name, value }) => {
      const destination = value.compensated_to?.destination;
      return (
        <Row alignItems="center">
          {destination && (
            <Col width="1/5" marginRight="md">
              <InvoiceWidget link invoice={destination} />
            </Col>
          )}
          <Col>
            <TextField name={`${name}.title`} required className="mb-0" />
          </Col>
        </Row>
      );
    },
  },
  {
    key: 'quantity',
    content: 'Quantity',
    cellProps: { className: sumCol },
    render: ({ name }) => <DecimalField name={`${name}.quantity`} precision={4} required className="mb-0" />,
  },
  {
    key: 'unit',
    content: 'Unit',
    cellProps: { className: sumCol },
    render: ({ name }) => (
      <SelectField name={`${name}.unit`} options={rtbUnitOptions} required simple className="mb-0" />
    ),
  },
  {
    key: 'unit_price',
    content: 'Unit price',
    cellProps: { className: sumCol },
    render: ({ name }) => <DecimalField name={`${name}.unit_price`} precision={16} required className="mb-0" />,
  },
  {
    key: 'total',
    content: 'Total',
    cellProps: { className: sumCol, textAlign: 'right' },
    render: ({ value }) => fmt(getRowTotal(value)),
  },
];

export function Form({ update }: FormProps): ReactElement {
  const {
    input: { value: creditSource, onChange: setCreditSource },
  } = useField<Income>('credit_source', {
    subscription: { value: true },
    allowNull: true,
  });
  const creditLock = Boolean(creditSource);
  const {
    input: { value: type },
  } = useField('type', { subscription: { value: true } });

  return (
    <div className="d-flex flex-column flex-xl-row">
      <div className="container px-0 ml-0">
        {creditSource && <CreditSource invoice={creditSource} clear={() => setCreditSource(null)} />}
        <Fields type={type} lockType={update || creditLock} lockPayer={creditLock} />

        <div className="mb-3">
          <TemplateNotice />
          <TableInlines
            name="rows"
            // min={1}
            columns={columns}
            actions={
              <>
                <FormInlineAdd
                  testId="add-amount"
                  icon="paper-plane"
                  name="rows"
                  initialValues={{ ...initialRow, kind: 'AMOUNT' }}
                  addLabel="Add row"
                />
                <ImplicitCompensation />
              </>
            }
            tfoot={<Totals />}
          />

          <Box marginTop="sm"></Box>
        </div>
      </div>

      {update && <Sidebar />}
    </div>
  );
}
