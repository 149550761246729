import { ReactElement } from 'react';
import { EnterpriseField } from 'components';
import { DateRangePickerField, TextField } from '@ff-it/form';
import { ExternalSums } from './ExternalSums';

export function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <DateRangePickerField fromName="date_from" toName="date_to" label="Period" className="col-md-3" required />
        <EnterpriseField name="agency" label="Agency" className="col-md-3" required />
      </div>
      <div className="form-row">
        <TextField name="comment" className="col-md-6" label="Comment" rows={2} autosize multiline />
      </div>
      <h5>Sums</h5>

      <ExternalSums />
    </div>
  );
}
