import { createSelectField } from './createSelectField';
import type { EmbeddedUser } from 'modules/core/types';
import { UserTitle } from '../../components/UserTitle';

export const UserField = createSelectField<EmbeddedUser>('auth/users/', {
  getOptionLabel: (a: EmbeddedUser) => a.email,
  getOptionValue: (a: EmbeddedUser) => a.id.toString(),
});

export const ExtendedUserField = createSelectField<EmbeddedUser>('auth/users/', {
  getOptionLabel: (a: EmbeddedUser) => a.email,
  getOptionValue: (a: EmbeddedUser) => a.id.toString(),
  formatOptionLabel: (u: EmbeddedUser) => <UserTitle key={u.id} {...u} />,
});
