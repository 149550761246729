import { any } from 'core/permissions';

export const clientPermissions = {
  view: any(
    'guarantee.view_guarantee',
    'guarantee.report_client',
    'guarantee.report_client_managed',
    'guarantee.report_client_managed_type',
  ),
  add: 'guarantee.add_guarantee',
  change: 'guarantee.change_guarantee',
  delete: 'guarantee.delete_guarantee',
  sensitive_info: 'guarantee.sensitive_info_guarantee',
};

export const agencyPermissions = {
  view: any(
    'guarantee.view_guarantee',
    'guarantee.report_agency',
    'guarantee.report_agency_managed',
    'guarantee.report_agency_managed_type',
  ),
  add: 'guarantee.add_guarantee',
  change: 'guarantee.change_guarantee',
  delete: 'guarantee.delete_guarantee',
  sensitive_info: 'guarantee.sensitive_info_guarantee',
};
