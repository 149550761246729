import { ReactElement } from 'react';
import { SelectField } from '@ff-it/form';
import { InvoiceType } from 'modules/invoicing/common/types';

const kindOptions = [
  {
    value: 'fakturrekins',
    label: 'Faktūrrēķins',
  },
  {
    value: 'invoice',
    label: 'Invoice',
  },
  {
    value: 'credit',
    label: 'Kredītrēķins',
  },
];

const endpointOptions = [
  {
    value: 'income',
    label: 'income',
  },
  {
    value: 'expense',
    label: 'expense',
  },
  {
    value: 'advance',
    label: 'advance',
  },
];

interface DocConfig {
  id?: number;
  type: InvoiceType;
  domain: 'LOCAL' | 'EU' | 'OTHER';
  kind: string;
  endpoint: string;
}

export interface DocumentProps {
  items: DocConfig[];
}

export function Documents({ items }: DocumentProps): ReactElement {
  return (
    <>
      <h5>Documents</h5>
      <table className="table form-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Domain</th>
            <th>Kind</th>
            <th>Endpoint</th>
          </tr>
        </thead>
        <tbody>
          {items.map(({ type, domain }, i) => (
            <tr key={i}>
              <td className="align-middle">{type}</td>
              <td className="align-middle">{domain}</td>
              <td>
                <SelectField name={`doc[${i}].kind`} options={kindOptions} simple size="sm" />
              </td>
              <td>
                <SelectField name={`doc[${i}].endpoint`} options={endpointOptions} simple size="sm" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
