import { ReactElement, ReactNode } from 'react';
import { EditorProps } from './types';
import { CellDropdown } from './CellDropdown';
import ReactSelect from 'react-select';
import { Dummy, selectProps } from './selectEditor';
import { CheckboxOption } from 'components/Select';
import { controlLabel, editorChoice, editorContainer } from './styles.css';
import { Box, Button } from '@ff-it/ui';
import { Icon } from 'components/ui';

type SelectProps<V> = {
  options: V[];
  value: V[];
  getOptionLabel: (v: any) => string;
  getOptionValue: (v: any) => string;
  formatOptionLabel?: (v: any) => ReactNode;
  onSelect: (v: V) => void;
  onDeselect: (v: V) => void;
};

type EditProps<V> = {
  value: V[];
  getOptionLabel: (v: any) => string;
  formatOptionLabel?: (v: any) => ReactNode;
  getOptionValue: (v: any) => string;
  onAdd: () => void;
  onUpdate: (index: number, v: V) => void;
  autoFocus?: boolean;
};

type EditableChoiceEditorProps<V, Row, TSummaryRow> = Pick<EditorProps<Row, TSummaryRow>, 'onClose'> &
  SelectProps<V> &
  Pick<EditProps<V>, 'onAdd' | 'onUpdate'>;

function Select<V>({ onSelect, onDeselect, ...props }: SelectProps<V>): ReactNode {
  return (
    <ReactSelect
      {...props}
      {...selectProps}
      onChange={(_v, action: any) => {
        switch (action.action) {
          case 'deselect-option':
            onDeselect(action.option);
            return;
          case 'select-option':
            onSelect(action.option);
            return;
          default:
            throw new Error();
        }
      }}
      isMulti
      autoFocus
      components={{
        IndicatorsContainer: Dummy,
        Option: CheckboxOption,
      }}
    />
  );
}

function Edit<V>({
  value,
  onAdd,
  onUpdate,
  getOptionLabel,
  //   getOptionValue,
  formatOptionLabel = getOptionLabel,
  autoFocus,
}: EditProps<V>): ReactNode {
  return (
    <Box className={editorContainer}>
      <Box className={controlLabel}>Edit values</Box>
      {value.map((item, idx) => (
        <Box key={idx} display="flex" alignItems="center" className={editorChoice} role="option">
          <Box flexGrow={1}>{formatOptionLabel(item)}</Box>
          {/* getOptionValue ? */}
          <Button
            variant="outline-primary"
            size="sm"
            className="p-1 border-0"
            onClick={() => onUpdate(idx, item)}
            testId="update-option"
          >
            <Icon icon="pencil" />
          </Button>
        </Box>
      ))}
      <div className="p-1">
        <Button size="sm" variant="outline-primary" onClick={onAdd} testId="add-option" autoFocus={autoFocus}>
          Add <Icon icon="plus" className="ml-1" />
        </Button>
      </div>
    </Box>
  );
}

export function EditableChoiceEditor<V, Row, TSummaryRow>({
  value,
  options,
  onClose,
  onSelect,
  onAdd,
  onUpdate,
  ...common
}: EditableChoiceEditorProps<V, Row, TSummaryRow>): ReactElement {
  const selectValue: V[] = [];
  const editValue: V[] = [];

  // partition values
  value.forEach((v) => {
    if (options.find((opt) => common.getOptionValue(opt) === common.getOptionValue(v))) {
      selectValue.push(v);
    } else {
      editValue.push(v);
    }
  });

  return (
    <CellDropdown trapEnter onRequestClose={onClose}>
      {options.length > 0 ? <Select options={options} value={selectValue} onSelect={onSelect} {...common} /> : null}
      <Edit value={editValue} onAdd={onAdd} onUpdate={onUpdate} {...common} autoFocus={options.length === 0} />
    </CellDropdown>
  );
}
