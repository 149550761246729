import type { PlanStrategyType } from '../types';
import type { PlanStrategy } from './type';
import { NAIVE } from './naive';
import { RTB } from './rtb';

export type { PlanStrategy };

export const strategies: Record<PlanStrategyType, PlanStrategy> = {
  NAIVE,
  RTB,
};

export const strategyLabelMap: Record<PlanStrategyType, string> = Object.keys(strategies).reduce(
  (acc: any, key: any) => {
    acc[key] = strategies[key as PlanStrategyType].TITLE;
    return acc;
  },
  {},
);
