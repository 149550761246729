import { ReactNode } from 'react';
import { DialogEditor, type RenderEditCellProps } from 'components/DataGrid';
import type { GridRow, GridSummaryRow } from '../../types';
import { DecimalField } from '@ff-it/form';
import invariant from 'tiny-invariant';
import { usePlanController } from '../../../usePlanController';

export function Edit({
  direction,
  row,
  ...props
}: RenderEditCellProps<GridRow, GridSummaryRow> & {
  direction: 'client' | 'provider';
}): ReactNode {
  invariant(row.supplier);
  const controller = usePlanController();

  const initialValues = {
    discounts: direction === 'client' ? row.client_discount : row.provider_discount,
  };

  return (
    <DialogEditor
      testId={`discount-edit-${direction}`}
      dialogHeader={`${direction === 'client' ? 'Client' : 'Provider'} discounts`}
      initialValues={initialValues}
      submitHandler={async ({ discounts }: { discounts: number[] }) =>
        controller
          .formRequest({
            method: 'PUT',
            url: `plan/rows/${row.id}/${direction}_discount/`,
            body: discounts ? discounts.filter((v: any) => v !== null) : null,
          })
          .then((err) => {
            if (err) {
              // we have to nest form into field
              return { discounts: err };
            }
          })
      }
      {...props}
    >
      <div className="form-row">
        <DecimalField name="discounts[0]" placeholder="Discount 1" className="col" size="sm" precision={6} />
        <DecimalField name="discounts[1]" placeholder="Discount 2" className="col" size="sm" precision={6} />
        <DecimalField name="discounts[2]" placeholder="Discount 3" className="col" size="sm" precision={6} />
      </div>
    </DialogEditor>
  );
}
