import { ReactElement } from 'react';
import { Breadcrumb, Heading, Page } from 'components';
import { Button } from '@ff-it/ui';
import { Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';

import { NewCompany } from './NewCompany';
import { NewDepartment } from './NewDepartment';
import { NewClient } from './NewClient';
import { Card, CardBody } from 'components/Card';

function Categories(): ReactElement {
  return (
    <>
      <Heading title="Create a ticket" />
      <div className="row">
        <div className="col-md-3">
          <Card>
            <CardBody>
              <p className="card-text">New company credentials</p>
              <Button variant="outline-primary" to="new_company" component={Link}>
                <div>New company</div>
              </Button>
            </CardBody>
          </Card>
        </div>
        <div className="col-md-3">
          <Card>
            <CardBody>
              <p>New department for existing provider&apos;s company credentials</p>
              <Button variant="outline-primary" to="new_department" component={Link}>
                <div>New department</div>
              </Button>
            </CardBody>
          </Card>
        </div>
        <div className="col-md-3">
          <Card>
            <CardBody>
              <p>New client or agreement for existing company credentials</p>
              <Button variant="outline-primary" to="new_client" component={Link}>
                <div>New client</div>
              </Button>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export function SubmitTicket(): ReactElement {
  return (
    <Page>
      <Breadcrumb>Support</Breadcrumb>
      <Routes>
        <Route index element={<Categories />} />
        <Route path="new_company" element={<NewCompany />} />
        <Route path="new_department" element={<NewDepartment />} />
        <Route path="new_client" element={<NewClient />} />
      </Routes>
    </Page>
  );
}
