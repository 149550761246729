import type { SessionUser } from 'modules/core/types';
import { AuthState, useSession } from 'services';

export type Check = (sess: AuthState) => boolean;

export type Perm = Check | string;
export type Perms = Array<Perm | undefined>;

export function checkPerm(sess: AuthState, perm?: Perm): boolean {
  if (typeof perm === 'function') {
    return perm(sess);
  }

  const { user, impersonation, scope } = sess;
  const currentUser = impersonation || user;

  if (!currentUser) {
    return false;
  }

  if (!perm) {
    return true;
  }

  // direct user permissions
  if (currentUser.is_superuser || currentUser.permissions.indexOf(perm) !== -1) {
    return true;
  }

  if (scope?.kind === 'AGENCY' && scope.permissions.indexOf(perm) !== -1) {
    return true;
  }

  return false;
}

export function has(perm?: Perm): Check {
  return (sess) => checkPerm(sess, perm);
}

export function any(...perms: Perms): Check {
  return (session) => {
    return perms.some((perm?: Perm) => has(perm)(session));
  };
}

export function never(_: AuthState): boolean {
  return false;
}

export function sessionUser({ user, impersonation }: AuthState): SessionUser | null {
  return impersonation || user;
}

export function superuser(sess: AuthState): boolean {
  return sessionUser(sess)?.is_superuser || false;
}

export function manageGroup(sess: AuthState): boolean {
  const u = sessionUser(sess);
  return u?.is_superuser || u?.manage_group || false;
}

export function all(...perms: Perms): Check {
  return (session) => perms.every((perm?: Perm) => has(perm)(session));
}

export function useCheck(): (perm?: Perm) => boolean {
  const session = useSession();
  return (perm?: Perm): boolean => {
    if (!perm) {
      return true;
    }
    if (typeof perm === 'function') {
      return perm(session);
    }
    return has(perm)(session);
  };
}

export function useHasPerm(perm?: Perm): boolean {
  const check = useCheck();
  return check(perm);
}

export function useHasPerms(...perms: Perms): boolean[] {
  const check = useCheck();
  return perms.map(check);
}

export function listPerm(permissions?: Record<string, Perm | undefined>): Check {
  return all(permissions?.view, permissions?.change);
}

export const agencyScopeRequired: Check = (sess: AuthState): boolean => sess.scope?.kind === 'AGENCY';
