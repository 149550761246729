import { ReactElement, useId } from 'react';
import { InvoicingProgress } from 'components';
import { sepFormat } from 'utilities';
import { Tooltip } from 'react-tooltip';
import './SideProgress.scss';

type SideProgressProps = {
  planned: string;
  actual?: string;
  bound: string;
  remainder: string;
  direction: 'income' | 'expense';
  ratio: number | null;
};

export function SideProgress({ planned, actual, bound, remainder, ratio, direction }: SideProgressProps): ReactElement {
  const id = useId();

  return (
    <>
      {ratio !== null && (
        <Tooltip id={id} place="bottom">
          <div className={`side-progress side-progress--${direction}`}>
            <div className="d-flex">
              <div>Planned</div>
              <div className="ml-auto pl-3">{sepFormat(planned)}</div>
            </div>
            {actual && (
              <div className="d-flex">
                <div>Actual</div>
                <div className="ml-auto pl-3">{sepFormat(actual)}</div>
              </div>
            )}
            <div className="d-flex">
              <div>Invoiced</div>
              <div className="ml-auto pl-3">{sepFormat(bound)}</div>
            </div>
            <div className="d-flex">
              <div>Remainder</div>
              <div className="ml-auto pl-3">{sepFormat(remainder)}</div>
            </div>
          </div>
        </Tooltip>
      )}
      <InvoicingProgress ratio={ratio} direction={direction} id={id} />
    </>
  );
}
