import { Box } from '@ff-it/ui';
import { ReactElement } from 'react';
import { useFilterFields } from '../filter';
import { EntityMap } from '../types';

export function FilterFields({ entities, clients }: { entities: EntityMap; clients?: string[] }): ReactElement {
  const { client, department, provider, block } = useFilterFields(entities, clients);
  return (
    <Box display="flex" flexDirection="row" marginBottom="xs">
      {client}
      {department}
      {provider}
      {block}
    </Box>
  );
}
