import type { ReactElement, ReactNode } from 'react';
import { TextField, useField } from '@ff-it/form';
import type { Size } from '@ff-it/ui';

// Shared with income builder
export function IncomeClientPONumberField(props: {
  label?: ReactNode;
  placeholder?: string;
  size?: Size;
  className?: string;
}): ReactElement | null {
  const {
    input: { value },
  } = useField<boolean>('hide_client_po_number', { subscription: { value: true } });
  if (value) {
    return null;
  }
  return <TextField name="client_po_number" help="Leave empty to use linked" {...props} />;
}
