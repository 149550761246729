import { RouteObject } from 'react-router';
import { BlockProvider } from './BlockProvider';
import { Agreement, History, Plan } from './views';

export const route: RouteObject = {
  path: 'planning/:block_id/*',
  element: <BlockProvider />,
  children: [
    {
      index: true,
      element: <Plan />,
    },
    {
      path: 'agreement',
      element: <Agreement />,
    },
    {
      path: 'history',
      element: <History />,
    },
  ],
};
