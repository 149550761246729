import { TableInlineColumn, TableInlines } from 'components';
import { Card, CardBody } from 'components/Card';
import { ReactElement } from 'react';
import { ActivityType } from 'types';
import { MediaDiscountRate } from './types';
import { DecimalField, TextField } from '@ff-it/form';
import { CategoryField } from 'modules/supplier/category/MediaCategoryField';
import { SupplierField } from 'modules/supplier/supplier/components';
import { Box } from '@ff-it/ui';
import { required } from 'utilities';
import { DepartmentField } from 'modules/supplier/department/DepartmentField';

const initialValues = {
  client_discount: null,
  provider_discount: null,
  target_department: null,
  target_category: null,
  target_supplier: {
    provider: null,
    activity: null,
    product: null,
  },
  terms: '',
};

export function MediaDiscountRates({ type }: { type: ActivityType }): ReactElement {
  const columns: TableInlineColumn<MediaDiscountRate>[] = [
    {
      key: 'target_department',
      content: 'Department',
      render: ({ name }) => (
        <DepartmentField name={`${name}.target_department`} className="mb-0" required validate={required} />
      ),
      width: '170px',
    },
    {
      key: 'client_discount',
      content: 'Cl. disc.',
      render: ({ name }) => (
        <DecimalField name={`${name}.client_discount`} className="mb-0" required validate={required} />
      ),
      width: '90px',
    },
    {
      key: 'provider_discount',
      content: 'Prov. disc.',
      render: ({ name }) => <DecimalField name={`${name}.provider_discount`} className="mb-0" />,
      width: '90px',
    },

    {
      key: 'target_category',
      content: 'Target category',
      render: ({ name }) => <CategoryField name={`${name}.target_category`} className="mb-0" />,
    },
    {
      key: 'target_supplier',
      content: 'Target supplier fields',
      render: ({ name, value }) => (
        <SupplierField
          name={`${name}.target_supplier`}
          className="mb-0"
          type={type}
          kind="MEDIA"
          incomplete
          disabled={!value.target_department}
          department={value.target_department?.id}
        />
      ),
    },
    {
      key: 'terms',
      content: 'Comment',
      render: ({ name }) => <TextField multiline autosize name={`${name}.terms`} className="mb-0" />,
    },
  ];

  return (
    <Card
      title={
        <Box display="flex" alignItems="center">
          Media discount rates
          <Box marginLeft="auto" fontWeight="regular" fontSize="xs">
            Set client and provider discount when target conditions are met.
          </Box>
        </Box>
      }
      testId="media-discount-rates"
    >
      <CardBody>
        <TableInlines name="media_discount_rates" initialValues={initialValues} columns={columns} />
      </CardBody>
    </Card>
  );
}
