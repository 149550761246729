import type { ReactNode } from 'react';
import cx from 'clsx';
import Big from 'big.js';
import { Box } from '@ff-it/ui';
import { fmt } from 'utilities';
import { budget as budgetClassName, budgetProgress } from './styles.css';

type BudgetProps = {
  budget: Big;
  amount: Big;
  details?: boolean;
};

export function Budget({ budget, amount, details }: BudgetProps): ReactNode {
  if (budget.eq(0)) {
    return null;
  }
  // const remaining = budget.minus(amount);
  const ratio = amount.div(budget).mul(100).round();

  const res = (
    <Box className={budgetClassName}>
      <progress
        value={ratio.toFixed()}
        max={100}
        className={cx(budgetProgress, ratio.gt(100) && 'overspent')}
        title={`${ratio.toFixed()}%`}
      />
      <Box
        fontSize="xs"
        fontWeight="regular"
        color="quarterary"
        title={fmt(budget)}
        data-testid="budget"
        textAlign="left"
      >
        {fmt(budget)}
      </Box>
    </Box>
  );

  if (details) {
    return (
      <Box display="flex" alignItems="center">
        {res}
        <Box marginX="sm" fontWeight="regular" fontSize="xs">
          {details && `${ratio.toFixed(0)}% of budget`}
        </Box>
      </Box>
    );
  }
  return res;
}
