import cx from 'clsx';
import { useMemo } from 'react';
import { Calendar } from './types';
import { createColumn } from '../columns/createColumn';
import { Column } from 'components/DataGrid';
import { RowOrGroup } from '../types';
import { Header } from './Header';
import { flush } from '../columns/style.css';
export { useCalendar } from './useCalendar';
import { Edit } from './Edit';
import { View } from './View';
import { editableClassname, emptyCellClassname, requiredClassname } from 'components/DataGrid/style/cell.css';

// @OTOD empty classname
// edtiable classname

export function useCalendarColumn(calendar: Calendar): Column<RowOrGroup, any> {
  return useMemo(
    () =>
      createColumn(
        {
          key: 'positions',
          name: <Header calendar={calendar} />,
          width: calendar.width,
          className: flush,
        },
        {
          renderView: (props) => {
            if (props.row.kind === 'SMART') {
              return null;
            }
            const locked = props.row.config.positions?.locked;
            if (locked) {
              return <View {...props} calendar={calendar} />;
            }

            return <Edit {...(props as any)} calendar={calendar} key={props.row.modified_at} />;
          },
          className(row) {
            const locked = row.kind === 'SMART' || row.config.positions?.locked;
            return locked
              ? undefined
              : cx(editableClassname, requiredClassname, row.positions.length === 0 && emptyCellClassname);
          },
        },
      ),
    [calendar],
  );
}
