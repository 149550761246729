import type { EmbeddedDepartment } from 'modules/supplier/department/types';
import type { EntityMap } from '../types';
import type { FilterOptions } from './types';
import type { ActivityType } from 'types';

export function createFilterOptions({ company, activity, block, product }: EntityMap): FilterOptions {
  const departmentMap: Record<number, EmbeddedDepartment> = {};

  const types: ActivityType[] = [];
  // const types

  for (const { department, type } of Object.values(product)) {
    if (!departmentMap[department.id]) {
      departmentMap[department.id] = department;
    }
    if (!types.includes(type)) {
      types.push(type);
    }
  }

  return {
    activity: Object.values(activity),
    department: Object.values(departmentMap),
    company: Object.values(company),
    block: Object.values(block),
    type: types.map((value) => ({
      value,
      label: value,
    })),
  };
}
