import type { ReactElement } from 'react';
import type { BookingState, InvoiceState } from './types';
import { IconBadge, IconBadgeBaseProps } from 'components/IconBadge/IconBadge';
import { useSessionScope } from 'services';

const STATE_MAP: Record<BookingState, IconBadgeBaseProps> = {
  NONE: {
    title: 'Not booked',
    icon: 'book-open',
    background: 'warning-solid',
    color: 'white',
  },
  CREATED: {
    title: 'Created',
    icon: 'book',
    background: 'tertiary',
    color: 'tertiary_hover',
  },
  BOOKED: {
    title: 'Booked',
    icon: 'book',
    background: 'tertiary',
    color: 'success-primary',
  },
};

export function BookingStateBadge({
  booking_state,
  state,
}: {
  state: InvoiceState;
  booking_state: BookingState;
}): ReactElement | null {
  // FIXME: #1141, #1005 server should return null where state not applicable
  const accounting_integration = useSessionScope().accounting_integration;
  if (accounting_integration === null) {
    return null;
  }
  const props = state === 'CANCELED' ? { icon: null } : STATE_MAP[booking_state];
  return <IconBadge {...props} />;
}
