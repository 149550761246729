export function createFormat(title: string): any {
  // @TODO
  return {
    title,
    material_type: null,
    size: '',
    file_format: '',
    file_weight: '',
    description: '',
    specification_url: '',
    example_url: '',
  };
}

export function createPlacement(title: string): any {
  return {
    title,
  };
}
