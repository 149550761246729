import { useModel, ItemLoader, UpdateContainer, useEntity, withHasPerm, UserField, FormInline } from 'components';
import { Company } from 'types';
import { TextField } from '@ff-it/form';
import { ReactElement } from 'react';

export const permissions = {
  change: 'client.change_clientmanager',
};

const initialValues = {
  user: null,
  notes: '',
};

export const Managers = withHasPerm(function Managers(): ReactElement {
  const endpoint = useModel().endpoint;
  const entityId = useEntity<Company>().item.id;

  const url = `${endpoint}${entityId}/managers/`;
  return (
    <ItemLoader<any> url={url}>
      {({ setItem, item }) => (
        <UpdateContainer url={url} initialValues={item} updateItem={setItem} method="POST">
          <div className="container px-0 ml-0">
            <FormInline
              name="managers"
              title="Managers"
              initialValues={initialValues}
              help="Allows manager to view all client campaigns and reporting data"
            >
              {(name) => (
                <div className="form-row">
                  <UserField
                    name={`${name}.user`}
                    placeholder="User"
                    className="col mb-0"
                    filter={{ manage_campaign: true }}
                  />
                  <TextField
                    name={`${name}.notes`}
                    placeholder="Note"
                    className="col mb-0"
                    help="Private notes for internal use"
                  />
                </div>
              )}
            </FormInline>
          </div>
        </UpdateContainer>
      )}
    </ItemLoader>
  );
}, permissions.change);
