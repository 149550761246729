export default {
  // CRUD
  view: 'guarantee.view_avb',
  add: 'guarantee.add_avb',
  change: 'guarantee.change_avb',
  delete: 'guarantee.delete_avb',
  // Views
  invoice: 'guarantee.invoice_avb',
  sensitive_info: 'guarantee.sensitive_info_avb',
};
