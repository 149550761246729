import type { ReactElement } from 'react';
import type { InvoiceDirection } from 'modules/invoicing/common/types';
import type { LinkerSummaryState } from './types';
import { Sprinkles } from '@ff-it/ui';
import { SummaryContainer, SummaryField } from 'modules/invoicing/common';
import { InvoiceCheck } from 'modules/invoicing/common/components/InvoiceCheck';

type LinkerSummaryProps = {
  state: LinkerSummaryState;
  direction: InvoiceDirection;
} & Sprinkles;

export function LinkerSummary({
  state: { amount, credited, compensated, applied, balance },
  direction,
  ...rest
}: LinkerSummaryProps): ReactElement {
  return (
    <SummaryContainer direction={direction} testId="linker-summary" {...rest}>
      <SummaryField label="Amount" testId="amount">
        {amount.toFixed(2)}
      </SummaryField>
      {!credited.eq(0) && (
        <SummaryField label="Credited" testId="credited">
          {credited.mul(-1).toFixed(2)}
        </SummaryField>
      )}
      {!compensated.eq(0) && (
        <SummaryField label="Compensated" testId="compensated">
          {compensated.mul(-1).toFixed(2)}
        </SummaryField>
      )}
      <SummaryField label="Linked" testId="applied">
        {applied.mul(-1).toFixed(2)}
      </SummaryField>
      <SummaryField label="Balance" testId="balance" display="flex" alignItems="center">
        {balance.toFixed(2)}{' '}
        <InvoiceCheck
          direction={direction}
          marginLeft="sm"
          state={balance.eq(0) ? 'success' : balance.lt(0) ? 'error' : 'warn'}
        />
      </SummaryField>
    </SummaryContainer>
  );
}
