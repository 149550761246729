import { EntityAction, createConfirmDialog } from 'components';
import { Invoice } from '../types';

export const unlinkAll: EntityAction<Invoice> = {
  action: 'unlink-all',
  button: {
    children: 'Unlink all',
    variant: 'outline-danger',
    size: 'sm',
  },
  visible: ({ state, type, linked_at, sums }) => {
    return type === 'INVOICE' && state !== 'CANCELED' && !linked_at && sums.link_applied !== '0.00';
  },
  permKey: 'link',
  requestDialog: createConfirmDialog(),
  createRequest: ({ endpoint }) => ({
    url: `${endpoint}link/`,
    method: 'DELETE',
  }),
  partial: true, // only sums
};
