import { NavLink } from 'react-router-dom';
import { Crumb } from '@ff-it/ui';
import { ReactElement, ReactNode } from 'react';

interface BreadCrumbProps {
  children: ReactNode;
  to?: string | null;
  end?: boolean;
  className?: string;
}
export function Breadcrumb({ children, to = '', end, className }: BreadCrumbProps): ReactElement {
  // @TOOD: aria acitve
  return (
    <Crumb className={className}>
      {to !== null ? (
        <NavLink to={to} end={end}>
          {children}
        </NavLink>
      ) : (
        children
      )}
    </Crumb>
  );
}
