import type { Entity } from 'types';
import type { EmbeddedUser } from 'modules/core/types';
import { ApplicationState } from 'core/types';

export const invoiceStates = ['DRAFT', 'CONFIRMED', 'CANCELED'] as const;
export type InvoiceState = (typeof invoiceStates)[number];

export const invoiceTypes = ['INVOICE', 'ADVANCE', 'CREDIT'] as const;
export type InvoiceType = (typeof invoiceTypes)[number];

export const invoiceDirections = ['income', 'expense'] as const;
export type InvoiceDirection = (typeof invoiceDirections)[number];

export const bookingStates = ['NONE', 'CREATED', 'BOOKED'] as const;
export type BookingState = (typeof bookingStates)[number];

export const paymentStates = ['PENDING', 'PARTIAL', 'PAID', 'LATE'] as const;
export type PaymentState = (typeof paymentStates)[number];

export interface PaymentFields {
  paym_amount: string;
  paym_applied: string;
  paym_advanced: string;
  paym_balance: string;
  // null for CANCELED or DRAFT or non INVOICE
  paym_state: PaymentState | null;
}

export interface LinkFields {
  link_amount: string;
  link_credited: string;
  link_compensated: string;
  link_applied: string;
  link_balance: string;
  // null for CANCELED or non INVOICE
  link_state: ApplicationState | null;
}

export interface CompensationFields {
  comp_amount: string;
  comp_applied: string;
  comp_balance: string;
  // null for CANCELED or non INVOICE or 0 compensation
  comp_state: ApplicationState | null;
}

export interface CreditFields {
  cred_amount: string;
  cred_applied: string;
  cred_balance: string;
  //  null for CANCELED or non CREDIT
  cred_state: ApplicationState | null;
}

export interface AdvanceFields {
  adv_amount: string;
  adv_applied: string;
  adv_balance: string;
  // null for CANCELED or non CREDIT
  adv_state: ApplicationState | null;
}

// @TODO to core
export type CurrencyRate = {
  id: number;
  currency: string;
  rate: string;
  // manual
  date: string | null;
};

export interface LinkedInvoice extends Entity {
  display_number: string;
  state: InvoiceState;
  checkout_date: string;
  payment_date: string;
  type: InvoiceType;
  sub_total: string;
  total: string;
}

export interface InvoiceLink {
  id: number;
  number: string;
  checkout_date: string;
  slug: string;
  amount: string;
  kind: 'income' | 'expense';
}

export interface BaseInvoice extends Entity {
  number: string;
  state: InvoiceState;
  // of_enterprise
  // partner
  checkout_date: string;
  payment_date: string;
  comment: string;
  type: InvoiceType;
  created_at: string;
  modified_at: string;
  linked_at: string | null;
  booking_state: BookingState;
  booking_state_at: string | null;
  issued_by: EmbeddedUser;
  hide_issuer: boolean;
  vat_rate: number;

  alt_currency: CurrencyRate | null;
  alt_sub_total: string | null;
  alt_vat_amount: string | null;
  alt_total: string | null;

  sub_total: string;
  vat_amount: string;
  total: string;
  paid: string;
}
