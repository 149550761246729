import { ReactElement, useCallback } from 'react';

import { Calendar } from './types';
import { format, isWeekend } from 'date-fns';
import { Draw, Canvas } from './Canvas';
import { headerCanvas } from './style.css';
import { CALENDAR_ROW_HEIGHT, INNER_HEADER_HEIGHT } from './const';

const BG = '#101828';
const FG = '#FFFFFF';
const FG_ALT = '#667085';

export function Header({ calendar: { months, width, weeks, days } }: { calendar: Calendar }): ReactElement | null {
  const draw: Draw = useCallback(
    (ctx) => {
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';

      ctx.font = '10px Roboto Flex';
      let y = 0;

      for (let i = 0; i < months.length; i++) {
        const col = months[i];
        ctx.fillStyle = BG;
        ctx.fillRect(col.left, y, col.width, CALENDAR_ROW_HEIGHT);
        const text = format(col.start, 'MMM. yyyy');
        ctx.fillStyle = FG;
        ctx.fillText(text, col.left + col.width / 2, CALENDAR_ROW_HEIGHT / 2);
      }
      y += CALENDAR_ROW_HEIGHT + 1;

      for (let i = 0; i < weeks.length; i++) {
        const col = weeks[i];
        ctx.fillStyle = BG;
        ctx.fillRect(col.left, y, col.width, CALENDAR_ROW_HEIGHT);
        const text = `w${format(col.start, 'I')}`;
        ctx.fillStyle = FG;
        ctx.fillText(text, col.left + col.width / 2, y + CALENDAR_ROW_HEIGHT / 2 - 1);
      }

      y += CALENDAR_ROW_HEIGHT + 1;

      for (let i = 0; i < days.length; i++) {
        const col = days[i];
        ctx.fillStyle = BG;
        ctx.fillRect(col.left, y, col.width, CALENDAR_ROW_HEIGHT);
        const text = col.start.getDate().toString();

        ctx.fillStyle = isWeekend(col.start) ? FG_ALT : FG;
        ctx.fillText(text, col.left + col.width / 2, y + CALENDAR_ROW_HEIGHT / 2);
      }
    },
    [months, weeks, days],
  );

  return <Canvas className={headerCanvas} draw={draw} width={width} height={INNER_HEADER_HEIGHT} />;
}
