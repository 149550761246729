import {
  SelectCellFormatter,
  type RenderCellProps,
  type RenderHeaderCellProps,
  useRowSelection,
} from 'components/DataGrid';
import type { ReactElement, ReactNode } from 'react';
import { createColumn } from './createColumn';
import type { GridSummaryRow, RowOrGroup } from '../types';

function HeaderRenderer(props: RenderHeaderCellProps<RowOrGroup, GridSummaryRow>): ReactElement {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();

  return (
    <SelectCellFormatter
      aria-label="Select All"
      tabIndex={props.tabIndex}
      value={isRowSelected}
      onChange={(checked) => {
        onRowSelectionChange({ type: 'HEADER', checked });
      }}
    />
  );
}

function SelectFormatter(props: RenderCellProps<RowOrGroup, GridSummaryRow>): ReactNode {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();
  if (props.row._isGroup && props.row.empty) {
    return null;
  }
  return (
    <SelectCellFormatter
      aria-label="Select"
      tabIndex={props.tabIndex}
      value={isRowSelected}
      onChange={(checked, isShiftClick) => {
        onRowSelectionChange({ type: 'ROW', row: props.row, checked, isShiftClick });
      }}
    />
  );
}

export const SelectColumn = createColumn({
  key: 'select',
  width: 30,
  frozen: true,
  renderHeader: (props) => <HeaderRenderer {...props} />,
  renderView: (props) => <SelectFormatter {...props} />,
});
