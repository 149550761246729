import { ReactElement } from 'react';
import { TextField } from '@ff-it/form';
import { TicketForm } from './TicketForm';
import { CommonCompanyFields } from 'modules/core/company/components';

const initialValues = {
  title: '',
  internal_name: '',
  code: '',
  kind: 'company',
  country: window.__RUNTIME_CONFIG__?.DEFAULT_COUNTRY,
  registration_number: '',
  vat_number: '',

  legal_address: '',
  office_address: '',
  office_same_as_legal: false,

  // company here we have a test field for swift
  bank_swift: '',
  bank_account_number: '',

  //
  extra_info: '',
};
export function NewCompany(): ReactElement {
  return (
    <TicketForm title="New company" url="support/tickets/new_company/" initialValues={initialValues}>
      <CommonCompanyFields />
      <div className="form-row">
        <TextField name="bank_swift" label="Bank (SWIFT)" className="col-md-6" />
        <TextField name="bank_account_number" label="Account number (IBAN)" className="col-md-6" />
      </div>
      <div className="form-row">
        <TextField
          name="extra_info"
          label="Extra info"
          help="Any other info to know"
          className="col-6"
          multiline
          autosize
        />
      </div>
    </TicketForm>
  );
}
