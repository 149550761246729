import { CreateScene, DeleteScene, EntityScene, ModelScene, IndexScene, UpdateScene, useEntity } from 'components';
import { useRoutes } from 'react-router';
import { Company } from 'types';
import { permissions } from 'modules/supplier/agreement/permissions';
import { Form } from 'modules/supplier/agreement/Form';
import { Link } from 'react-router-dom';
import { date } from 'components/columns';
import { ReactElement } from 'react';
import { ProviderAgreement } from 'modules/supplier/agreement/types';
import { TableColumns } from '@ff-it/ui';

const columns: TableColumns<ProviderAgreement> = [
  {
    content: 'Number',
    key: 'number',
    width: 180,
    render: ({ id, number }: ProviderAgreement) => <Link to={`${id}`}>{number}</Link>,
  },
  date('Date from', 'date_from', { sortable: true }),
  date('Date to', 'date_to', { sortable: true }),
];

function copy(agreement: ProviderAgreement): any {
  return {
    ...agreement,
    id: undefined,
    date_from: null,
    date_to: null,
    number: '',
    attachments: [],
    provided_departments: agreement.provided_departments.map((department) => ({
      ...department,
      provided_products: department.provided_products.map((product) => ({ ...product, id: undefined })),
      id: undefined,
    })),
  };
}

export function Agreements(): ReactElement {
  const { item } = useEntity<Company>();

  const model = {
    endpoint: `supplier/providers/${item.id}/agreements/`,
    title: 'Provider agreement',
    pluralTitle: 'Provider agreements',
    entityTitle: (e: ProviderAgreement) => e.number,
    permissions,
  };

  const initialValues = {};

  const routes = useRoutes([
    {
      index: true,
      element: <IndexScene columns={columns} />,
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <UpdateScene viewDisabled={true} copy={copy}>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ]);
  return <ModelScene {...model}>{routes}</ModelScene>;
}
