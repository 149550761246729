import { Perm, useHasPerm } from 'core/permissions';
import { ComponentType, FC } from 'react';
import { PermissionDenied } from './scenes';

export const withHasPerm =
  <P extends object>(Component: ComponentType<P>, check: Perm): FC<P> =>
  (props) => {
    const hasPerm = useHasPerm(check);
    if (!hasPerm) {
      return <PermissionDenied />;
    }
    return <Component {...props} />;
  };
