import { ReactElement } from 'react';
import { IconBadge, IconBadgeBaseProps } from 'components/IconBadge/IconBadge';
import { ReservationState } from '../row/media';

export const RESERVATION_STATE_PROPS: Record<ReservationState, IconBadgeBaseProps> = {
  NONE: {
    title: 'None',
    icon: 'circle-dashed',
    background: 'tertiary',
    color: 'quarterary',
  },
  BOOKED: {
    title: 'Booked',
    icon: 'book-open',
    background: 'brand-solid',
    color: 'white',
  },
  ON_AIR: {
    title: 'On Air',
    icon: 'play',
    background: 'success-solid',
    color: 'white',
  },
  ON_HOLD: {
    title: 'On Hold',
    icon: 'pause',
    background: 'warning-solid',
    color: 'white',
  },
  DONE: {
    title: 'Done',
    icon: 'check',
    background: 'tertiary',
    color: 'success-primary',
  },
};

export function ReservationStateBadge({
  reservation_state,
  testId,
}: { reservation_state: ReservationState; testId?: string }): ReactElement {
  return <IconBadge {...RESERVATION_STATE_PROPS[reservation_state]} testId={testId} />;
}
