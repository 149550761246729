import { ReactElement, ReactNode } from 'react';
import { EditorProps } from './types';
import { RenderDialogForm } from 'components/ui';
import { SubmissionErrors } from '@ff-it/form';
import { trapEnterHandler } from './utils';

type DialogEditorProps<Row, TSummaryRow> = Pick<EditorProps<Row, TSummaryRow>, 'onClose'> & {
  dialogHeader?: ReactNode;
  testId?: string;
  children: ReactNode;
  initialValues: any;
  submitHandler: (v: any) => Promise<SubmissionErrors | void>;
  onRemove?: () => Promise<void>;
};

export function DialogEditor<Row, TSummaryRow>({
  children,
  onClose,
  dialogHeader,
  testId,
  submitHandler,
  initialValues,
  onRemove,
}: DialogEditorProps<Row, TSummaryRow>): ReactElement {
  return (
    <div onKeyDown={trapEnterHandler}>
      <RenderDialogForm
        submitHandler={submitHandler}
        onClose={onClose}
        dialogHeader={dialogHeader}
        testId={testId}
        initialValues={initialValues}
        onRemove={onRemove}
      >
        {children}
      </RenderDialogForm>
    </div>
  );
}
