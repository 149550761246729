import { IconBadge, IconBadgeBaseProps } from 'components/IconBadge/IconBadge';
import type { ApplicationState } from 'core/types';
import type { ReactElement } from 'react';
import type { InvoiceDirection } from './types';

type LinkApplicationStateBadgeProps = {
  link_state: ApplicationState | null;
  direction: InvoiceDirection;
};

const STATE_MAP: Record<ApplicationState, IconBadgeBaseProps> = {
  EMPTY: {
    icon: null,
    title: 'Empty',
  },
  NONE: {
    icon: 'circle',
    title: 'Nothing linked',
    background: 'tertiary',
    color: 'success-primary',
  },
  PARTIAL: {
    icon: 'circle-half-stroke',
    title: 'Partially linked',
    color: 'success-primary',
  },
  FULL: {
    icon: 'circle-check',
    title: 'Fully linked',
    background: 'tertiary',
    color: 'success-primary',
  },
  OVERSPENT: {
    icon: 'circle-exclamation',
    title: 'Overspent',
    background: 'error-solid',
    color: 'white',
  },
};

export function LinkApplicationStateBadge({ link_state, direction }: LinkApplicationStateBadgeProps): ReactElement {
  const { color, ...props } = link_state === null ? { icon: null, color: undefined } : STATE_MAP[link_state];
  return (
    <IconBadge
      {...props}
      color={direction === 'expense' && color === 'success-primary' ? 'brand-primary' : color}
      testId={`link-state-${link_state}`}
    />
  );
}
