import { ReactElement } from 'react';
import { AuthContainer } from 'components';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Form, Submit, TextField, useSubmitHandler } from '@ff-it/form';
import { parse } from 'qs';
import { auth } from 'services';
import type { SessionUser } from 'modules/core/types';

export function Login(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const submitHandler = useSubmitHandler(
    {
      url: 'auth/login/',
      method: 'POST',
    },
    {
      onSuccess: ({ token, user }: { token: string; user: SessionUser }) => {
        auth.signin(user, token, () => {
          const next = parse(location.search, { ignoreQueryPrefix: true }).next as string | undefined;
          navigate(next && next.startsWith('/') ? next : '/');
        });
      },
    },
  );

  return (
    <AuthContainer>
      <h3 className="text-center">Sign In</h3>
      <Form onSubmit={submitHandler} autoComplete="off">
        <TextField name="email" label="Email" type="email" size="lg" required />
        <TextField name="password" label="Password" type="password" size="lg" required />
        <Submit variant="primary" size="lg" className="w-100" />
        <small className="mt-2 d-block">
          <Link to="/password_reset">Forgot password?</Link>
        </small>
      </Form>
    </AuthContainer>
  );
}
