import { Fragment, ReactNode } from 'react';
import { RenderCellProps, RenderEditCellProps, SelectEditor } from 'components/DataGrid';
import { createColumn } from '../createColumn';
import type { GridRow } from '../../types';
import { wrapContainer } from '../style.css';
import { CheckboxOption } from 'components/Select';
import invariant from 'tiny-invariant';
import { Box } from '@ff-it/ui';
import { emptyCellClassname } from 'components/DataGrid/style/cell.css';
import { Label } from './Label';
import { renderEdit } from './renderEdit';
import { SmartOption } from '../../../types';
import { useSmartOptions } from '../../../useSmartOptions';

function View({ row }: RenderCellProps<GridRow, any>): ReactNode {
  const options = useSmartOptions();
  if (row.kind === 'SMART') {
    const value = options.find((opt) => opt.id === row.id);
    // empty if no rate
    invariant(value);
    // even if there is no rate we want the colorcode badget
    return (
      <Box className={wrapContainer}>
        <Label {...value} />
      </Box>
    );
  }
  const values = options.filter((o) => row.applied_to.includes(o.id));
  if (values.length > 0) {
    return (
      <Box className={wrapContainer}>
        {values.map((v) => (
          <Fragment key={v.id}>
            <Label {...v} />{' '}
          </Fragment>
        ))}
      </Box>
    );
  }
}

export const getOptionValue = (v: null | SmartOption): any => (v ? v.id : v);
export const formatOptionLabel = (v: null | SmartOption): any => (v ? <Label {...v} /> : v);

function Edit(props: RenderEditCellProps<GridRow, any>): ReactNode {
  const options = useSmartOptions();
  if (props.row.kind === 'SMART') {
    return renderEdit(props);
  }

  // this has to be inline dropdown editor
  return (
    <SelectEditor
      {...props}
      field="applied_to"
      options={options}
      getOptionValue={getOptionValue}
      formatOptionLabel={formatOptionLabel}
      isSearchable={false}
      isMulti
      isSimple
      keepOpenOnSelect
      Option={CheckboxOption}
    />
  );
}

export const SmartColumn = createColumn(
  { key: 'smart', name: 'Smart rate', width: 96 },
  {
    renderView: (props) => <View {...props} />,
    renderEdit: (props) => <Edit {...props} />,
    // displayCellContent: (row) => row.kind !== 'SMART',
    className: (row) =>
      row.kind === 'SMART' && !row.rate.client && !row.rate.provider ? emptyCellClassname : undefined,
  },
);
