import { ReactElement } from 'react';
import { NavLinkProps } from 'react-router-dom';
import { useEntity, TabNav } from 'components';

const nav: NavLinkProps[] = [
  {
    to: '',
    end: true,
    children: 'Department',
  },
  {
    to: 'products',
    children: 'Products',
  },
  {
    to: 'factors',
    children: 'Factors',
  },
];

export function Nav(): ReactElement {
  const {
    item: { name, types },
  } = useEntity();

  return (
    <div className="container-fluid header header--department">
      <h2 className="py-2">
        {name} <small>({types.join(', ')})</small>
      </h2>
      <div className="d-flex flex-row">
        <TabNav nav={nav} />
      </div>
    </div>
  );
}
