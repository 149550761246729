import type { ReactElement } from 'react';
import type { RouteObject } from 'react-router';

import {
  createConfirmDialog,
  CreateScene,
  DeleteScene,
  EntityAction,
  EntityFormActions,
  EntityScene,
  Heading,
  ModelScene,
  IndexScene,
  UpdateScene,
  useEntity,
} from 'components';
import { Form } from './Form';
import { columns } from './columns';
import type { SystemUser } from 'modules/core/types';

const model = {
  endpoint: 'auth/users/',
  title: 'User',
  pluralTitle: 'Users',
  entityTitle: ({ email }: SystemUser) => email,
};

const initialValues = {
  email: '',
  first_name: '',
  last_name: '',
  is_active: true,
  is_superuser: false,
  manage_group: false,
  membership: [],
};

// is_superuser implied for actions
const actions: EntityAction<SystemUser>[] = [
  ({ item: { has_password, email } }) =>
    has_password
      ? {
          action: 'reset_password',
          permKey: null,
          button: {
            children: 'Reset password',
            variant: 'outline-primary',
            icon: 'envelope',
          },
          successMessage: `Reset link has been sent to ${email}`,
        }
      : {
          action: 'send_invite',
          permKey: null,
          button: {
            children: 'Send invite',
            variant: 'outline-primary',
            icon: 'envelope',
          },
          dialog: createConfirmDialog('This will invalidate existing invite if user has one.\nAre you sure?'),
          successMessage: `Invite has been sent to ${email}`,
        },
];

function Nav(): ReactElement {
  const { item: user } = useEntity();
  return (
    <div className="container-fluid mb-2">
      <Heading>
        <h1>
          {`${user.first_name} ${user.last_name}`}
          <small className="text-muted ml-2">{user.email}</small>
        </h1>
      </Heading>
    </div>
  );
}

export const route: RouteObject = {
  path: 'users',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: <IndexScene columns={columns} />,
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues} successMessage="User has been invited">
          <Form />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <Nav />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: (
            <UpdateScene
              viewDisabled
              heading={null}
              actions={<EntityFormActions className="ml-auto" actions={actions} />}
            >
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};
