import { Dispatch, SetStateAction, ReactElement } from 'react';
import { createOptions } from 'options';
import { Button, Check } from '@ff-it/ui';
import { canExpandFee, canAppendComment, canAltActivitName, canTransparentUnits, canAppendPONumbers } from './template';
import { facets, Template } from './types';

const facetOptions = createOptions(facets);

type TemplateProps = {
  template: Template;
  setTemplate: Dispatch<SetStateAction<Template>>;
};

export function TemplateForm({
  template: { facet, expandFees, appendComment, altActivityName, transparentUnits, appendPONumbers },
  setTemplate,
}: TemplateProps): ReactElement {
  return (
    <div className="form-inline py-2">
      <div className="btn-group btn-group-sm">
        {facetOptions.map(({ value, label }: any) => (
          <Button
            key={value}
            variant="outline-dark"
            active={value === facet}
            onClick={() => {
              setTemplate((template) => ({ ...template, facet: value }));
            }}
          >
            {label}
          </Button>
        ))}
      </div>
      {canExpandFee(facet) && (
        <div className="btn-group btn-group-sm ml-2">
          <Check
            label="Expand fees"
            variant="switch"
            checked={expandFees}
            onChange={() => {
              setTemplate(({ expandFees, ...rest }) => ({
                ...rest,
                expandFees: !expandFees,
              }));
            }}
          />
        </div>
      )}
      {canAppendPONumbers(facet) && (
        <div className="btn-group btn-group-sm ml-2">
          <Check
            label="Append PO numbers"
            variant="switch"
            checked={appendPONumbers}
            onChange={() => {
              setTemplate(({ appendPONumbers, ...rest }) => ({
                ...rest,
                appendPONumbers: !appendPONumbers,
              }));
            }}
          />
        </div>
      )}
      {canAppendComment(facet) && (
        <div className="btn-group btn-group-sm ml-2">
          <Check
            label="Append comment"
            variant="switch"
            checked={appendComment}
            onChange={() => {
              setTemplate(({ appendComment, ...rest }) => ({
                ...rest,
                appendComment: !appendComment,
              }));
            }}
          />
        </div>
      )}
      {canAltActivitName(facet) && (
        <div className="btn-group btn-group-sm ml-2">
          <Check
            label="Alternative activity name"
            variant="switch"
            checked={altActivityName}
            onChange={() => {
              setTemplate(({ altActivityName, ...rest }) => ({
                ...rest,
                altActivityName: !altActivityName,
              }));
            }}
          />
        </div>
      )}
      {canTransparentUnits(facet) && (
        <div className="btn-group btn-group-sm ml-2">
          <Check
            label="Transparent units and quantities"
            variant="switch"
            checked={transparentUnits}
            onChange={() => {
              setTemplate(({ transparentUnits, ...rest }) => ({
                ...rest,
                transparentUnits: !transparentUnits,
              }));
            }}
          />
        </div>
      )}
    </div>
  );
}
