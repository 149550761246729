import { ListExpense } from 'modules/invoicing/expenses/types';
import { ListIncome } from 'modules/invoicing/income/types';
import { ApplicationType } from './types';

export function isApplicable(invoice: ListIncome | ListExpense): ApplicationType | null {
  if (invoice.sums.cred_state !== null) {
    return 'credit';
  }
  if (invoice.direction === 'income') {
    if (invoice.sums.adv_state !== null) {
      return 'advance';
    }
    if (invoice.sums.comp_state !== null) {
      return 'compensation';
    }
  }
  return null;
}
