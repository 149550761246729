import { Dialog, DialogContent, DialogTrigger } from '@ff-it/ui';
import { ReactElement } from 'react';
import { Brief } from './Brief';

export function BriefButton({ className }: { className?: string }): ReactElement {
  return (
    <Dialog>
      <DialogTrigger>
        <a role="button" className={className} data-testid="toggle-brief">
          Brief
        </a>
      </DialogTrigger>
      <DialogContent testId="brief-dialog">
        <Brief />
      </DialogContent>
    </Dialog>
  );
}
