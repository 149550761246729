import { PlanRowKindIcon } from 'modules/campaign/common/PlanRowKindIcon';
import { ReactElement, ReactNode } from 'react';
import { RenderEditCellProps, RenderSummaryCellProps, textEditor } from 'components/DataGrid';
import { Box, Col, Row, openModal } from '@ff-it/ui';
import { SupplierSelector, SupplierTitle } from 'modules/supplier/supplier/components';
import type { RowField, PlanRow } from 'modules/campaign/row';
import type { Supplier } from 'modules/supplier/supplier/types';
import { TitleContainer } from 'components/TitleContainer';
import { ConfirmDialog } from 'components/Actions/createConfirmDialog';
import { GridRow, GridSummaryRow, RowOrGroup } from '../types';
import { Icon, useEntity } from 'components';
import { BlockDetails } from 'modules/campaign/block/types';
import { createColumn } from './createColumn';
import { commentContainer, supplierIconLock, supplierIcons } from './style.css';
import { usePlanController } from '../../usePlanController';
import { trapEnterHandler } from 'components/DataGrid/editors/utils';
import { fmt, parseBig } from 'utilities';
import { Budget } from '../../Budget';
import { emptyCellClassname } from 'components/DataGrid/style/cell.css';

export function getSupplier({ supplier, activity }: PlanRow): Supplier | null {
  // @FIXME #767
  return supplier
    ? {
        ...supplier,
        activity: activity || supplier.activity,
      }
    : null;
}

function checkHasValue(row: GridRow, supplier: Supplier | null): boolean {
  if (!supplier) {
    return false;
  }

  // # 1154, We can only keep values for same direction
  if (row.kind === 'SMART') {
    if (row.direction === supplier.product.direction && (row.rate.client || row.rate.provider)) {
      return true;
    }
    return false;
  }
  // @TODO RTB fields, should be on strategy?
  return ['gross_price', 'client_price', 'provider_price'].some((field) => row[field as RowField] !== null);
}

function Edit({ row, onClose }: RenderEditCellProps<GridRow, any>): ReactElement {
  // FIXME: this should use DialogEditor but would have to take SupplierSelector apart
  const value = getSupplier(row);
  const type = useEntity<BlockDetails>().item.type;

  const controller = usePlanController();

  const onChange = async (supplier: Supplier | null): Promise<void> => {
    const hasValue = checkHasValue(row, supplier);

    let _keep_values = false;
    if (hasValue) {
      const res = await openModal((props) => <ConfirmDialog {...props} title="Keep values?" />, {
        testId: 'confirm-dialog',
      });
      if (typeof res === 'undefined') {
        // bail
        return;
      }
      if (res) {
        _keep_values = true;
      }
    }
    await controller.formRequest({
      method: _keep_values ? 'PATCH' : 'PUT',
      url: `plan/rows/${row.id}/supplier/`,
      body: supplier,
    });
    onClose(false, true);
  };
  return (
    <div onKeyDown={trapEnterHandler}>
      <SupplierSelector
        value={value}
        type={type}
        kind={row.kind}
        onRequestClose={() => onClose(false, true)}
        onChange={onChange}
      />
    </div>
  );
}

const name = (
  <Box display="flex" flexDirection="row">
    <TitleContainer title="Department" subtitle="Product" style={{ flex: 1 }} />
    <TitleContainer title="Provider" subtitle="Activity" style={{ flex: 1 }} />
  </Box>
);

function ViewSummaryBudget({ row: { clientTotal } }: RenderSummaryCellProps<GridSummaryRow, RowOrGroup>): ReactNode {
  const { item } = useEntity<BlockDetails>();
  const budget = parseBig(item.budget);
  return (
    <Row alignItems="center">
      {budget && <Budget budget={budget} amount={clientTotal} details />}
      <Col textAlign="right" title={fmt(clientTotal)} data-testid="amount">
        {fmt(clientTotal)}
      </Col>
    </Row>
  );
}

export const SupplierColumn = createColumn(
  { key: 'supplier', name, width: 326, frozen: true },
  {
    renderView: ({ row }) => {
      const value = getSupplier(row);
      const comment = row.comment;
      return (
        <>
          <Box display="flex" alignItems="center">
            <Box className={supplierIcons}>
              <PlanRowKindIcon kind={row.kind} />
              {row.sums.is_locked && <Icon icon="lock" className={supplierIconLock} />}
            </Box>
            {value && <SupplierTitle {...value} flexGrow={1} minWidth={0} />}
          </Box>
          {comment && (
            <div data-testid="comment" className={commentContainer} title={comment}>
              {comment}
            </div>
          )}
        </>
      );
    },
    renderEdit: (props) => <Edit {...props} />,
    displayCellContent: true,
    className: (row) => (!row.supplier ? emptyCellClassname : undefined),
  },
  {
    renderView: ({ row }) =>
      // italic ?
      row.id === 0 ? (
        <Box fontWeight="regular" color="quarterary">
          {row.title}
        </Box>
      ) : (
        row.title
      ),
    renderEdit: (props) => textEditor({ ...props, field: 'title' }),
  },
  { renderSummaryCell: (props) => <ViewSummaryBudget {...props} /> },
);
