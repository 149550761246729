import cx from 'clsx';
import { ReactElement, ReactNode } from 'react';

interface PageProps {
  children: ReactNode;
  narrow?: boolean;
  className?: string;
}

export function Page({ children, className, narrow = false }: PageProps): ReactElement {
  return <div className={cx(className, 'px-3', narrow ? 'container ml-0' : 'container-fluid')}>{children}</div>;
}
