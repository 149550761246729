import { ReactElement, ReactNode } from 'react';
import { useField } from 'react-final-form';
import { Form, SelectField, TextField } from '@ff-it/form';
import { Button, DialogBody, DialogClose, DialogFooter, RenderProps } from '@ff-it/ui';
import { required } from 'utilities';
import { Options } from 'options';

export const __OTHER__ = '__OTHER__';

export const otherOption = {
  value: __OTHER__,
  label: 'Other*',
};

function OtherMotivation(): ReactElement | null {
  const {
    input: { value },
  } = useField('message', { subscription: { value: true } });
  if (value === __OTHER__) {
    return <TextField name="other" label="Reason" required multiline autosize rows={2} validate={required} />;
  }
  return null;
}

interface MotivationDialogProps extends RenderProps<{ message: string }> {
  options: Options;
  label: ReactNode;
}

export function MotivationDialog({ onSubmit, options, label }: MotivationDialogProps): ReactElement {
  return (
    <Form
      onSubmit={({ message, other }: any) => {
        onSubmit({ message: message === '__OTHER__' ? other : message });
      }}
      noValidate
    >
      <DialogBody>
        <SelectField
          name="message"
          label={label}
          options={options}
          isSearchable={false}
          validate={required}
          required
          simple
        />
        <OtherMotivation />
      </DialogBody>
      <DialogFooter>
        <Button variant="outline-danger" type="submit">
          Proceed
        </Button>
        <DialogClose className="ml-auto" />
      </DialogFooter>
    </Form>
  );
}
