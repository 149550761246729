import { useState } from 'react';
import useSWR from 'swr';
import { LoadingOverlay } from '@ff-it/ui';
import { Breadcrumb, Page, useEntity } from 'components';
import { CampaignDetails } from 'modules/campaign/campaign/types';
import { checkActionError, parseBig } from 'utilities';
import { fetcher } from 'services';
import { Container } from './Container';
import type { CorrectableInvoice, CorrectionPayload, CorrectorData } from './types';
import { toast } from 'react-toastify';

const mapPayload = ({ id, rows }: CorrectableInvoice): CorrectionPayload => ({
  invoice: id,
  correction: rows.map(({ id: campaign_row_id, overspent }) => ({
    campaign_row_id,
    delta: parseBig(overspent).mul(-1).toString(),
  })),
});

export function CampaignCorrector(): React.ReactElement {
  const { endpoint } = useEntity<CampaignDetails>();

  const url = `${endpoint}correction/`;

  const {
    data,
    isLoading,
    error: loadingError,
    mutate,
  } = useSWR<CorrectorData>({
    url,
    method: 'GET',
  });

  const [isMutating, setIsMutating] = useState(false);
  const [mutationError, setMutationError] = useState<any>(undefined);

  const handleCorrection = async (invoice: CorrectableInvoice): Promise<void> => {
    setIsMutating(true);

    // startTransition? safe to unmount?
    try {
      await mutate(
        fetcher<CorrectorData>({
          url,
          method: 'POST',
          body: mapPayload(invoice),
        }),
        {
          throwOnError: true,
          revalidate: false,
        },
      );
      setIsMutating(false);
      toast.success('Invoice links have been adjusted.');
    } catch (err: any) {
      if (!checkActionError(err)) {
        setMutationError(err);
      }
      setIsMutating(false);
    }
  };

  if (loadingError) {
    throw loadingError;
  }
  if (mutationError) {
    throw mutationError;
  }

  return (
    <Page className="scene flex-grow-1 d-flex flex-column" narrow>
      <Breadcrumb to={null}>Correct</Breadcrumb>
      <LoadingOverlay visible={isLoading || isMutating} />
      <h4 className="mt-2">Correct overspent amounts</h4>
      {data && <Container data={data} handleCorrection={handleCorrection} />}
    </Page>
  );
}
