import { useEffect } from 'react';
import { useSession } from 'services';

export function useDocumentTitle(title: string): void {
  const session = useSession();

  let newTitle = `Niit CRM - ${title}`;
  if (session.scope) {
    newTitle = `${newTitle} - ${session.scope.title}`;
  }
  useEffect(() => {
    const originalTitle = document.title;
    document.title = newTitle;
    return () => {
      document.title = originalTitle;
    };
  }, [newTitle]);
}
