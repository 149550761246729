import { Check, checkPerm, sessionUser } from 'core/permissions';
import invariant from 'tiny-invariant';
import { Client } from './types';
import campaignPermissions from 'modules/campaign/campaign/permissions';
import agreementPermissions from './agreement/permissions';
import clientPermissions from './persons/permissions';

export function isManagerOfClient(client: Client): Check {
  return (sess) => {
    if (!checkPerm(sess, campaignPermissions.manage)) {
      return false;
    }

    const currentUser = sessionUser(sess);
    invariant(currentUser);

    if (currentUser.is_superuser) {
      return true;
    }

    return client.managers.findIndex((m) => m.user.id === currentUser?.id) !== -1;
  };
}

export function canViewClientAgreements(client: Client): Check {
  return (sess) =>
    checkPerm(sess, agreementPermissions.view) ||
    (checkPerm(sess, agreementPermissions.view_managed) && checkPerm(sess, isManagerOfClient(client)));
}

export function canViewClientPersons(client: Client): Check {
  return (sess) =>
    checkPerm(sess, clientPermissions.view) ||
    (checkPerm(sess, clientPermissions.view_managed) && checkPerm(sess, isManagerOfClient(client)));
}
