import { ReactElement, ReactNode } from 'react';
import { useEntity } from './context';
import { getIn } from 'final-form';
import cx from 'clsx';

export interface FieldProps {
  name?: string;
  className?: string;
  label?: ReactNode;
  help?: ReactNode;
  children?: (v: any) => ReactNode | string;
}

export function Field({ name, label, className, help, children }: FieldProps): ReactElement {
  const { item } = useEntity();
  const v = name ? getIn(item, name) : item;
  return (
    <div className={cx('form-group', className)}>
      {label && <small className="font-weight-bold">{label}</small>}
      <div className="form-control-plaintext py-0" data-testid-field={name}>
        {v ? (children ? children(v) : v) : '—'}
      </div>
      {help && <small className="form-text text-muted mt-0">{help}</small>}
    </div>
  );
}
