import { type ComponentType, type ReactElement, useState, memo } from 'react';
import type { Amounts, EntityMap } from '../types';
import type { Linkable, Side } from './types';
import { AddButton, BlockWidget, RemoveButton, TailToggler } from 'components';
import { LinkedRow, CandidateRow, RowProps } from './Row';
import Big from 'big.js';
import { fmt } from 'utilities';
import { RowContainer } from './RowContainer';
import { useForm, useIsDisabled } from '@ff-it/form';
import { header, oddRow } from 'styles/style.css';

function chopRows(rows: Linkable[], headSize: number): [head: Linkable[], tail: Linkable[]] {
  if (rows.length <= headSize + 1) {
    return [rows, []];
  }
  return [rows.slice(0, headSize), rows.slice(headSize)];
}

function sumRows(rows: Linkable[]): Amounts {
  let invoiceable = Big(0);
  let invoiced = Big(0);
  let balance = Big(0);

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    invoiceable = invoiceable.plus(row.invoiceable);
    invoiced = invoiced.plus(row.invoiced);
    balance = balance.plus(row.balance);
  }
  return {
    invoiceable,
    invoiced,
    balance,
  };
}

interface TailProps {
  rows: Linkable[];
  entities: EntityMap;
  Row: ComponentType<RowProps>;
  disabled: boolean;
}

const HEAD_SIZE = 3;

function Tail({ rows, Row, entities, disabled }: TailProps): ReactElement {
  const [showTail, setShow] = useState<boolean>(false);
  const tail = showTail
    ? rows.map((row, idx) => (
        <Row
          linkable={row}
          entities={entities}
          key={row.id}
          className={(idx + HEAD_SIZE) % 2 === 0 ? oddRow : undefined}
          disabled={disabled}
        />
      ))
    : null;
  return (
    <>
      {tail}
      <tr>
        <td colSpan={7} className="p-0">
          <TailToggler count={rows.length} show={showTail} setShow={setShow} />
        </td>
      </tr>
    </>
  );
}

type BlockProps = {
  blockId: string;
  entities: EntityMap;
  side: Side;
  rows: Linkable[];
};

export const Block = memo(function Block({ blockId, entities, side, rows }: BlockProps): React.ReactElement {
  const disabled = useIsDisabled();
  const form = useForm();

  let Row: ComponentType<RowProps>;
  let control = null;
  if (side === 'linked') {
    Row = LinkedRow;
    control = (
      <RemoveButton
        icon="circle-xmark"
        onClick={() => {
          form.batch(() => {
            rows.forEach((row) => form.change(`bound._${row.id}`, undefined));
          });
        }}
      />
    );
  } else if (side === 'candidates') {
    Row = CandidateRow;
    control = (
      <AddButton
        icon="circle-plus"
        onClick={() => {
          form.batch(() => {
            rows.forEach((row) => form.change(`bound._${row.id}`, row.balance.toFixed()));
          });
        }}
      />
    );
  } else {
    throw new Error('ValueError');
  }

  const { invoiceable, invoiced, balance } = sumRows(rows);
  const [head, tail] = chopRows(rows, HEAD_SIZE);

  const block = entities.block[blockId];

  return (
    <tbody>
      <RowContainer
        cell="th"
        className={header.dark}
        supplier={<BlockWidget {...block} link flat />}
        invoiceable={fmt(invoiceable)}
        invoiced={fmt(invoiced)}
        balance={fmt(balance)}
        control={disabled ? undefined : control}
      />
      {head.map((row, idx) => (
        <Row
          linkable={row}
          key={row.id}
          entities={entities}
          className={idx % 2 === 0 ? undefined : oddRow}
          disabled={disabled}
        />
      ))}
      {tail.length !== 0 && <Tail rows={tail} Row={Row} entities={entities} disabled={disabled} />}
    </tbody>
  );
});
