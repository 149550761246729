import { ReactElement } from 'react';
import type { EntityMap } from '../types';
import type { Linkable, Side } from './types';
import { Campaign } from './Campaign';
import { useGroupRows } from './useGroupRows';

type CampaignsProps = {
  rows: Linkable[];
  entities: EntityMap;
  side: Side;
};
export function Campaigns({ rows, entities, side }: CampaignsProps): ReactElement {
  const campaingRows = useGroupRows(rows, 'campaign_id');

  return (
    <>
      {Object.keys(campaingRows).map((campaignId) => (
        <Campaign
          key={campaignId}
          campaignId={campaignId}
          entities={entities}
          side={side}
          rows={campaingRows[campaignId]}
        />
      ))}
    </>
  );
}
