import { Link } from 'react-router-dom';
import { bool } from 'components/columns';
import type { EmbeddedUser } from 'modules/core/types';
import { TableColumns } from '@ff-it/ui';

export const columns: TableColumns<EmbeddedUser> = [
  {
    content: 'Email',
    key: 'email',
    render: ({ id, email }: EmbeddedUser) => <Link to={id.toString()}>{email}</Link>,
  },
  {
    content: 'First name',
    key: 'first_name',
  },
  {
    content: 'Last name',
    key: 'last_name',
  },
  bool('Active', 'is_active'),
  bool('Manage group', 'manage_group'),
  bool('Superuser', 'is_superuser'),
];
