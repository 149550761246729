import { ReactElement } from 'react';
import { DialogClose, RenderProps } from '@ff-it/ui';
import { Submit, useSubmitHandler } from '@ff-it/form';
import type { ActivityType } from 'types';
import type { BlockDetails } from '../types';
import { BriefForm } from './BriefForm';
import { CampaignDetails } from 'modules/campaign/campaign/types';

const fields: Partial<Record<ActivityType, object>> = {
  TV: {
    clip_length: '',
    yearly_budget_by_group: '',
    agreed_prices: '',
  },
  RADIO: {
    spot_length: '',
    time_zones: '',
  },
  PRINT: {
    area: '',
    orientation: '',
    mockup_ready: '',
  },
  WEB: {
    suggested_format: '',
    video: '',
    mobile: '',
    kpi: '',
  },
  SEO: {
    website: '',
    public: false,
    indexed: false,
    audit: false,
    consultations: false,
    meta_tags: false,
    content: false,
    backlinks: false,
    articles: false,
    reports: false,
  },
};

const specialization: Partial<Record<ActivityType, object>> = {
  CREATIVE: {
    first_sketch: null,
    materials: [
      {
        kind: '',
        amount: null,
      },
    ],
    styleguide: '',
    copywrite: '',
    website: '',
    social_media: '',
  },
};

interface CreateBlockDialogProps extends RenderProps<BlockDetails> {
  type: ActivityType;
  campaign: CampaignDetails;
  copyValues?: any;
}

export function CreateBlockDialog({
  onSubmit: onSuccess,
  type,
  campaign,
  copyValues,
}: CreateBlockDialogProps): ReactElement {
  const submitHandler = useSubmitHandler<any, BlockDetails>(
    {
      url: `campaign/campaigns/${campaign.id}/blocks/`,
      method: 'POST',
    },
    { onSuccess },
  );

  return (
    <BriefForm
      title={copyValues ? 'Copy brief' : 'Create brief'}
      type={type}
      campaign={campaign}
      submitHandler={submitHandler}
      initialValues={
        copyValues ||
        ({
          type,
          fields: fields[type] || {},
          ...specialization[type],
        } as any)
      }
    >
      <Submit>Create</Submit>
      <DialogClose className="ml-auto" />
    </BriefForm>
  );
}
