import { ActionDropdown } from 'components';
import type { ReactElement } from 'react';
import { UseImportExportActionsArgs, useImportExportActions } from './useImportExportActions';

export function ImportExportButton(props: UseImportExportActionsArgs): ReactElement {
  const actions = useImportExportActions(props);
  return (
    <ActionDropdown actions={actions} variant="outline-secondary" icon="file-xls" testId="import-export-actions">
      Import / Export
    </ActionDropdown>
  );
}
