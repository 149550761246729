import { parseBig } from 'utilities';
import type { Bound } from 'modules/linker/types';
import type { LinkerSummaryState } from '../LinkerSummary/types';
import type { LinkFields } from 'modules/invoicing/common/types';
import Big from 'big.js';

export function calcSummaryState(
  { link_amount, link_credited, link_compensated }: LinkFields,
  bound: Bound,
): LinkerSummaryState {
  const amount = parseBig(link_amount);
  const credited = parseBig(link_credited);
  const compensated = parseBig(link_compensated);

  const applied = Object.values(bound)
    .reduce((acc, v) => acc.plus(parseBig(v)), Big(0))
    .round(2);

  const balance = amount.minus(credited).minus(compensated).minus(applied);
  return {
    amount,
    credited,
    compensated,
    applied,
    balance,
  };
}
