import { Fragment, ReactElement, ReactNode } from 'react';
import type { BlockDetails } from 'modules/campaign/block/types';
import type { ActivityType } from 'types';

const bool = (v: any): ReactNode => (v ? 'Yes' : 'No');

const genericFields: Partial<
  Record<
    ActivityType,
    {
      key: string;
      label: ReactNode;
      render?: (v: any) => ReactNode;
    }[]
  >
> = {
  TV: [
    { key: 'clip_length', label: 'Clip length (seconds)' },
    { key: 'yearly_budget_by_group', label: 'Yearly budget by media group' },
    { key: 'agreed_prices', label: 'Agreed prices' },
  ],
  WEB: [
    { key: 'suggested_format', label: 'Suggested format' },
    { key: 'video', label: 'Video, clip length' },
    { key: 'mobile', label: 'Mobile' },
    { key: 'kpi', label: 'KPI' },
  ],
  PRINT: [
    { key: 'area', label: 'Area' },
    { key: 'orientation', label: 'Orientation' },
    { key: 'mockup_ready', label: 'Mockup read' },
  ],
  RADIO: [
    { key: 'spot_length', label: 'Spot length (seconds)' },
    { key: 'time_zones', label: 'Suggested time zones' },
  ],
  RTB: [{ key: 'kpi', label: 'KPI' }],
  SEO: [
    { key: 'website', label: 'Website' },
    { key: 'public', label: 'Public', render: bool },
    { key: 'indexed', label: 'Indexed', render: bool },
    { key: 'audit', label: 'Audit', render: bool },
    { key: 'consultations', label: 'Consultations', render: bool },
    { key: 'meta_tags', label: 'Meta tags', render: bool },
    { key: 'content', label: 'Content', render: bool },
    { key: 'backlinks', label: 'Backlinks', render: bool },
    { key: 'articles', label: 'Articles', render: bool },
    { key: 'reports', label: 'Reports', render: bool },
  ],
};

export function Generic({ type, fields }: BlockDetails): ReactElement {
  return (
    <dl>
      {(genericFields[type] || []).map(({ key, label, render }) => {
        const value = fields[key];
        if (!value) return null;
        return (
          <Fragment key={key}>
            <dt>{label}</dt>
            <dd data-test-id={`field-${key}`}>{render ? render(fields[key]) : fields[key]}</dd>
          </Fragment>
        );
      })}
    </dl>
  );
}
