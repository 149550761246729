import { Row } from './types';
import { sumColumns } from '../common';
import { TableColumns } from '@ff-it/ui';

export const columns: TableColumns<Row> = [
  {
    content: 'Agency',
    key: 'enterprise_slug',
    width: 80,
  },
  {
    content: 'Type',
    key: 'type',
    sortable: true,
  },
  ...sumColumns,
];
