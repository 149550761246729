import type { PlanRow } from 'modules/campaign/row';
import { parseBig } from 'utilities';

export function goalSeekProps(
  row: PlanRow,
  currentQuantity?: string,
):
  | {
      balance: Big;
      price: Big;
    }
  | undefined {
  const client_price = row.client_price;
  const rawBalance = row.sums.planned_budget?.balance;

  // budget has to be set, and client price has to be set
  if (client_price && rawBalance) {
    const price = parseBig(client_price);
    let balance = parseBig(rawBalance);
    // if current value is set we have to deduce that
    if (currentQuantity) {
      const currentAmount = price.mul(parseBig(currentQuantity));
      balance = balance.plus(currentAmount);
    }
    if (balance.gt(0) && !price.eq(0)) {
      return {
        balance,
        price,
      };
    }
  }

  return;
}
