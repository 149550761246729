import { CreativeBlockDetails } from 'modules/campaign/block/types';
import { ReactElement } from 'react';
import { formatDate, nl2br } from 'utilities';

export function Creative({
  first_sketch,
  materials,
  styleguide,
  copywrite,
  website,
  social_media,
}: CreativeBlockDetails): ReactElement {
  return (
    <dl>
      <dt>First sketch</dt>
      <dd data-test-id="field-first_sketch">{formatDate(first_sketch)}</dd>
      <dt>Materials</dt>
      <dd data-test-id="field-materials">
        <ul className="mb-0">
          {materials.map(({ kind, amount }, idx) => (
            <li key={idx}>
              {kind} × {amount}
            </li>
          ))}
        </ul>
      </dd>
      <dt>Style guidelines</dt>
      <dd data-test-id="field-styleguide">{nl2br(styleguide)}</dd>
      <dt>Copywrite</dt>
      <dd data-test-id="field-copywrite">{nl2br(copywrite)}</dd>
      <dt>Website</dt>
      <dd data-test-id="field-website">{website}</dd>
      <dt>Social media</dt>
      <dd data-test-id="field-social_media">{social_media}</dd>
    </dl>
  );
}
