import { ReactElement } from 'react';
import { TextField, CheckField, SelectField } from '@ff-it/form';
import { Field } from 'react-final-form';
import { CountryField } from 'components';
import { companyKindOptions } from 'configuration';
import { identity } from 'utilities';
import { CompanyCodeField } from './CompanyCodeField';

export function CommonCompanyFields(): ReactElement {
  return (
    <>
      <div className="form-row">
        <TextField name="title" label="Title" help="Full legal name" className="col-md-4" required />
        <TextField
          name="internal_name"
          label="Internal name"
          className="col-md-4"
          required
          help="Name for internal use (useful in cases where the full name of the company is very long)"
        />
        <CompanyCodeField name="code" className="col-md-4" help="Unique code which will appear in the campaigns" />
      </div>
      <div className="form-row">
        <SelectField name="kind" label="Kind" className="col-md-2" required options={companyKindOptions} simple />
        <CountryField name="country" label="Country" className="col-md-2" required />
        <TextField name="registration_number" label="Registration number" className="col-md-4" required />
        <TextField name="vat_number" label="VAT number" className="col-md-4" parse={identity} />
      </div>
      <div className="form-row">
        <TextField
          name="legal_address"
          label="Legal address"
          multiline
          autosize
          rows={2}
          className="col-md-6"
          required
        />

        <Field name="office_same_as_legal" subscription={{ value: true }}>
          {({ input: { value } }) =>
            value ? null : (
              <TextField
                name="office_address"
                label="Office address"
                multiline
                autosize
                rows={2}
                className="col-md-6"
                required
              />
            )
          }
        </Field>
      </div>
      <div className="form-row">
        <CheckField
          name="office_same_as_legal"
          fieldLabel="Office address same as legal"
          variant="switch"
          className="col-md-6"
        />
      </div>
    </>
  );
}
