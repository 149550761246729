import { ControlledTable, ControlledTableProps, KeyGetter, RowType, TableFilter } from 'components/ControlledTable';
import { ReactElement, ReactNode } from 'react';
import { Box } from '@ff-it/ui';
import { TotalsHeader } from './TotalsHeader';
import { XLSXDownloadButton } from './XLSXDownload';
import { reportTable } from './style.css';

type ReportTableProps<R extends RowType> = Pick<
  ControlledTableProps<R>,
  'columns' | 'defaultFilter' | 'renderTable' | 'queryParams' | 'storageKey'
> & {
  url: string;
  filterFields?: ReactNode;
};

export function ReportTable<R extends RowType>({ url, filterFields, ...rest }: ReportTableProps<R>): ReactElement {
  const getKey: KeyGetter = ({ filter, pageSize, pageIndex, sort, queryParams }) => {
    /*
    Same as `generic DRF key getter` exepct for
      * POST
      * passing sort as object
     */

    return {
      url,
      method: 'POST',
      body: {
        // FIXME
        ...filter,
        page_size: pageSize,
        page: pageIndex,
        ordering: sort
          ? {
              order: sort.direction || 'DESC',
              key: sort.key,
            }
          : undefined,
        ...queryParams,
      },
    };
  };

  return (
    <ControlledTable<R>
      pageSize={50}
      getKey={getKey}
      storageKey={url}
      tableProps={{
        subHeaders: <TotalsHeader />,
        size: 'sm',
        fontSize: 'sm',
        variant: 'dark',
        hover: true,
        striped: true,
        fixed: true,
        tableClassName: reportTable,
        before: (
          <Box display="flex" marginTop="sm">
            <Box>
              <TableFilter>{filterFields}</TableFilter>
            </Box>
            <XLSXDownloadButton url={url} />
          </Box>
        ),
      }}
      {...rest}
    />
  );
}
