import { useMemo } from 'react';
import type { PlanRow } from 'modules/campaign/row';
import type { Calendar, Segment } from './types';
import { calcRowSegments } from './calc';

export function useRowSegments(
  positions: PlanRow['positions'],
  intervalToBounds: Calendar['intervalToBounds'],
): Segment[] {
  return useMemo(() => calcRowSegments(positions, intervalToBounds), [positions, intervalToBounds]);
}
