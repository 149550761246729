import { ReactElement, useState } from 'react';
import { AuthContainer } from 'components';
import { Link, useParams } from 'react-router-dom';
import { Form, Submit, TextField, useSubmitHandler } from '@ff-it/form';
import { Alert, Button, LoadingContainer } from '@ff-it/ui';
import useSWR from 'swr';

function InviteForm({ url, initialValues }: { url: string; initialValues: any }): ReactElement {
  const [done, setDone] = useState<boolean>(false);

  const submitHandler = useSubmitHandler(
    {
      url,
      method: 'POST',
    },
    {
      onSuccess: () => {
        setDone(true);
      },
    },
  );

  if (done) {
    return (
      <div className="mt-4">
        <p>Your password has been set. You may go ahead and sign in now.</p>
        <Button variant="outline-primary" size="lg" to="/login" component={Link}>
          Sign in
        </Button>
      </div>
    );
  }

  return (
    <Form onSubmit={submitHandler} autoComplete="off" initialValues={initialValues} noValidate>
      <div className="form-row">
        <TextField name="first_name" label="First name" className="col-md-6" required size="lg" />
        <TextField name="last_name" label="Last name" className="col-md-6" required size="lg" />
      </div>
      <hr />
      <TextField name="new_password" label="Password" type="password" required size="lg" />
      <TextField name="new_password_repeat" label="Repeat password" type="password" required size="lg" />
      <Submit variant="primary" size="lg" className="w-100" />
    </Form>
  );
}

export function Invite(): ReactElement {
  const { token } = useParams();
  const url = `auth/invite/${token}/`;
  const { isLoading, data } = useSWR({
    url,
    method: 'GET',
  });

  return (
    <AuthContainer wide>
      <h3 className="text-center mb-3">Set password</h3>
      <LoadingContainer loading={isLoading}>
        {isLoading ? null : data ? (
          <InviteForm url={url} initialValues={data} />
        ) : (
          <Alert variant="danger" className="mt-4">
            The invite link was invalid, possibly because it has already been used.
          </Alert>
        )}
      </LoadingContainer>
    </AuthContainer>
  );
}
