import { createSelectField } from './createSelectField';
import type { EmbeddedEnterprise, Enterprise } from 'modules/core/types';
import { CompanyTitle } from 'modules/core/company/components/CompanyTitle';

export const enterpriseFieldProps = {
  getOptionLabel: (a: EmbeddedEnterprise) => a.company.title,
  getOptionValue: (a: EmbeddedEnterprise) => a.id.toString(),
  formatOptionLabel: (a: EmbeddedEnterprise) => <CompanyTitle {...a.company} />,
};

export const EnterpriseField = createSelectField<Enterprise>('core/enterprises/', enterpriseFieldProps);
