import { ReactElement } from 'react';
import { withHasPerm } from 'components';
import { Provider } from './Provider';
import permissions from './permissions';
import { LocalVisibilityReport, ReportContainer } from '../common';

export function LocalProvider(): ReactElement {
  return (
    <LocalVisibilityReport
      localPerm={permissions.local}
      managedPerm={permissions.local_managed}
      Component={Provider}
      title="Providers"
    />
  );
}

export const GlobalProvider = withHasPerm(
  () => (
    <ReportContainer title="Providers">
      <Provider visibility="GLOBAL" />
    </ReportContainer>
  ),
  permissions.global,
);
