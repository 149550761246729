// import type { PlanResolution } from 'modules/campaign/block/types';
import type { PositionError } from './types';

export const positionErrors: Record<PositionError, string> = {
  overlap: 'Positions overlap',
};

export const INNER_ROW_HEIGHT = 39;
export const INNER_HEADER_HEIGHT = 37;
export const CALENDAR_ROW_HEIGHT = Math.floor(INNER_HEADER_HEIGHT / 3);
