export default {
  // CRUD
  view: 'guarantee.view_cvb',
  add: 'guarantee.add_cvb',
  change: 'guarantee.change_cvb',
  delete: 'guarantee.delete_cvb',
  // Views
  invoice: 'guarantee.invoice_cvb',
  sensitive_info: 'guarantee.sensitive_info_cvb',
};
