import { openModal } from '@ff-it/ui';
import type { ReactElement } from 'react';
import { PlanningConfig, PlanState } from '../types';
import { useMembership } from 'services';
import type { ActivityType } from 'types';
import { PlanController } from '../usePlanController';
import { strategies } from 'modules/campaign/block/strategy';
import { ButtonGroupField, DialogForm } from 'components';
import type { PlanResolution } from 'modules/campaign/block/types';
import { createOptions } from 'options';
import { CheckField, DecimalField } from '@ff-it/form';
import { useFieldValue } from 'hooks';

type SettingsDialogProps = {
  planState: PlanState;
  controller: PlanController;
  type: ActivityType;
};

const resolutionOptions = createOptions<PlanResolution>(['DAY', 'WEEK', 'MONTH']);

type SettingsFormProps = { type: ActivityType };

function ReachFields(): ReactElement {
  const reach = useFieldValue<boolean>('plan_reach');

  return (
    <>
      <hr />
      <CheckField name="plan_reach" label="Estimate reach" variant="switch" fieldLabel="Reach columns" />
      {reach && (
        <div className="form-row">
          <DecimalField label="Target group size" name="target_group_size" className="col-md-6" required />
          <DecimalField label="Media overlap rate %" name="plan_reach_media_overlap" className="col-md-6" />
        </div>
      )}
    </>
  );
}

function SettingsForm({ type }: SettingsFormProps): ReactElement {
  const planningConfig: PlanningConfig = useMembership().planning_config;
  const { available_strategies: available, lock } = planningConfig[type];
  const strategy = useFieldValue('plan_strategy');

  return (
    <>
      <ButtonGroupField
        disabled={lock}
        label="Plan strategy"
        name="plan_strategy"
        variant="outline-primary"
        size="sm"
        options={available.map((value) => ({ value, label: strategies[value].TITLE }))}
      />
      <ButtonGroupField
        label="Calendar scale"
        name="plan_resolution"
        variant="outline-primary"
        size="sm"
        options={resolutionOptions}
      />
      {strategy === 'RTB' && <ReachFields />}
    </>
  );
}

export function openSettings({
  type,
  planState: { planning, plan_resolution, plan_strategy, plan_reach, target_group_size, plan_reach_media_overlap },
  controller,
}: SettingsDialogProps) {
  openModal(
    (props) => (
      <DialogForm
        disabled={!planning}
        {...props}
        dialogHeader="Plan settings"
        initialValues={{
          plan_strategy,
          plan_resolution,
          plan_reach,
          target_group_size,
          plan_reach_media_overlap,
        }}
        submitHandler={controller.updateSettings}
      >
        <SettingsForm type={type} />
      </DialogForm>
    ),
    {
      testId: 'plan-settings',
    },
  );
}
