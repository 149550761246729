import { ReactElement } from 'react';
import type { BoundState as BoundStateType } from 'modules/campaign/common/types';
import { Badge } from './Badge';
import { Box, Button, Popover, Spinner } from '@ff-it/ui';
import { InvoiceDirection } from 'modules/invoicing/common/types';
import useSWR from 'swr';
import { RowLinkState, RowLinkStateProps } from 'components/RowLinkState';
import { EnterpriseLink } from 'components/navigation';
import { serializeQs } from 'components/ControlledTable/useQueryState';

interface BaseProps {
  state: BoundStateType;
  is_overspent: boolean;
  variant: 'income' | 'expense';
  kind?: 'net' | 'bonus';
}

type BoundStateProps = BaseProps & {
  row?: number | string;
};

type RowLinkStateResponse = Pick<RowLinkStateProps, 'months' | 'invoices'> & {
  filterCandidates?: {
    client: number;
    provider: number;
  };
};

//

function RowLinkStateLoader({
  id,
  direction,
  kind = 'net',
}: {
  id: number | string;
  direction: InvoiceDirection;
  // if ommited, defaults to net + fee
  kind?: 'net' | 'bonus';
  className?: string;
}): React.ReactElement {
  const { data } = useSWR<RowLinkStateResponse>({
    url: `campaign/rows/${id}/link_state/${direction}/${kind}/`,
    method: 'GET',
  });

  return data ? (
    <Box>
      <RowLinkState {...data} direction={direction} />
      {data.filterCandidates && (
        <Box padding="xs" display="flex" justifyContent="center">
          <Button
            variant="link"
            component={EnterpriseLink}
            className="p-0"
            to={`/invoices/expense${serializeQs({
              pageIndex: 1,
              pageSize: 15,
              sort: undefined,
              filter: {
                client: data.filterCandidates.client.toString(),
                partner: data.filterCandidates.provider?.toString(),
                sums__state: ['NONE', 'PARTIAL'],
              },
            })}`}
            size="sm"
          >
            Find expenses
          </Button>
        </Box>
      )}
    </Box>
  ) : (
    <Box
      display="flex"
      alignItems="center"
      padding="md"
      style={{
        // width to align properly before it loads
        width: '228px',
      }}
    >
      <Spinner />
    </Box>
  );
}

/**
 * There are multiple ways to display bound state:
 * 1) only badge
 * 2) lazy campaign row
 */
export function BoundState({ row, ...props }: BoundStateProps): ReactElement {
  if (row) {
    return (
      <Popover
        placement="auto"
        offsetOptions={6}
        content={<RowLinkStateLoader id={row} direction={props.variant} kind={props.kind} />}
      >
        <Badge {...props} className="clickable" />
      </Popover>
    );
  }
  return <Badge {...props} />;
}
