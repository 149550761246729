import { RenderProps } from '@ff-it/ui';
import { DialogForm } from 'components';
import { ReactElement } from 'react';
import { PlanController } from '../usePlanController';
import type { GridRow } from '../Grid/types';
import { PlanFactor } from 'modules/campaign/block/types';
import { DepartmentFactor } from 'modules/supplier/factors';
import invariant from 'tiny-invariant';
import { FactorSelector } from './FactorSelecor';
import { useDepartmentFactors, usePlanFactors } from '../useFactors';

function useRowFactors(row: GridRow): {
  planFactors: PlanFactor[];
  departmentFactors: DepartmentFactor[];
} {
  invariant(row.supplier);
  const allPlanFactors = usePlanFactors();

  const departmentIds = new Set([row.supplier?.product.department.id || 0]);
  // product might get assigned different department
  const factorIds = new Set([...row.factors.client, ...row.factors.provider]);
  const planFactors = allPlanFactors.filter((f) => departmentIds.has(f.department.id) || factorIds.has(f.id));

  const planFactorKeys = planFactors.map(({ name }) => name.toLowerCase().trim());
  const departmentFactors = useDepartmentFactors(row.supplier.product).filter(
    (d) => !planFactorKeys.includes(d.name.toLowerCase().trim()),
  );

  return {
    planFactors,
    departmentFactors,
  };
}

export function RowFactorsDialog({
  row,
  controller,
  ...props
}: { row: GridRow; controller: PlanController } & RenderProps): ReactElement {
  const { planFactors, departmentFactors } = useRowFactors(row);
  const department = row.supplier!.product.department;
  return (
    <DialogForm
      {...props}
      dialogHeader="Row factors"
      initialValues={row.factors}
      submitHandler={async ({
        client,
        provider,
      }: {
        client: (number | DepartmentFactor)[];
        provider: (number | DepartmentFactor)[];
      }) => {
        // FIXME: we create plan factors from departemnt factors
        const createdFactors: Record<number, number> = {};
        for (let i = 0; i < client.length; i++) {
          const factor = client[i];
          if (typeof factor !== 'number') {
            if (!createdFactors[factor.id]) {
              createdFactors[factor.id] = (
                await controller.createPlanFactorFromDepartmentFactor({
                  ...factor,
                  department,
                })
              ).id;
            }
            client[i] = createdFactors[factor.id];
          }
        }

        for (let i = 0; i < provider.length; i++) {
          const factor = provider[i];
          if (typeof factor !== 'number') {
            if (!createdFactors[factor.id]) {
              createdFactors[factor.id] = (
                await controller.createPlanFactorFromDepartmentFactor({
                  ...factor,
                  department,
                })
              ).id;
            }
            provider[i] = createdFactors[factor.id];
          }
        }

        return controller.formRequest({
          method: 'PUT',
          url: `plan/rows/${row.id}/factors/`,
          body: { client, provider },
        });
      }}
    >
      <FactorSelector planFactors={planFactors} departmentFactors={departmentFactors} controller={controller} />
    </DialogForm>
  );
}
