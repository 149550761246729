import { LoadingContainer } from '@ff-it/ui';
import { Section } from './Section';
import { SectionType } from './types';
import { PermissionContext, useCreatePermissionsContext } from './context';
import { ReactElement } from 'react';
import useSWR from 'swr';

function ManagePermissions({ sections }: { sections: SectionType[] }): ReactElement {
  return (
    <div>
      {sections.map((section, index) => (
        <Section {...section} key={index} />
      ))}
    </div>
  );
}

export function Permissions(): ReactElement {
  const { isLoading, data } = useSWR<SectionType[]>({ url: 'auth/permissions/', method: 'GET' });
  const ctx = useCreatePermissionsContext();

  return (
    <div className="container ml-0 mb-5">
      <PermissionContext.Provider value={ctx}>
        <LoadingContainer loading={isLoading || ctx.loading}>
          {data && <ManagePermissions sections={data} />}
        </LoadingContainer>
      </PermissionContext.Provider>
    </div>
  );
}
