import { Box, Button, Check, DialogBody, DialogClose, DialogHeader, openModal } from '@ff-it/ui';
import { ReactElement, useState } from 'react';
import { Summary } from './Summary';
import { PlanRow } from 'modules/campaign/row';
import { Icon } from 'components';

type SummaryDialogProps = {
  rows: PlanRow[];
};

function SummaryDialog({ rows }: SummaryDialogProps): ReactElement {
  const [showMonths, setShowMonths] = useState<boolean>(false);

  return (
    <>
      <DialogHeader title="Plan summary">
        <Box marginLeft="md" marginTop="xs">
          <Check
            checked={showMonths}
            onChange={(e) => setShowMonths(e.target.checked)}
            label="Expand months"
            inline
            variant="switch"
          />
        </Box>
        <DialogClose className="ml-auto" size="sm" />
      </DialogHeader>
      <DialogBody>
        <Summary showMonths={showMonths} rows={rows} />
      </DialogBody>
    </>
  );
}

export function SummaryButton({ rows }: SummaryDialogProps): ReactElement {
  return (
    <Button
      variant="outline-secondary"
      data-testid="toggle-summary"
      onClick={() => openModal(() => <SummaryDialog rows={rows} />, { className: 'right', testId: 'summary' })}
    >
      Summary <Icon icon="list-tree" className="ml-1" />
    </Button>
  );
}
