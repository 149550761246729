import { ReactElement } from 'react';
import { Link, useRoutes } from 'react-router-dom';
import { CreateScene, DeleteScene, EntityScene, ModelScene, IndexScene, UpdateScene, useEntity } from 'components';
import type { DepartmentSet, ListPriceList } from 'modules/supplier/department/types';
import permissions from 'modules/supplier/department/permissions';
import { formatDate } from 'utilities';
import { PriceListForm } from 'modules/supplier/department';
import { TableColumns } from '@ff-it/ui';

const columns: TableColumns<ListPriceList> = [
  {
    content: 'Period',
    key: 'date_from',
    sortable: true,
    render: ({ id, date_from, date_to }: ListPriceList) => (
      <Link to={`${id}`}>
        {formatDate(date_from)} - {formatDate(date_to)}
      </Link>
    ),
  },
];

const initialValues = {
  date_from: null,
  date_to: null,
  prices: {},
};

export function PriceLists(): ReactElement {
  const {
    item: { id, channels },
  } = useEntity<DepartmentSet>();

  const routes = useRoutes([
    { index: true, element: <IndexScene columns={columns} filterFields={null} /> },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <PriceListForm channels={channels} />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <UpdateScene viewDisabled>
              <PriceListForm channels={channels} />
            </UpdateScene>
          ),
        },

        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ]);
  return (
    <ModelScene
      endpoint={`supplier/department_sets/${id}/price_lists/`}
      title="Price list"
      pluralTitle="Price lists"
      entityTitle={({ date_from, date_to }: ListPriceList) => `${formatDate(date_from)} - ${formatDate(date_to)}`}
      permissions={permissions}
    >
      {routes}
    </ModelScene>
  );
}
