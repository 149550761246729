import type { Maybe } from '../types';

export function stopPropagation(event: React.SyntheticEvent): void {
  event.stopPropagation();
}

export function scrollIntoView(element: Maybe<Element>): void {
  element?.scrollIntoView({
    inline: 'nearest',
    block: 'nearest',
    //  behavior: 'smooth'
  });
}
