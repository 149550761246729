import { useMemo } from 'react';

/**
 * Splits row into head of size and remaining tail
 * allows for one extra row in head to avoid toggling of single row
 */
export function useSplitTail<T>(rows: T[], headSize?: number): [T[], T[]] {
  return useMemo(() => {
    if (!headSize || rows.length <= headSize + 1) {
      return [rows, []];
    }
    return [rows.slice(0, headSize), rows.slice(headSize)];
  }, [rows, headSize]);
}
