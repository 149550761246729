import { Company } from 'types';
import { useEntity, TabNav } from 'components';
import { NavLinkProps } from 'react-router-dom';
import { useHasPerms } from 'core/permissions';
import { permissions as agreementPermissions } from 'modules/supplier/agreement/permissions';
import { personPermissions } from './permissions';
import { ReactElement } from 'react';

export function Nav(): ReactElement {
  const { item } = useEntity<Company>();

  const [canViewAgreements, hasPersons] = useHasPerms(agreementPermissions.view, personPermissions.view);

  const nav: NavLinkProps[] = [
    {
      to: '',
      end: true,
      children: 'View',
    },
  ];

  if (canViewAgreements) {
    nav.push({
      to: 'agreements',
      children: 'Agreements',
    });
  }
  if (hasPersons) {
    nav.push({
      to: 'persons',
      children: 'Persons',
    });
  }
  return (
    <div className="container-fluid header header--provider">
      <div className="d-flex flex-row align-items-center">
        <div className="mb-2">
          <h2>{item.title}</h2>
        </div>
      </div>
      <TabNav nav={nav} />
    </div>
  );
}
