import { ReactElement } from 'react';
import { Fieldset, FormInline, FormInlineMemberError, LanguageField, RemoveButton } from 'components';
import { SelectField } from '@ff-it/form';
import { createFormat, createPlacement } from 'modules/supplier/products';
import { webDeviceOptions } from 'options';
import { FormatForm, PlacementForm } from 'modules/supplier/products/rtb';

function PlacementRow({ name, remove }: { name: string; remove?: () => void }): ReactElement {
  return (
    <div className="bg-light px-3 pt-3 mb-2 border">
      <FormInlineMemberError name={name} />
      <PlacementForm name={`${name}.`} />

      <RemoveButton data-test-id="remove-inline" onClick={remove} disabled={!remove}>
        remove placement
      </RemoveButton>
    </div>
  );
}

function FormatRow({ name, remove }: { name: string; remove?: () => void }): ReactElement {
  return (
    <div className="bg-light px-3 pt-3 mb-2 border">
      <FormInlineMemberError name={name} />
      <FormatForm name={`${name}.`} />
      <RemoveButton data-test-id="remove-inline" onClick={remove} disabled={!remove}>
        remove placement
      </RemoveButton>
    </div>
  );
}

export function RTBConfig(): ReactElement {
  return (
    <>
      <Fieldset title="Product" flush>
        <div className="row">
          <LanguageField
            placeholder="Any"
            name="configuration.language_options"
            label="Language"
            isMulti
            size="sm"
            className="col-md-3"
          />
          <SelectField
            placeholder="Any"
            name="configuration.web_device_options"
            label="Device"
            isMulti
            options={webDeviceOptions}
            simple
            size="sm"
            className="col-md-3"
          />
        </div>
        <div className="row">
          <FormInline
            name="configuration.placement_options"
            className="col-md-4"
            title="Placements"
            addLabel="Add placement"
            initialValues={() => ({ ...createPlacement('') })}
            flush
            // min={1}
            Row={PlacementRow}
          />
          <FormInline
            name="configuration.format_options"
            className="col-md-5"
            title="Formats"
            addLabel="Add format"
            initialValues={() => ({ ...createFormat('') })}
            flush
            // min={1}
            Row={FormatRow}
          />
        </div>
      </Fieldset>
    </>
  );
}
