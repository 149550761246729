import { ReactElement, ComponentType } from 'react';
import { ActivityType } from 'types';
import { CheckField, TextField } from '@ff-it/form';

function TVFields(): ReactElement {
  return (
    <>
      <TextField name="fields.clip_length" label="Clip length (seconds)" required />
      <h5 className="mt-3">Only for new clients</h5>
      <TextField name="fields.yearly_budget_by_group" label="Yearly budget by media group" />
      <TextField name="fields.agreed_prices" label="Agreed prices" />
    </>
  );
}

function RadioFields(): ReactElement {
  return (
    <>
      <TextField name="fields.spot_length" label="Spot length (seconds)" required />
      <TextField name="fields.time_zones" label="Suggested time zones" />
    </>
  );
}

function PrintFields(): ReactElement {
  return (
    <>
      <TextField name="fields.area" label="Area" required />
      <TextField name="fields.orientation" label="Orientation" required />
      <TextField name="fields.mockup_ready" label="Mockup ready" required />
    </>
  );
}

function WebFields(): ReactElement {
  return (
    <>
      <TextField name="fields.suggested_format" label="Suggested format" />
      <TextField name="fields.video" label="Video, clip length" />
      <TextField name="fields.mobile" label="Mobile" />
      <TextField name="fields.kpi" label="KPI" required multiline />
    </>
  );
}

function RTBFields(): ReactElement {
  return (
    <>
      <TextField name="fields.kpi" label="KPI" required multiline />
    </>
  );
}

function SocialFields(): ReactElement {
  return (
    <>
      <TextField name="fields.website" label="Website" required />
      <div className="form-row form-group">
        <div className="col-md-4">
          <CheckField name="fields.public" fieldLabel="Public" variant="switch" />
        </div>
        <div className="col-md-6">
          <CheckField name="fields.indexed" fieldLabel="Indexed" variant="switch" />
        </div>
      </div>
      <div className="form-group position-relative">
        <label className="font-weight-bold">Requirements</label>
        <div className="form-row">
          <div className="col-md-4">
            <CheckField name="fields.audit" fieldLabel="Audit" variant="switch" />
          </div>
          <div className="col-md-6">
            <CheckField name="fields.consultations" fieldLabel="Consultations" variant="switch" />
          </div>
        </div>
      </div>
      <div className="form-group position-relative">
        <label className="font-weight-bold">On-site</label>
        <div className="form-row">
          <div className="col-md-4">
            <CheckField name="fields.meta_tags" fieldLabel="Meta tags" variant="switch" />
          </div>
          <div className="col-md-6">
            <CheckField name="fields.content" fieldLabel="Content" variant="switch" />
          </div>
        </div>
      </div>
      <div className="form-group position-relative">
        <label className="font-weight-bold">Off-site</label>
        <div className="form-row">
          <div className="col-md-4">
            <CheckField name="fields.backlinks" fieldLabel="Backlinks" variant="switch" />
          </div>
          <div className="col-md-6">
            <CheckField name="fields.articles" fieldLabel="Articles" variant="switch" />
          </div>
        </div>
      </div>
      <CheckField name="fields.reports" label="Reports" variant="switch" />
    </>
  );
}

export const FIELD_MAP: Partial<Record<ActivityType, ComponentType>> = {
  TV: TVFields,
  RADIO: RadioFields,
  PRINT: PrintFields,
  WEB: WebFields,
  RTB: RTBFields,
  SEO: SocialFields,
};
