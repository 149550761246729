import { ComponentType, ReactElement } from 'react';
import cx from 'clsx';
import { useField } from '@ff-it/form';
import { Check } from '@ff-it/ui';
import { ConfigProps } from './types';
import { RTBConfig } from './RTBConfig';

function resolveConfigComponent({ type }: ConfigProps): ComponentType | null {
  const isRTB = ['WEB', 'RTB'].includes(type);
  if (isRTB) {
    return RTBConfig;
  }

  return null;
}

export function Config(props: ConfigProps): ReactElement | null {
  const {
    input: { value },
  } = useField('configuration', { allowNull: true, subscription: { value: true } });

  const Component = resolveConfigComponent(props);

  return Component && value ? <Component /> : null;
}

export function ConfigToggle({ className, ...props }: ConfigProps & { className?: string }): ReactElement | null {
  const {
    input: { value, onChange },
  } = useField('configuration', { allowNull: true, subscription: { value: true } });
  if (resolveConfigComponent(props) == null) {
    return null;
  }

  const enabled = Boolean(value);
  return (
    <div className={cx('form-group', className)}>
      <label htmlFor="product-toggle">Product type </label>
      <Check
        checked={enabled}
        onChange={() =>
          onChange(
            enabled
              ? null
              : {
                  language_options: [],
                  web_device_options: [],
                  placement_options: [],
                  format_options: [],
                },
          )
        }
        variant="switch"
        label="Extended product"
        id="product-toggle"
      />
      <small className="form-text text-muted">product with planning capability</small>
    </div>
  );
}
