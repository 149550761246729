import { createColumn } from './createColumn';
import { ReactElement } from 'react';
import { RenderEditCellProps, SelectEditor } from 'components/DataGrid';
import { GridRow } from '../types';
import { getUnitOptions } from 'options';
import { useEntity } from 'components';
import { BlockDetails } from 'modules/campaign/block/types';

function Edit(props: RenderEditCellProps<GridRow, any>): ReactElement {
  const type = useEntity<BlockDetails>().item.type;
  return <SelectEditor {...props} options={getUnitOptions(props.row.kind, type)} isSimple isSearchable />;
}

export const UnitColumn = createColumn(
  {
    key: 'unit',
    name: 'Unit',
    width: 76,
  },
  {
    renderView: ({ row }) => (row.kind === 'SMART' ? row.application : row.unit),
    renderEdit: (props) => <Edit {...props} />,
    displayCellContent: true,
  },
);
