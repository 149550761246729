import { ReactElement } from 'react';
import cx from 'clsx';
import { Link } from 'react-router-dom';
import { TitleContainer } from 'components/TitleContainer';

export type CampaignWidgetProps = {
  id: number;
  slug: string;
  title: string;
  number: string;
  client_id: number;
};

export function CampaignWidget({
  id,
  number,
  title,
  slug,
  link,
  flat,
}: CampaignWidgetProps & { link?: boolean; flat?: boolean }): ReactElement {
  const content = link ? (
    <Link
      to={`/${slug}/planning/campaigns/${id}/planning`}
      className={cx('text-nowrap', !flat && 'd-block')}
      target="_blank"
    >
      {number}
    </Link>
  ) : (
    number
  );

  return <TitleContainer title={content} flat={flat} subtitle={title} testId={`campaign-${id}`} />;
}
