import { ReactElement } from 'react';
import { AttachmentField, FormInline } from 'components';
import { TextField, DatePickerField, IntField } from '@ff-it/form';
import { CommonFields } from './CommonFields';
import { FieldsProps } from './types';

// TODO materials
const initialMaterial = {
  kind: '',
  amount: null,
};

export function CreativeFields(props: FieldsProps): ReactElement {
  return (
    <>
      <CommonFields {...props} />
      <hr className="mt-0" />
      <div className="form-row">
        <TextField
          name="suggested_media"
          label="For which channels materials will be used for"
          className="col-md-8"
          multiline
          autosize
          required
        />
        <DatePickerField name="first_sketch" label="First sketch" className="col-md-4" required />
      </div>
      <FormInline
        name="materials"
        title="Describe required materials"
        addLabel="Add material"
        initialValues={initialMaterial}
        flush
        min={1}
      >
        {(name) => (
          <div className="form-row">
            <TextField
              placeholder="Material"
              name={`${name}.kind`}
              className="col-md-8"
              multiline
              autosize
              required
              size="sm"
            />
            <IntField name={`${name}.amount`} className="col-md-4" placeholder="Amount" required size="sm" />
          </div>
        )}
      </FormInline>
      <TextField
        name="styleguide"
        label="Style guidelines"
        help="Brandbook or any other materials"
        multiline
        autosize
        required
      />
      <AttachmentField name="brief_attachments" label="Attachements" />

      <TextField name="copywrite" label="Copywrite" multiline autosize required />

      <div className="form-row">
        <TextField name="website" label="Website" className="col-md-6" multiline autosize required />
        <TextField name="social_media" label="Social media" className="col-md-6" multiline autosize required />
      </div>

      <TextField name="comment" label="Comment" multiline autosize />
    </>
  );
}
