import { Box, Check, Tbody, Td, Tr } from '@ff-it/ui';
import { Badge, type EntityContextType } from 'components';
import type { BlockDetails, BlockState } from 'modules/campaign/block/types';
import { type ReactElement, useState } from 'react';
import { Block } from './Block';
import { CampaignDetails } from '../types';

type StateGroupProps = {
  state: BlockState;
  blocks: BlockDetails[];
  collapse?: boolean;
  campaign: CampaignDetails;
  toggleLoading: (loading: boolean) => void;
  replace: (block: BlockDetails) => void;
  remove: (block: BlockDetails) => void;
  add: (block: BlockDetails) => void;
};

export function BlockGroup({
  state,
  blocks,
  collapse,
  toggleLoading: setLoading,
  replace: setItem,
  campaign,
  remove,
  add,
}: StateGroupProps): ReactElement {
  const [show, setShow] = useState(!collapse);

  return (
    <Tbody>
      <Tr>
        <Td colSpan={12}>
          <Box display="flex" alignItems="center">
            {collapse ? (
              <Check
                variant="switch"
                inline
                checked={show}
                data-testid="toggle-show-group"
                data-block-state={state}
                label={
                  <Badge state={state} dataTestId="block-state">
                    {' '}
                    {blocks.length}
                  </Badge>
                }
                onChange={() => setShow((c) => !c)}
              />
            ) : (
              <Badge state={state} dataTestId="block-state" />
            )}
          </Box>
        </Td>
      </Tr>
      {show &&
        blocks.map((block) => {
          const itemContext = {
            item: block,
            setSpinning: setLoading,
            setItem,
            endpoint: `campaign/blocks/${block.id}/`,
          } as EntityContextType<BlockDetails>;
          return <Block key={block.id} campaign={campaign} itemContext={itemContext} remove={remove} add={add} />;
        })}
    </Tbody>
  );
}
