import { dateFrom, dateTo } from 'components/columns';
import { ActivityIcon, Badge, BoundStates, ClientTitle, EnterpriseLink } from 'components';
import { sepFormat } from 'utilities';
import type { ListBlock } from 'modules/campaign/block/types';
import { TableColumns } from '@ff-it/ui';

const sumProps = {
  width: 120,
  cellProps: {
    textAlign: 'right' as const,
  },
};

export const columns: TableColumns<ListBlock> = [
  {
    content: 'Number',
    key: 'code',
    width: 120,
    render: ({ id, code, campaign }: ListBlock) => (
      <EnterpriseLink to={`/planning/campaigns/${campaign.id}/planning/${id}`}>{code}</EnterpriseLink>
    ),
    sortable: true,
  },
  {
    content: 'State',
    width: 100,
    key: 'state',
    sortable: true,
    render: ({ state }) => <Badge state={state} />,
  },
  {
    width: 30,
    key: 'type',
    render: ({ type }) => <ActivityIcon type={type} />,
    sortable: false,
    cellProps: {
      textAlign: 'center',
      flush: true,
    },
  },
  {
    content: 'Client',
    key: 'client',
    render: ({ campaign }) => <ClientTitle {...campaign} />,
  },
  {
    content: 'Title',
    key: 'title',
    sortable: true,
    render: ({ title, final_client_po_number }) => (
      <>
        {title}{' '}
        {final_client_po_number && (
          <small className="text-muted" data-testid="client-po-number">
            {final_client_po_number}
          </small>
        )}
      </>
    ),
  },
  { ...dateFrom(), sortable: true },
  { ...dateTo(), sortable: true },

  {
    ...sumProps,
    content: 'Income',
    key: 'sums.income_total',
    render: ({ sums }) => sepFormat(sums ? sums.income_total : null),
    sortable: true,
  },
  {
    ...sumProps,
    content: 'Expense',
    key: 'sums.expense_total',
    render: ({ sums }) => sepFormat(sums ? sums.expense_total : null),
    sortable: true,
  },
  {
    width: 60,
    cellProps: {
      className: 'align-middle',
    },
    key: 'sums',
    render: ({ sums }: ListBlock) => (sums ? <BoundStates {...sums} /> : null),
  },
  {
    ...sumProps,
    content: 'Revenue',
    key: 'sums.revenue',
    sortable: true,
    render: ({ sums }) => sepFormat(sums ? sums.revenue : null),
  },
];
