import { parseBig } from 'utilities';
import { AnyLinkable, Parsed } from './types';

export function parseLinkable<T extends AnyLinkable>(linkable: T): Parsed<T> {
  return {
    ...linkable,
    invoiceable: parseBig(linkable.invoiceable),
    invoiced: parseBig(linkable.invoiced),
    balance: parseBig(linkable.balance),
  };
}
