import { createColumn } from './createColumn';
import { renderView, renderEdit } from './resettableDecimal';
import { right } from './style.css';

export const ProviderPriceColumn = createColumn(
  {
    key: 'provider_price',
    name: 'Net agency p.u.',
    width: 96,
    className: right,
  },
  {
    renderView: (props) => (props.row.kind === 'SMART' ? null : renderView(props)),
    renderEdit: (props) => renderEdit({ ...props, precision: 16, normalize: true }),
  },
);
