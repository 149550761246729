import { ReactElement } from 'react';
import { Attachment } from 'types';
import cx from 'clsx';
import { Button } from '@ff-it/ui';
import { Icon } from '../../ui/Icon';
import './FileContainer.scss';

interface FileContainerProps {
  files: Attachment[];
  markRemove?: (id: number) => void;
  disabled?: boolean;
}

function FileContainer({ files, markRemove, disabled }: FileContainerProps): ReactElement | null {
  if (files.length === 0) {
    return null;
  }
  return (
    <div className="card mb-1 file-container">
      <ul className="list-group list-group-flush">
        {files.map(({ file, filename, id }) => (
          <li
            key={id}
            className={cx('list-group-item d-flex justify-content-between align-items-center', {
              removed: !markRemove,
            })}
          >
            <a href={file} target="_blank" rel="noopener noreferrer">
              {filename}
            </a>
            {markRemove ? (
              disabled ? null : (
                <Button
                  className="border-0"
                  variant="outline-danger"
                  size="sm"
                  type="button"
                  onClick={() => markRemove(id)}
                >
                  <Icon icon="xmark" />
                </Button>
              )
            ) : (
              <Button className="border-0" variant="outline-secondary" size="sm" type="button" disabled>
                <Icon icon="trash" />
              </Button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FileContainer;
