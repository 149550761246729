import { ReactElement } from 'react';
import { TitleContainer } from './TitleContainer';

// This should have ids. But for reports and such there are none
type ClientProps = { internal_name: string };

interface ClientTitleProps {
  client: ClientProps;
  payer: ClientProps;
  className?: string;
  flat?: boolean;
}

export function ClientTitle({ client, payer, className, flat }: ClientTitleProps): ReactElement {
  if (client.internal_name !== payer.internal_name) {
    return (
      <TitleContainer
        title={client.internal_name}
        subtitle={`paid by ${payer.internal_name}`}
        className={className}
        flat={flat}
      />
    );
  }
  return <TitleContainer title={client.internal_name} className={className} flat={flat} />;
}
