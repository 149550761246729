import cx from 'clsx';
import { ReactElement } from 'react';
import { NewSelectionState } from './types';
import { differenceInDays } from 'date-fns';
import { positionErrors } from './const';
import { segmentSelection, selectionError } from './style.css';

type SegmentProps = {
  intervalStart: Date;
  dayWidth: number;
  selection: NewSelectionState;
};

export function Selection({ intervalStart, dayWidth, selection }: SegmentProps): ReactElement {
  const { start, end, error } = selection;
  const left = differenceInDays(start, intervalStart) * (dayWidth + 1);
  const days = differenceInDays(end, start) + 1;
  const width = days * (dayWidth + 1);
  return (
    <div className={cx(segmentSelection, { error })} style={{ left, width }}>
      <span className="contain">{days}d</span>
      {error && <span className={selectionError}>{positionErrors[error]}</span>}
    </div>
  );
}
