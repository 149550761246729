import { ReactElement } from 'react';
import { container, item, plannedAmount } from './style.css';
import { fmtMonth, sepFormat } from 'utilities';
import { Box } from '@ff-it/ui';
import { TitleContainer } from 'components/TitleContainer';
import type { Months } from '../types';

export function MonthList({ months }: { months: Months }): ReactElement {
  return (
    <Box component="ul" className={container}>
      {/* rendered in reverse */}
      {months.map(({ month, planned, target }) => (
        <Box component="li" key={month} className={item}>
          <TitleContainer
            title={fmtMonth(month)}
            htmlTitle={`${sepFormat(target)} ${planned !== target ? `(${sepFormat(planned)})` : ''}`}
            subtitle={
              <>
                <span data-testid="target">{sepFormat(target)}</span>
                {planned !== target && (
                  <span className={plannedAmount} data-testid="planned">
                    ({sepFormat(planned)})
                  </span>
                )}
              </>
            }
          />
        </Box>
      ))}
    </Box>
  );
}
