import type { ReactElement } from 'react';
import useSWR, { SWRResponse } from 'swr';
import { Box, openModal } from '@ff-it/ui';
import { ApplicationSummary } from './ApplicationSummary';
import { useSummaryState } from './useSummaryState';
import { ApplyForm } from './ApplyForm';
import { RemoveForm } from './RemoveForm';
import type { Invoice } from 'modules/invoicing/types';
import type { ApplicationResponse, ApplicationType, AppliedLink, LinkCandidate } from 'modules/applicator/types';
import { EntityContextType } from 'components';
import { parseBig } from 'utilities';
import { IncomeSums } from 'modules/invoicing/income/types';
import { ExpenseSums } from 'modules/invoicing/expenses/types';
import { AppliedList } from './AppliedList';
import { CandidateList } from './CandidateList';

export type ApplicationContainerProps = {
  type: ApplicationType;
  ctx: EntityContextType<Invoice>;
};

const typeFieldMap: Record<ApplicationType, keyof IncomeSums | keyof ExpenseSums> = {
  credit: 'cred_amount',
  advance: 'adv_amount',
  compensation: 'comp_amount',
};

export function ApplicationContainer({
  type,
  ctx: {
    endpoint,
    item: { sums, direction },
    setItem,
  },
}: ApplicationContainerProps): ReactElement {
  const url = `${endpoint}${type}_application/`;
  const {
    data: { applied, candidates },
    mutate,
  }: SWRResponse<ApplicationResponse, unknown, { suspense: true }> = useSWR({ url, method: 'GET' }, { suspense: true });
  const amount = parseBig(sums[typeFieldMap[type] as keyof typeof sums]);
  const summaryState = useSummaryState(amount, applied);

  const applyResult = (result?: ApplicationResponse): void => {
    if (result) {
      const { sums, ...data } = result;
      setItem((item) => ({ ...item, sums }) as any);
      mutate(data);
    }
  };

  const removeHandler = (applied: AppliedLink): Promise<void> =>
    openModal<ApplicationResponse>(({ onSubmit }) => (
      <RemoveForm url={url} applied={applied} onSubmit={onSubmit} />
    )).then(applyResult);

  const addHandler = (candidate: LinkCandidate): Promise<void> =>
    openModal<ApplicationResponse>(({ onSubmit }) => (
      <ApplyForm balance={summaryState.balance} url={url} candidate={candidate} onSubmit={onSubmit} />
    )).then(applyResult);

  return (
    <Box data-testid={`application-${type}`}>
      <ApplicationSummary direction={direction} state={summaryState} />
      <AppliedList applicationType={type} items={applied} actionHandler={removeHandler} />
      <CandidateList applicationType={type} items={candidates} actionHandler={addHandler} />
    </Box>
  );
}
