import { RefObject, useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import type { RowOrGroup } from '../types';
import { DataGridHandle } from 'components/DataGrid';
import { MoveSet, MoveType } from '../../types';
import { PlanController } from '../../usePlanController';
import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';

type RowData = {
  id: number;
  move_set: MoveSet;
};

function getRowData(row: RowOrGroup): RowData {
  const { id, _isGroup } = row;
  return {
    id,
    move_set: _isGroup ? 'GROUP' : 'ROW',
  };
}

function getDropType(drag: RowData, drop: RowData): MoveType | null {
  const edge = extractClosestEdge(drop) as 'top' | 'bottom' | null;
  if (!edge) {
    return null;
  }
  if (drag.move_set === drop.move_set) {
    //  over self
    if (drag.id === drop.id) {
      return null;
    }

    if (edge === 'top') {
      // if (drag.rowIdx === drop.rowIdx - 1) {
      //   return null;
      // }
      return 'BEFORE';
    } else {
      // if (drag.rowIdx === drop.rowIdx + 1) {
      //   return null;
      // }
      return 'AFTER';
    }
  }

  // ROW INTO
  if (drag.move_set !== 'GROUP' && drop.move_set === 'GROUP') {
    // @TODO not current row
    return 'INTO';
  }

  return null;
}

export function useDropTarget(row: RowOrGroup): [RefObject<HTMLDivElement>, MoveType | null] {
  const [dropType, setDropType] = useState<MoveType | null>(null);
  const rowRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    invariant(rowRef.current);
    const data: RowData = getRowData(row);

    return dropTargetForElements({
      element: rowRef.current,
      getData: ({ input, element }) =>
        attachClosestEdge(data, { input, element: element.firstElementChild!, allowedEdges: ['top', 'bottom'] }),
      // canDrop({ source }) {
      onDrag: ({ self, source }) => {
        setDropType(getDropType(source.data as RowData, self.data as RowData));
      },
      onDragLeave: () => setDropType(null),
      onDrop: () => setDropType(null),
    });
  }, [row]);
  return [rowRef, dropType];
}

export function useDraggable(row: RowOrGroup): [RefObject<HTMLButtonElement>, isDragging: boolean] {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const handleRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    invariant(handleRef.current);
    const data = getRowData(row);
    return draggable({
      element: handleRef.current,
      getInitialData: () => data,
      onGenerateDragPreview: ({ nativeSetDragImage }) => disableNativeDragPreview({ nativeSetDragImage }),
      onDragStart: () => setIsDragging(true),
      onDrop: () => setIsDragging(false),
    });
  }, [row]);
  return [handleRef, isDragging];
}

export function useDragMonitor(gridRef: RefObject<DataGridHandle>, move: PlanController['move']): MoveSet | null {
  // monitor row drag, collapse groups if we start to drag group
  // @TODO:  make this smarter expand group weh wne drag row over?, collapse other groups
  // or inverse control by returning state and controlin in visible rows
  const [isDragging, setIsDragging] = useState<MoveSet | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: move and refs
  useEffect(() => {
    invariant(gridRef.current);
    const element = gridRef.current.element as HTMLElement;
    return combine(
      autoScrollForElements({ element }),
      monitorForElements({
        onDragStart({ source }) {
          const sourceData = source.data as RowData;

          setIsDragging(sourceData.move_set);
        },
        onDrop({ source, location }) {
          setIsDragging(null);
          const target = location.current.dropTargets[0];
          if (!target) {
            return;
          }
          const sourceData = source.data as RowData;
          const targedData = target.data as RowData;
          const move_type = getDropType(sourceData, targedData);

          if (move_type) {
            move({
              move_set: sourceData.move_set,
              source_id: sourceData.id,
              move_type,
              target_id: targedData.id,
            });
          }
        },
      }),
    );
  }, []);

  return isDragging;
}
