import { Company, EmbeddedCompany } from 'types';
import { ModelScene, CreateScene, EntityScene, UpdateScene, DeleteScene, IndexScene } from 'components';
import { Form } from './Form';
import { RouteObject } from 'react-router';
import { Link } from 'react-router-dom';
import permissions from 'modules/core/company/permissions';
import initialValues from './initialValues';
import { FromTicket } from './FromTicket';
import { ListImport, NewListExport } from 'components/ListActions';
import { TableColumns } from '@ff-it/ui';

export const baseColumns: TableColumns<EmbeddedCompany> = [
  {
    content: 'Title',
    key: 'title',
    sortable: true,
    render: ({ id, title }: EmbeddedCompany) => <Link to={`${id}`}>{title || <em>Title</em>}</Link>,
  },
  {
    content: 'Internal name',
    sortable: true,
    key: 'internal_name',
  },
  { content: 'Code', key: 'code', sortable: true, width: 120 },

  { content: 'Kind', key: 'kind', sortable: true },
  { content: 'Registration number', sortable: true, key: 'registration_number', width: 250 },
];

const model = {
  endpoint: 'core/companies/',
  title: 'Company',
  pluralTitle: 'Companies',
  entityTitle: ({ title }: Company) => title,
  permissions,
};

export const route: RouteObject = {
  path: 'companies',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene
          columns={baseColumns}
          toolbar={
            <>
              <ListImport permission={permissions.import} />
              <NewListExport permission={permissions.export} />
            </>
          }
        />
      ),
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: 'from_ticket/:id',
      element: <FromTicket />,
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <UpdateScene viewDisabled>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};
