type SignedInState<U> = {
  isAuthenticated: true;
  user: U;
};

type SignedOutState = {
  isAuthenticated: false;
  user: null;
};

export type AuthState<U> = SignedInState<U> | SignedOutState;

export class BaseAuthenticationService<S extends AuthState<unknown>> {
  protected _state: S;
  protected _initial_state: S;
  // @TODO tokenstoreage, void for session
  readonly _token_key: string;

  constructor(initialState: S, token_key: string) {
    this._state = initialState;
    this._initial_state = initialState;
    this._token_key = token_key;
  }

  private _subscription: VoidFunction | null = null;

  public set state(state: S) {
    this._state = state;
    this._subscription && this._subscription();
  }

  public set user(user: S['user']) {
    this.state = {
      ...this._state,
      user,
      isAuthenticated: true,
    };
  }

  public get token(): string | null {
    try {
      return localStorage.getItem(this._token_key);
    } catch {
      return null;
    }
  }

  public set token(token: string | null) {
    if (token === null) {
      localStorage.removeItem(this._token_key);
    } else {
      localStorage.setItem(this._token_key, token);
    }
  }

  signin(user: S['user'], token?: string, callback?: VoidFunction): void {
    if (typeof token !== 'undefined') {
      this.token = token;
    }

    this.state = {
      ...this._state,
      user,
      isAuthenticated: true,
    };

    callback && callback();
  }

  signout(callback?: VoidFunction): void {
    this.token = null;
    this.state = { ...this._initial_state };

    callback && callback();
  }

  subscribe = (callback: VoidFunction): VoidFunction => {
    this._subscription = callback;
    return () => {
      this._subscription = null;
    };
  };

  getSnapshot = (): S => {
    return this._state;
  };
}
