import { ReactElement, ReactNode } from 'react';
import type { Options } from 'options';
import { Row } from './Row';

type HeaderRowProps = {
  months: Options;
  group?: ReactNode;
};

export function HeaderRow({ months, group }: HeaderRowProps): ReactElement {
  return (
    <Row kind="header" group={group} total="Total (Invoiceable)" bound="Invoiced" remainder="Remaining">
      {months.map(({ label, value }) => (
        <th key={value} className="f__c--month">
          {label}
        </th>
      ))}
    </Row>
  );
}
