import { Box, Check } from '@ff-it/ui';
import { OptionProps, components } from 'react-select';
import { ReactElement } from 'react';

export function CheckboxOption({ children, isSelected, ...rest }: OptionProps<any, any, any>): ReactElement {
  return (
    <components.Option {...rest} isSelected={false}>
      <Box display="flex" alignItems="center">
        <Box style={{ pointerEvents: 'none' }}>
          <Check variant="checkbox" checked={isSelected} readOnly />
        </Box>
        {children}
      </Box>
    </components.Option>
  );
}
