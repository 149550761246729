import type { PlanRow } from 'modules/campaign/row';
import type { GridGroup, GridRow, GridSummaryRow, RowOrGroup, RowSetSummary, SummaryFields } from '../types';
import { useMemo, useRef } from 'react';

import equal from 'fast-deep-equal';
import Big from 'big.js';
import { parseBig } from 'utilities';
import { PlanState } from '../../types';

type GroupByDictionary = Record<string, GridRow[]>;

function diffCollection(current: RowOrGroup[], next: RowOrGroup[]): RowOrGroup[] {
  const indexed = current.reduce((acc: Record<string, RowOrGroup>, row) => {
    acc[row._key] = row;
    return acc;
  }, {});
  const res = [];

  for (let i = 0; i < next.length; i++) {
    const nextRow = next[i];
    const currentRow = indexed[nextRow._key];
    /*
    FIXME: we would like to use patch information or row modified timestamp but we can't:
      * does not work for groups
      * price update toggles locks, config changes
      * plan setting changes
    */
    res.push(!currentRow || !equal(currentRow, nextRow) ? nextRow : currentRow);
  }
  return res;
}

const initialSum: SummaryFields = {
  clientGrossTotal: Big(0),
  clientTotal: Big(0),
  providerTotal: Big(0),
  months: {} as Record<string, { income: Big; expense: Big }>,
  // RTB
  estimatedClicks: Big(0),
  estimatedImpressions: Big(0),
  // RTB, reach
  reachImpressions: Big(0),
  reachRate: Big(0),
  maxReachRate: Big(0),
};

function sumFields(a: SummaryFields, b: SummaryFields): SummaryFields {
  const months = { ...a.months };

  Object.keys(b.months).map((monthKey) => {
    const { income, expense } = b.months[monthKey];

    if (monthKey in months) {
      months[monthKey].income = months[monthKey].income.plus(income);
      months[monthKey].expense = months[monthKey].expense.plus(expense);
    } else {
      months[monthKey] = {
        income,
        expense,
      };
    }
  });

  return {
    clientGrossTotal: a.clientGrossTotal.plus(b.clientGrossTotal),
    clientTotal: a.clientTotal.plus(b.clientTotal),
    providerTotal: a.providerTotal.plus(b.providerTotal),
    months,
    estimatedClicks: a.estimatedClicks.plus(b.estimatedClicks),
    estimatedImpressions: a.estimatedImpressions.plus(b.estimatedImpressions),
    reachImpressions: a.reachImpressions.plus(b.reachImpressions),
    reachRate: a.reachRate.plus(b.reachRate),
    maxReachRate: a.maxReachRate.gt(b.maxReachRate) ? a.maxReachRate : b.maxReachRate,
  };
}

function calcGroup(rows: PlanRow[]): RowSetSummary {
  const res = rows.reduce((acc, row) => {
    const reachRate = parseBig(row.reach);
    return sumFields(acc, {
      clientGrossTotal: parseBig(row.sums.client_gross_total),
      clientTotal: parseBig(row.sums.target_income),
      providerTotal: parseBig(row.sums.target_expense),
      months: Object.keys(row.months).reduce(
        (acc, monthKey) => {
          acc[monthKey] = {
            income: parseBig(row.months[monthKey].target_income),
            expense: parseBig(row.months[monthKey].target_expense),
          };
          return acc;
        },
        {} as Record<string, { income: Big; expense: Big }>,
      ),
      estimatedClicks: parseBig(row.est_clicks),
      estimatedImpressions: parseBig(row.est_impressions),
      reachImpressions: parseBig(row.reach_impressions),
      reachRate,
      maxReachRate: reachRate,
    });
  }, initialSum);

  const { clientGrossTotal, clientTotal, providerTotal } = res;
  const discountRate = clientGrossTotal.eq(0)
    ? undefined
    : clientGrossTotal.minus(clientTotal).div(clientGrossTotal).mul(100);
  const revenue = clientTotal.minus(providerTotal);
  const margin = clientTotal.eq(0) ? undefined : revenue.div(clientTotal).mul(100);

  return {
    ...res,
    discountRate,
    revenue,
    margin,
  };
}

export function useGridRows({ groups: planGroups, rows: planRows }: PlanState): RowOrGroup[] {
  const ref = useRef<RowOrGroup[]>();

  return useMemo(() => {
    const groupedRows: GroupByDictionary = {};

    let flattenedRows: Array<RowOrGroup> = [];

    for (const row of planRows) {
      const groupId = row.group_id || 0;
      const groupKey = `group-${groupId}`;
      if (!(groupKey in groupedRows)) {
        groupedRows[groupKey] = [];
      }
      groupedRows[groupKey].push({ ...row, _isGroup: false, _key: `row-${row.id}`, _groupKey: groupKey });
    }

    // groups first
    for (const group of planGroups) {
      const groupKey = `group-${group.id}`;
      const children = groupedRows[groupKey] || [];
      const groupSummary = calcGroup(children);

      flattenedRows.push({
        _isGroup: true,
        _key: groupKey,
        _groupKey: groupKey,
        childIds: children.map((c) => c.id),
        ...group,
        ...groupSummary,
        empty: children.length === 0,
      });

      if (groupKey in groupedRows) {
        flattenedRows.push(...children);
      }
    }

    // virtual, don't render if no rows
    // FIXME: probably hidde if no ungourped rows unless dragging
    if (planRows.length > 0) {
      const groupKey = 'group-0';

      const children = groupedRows[groupKey] || [];
      const groupSummary = calcGroup(children);

      flattenedRows.push({
        _isGroup: true,
        _key: groupKey,
        _groupKey: groupKey,
        id: 0,
        budget: null,
        title: 'Ungrouped',
        config: { locked: true },
        childIds: children.map((c) => c.id),
        modified_at: 0.0,
        ...groupSummary,
        empty: children.length === 0,
      });
      flattenedRows.push(...children);
    }

    if (ref.current) {
      flattenedRows = diffCollection(ref.current, flattenedRows);
    }
    ref.current = flattenedRows;

    return flattenedRows;
  }, [planGroups, planRows]);
}

export function useGridSummaryRow(
  rows: RowOrGroup[],
  { plan_reach_media_overlap, target_group_size }: PlanState,
): GridSummaryRow {
  return useMemo(() => {
    const sum: SummaryFields = rows
      .filter((r) => r._isGroup)
      .reduce((acc, r) => sumFields(acc, r as GridGroup), initialSum);

    const { clientGrossTotal, clientTotal, providerTotal, reachRate, maxReachRate, reachImpressions } = sum;
    const discountRate = clientGrossTotal.eq(0)
      ? undefined
      : clientGrossTotal.minus(clientTotal).div(clientGrossTotal).mul(100);
    const revenue = clientTotal.minus(providerTotal);
    const margin = clientTotal.eq(0) ? undefined : revenue.div(clientTotal).mul(100);

    let reach = plan_reach_media_overlap
      ? reachRate.minus(reachRate.mul(parseBig(plan_reach_media_overlap).div(100)))
      : undefined;

    /*
     FIXME: can't really calculate total reach without knowing overlap of individual audience segments
      Total Reach = Reach(A) + Reach(B) + Reach(C)
      - Overlap(A,B) - Overlap(A,C) - Overlap(B,C)
      + Overlap(A,B,C)
     This nonsense sanity check that referebce excel plan had
    */
    if (reach && maxReachRate.gt(reach)) {
      reach = undefined;
    }

    const targetGroupSize = parseBig(target_group_size);

    const reachUniqueUsers = targetGroupSize && reach && !reach.eq(0) ? targetGroupSize.mul(reach.div(100)) : undefined;

    const reachFrequency =
      reachUniqueUsers && !reachUniqueUsers.eq(0)
        ? // thousands
          reachImpressions
            .mul(1000)
            .div(reachUniqueUsers)
        : undefined;

    return {
      ...sum,
      discountRate,
      revenue,
      margin,
      reach,
      reachUniqueUsers,
      reachFrequency,
    };
  }, [rows, plan_reach_media_overlap, target_group_size]);
}
