import { useField } from '@ff-it/form';
import { ReactElement, ReactNode, useRef } from 'react';
import useSWR from 'swr';
import { LinkerContext } from './types';
import { LoadingContainer } from '@ff-it/ui';
import { Period } from 'types';

export function useFiancePeriod(): Period | null {
  const {
    input: { value },
    meta: { valid },
  } = useField<Period | null>('finance_period', {
    allowNull: true,
    defaultValue: null,
    subscription: { value: true, valid: true },
  });

  const previous = useRef(value);

  if (valid && value !== previous.current) {
    previous.current = value;
  }

  return previous.current;
}

type LinksProviderProps = {
  url: string;
  children: (ctx: LinkerContext) => ReactNode;
};

export function LinksProvider({ url, children }: LinksProviderProps): ReactElement {
  const financePeriod = useFiancePeriod();
  const { data, error, isLoading } = useSWR<LinkerContext>(
    {
      url,
      method: 'GET',
      queryParams: {
        links: financePeriod ? `${financePeriod.start},${financePeriod.end}` : '',
      },
    },
    null,
    {
      keepPreviousData: true,
    },
  );

  if (error) {
    throw error;
  }

  return (
    <LoadingContainer loading={isLoading} className="flex-grow-1 flex-column d-flex">
      {data && children(data)}
    </LoadingContainer>
  );
}
