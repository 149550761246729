export const permissions = {
  view: 'supplier.view_provider',
};

export const personPermissions = {
  view: 'crm.view_provider_person',
  add: 'crm.add_provider_person',
  change: 'crm.change_provider_person',
  delete: 'crm.delete_provider_person',
};
