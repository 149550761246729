import { ReactElement } from 'react';
import { strategies } from 'modules/campaign/block/strategy';
import { Button } from '@ff-it/ui';
import { Icon } from 'components';
import type { PlanController } from '../usePlanController';
import type { PlanState } from '../types';
import { openSettings } from '../Settings';
import type { ActivityType } from 'types';

type StrategyButtonProps = {
  planState: PlanState;
  controller: PlanController;
  type: ActivityType;
};

export function SettingsButton(props: StrategyButtonProps): ReactElement {
  return (
    <Button variant="outline-secondary" testId="toggle-settings" onClick={() => openSettings(props)}>
      {strategies[props.planState.plan_strategy].TITLE} <Icon icon="gear" className="ml-1" />
    </Button>
  );
}
