import { parseISO } from 'date-fns';
import useSWR from 'swr';

export function usePeriod(): {
  date_from: Date | undefined;
  date_to: Date | undefined;
} {
  const { data } = useSWR<{ date_from: string; date_to: string }>(
    { method: 'GET', url: 'reporting/period/' },
    {
      refreshInterval: 1000 * 60 * 60, // once a hour
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  if (data) {
    return {
      date_from: parseISO(data.date_from),
      date_to: parseISO(data.date_to),
    };
  }
  return {
    date_from: undefined,
    date_to: undefined,
  };
}
