import { ReactElement } from 'react';
import { ItemLoader, UpdateContainer, useEntity } from 'components';
import { ActivityType, activityTypes } from 'types';
import { CheckField, SelectField, useField } from '@ff-it/form';
import { createOptions } from 'options';
import { strategyLabelMap } from 'modules/campaign/block/strategy';

function Row({ type }: { type: ActivityType }): ReactElement {
  const {
    input: { value: available_strategies },
  } = useField(`planning.${type}.available_strategies`, {
    subscription: { value: true },
  });
  return (
    <tr>
      <th>{type}</th>
      <td>
        <CheckField name={`planning.${type}.enabled`} variant="switch" />
      </td>
      <td>
        <CheckField name={`planning.${type}.lock`} variant="switch" />
      </td>
      <td>
        <SelectField
          simple
          name={`planning.${type}.default_plan_strategy`}
          options={createOptions(available_strategies, strategyLabelMap)}
          required
        />
      </td>
    </tr>
  );
}

function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <table className="table form-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Enabled</th>
            <th>Lock strategy</th>
            <th>Default planning strategy</th>
          </tr>
        </thead>
        <tbody>
          {activityTypes.map((type) => (
            <Row key={type} type={type} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function Planning(): ReactElement {
  const { endpoint } = useEntity();

  const url = `${endpoint}planning/`;
  return (
    <ItemLoader<any> url={url}>
      {({ setItem, item }) => (
        <UpdateContainer url={url} initialValues={item} updateItem={setItem} method="POST">
          <Form />
        </UpdateContainer>
      )}
    </ItemLoader>
  );
}
