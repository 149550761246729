import { ReactElement } from 'react';
import { PaymentState as PaymentStateType } from 'modules/invoicing/common/types';
import { IconBadge, IconBadgeBaseProps } from 'components/IconBadge/IconBadge';

interface PaymentStateProps {
  state: PaymentStateType | null;
}

const STATE_MAP: Record<PaymentStateType, IconBadgeBaseProps> = {
  PENDING: {
    title: 'Payment pending',
    icon: 'timer',
    background: 'tertiary',
    color: 'tertiary_hover',
  },
  PARTIAL: {
    title: 'Partial payment',
    icon: 'coins',
    background: 'warning-solid',
    color: 'white',
  },
  PAID: {
    title: 'Paid',
    icon: 'coins',
    background: 'tertiary',
    color: 'success-primary',
  },
  LATE: {
    title: 'Late',
    icon: 'timer',
    background: 'error-solid',
    color: 'white',
  },
};

export function PaymentStateBadge({ state }: PaymentStateProps): ReactElement {
  const props = state ? STATE_MAP[state] : { icon: null };
  return <IconBadge {...props} />;
}
