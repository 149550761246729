import { ReactElement } from 'react';
import { TextField } from '@ff-it/form';
import { AllActivityTypesField } from 'components';
import { TicketForm } from './TicketForm';
import { CompanyField } from 'modules/core/company/components';

const initialValues = {
  company: null,
  name: '',
  service: '',
  activity_type: null,
  extra_info: '',
};

export function NewDepartment(): ReactElement {
  return (
    <TicketForm title="New department" url="support/tickets/new_department/" initialValues={initialValues}>
      <div className="form-row">
        <CompanyField name="company" label="Provider" className="col-md-6" required includeBranches={false} />
      </div>
      <div className="form-row border-top pt-3">
        <TextField name="name" label="Department name" className="col-md-6" required />
      </div>
      <div className="form-row">
        <TextField
          name="service"
          label="Service"
          className="col-md-6"
          help="Describe the service"
          required
          multiline
          autosize
        />
        <AllActivityTypesField name="activity_type" label="Type" className="col-md-2" required />
      </div>
      <div className="form-row">
        <TextField
          name="extra_info"
          label="Extra info"
          help="Any other info to know"
          className="col-6"
          multiline
          autosize
        />
      </div>
    </TicketForm>
  );
}
