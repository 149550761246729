import { AgencyActivityTypesField, Breadcrumb, EnterpriseField, Page, TabNav, withHasPerm } from 'components';
import { CompanyField, CompanyTitle } from 'modules/core/company/components';
import { ListProvidedDepartment } from 'modules/supplier/agreement/types';
import { DepartmentTitle } from 'modules/supplier/department/DepartmentTitle';
import { date } from 'components/columns';

import { Navigate, Outlet } from 'react-router';
import permissions from 'modules/client/permissions';
import { Link } from 'react-router-dom';
import { DepartmentField } from 'modules/supplier/department/DepartmentField';
import { IndexTable } from 'components/ControlledTable';
import { TableColumns } from '@ff-it/ui';

const directColumns: TableColumns<ListProvidedDepartment> = [
  {
    content: 'Department',
    key: 'department.name',
    render: ({ department }: ListProvidedDepartment) => <DepartmentTitle {...department} />,
    width: 300,
  },
  {
    content: 'Type',
    key: 'type',
  },
  {
    content: 'Provider',
    key: 'agreement.provider',
    render: ({ agreement: { provider } }) => <CompanyTitle {...provider} />,
  },
  {
    content: 'Agreement',
    key: 'agreement.number',
    render: ({ agreement }) => (
      <Link
        to={`/${agreement.of_enterprise.slug}/contacts/providers/${agreement.provider.id}/agreements/${agreement.id}`}
      >
        {agreement.number}
      </Link>
    ),
  },
  date('Date  from', 'agreement.date_from'),
  date('Date  from', 'agreement.date_to'),
];

const proxyColumns: TableColumns<ListProvidedDepartment> = [
  {
    content: 'Department',
    key: 'department.name',
    render: ({ department }) => <DepartmentTitle {...department} />,
    width: 300,
  },
  {
    content: 'Type',
    key: 'type',
  },
  {
    content: 'Agency',
    key: 'agreement.of_enterprise.company',
    render: ({ agreement: { of_enterprise } }) => <CompanyTitle {...of_enterprise.company} />,
  },
  {
    content: 'Provider',
    key: 'agreement.provider',
    render: ({ agreement: { provider } }) => <CompanyTitle {...provider} />,
  },
];

const nav = [
  {
    to: 'direct',
    children: 'Direct',
  },
  {
    to: 'proxy',
    children: 'Proxy',
  },
];

const Provided = withHasPerm(function Provided() {
  return (
    <>
      <Breadcrumb>Provided departments</Breadcrumb>
      <div className="container-fluid header">
        <div className="d-flex flex-row align-items-center">
          <div className="mb-2">
            <h2>Provided departments</h2>
          </div>
        </div>
        <TabNav nav={nav} />
      </div>
      <Outlet />
    </>
  );
}, permissions.view);

export const route = {
  path: 'provided',
  element: <Provided />,
  children: [
    {
      path: 'direct',
      element: (
        <Page>
          <Breadcrumb to={null}>Direct</Breadcrumb>
          <IndexTable
            columns={directColumns}
            url="supplier/provided/direct/"
            filterFields={
              <>
                <DepartmentField name="department" simple placeholder="Department" />
                <AgencyActivityTypesField name="type" placeholder="Type" className="ml-2" />
                <CompanyField
                  name="agreement__provider"
                  placeholder="Provider"
                  simple
                  className="ml-2"
                  includeBranches={true}
                />
              </>
            }
          />
        </Page>
      ),
    },
    {
      path: 'proxy',
      element: (
        <Page>
          <Breadcrumb to={null}>Proxy</Breadcrumb>
          <IndexTable
            columns={proxyColumns}
            url="supplier/provided/proxy/"
            filterFields={
              <>
                <DepartmentField name="department" simple placeholder="Department" />
                <AgencyActivityTypesField name="type" placeholder="Type" className="ml-2" />
                <EnterpriseField name="agreement__of_enterprise" placeholder="Agency" simple className="ml-2" />
              </>
            }
          />
        </Page>
      ),
    },
    {
      index: true,
      element: <Navigate to="direct" replace />,
    },
  ],
};
