import { Field, useEntity, Fieldset } from 'components';
import type { ReactElement } from 'react';
import invariant from 'tiny-invariant';
import type { Ticket } from 'modules/support/types';

export function AddCompany(): ReactElement {
  const {
    item: { data },
  } = useEntity<Ticket>();
  invariant(data, 'data present');

  return (
    <Fieldset title="Add company">
      <div className="form-row">
        <Field name="data.title" label="Title" help="Full legal name" className="col-md-4" />
        <Field name="data.internal_name" label="Internal name" className="col-md-4" />
        <Field name="data.code" label="Code" className="col-md-4" />
      </div>
      <div className="form-row">
        <Field name="data.kind" label="Kind" className="col-md-2" />
        <Field name="data.country.name" label="Country" className="col-md-2" />
        <Field name="data.registration_number" label="Registration number" className="col-md-4" />
        <Field name="data.vat_number" label="VAT number" className="col-md-4" />
      </div>
      <div className="form-row">
        <Field name="data.legal_address" label="Legal address" className="col-md-4" />
        {!data.office_same_as_legal && <Field name="data.office_address" label="Office address" className="col-md-4" />}
      </div>
      <div className="form-row">
        <Field name="data.bank_swift" label="Bank (SWIFT)" className="col-md-4" />
        <Field name="data.bank_account_number" label="Account number (IBAN)" className="col-md-4" />
      </div>
      <div className="form-row">
        <Field name="data.extra_info" label="Extra info" help="Any other info to know" className="col-md-4" />
      </div>
    </Fieldset>
  );
}
