import { ReactElement } from 'react';
import { withHasPerm } from 'components';
import { Client } from './Client';
import permissions from './permissions';
import { LocalVisibilityReport, ReportContainer } from '../common';

export function LocalClient(): ReactElement {
  return (
    <LocalVisibilityReport
      localPerm={permissions.local}
      managedPerm={permissions.local_managed}
      Component={Client}
      title="Clients"
    />
  );
}

export const GlobalClient = withHasPerm(
  () => (
    <ReportContainer title="Clients">
      <Client visibility="GLOBAL" />
    </ReportContainer>
  ),
  permissions.global,
);
