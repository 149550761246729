import { Link } from 'react-router-dom';
import { ReactElement } from 'react';
import { TitleContainer } from 'components/TitleContainer';
import { Box } from '@ff-it/ui';
import { formatDate, sepFormat } from 'utilities';
import type { EmbeddedInvoice } from '../types';

export function InvoiceWidget({
  invoice: { id, direction, slug, checkout_date, number, total },
  link,
  flat,
}: {
  invoice: EmbeddedInvoice;
  link?: boolean;
  flat?: boolean;
}): ReactElement {
  const content =
    link && slug ? (
      <Link to={`/${slug}/invoices/${direction}/${id}`} target="_blank">
        {number}
      </Link>
    ) : (
      number
    );

  // FIXME: <Badge state={state} className="ml-1" />
  return (
    <TitleContainer
      testId={`invoice-${direction}-${id}`}
      flat={flat}
      title={content}
      subtitle={
        <Box>
          {formatDate(checkout_date)}{' '}
          <Box component="span" marginLeft="xs">
            {sepFormat(total)}
          </Box>
        </Box>
      }
    />
  );
}
