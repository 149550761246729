import { RouteObject } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import { Heading, Breadcrumb, SubNav } from 'components';
import { Update } from './Update';
import { SetPassword } from './SetPassword';
import { useUser } from 'services';
import { ReactElement } from 'react';

const nav = [
  {
    to: '',
    end: true,
    children: 'Update',
  },
  {
    to: 'set_password',
    children: 'Set password',
  },
];

function Profile(): ReactElement {
  const user = useUser();

  return (
    <>
      <Breadcrumb>Profile</Breadcrumb>
      <SubNav nav={nav}>
        <Heading>
          <h1>
            {`${user.first_name} ${user.last_name}`}
            <small className="text-muted ml-2">{user.email}</small>
          </h1>
        </Heading>
      </SubNav>
      <Routes>
        <Route index element={<Update />} />
        <Route path="set_password" element={<SetPassword />} />
      </Routes>
    </>
  );
}

// @todo export route, so nav works
export const route: RouteObject = {
  path: 'profile/*',
  element: <Profile />,
};
