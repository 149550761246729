import { ReactElement } from 'react';
import { TextField } from '@ff-it/form';
import { CategoryField } from '../category/MediaCategoryField';

export function DepartmentForm(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="name" label="Name" className="col-md-6" required />
        <CategoryField name="category" label="Category" className="col-md-6" help="Default category for products" />
      </div>
    </div>
  );
}
