import { RouteObject, Navigate } from 'react-router';
import { route as dashboard } from './Dashboard';
import { route as planning } from './Planning';
import { route as invoices } from './Invoices';
import { route as guarantee } from './Guarantee';
import { route as reporting } from './Reporting';
import { route as contacts } from './Contacts';
import { route as preferences } from './Preferences';

import { route as system } from '../_system';
import { SubmitTicket } from './Support/Ticket';
import { route as profile } from '../Profile';

export const routes: RouteObject[] = [
  dashboard,
  planning,
  invoices,
  guarantee,
  reporting,
  contacts,
  preferences,
  system,
  profile,
  {
    path: '/support/ticket/*',
    element: <SubmitTicket />,
  },
  {
    index: true,
    element: <Navigate to="dashboard" replace />,
  },
  { path: '*', element: <h1>404</h1> },
];
