import { ReactElement } from 'react';
import { enterpriseFieldProps, Field, Period, useEntity } from 'components';
import type { Activity } from 'types';
import type { Guarantee } from 'modules/guarantee/types';
import { Check } from '@ff-it/ui';
import { parseBig, sepFormat } from 'utilities';
import { useHasPerms } from 'core/permissions';
import { Enterprise } from 'modules/core/types';
import { departmentFieldProps } from 'modules/supplier/department/DepartmentField';
import { CompanyTitle } from 'modules/core/company/components';

interface DetailsProps {
  global: boolean;
}

function Commisions(): ReactElement {
  const {
    item: {
      commission,
      execution: { approved },
    },
  } = useEntity<Guarantee>();

  const a = parseBig(approved);

  return (
    <div className="mb-3">
      <table className="table form-table mb-0">
        <thead>
          <tr>
            <th className="text-center">Target</th>
            <th>From</th>
            <th className="text-right">To</th>
            <th className="text-center text-muted">Rate, %</th>
            <th className="text-right">Execution</th>
          </tr>
        </thead>
        <tbody>
          {commission.map(({ current, target, rate }, index) => {
            const to = index + 1 < commission.length ? commission[index + 1].target : undefined;

            const f = parseBig(target);
            const t = to ? parseBig(to) : undefined;

            let execution = null;

            if (t) {
              if (a.gte(f) && a.lt(t)) {
                execution = a.toFixed();
              }
            } else if (a.gte(f)) {
              execution = a.toFixed();
            }

            return (
              <tr key={index} className={current ? 'table-primary' : undefined}>
                <td className="align-middle text-center py-0">
                  {current ? <Check variant="radio" checked disabled /> : null}
                </td>
                <td>{sepFormat(target)}</td>
                <td className="text-right">{sepFormat(to || null, true)}</td>
                <td className="text-center text-muted">{rate}</td>
                <td className="text-right">
                  <strong>{sepFormat(execution, true)}</strong>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export function Details({ global }: DetailsProps): ReactElement {
  // FIXME
  const [hasChange, hasViewSensitive] = useHasPerms('guarantee.change_guarantee', 'guarantee.sensitive_info_guarantee');

  const showSensitive = global || hasChange || hasViewSensitive;

  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <Field name="title" label="Title" className="col-md-5" />
        <Field label="Period" className="col-md-3">
          {({ date_from, date_to }) => <Period date_from={date_from} date_to={date_to} flat normal />}
        </Field>
        <Field name="type" label="Type" className="col-md-2" />
      </div>
      <div className="form-row">
        <Field name="partner" className="col-md-3" label="Partner">
          {(c) => c.title}
        </Field>
      </div>
      {showSensitive && (
        <div data-testid="details">
          <hr />
          <div className="form-row">
            <Field name="comment" className="col-md-6" label="Terms & conditions" />
          </div>

          <div className="row pt-2">
            <div className="col-6">
              <h5 className="border-bottom pb-1">Campaign</h5>
              {global && (
                <div className="form-row">
                  <Field name="agencies" label="Agencies" className="col">
                    {(c: Enterprise[]) =>
                      c.length > 0
                        ? c.map((e, i) => <div key={i}>{enterpriseFieldProps.formatOptionLabel(e)}</div>)
                        : 'All'
                    }
                  </Field>
                </div>
              )}

              <div className="form-row">
                <Field name="clients_op" label="Condition" className="col-3" />
                <Field name="clients" label="Clients" className="col">
                  {(c) =>
                    c.length > 0 ? c.map((a: any) => <CompanyTitle key={a.id} className="mb-2" {...a} />) : 'All'
                  }
                </Field>
              </div>

              <div className="form-row">
                <Field name="activities_op" label="Condition" className="col-3" />
                <Field name="activities" label="Activities" className="col">
                  {(c) => (c.length > 0 ? c.map((a: Activity) => a.name).join(', ') : 'All')}
                </Field>
              </div>

              <div className="form-row">
                <Field name="types" label="Types" className="col">
                  {(c) => (c.length > 0 ? c.join(', ') : 'All')}
                </Field>
              </div>
            </div>
            <div className="col-6">
              <h5 className="border-bottom pb-1">Supplier</h5>
              <div className="form-row">
                <Field name="departments_op" label="Condition" className="col-3" />
                <Field name="departments" label="Departments" className="col">
                  {(c) =>
                    c.length > 0
                      ? c.map(departmentFieldProps.getOptionLabel).reduce((prev: any, curr: any) => [prev, ', ', curr])
                      : 'All'
                  }
                </Field>
              </div>

              <div className="form-row">
                <Field name="providers" label="Providers" className="col">
                  {(c) => (c.length > 0 ? c.map((a: any) => a.title) : 'All')}
                </Field>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h5>Commission</h5>
              <Commisions />
            </div>
            <div className="col-6 pt-3">
              {!global && (
                <Field name="managed_types" label="Managed types" help="Visibility for reporting">
                  {(v) => v.join(', ')}
                </Field>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
