import { TextField, TextFieldProps } from '@ff-it/form';
import { ReactElement } from 'react';

const codeProps = {
  parse: (value: string) => {
    if (value === '') return null;
    return value.toUpperCase();
  },
  format: (value: string | null) => {
    if (!value) {
      return '';
    }
    return value;
  },
};

export function CompanyCodeField(props: TextFieldProps): ReactElement {
  return <TextField label="Code" required {...codeProps} {...props} />;
}
