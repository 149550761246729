import type { PlanStrategyType } from '../types';
import type { PlanRow } from 'modules/campaign/row';
import type { Column } from 'components/DataGrid';
import type { RowOrGroup } from '../views/Plan/Grid/types';
import type { PlanState } from '../views/Plan/types';

export const cancelExport = Symbol();

export interface PlanStrategy {
  readonly KEY: PlanStrategyType;
  readonly TITLE: string;
  copyRow: (row: PlanRow) => Partial<Omit<PlanRow, 'id'>>;
  columns: Column<RowOrGroup, any>[];
  exportPlanOptions: (planState: PlanState) => Promise<any | typeof cancelExport>;
  canExportMediaReservation: boolean;
}
