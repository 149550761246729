import type { EmbeddedCompany } from 'types';
import { CompanyTitle } from './CompanyTitle';
import { createSelectField } from 'components/fields/createSelectField';
import type { ComponentProps, ReactElement } from 'react';

export const companyFieldProps = {
  getOptionLabel: (a: EmbeddedCompany) => a.title,
  getOptionValue: (a: EmbeddedCompany) => a.id.toString(),
  formatOptionLabel: CompanyTitle,
};

const InnerCompanyField = createSelectField<EmbeddedCompany>('core/companies/', companyFieldProps);

type CompanyFieldProps = Omit<ComponentProps<typeof InnerCompanyField>, 'filter'> & {
  includeBranches: boolean;
};

export function CompanyField({ includeBranches, ...props }: CompanyFieldProps): ReactElement {
  return (
    <InnerCompanyField
      {...props}
      filter={
        includeBranches
          ? {
              include_branches: true,
            }
          : undefined
      }
    />
  );
}
