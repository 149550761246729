import { any } from 'core/permissions';

export const add = 'invoicing.add_income';

export const add_advance = 'invoicing.add_advance_income';

export default {
  // CRUD
  view: any('invoicing.view_income', 'invoicing.view_campaign_income'),
  add: any(add, add_advance),

  change: 'invoicing.change_income',
  delete: 'invoicing.delete_income',
  // VIEWS
  link: 'invoicing.link_income',
  // ACTIONS
  cancel: 'invoicing.cancel_income',
  draft: 'invoicing.draft_income',
  confirm: 'invoicing.confirm_income',
  force_confirm: 'invoicing.force_confirm_income',
  mark_linked: 'invoicing.mark_linked_income',
  mark_not_linked: 'invoicing.mark_not_linked_income',
  book_income: 'invoicing.book_income',
  // EXPORT
  export: 'invoicing.export_income',
};
