import { Page } from 'components';
import { TextField, Form, Submit, useSubmitHandler } from '@ff-it/form';
import { toast } from 'react-toastify';
import { ReactElement } from 'react';

export function SetPassword(): ReactElement {
  const onSubmit = useSubmitHandler(
    {
      url: 'profile/password/',
      method: 'POST',
    },
    {
      onSuccess: (d, form) => {
        toast.success('Password has been changed!');
        form.reset();
      },
    },
  );
  return (
    <Page>
      <Form onSubmit={onSubmit} className="container px-0 ml-0">
        <div className="form-row">
          <TextField name="new_password" label="Password" className="col-md-6" type="password" />
        </div>
        <div className="form-row">
          <TextField name="new_password_repeat" label="Repeat password" className="col-md-6" type="password" />
        </div>
        <Submit />
      </Form>
    </Page>
  );
}
