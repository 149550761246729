import type { ReactElement } from 'react';
import type { LinkState } from './types';
import { PeriodIntersection } from 'components/PeriodIntersection';
import { Popover } from '@ff-it/ui';
import { RowLinkState } from 'components/RowLinkState';

export function PeriodState({ direction, intersection, months, invoices }: LinkState): ReactElement {
  return (
    <Popover
      interaction="hover"
      placement="auto"
      content={<RowLinkState direction={direction} months={months} invoices={invoices} />}
      offsetOptions={6}
    >
      {/* something to inject children into */}
      <span>
        <PeriodIntersection intersection={intersection} direction={direction} />
      </span>
    </Popover>
  );
}
