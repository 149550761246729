import type { PlanRow } from 'modules/campaign/row';
import { RowOrGroup } from './types';
import { ProductKind } from 'types';

const mapColumnToField = (kind: ProductKind, columnKey: string): keyof PlanRow['config'] => {
  if (kind === 'SMART') {
    if (columnKey === 'smart') {
      return 'rate';
    }
  } else {
    /// MEDIA
    if (columnKey === 'client_factors' || columnKey === 'provider_factors') {
      return 'factors';
    }
    if (columnKey === 'smart') {
      return 'applied_to';
    }

    if (columnKey.startsWith('month_')) {
      return 'delivery';
    }
  }

  return columnKey as keyof PlanRow['config'];
};

const fieldIsLocked = (row: PlanRow, fieldName: keyof PlanRow['config']): boolean => {
  let locked = row.config[fieldName]?.locked;
  // readonly columns have no config and not all coulms map to row fields.
  if (typeof locked === 'undefined') {
    locked = true;
  }
  return locked;
};

const fieldIsValid = (row: PlanRow, fieldName: keyof PlanRow['config']): boolean => {
  let valid = row.config[fieldName]?.valid;
  // readonly columns have no config and not all coulms map to row fields.
  if (typeof valid === 'undefined') {
    valid = true;
  }
  return valid;
};

const fieldIsRequired = (row: PlanRow, fieldName: keyof PlanRow['config']): boolean => {
  return Boolean(row.config[fieldName]?.required);
};

export function cellIsEditable(row: RowOrGroup, columnKey: string): boolean {
  if (row._isGroup) {
    // group.title, group.budget
    if (columnKey === 'supplier' || columnKey === 'client_total') {
      return !row.config.locked;
    }
    return false;
  }

  return !fieldIsLocked(row, mapColumnToField(row.kind, columnKey));
}

export function cellIsRequired(row: RowOrGroup, columnKey: string): boolean {
  if (row._isGroup) {
    return false;
  }

  return fieldIsRequired(row, mapColumnToField(row.kind, columnKey));
}

export function cellIsValid(row: RowOrGroup, columnKey: string): boolean {
  if (row._isGroup) {
    return true;
  }

  return fieldIsValid(row, mapColumnToField(row.kind, columnKey));
}
