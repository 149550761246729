import { useEntity } from 'components';
import type { BlockDetails, BlockSums } from 'modules/campaign/block/types';
import useSWR from 'swr';
import { SUMS_KEY } from './usePlanController';
import { fetcher } from 'services';

export function useBlockSums(): BlockSums | undefined {
  // FIXME: just calculating at runtime probably makes more sense unless we want to use sums for summary
  const { endpoint } = useEntity<BlockDetails>();
  // We don't really care about etag
  // const requestHandler = useRequestHandler();

  const { data } = useSWR<BlockSums, unknown>(SUMS_KEY, {
    fetcher: () => fetcher<BlockSums>({ method: 'GET', url: `${endpoint}sums/` }),
    shouldRetryOnError: true,
  });

  return data;
}
