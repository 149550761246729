import { useMemo } from 'react';
import { ActivityType } from 'types';
import { Perm, useCheck } from 'core/permissions';
import campaign from 'modules/campaign/campaign/permissions';
import { useManagedTypes } from 'services';

export interface LocalVisibilityPerms {
  local: boolean;
  managedClients: boolean;
  managedTypes: ActivityType[];
}
export function useLocalVisiblityPerms(localPerm: Perm, managedPerm: Perm): LocalVisibilityPerms | null {
  const check = useCheck();
  const types = useManagedTypes();
  return useMemo(() => {
    const local = check(localPerm);
    const manage = check(managedPerm);

    const managedClients = manage && check(campaign.manage);
    const managedTypes = !local && manage ? types : [];

    if (!local && !managedClients && managedTypes.length === 0) {
      return null;
    }

    return {
      local,
      managedClients,
      managedTypes,
    };
  }, [check, localPerm, managedPerm, types]);
}
