import { ReactElement } from 'react';
import { Form, Submit, useSubmitHandler } from '@ff-it/form';
import { LoadingContainer } from '@ff-it/ui';
import { Breadcrumb, Page } from 'components';
import { Documents } from './Documents';
import { Rows, RowOptions } from './Rows';
import { InvoiceDirection } from 'modules/invoicing/common/types';
import useSWR from 'swr';

interface ConfigProps {
  direction: InvoiceDirection;
  options: RowOptions;
  enterpriseId: number;
}

export function Config({ direction, options: { nomenclature, operations }, enterpriseId }: ConfigProps): ReactElement {
  const url = `onec/config/${enterpriseId}/${direction}/`;
  const {
    isLoading,
    data: config,
    mutate,
  } = useSWR<any>({
    url,
    method: 'GET',
  });

  const onSubmit = useSubmitHandler<any, any>(
    { url, method: 'PUT' },
    { onSuccess: (v) => mutate(v, { revalidate: false }) },
  );
  const options = {
    operations: operations.filter(({ kind }) => kind === direction),
    nomenclature,
  };

  return (
    <Page>
      <Breadcrumb to={null}>{direction}</Breadcrumb>
      <LoadingContainer loading={isLoading}>
        {config ? (
          <Form initialValues={config} onSubmit={onSubmit} className="mt-3">
            <div className="row">
              <div className="col-5">
                <Documents items={config.doc} />
                <Submit />
              </div>
              <div className="col-7">
                <Rows items={config.row} options={options} />
              </div>
            </div>
          </Form>
        ) : null}
      </LoadingContainer>
    </Page>
  );
}
