import type { ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import './InvoicingProgress.scss';
import type { InvoiceDirection } from 'modules/invoicing/common/types';

type InvoicingProgressProps = {
  direction: InvoiceDirection;
  ratio: number | null;
  children?: ReactNode;
  id?: string;
  // total
  // bound
  // remainder
};

// clamp to min/max 10 % from edges to make obvious
const margin = 10;

export function InvoicingProgress({ direction, ratio, children, id }: InvoicingProgressProps): ReactElement {
  const empty = ratio === null;
  let width = 0;
  if (ratio !== null) {
    if (ratio > 0 && ratio < margin) {
      width = margin;
    } else if (ratio > 100 - margin && ratio < 100) {
      width = 100 - margin;
    } else {
      width = ratio;
    }
  }

  return (
    <div
      className={cx({ [`progress progress--invoicing progress--${direction}`]: true, 'progress--empty': empty })}
      data-tooltip-id={id}
    >
      {!empty && (
        <div className="progress-bar" role="progressbar" style={{ width: `${width}%` }}>
          {children}
        </div>
      )}
    </div>
  );
}
