import { SelectField } from '@ff-it/form';
import { InvoiceType } from 'modules/invoicing/common/types';
import { ReactElement } from 'react';

interface RowConfig {
  id?: number;
  type: InvoiceType;
  domain: 'LOCAL' | 'EU' | 'OTHER';
  vat_rate: number;
  nomenclature: string;
  operation: string;
}

interface NomenclatureOption {
  id: string;
  name: string;
  unit: string;
  vat: string;
}

interface OperationOption {
  id: string;
  kind: string;
}

export interface RowOptions {
  nomenclature: NomenclatureOption[];
  operations: OperationOption[];
}

export interface RowProps {
  items: RowConfig[];
  options: RowOptions;
}

const nomenclatureSelectProps = {
  simple: true,
  size: 'sm' as const,
  getOptionLabel: ({ name, vat }: NomenclatureOption) => `${name} (${vat})`,
  getOptionValue: (a: NomenclatureOption) => a.id,
};

const operationSelectProps = {
  simple: true,
  size: 'sm' as const,
  getOptionLabel: ({ id }: OperationOption) => `${id}`,
  getOptionValue: (a: OperationOption) => a.id,
};

export function Rows({ items, options: { nomenclature, operations } }: RowProps): ReactElement {
  return (
    <>
      <h5>Rows</h5>
      <table className="table  form-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Domain</th>
            <th className="text-right">VAT rate</th>
            <th>Nomenclature</th>
            <th>Operation</th>
          </tr>
        </thead>
        <tbody>
          {items.map(({ type, domain, vat_rate }, i) => (
            <tr key={i}>
              <td className="align-middle">{type}</td>
              <td className="align-middle">{domain}</td>
              <td className="align-middle text-right">{vat_rate}%</td>
              <td>
                <SelectField name={`row[${i}].nomenclature`} options={nomenclature} {...nomenclatureSelectProps} />
              </td>
              <td>
                <SelectField name={`row[${i}].operation`} options={operations} {...operationSelectProps} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
