import { Guarantee } from './types';

export const copyGuarantee = (guarantee: Guarantee): any => ({
  ...guarantee,
  id: undefined,
  number: undefined,
  date_from: null,
  date_to: null,
  commission: guarantee.commission.map((c) => ({ ...c, id: undefined })),
});
