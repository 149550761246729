import { useEffect, useCallback, ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import { Form, TextField, useFormState } from '@ff-it/form';
import { Button, useDebounce } from '@ff-it/ui';

import eq from 'fast-deep-equal';
import { Icon } from 'components/ui';
import { useTable } from './context';
import { FilterType } from './types';

interface FilterControlProps {
  filter: FilterType;
  defaultFilter: FilterType;
  setFilter: (f: FilterType) => void;
}

function FilterControl({ filter, setFilter, defaultFilter }: FilterControlProps): ReactElement | null {
  const { valid, values } = useFormState({
    subscription: { valid: true, values: true },
  });

  const applyFilter = useDebounce(
    useCallback(
      (raw) => {
        const values = Object.keys(raw).reduce((acc: any, key) => {
          const v = raw[key];
          // FIXME: seems suboptimal, selects are reset to null
          if (v === null) {
            return acc;
          }
          if (Array.isArray(v) && v.length === 0) {
            return acc;
          }
          acc[key] = v;
          return acc;
        }, {});

        if (!eq(filter, values)) {
          setFilter(values);
        }
      },
      [setFilter, filter],
    ),
    500,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: applyFilter is in sync
  useEffect(() => {
    valid && applyFilter(values);
  }, [valid, values]);

  if (!eq(values, defaultFilter)) {
    return (
      <Button
        variant="outline-danger"
        size="sm"
        className="ml-1  border-0"
        onClick={() => {
          setFilter(defaultFilter);
        }}
      >
        <Icon icon="xmark" />
      </Button>
    );
  }
  return null;
}

const onSubmit = async (): Promise<void> => undefined;

interface ListFilterProps {
  className?: string;
  children?: ReactNode;
}

export function TableFilter({ children, className }: ListFilterProps): ReactElement {
  const { filter, setFilter, defaultFilter } = useTable();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={filter}
      className={cx('form-inline list-filter', className)}
      autoComplete="off"
    >
      {children ? children : <TextField name="search" placeholder="Search..." size="sm" />}
      <FilterControl filter={filter} defaultFilter={defaultFilter} setFilter={setFilter} />
    </Form>
  );
}
