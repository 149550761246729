import { useField } from '@ff-it/form';
import { Bound } from './types';

export function useBound(): Bound {
  const {
    input: { value: bound = {} },
  } = useField<Bound>('bound', { subscription: { value: true, error: true, submitError: true }, format: (v) => v });

  return bound;
}
