import { useEntity } from 'components';
import { ReactElement } from 'react';

export function Nav(): ReactElement {
  const {
    item: { name },
  } = useEntity();

  return (
    <div className="container-fluid header header--department bg-white">
      <h3 className="pb-2">{name || <em>Generic product</em>}</h3>
    </div>
  );
}
