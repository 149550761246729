import { useEntity, IndexContainer } from 'components';
import type { Enterprise, MembershipLog as MembershipLogType } from 'modules/core/types';
import { timestamp } from 'components/columns';
import { ReactElement } from 'react';
import { NewListExport } from 'components/ListActions';
import { TableColumns } from '@ff-it/ui';

const columns: TableColumns<MembershipLogType> = [
  {
    content: 'Id',
    key: 'membership_id',
    width: 80,
  },
  {
    content: 'User',
    key: 'user_repr',
  },
  timestamp('Created', 'date_from', { sortable: true }),
  {
    content: 'Created by',
    key: 'created_by_repr',
    width: 200,
  },
  timestamp('Removed', 'date_to', { sortable: true }),
  {
    content: 'Removed by',
    key: 'removed_by_repr',
    width: 200,
  },
];

export function MembershipLog(): ReactElement {
  const { endpoint: entityEndpoint } = useEntity<Enterprise>();
  const endpoint = `${entityEndpoint}membership_log/`;

  return (
    <IndexContainer
      title="Membership log"
      url={endpoint}
      columns={columns}
      toolbar={<NewListExport endpoint={`${endpoint}export/`} />}
    />
  );
}
