import { ReactElement } from 'react';
import { SelectField, TextField, useField } from '@ff-it/form';
import { materialTypeOptions } from 'options';
import { required } from 'utilities';

type FormatFormProps = {
  name?: string;
};

export function FormatForm({ name = '' }: FormatFormProps): ReactElement {
  const {
    input: { value: type },
  } = useField(`${name}material_type`, { subscription: { value: true } });

  return (
    <>
      <div className="form-row">
        <SelectField
          className="col-md-6"
          label="Material type"
          name={`${name}material_type`}
          options={materialTypeOptions}
          simple
          required
          validate={required}
          size="sm"
        />
        <TextField
          label="Size"
          className="col-md-6"
          name={`${name}size`}
          required
          validate={required}
          size="sm"
          help="dimensions/length"
        />
      </div>
      <div className="form-row">
        <TextField label="File format" className="col-md-6" name={`${name}file_format`} size="sm" />
        <TextField label="File size (weight)" className="col-md-6" name={`${name}file_weight`} size="sm" />
      </div>

      <TextField label="Description" name={`${name}description`} size="sm" multiline autosize />
      <TextField label="Specification URL" name={`${name}specification_url`} size="sm" />
      <TextField label="Example URL" name={`${name}example_url`} size="sm" />

      <TextField label="Title" name={`${name}title`} size="sm" placeholder={type} />
    </>
  );
}
