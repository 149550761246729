import { useRef } from 'react';
import eq from 'fast-deep-equal';
import { Linkable } from './types';

export function useGroupRows(rows: Linkable[], field: string): Record<string, Linkable[]> {
  // this memos grouped rows since we memo Campaign and Block containers
  const previousRef = useRef<any>();

  const grouped: Record<string, Linkable[]> = {};
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const key = row[field as keyof Linkable] as string;
    if (key in grouped) {
      grouped[key].push(row);
    } else {
      grouped[key] = [row];
    }
  }

  if (previousRef.current) {
    Object.keys(grouped).map((key) => {
      if (eq(previousRef.current[key], grouped[key])) {
        grouped[key] = previousRef.current[key];
      }
    });
  }

  previousRef.current = grouped;

  return previousRef.current;
}
