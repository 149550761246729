import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useMembership } from 'services';
import { branding } from 'styles/style.css';

export function AgencyBrand(): ReactElement {
  const { slug, title, logo_url } = useMembership();
  return (
    <Link to={`/${slug}/`}>
      {logo_url ? (
        <img src={logo_url} className={branding} width="200" height="60" alt="logo" />
      ) : (
        <h1 className={branding}>{title}</h1>
      )}
    </Link>
  );
}
