import { Dispatch, ReactElement, SetStateAction } from 'react';
import { Alert, Button } from '@ff-it/ui';
import type { PlanSelection } from '../types';
import { Action, Actions, Icon } from 'components';
import type { PlanRow } from 'modules/campaign/row';
import { PlanController } from '../usePlanController';
import invariant from 'tiny-invariant';
import { toast } from 'react-toastify';

function useActions(
  selected: PlanRow[],
  unlocked: PlanRow[],
  controller: PlanController,
  setPlanSelection: Dispatch<SetStateAction<PlanSelection>>,
): Action[] {
  if (selected.length === 0) {
    return [];
  }
  invariant(controller.planning);

  const actions: Action[] = [
    {
      action: 'copy',
      button: {
        children: `Copy ${selected.length} rows`,
        variant: 'outline-secondary',
        icon: 'copy',
        size: 'sm',
        onClick: async (): Promise<void> => {
          const created = [];
          for (const i of selected) {
            created.push(await controller.copyRow(i, false));
          }

          controller.gridRef.current?.selectCell(`row-${created[created.length - 1].id}`);
          setPlanSelection(new Set());
          toast.success(`Copied ${created.length} rows!`);
        },
      },
    },
  ];

  if (unlocked.length > 0) {
    actions.push({
      action: 'remove',
      button: {
        children: `Delete ${unlocked.length} rows`,
        variant: 'outline-danger',
        icon: 'xmark',
        size: 'sm',
        onClick: async (): Promise<void> => {
          const removed = [];
          for (const i of unlocked) {
            await controller.removeRow(i.id);
            removed.push(i);
          }
          setPlanSelection(new Set());
          toast.warn(`Deleted ${removed.length} rows!`);
        },
      },
    });
  }

  return actions;
}

type SelectionControlProps = {
  controller: PlanController;
  rows: PlanRow[];
  planSelection: PlanSelection;
  setPlanSelection: Dispatch<SetStateAction<PlanSelection>>;
};

export function SelectionControls({
  rows,
  planSelection,
  setPlanSelection,
  controller,
}: SelectionControlProps): ReactElement | null {
  if (!controller.planning) {
    return null;
  }
  const selected: PlanRow[] = [];
  const unlocked: PlanRow[] = [];
  if (planSelection?.size) {
    for (const row of rows) {
      if (planSelection.has(row.id)) {
        selected.push(row);
        if (!row.sums.is_locked) {
          unlocked.push(row);
        }
      }
    }
  }
  const actions = useActions(selected, unlocked, controller, setPlanSelection);

  return (
    <div className="btn-toolbar">
      {planSelection == null ? (
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => setPlanSelection(new Set())}
          testId="toggle-selection"
          active={false}
        >
          Select <Icon icon="square-check" />
        </Button>
      ) : (
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => setPlanSelection(null)}
          testId="toggle-selection"
          active={true}
          data-selected-rows={selected.length}
          data-selected-unlocked-rows={unlocked.length}
        >
          Clear selection <Icon icon="square-xmark" />
        </Button>
      )}
      {selected.length > 100 ? (
        <Alert variant="danger" className="ml-1 block-notice d-inline-block">
          Too many ({selected.length}) rows selected
        </Alert>
      ) : (
        <Actions actions={actions} className="ml-2" />
      )}
    </div>
  );
}
