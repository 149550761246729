import { fmt, sepFormat } from 'utilities';
import { createColumn } from './createColumn';
import { DecimalEditor } from 'components/DataGrid';
import { right, center } from './style.css';
import { TitleContainer } from 'components/TitleContainer';

export const EstImpressionsColumn = createColumn(
  {
    key: 'est_impressions',
    name: <TitleContainer title="Est. impr." subtitle="000s" />,
    width: 96,
    className: right,
  },
  {
    renderView: (props) => (props.row.kind === 'MEDIA' ? sepFormat(props.row.est_impressions) : null),
    renderEdit: (props) => <DecimalEditor {...props} precision={2} normalize />,
  },
  undefined,
  { renderSummaryCell: (props) => fmt(props.row.estimatedImpressions) },
);

export const EstClicksColumn = createColumn(
  {
    key: 'est_clicks',
    name: 'Est. clicks',
    width: 96,
    className: right,
  },
  {
    renderView: (props) => (props.row.kind === 'MEDIA' ? sepFormat(props.row.est_clicks) : null),
    renderEdit: (props) => <DecimalEditor {...props} precision={2} normalize />,
  },
  undefined,
  { renderSummaryCell: (props) => fmt(props.row.estimatedClicks) },
);

export const CTRColumn = createColumn(
  {
    key: 'ctr',
    name: 'CTR%',
    width: 68,
    className: center,
  },
  {
    renderView: (props) =>
      props.row.kind === 'MEDIA' ? (props.row.ctr ? `${sepFormat(props.row.ctr)}%` : null) : null,
    renderEdit: (props) => <DecimalEditor {...props} precision={2} normalize />,
  },
);
