import { SetStateAction, Dispatch, useMemo } from 'react';
import type { PlanSelection } from '../../types';
import type { GridSelection, RowKey, RowOrGroup } from '../types';

/**
 * Turns planSelection ReadonlySet<RowId> into gridSelection ReadonlySet<RowKey>;
 */
export function useGridSelection(
  planSelection: PlanSelection,
  setSelection: Dispatch<SetStateAction<PlanSelection>>,
  rows: RowOrGroup[],
): [GridSelection | null, (selectRows: GridSelection) => void] {
  const selectedRows = useMemo((): GridSelection | null => {
    if (planSelection == null) return null;
    const selectedRows = new Set<RowKey>([...planSelection].map((id) => `row-${id}`));

    for (const row of rows) {
      if (row._isGroup) {
        // select parent row if all the children are selected
        const isGroupRowSelected = row.childIds.every((cid) => planSelection.has(cid));
        if (isGroupRowSelected) {
          selectedRows.add(row._key);
        }
      }
    }
    return selectedRows;
  }, [planSelection, rows]);

  function onSelectedRowsChange(newSelectedRows: GridSelection): void {
    const newRawSelectedRows = new Set(planSelection);
    for (const row of rows) {
      if (selectedRows?.has(row._key) && !newSelectedRows.has(row._key)) {
        if (row._isGroup) {
          // select all children if the parent row is selected
          for (const cid of row.childIds) {
            newRawSelectedRows.delete(cid);
          }
        } else {
          newRawSelectedRows.delete(row.id);
        }
      } else if (!selectedRows?.has(row._key) && newSelectedRows.has(row._key)) {
        if (row._isGroup) {
          // unselect all children if the parent row is unselected
          for (const cid of row.childIds) {
            newRawSelectedRows.add(cid);
          }
        } else {
          newRawSelectedRows.add(row.id);
        }
      }
    }
    setSelection(newRawSelectedRows);
  }

  return [selectedRows, onSelectedRowsChange];
}
