import { Box, CellProps, Table, Tbody, Td, Th, Thead, Tr, vars } from '@ff-it/ui';
import { DragHandle, useDraggable, useDragMonitor } from 'components/DND';
import { ReactElement, ReactNode } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { AddInline, InlineRenderProps, Inlines, RemoveInline } from './Inlines';
import { controlCol } from 'styles/style.css';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { FormInlineError } from './FormInlineError';

// similar to DataGrid
export type TableInlineColumn<V = any> = {
  key: string;
  content?: ReactNode;
  width?: number | string;
  minWidth?: number;
  maxWidth?: number;
  render: (props: InlineRenderProps<V>) => ReactNode;
  cellProps?: CellProps;
  headerCellProps?: CellProps;
};

type TableInlineRowProps = InlineRenderProps & {
  inlineName: string;
  min?: number;
  columns: TableInlineColumn[];
};

function TableInlineRow({ inlineName, min, columns, ...props }: TableInlineRowProps): ReactElement {
  const { index, disabled } = props;
  const { elementRef, handleRef, isDragging, closestEdge } = useDraggable(index, disabled, inlineName);

  return (
    <Tr
      style={{ position: 'relative', backgroundColor: isDragging ? vars.pallete.blue50 : undefined }}
      ref={elementRef}
    >
      <Td textAlign="center" flush>
        <DragHandle disabled={disabled} ref={handleRef} active={isDragging} />
        {closestEdge && <DropIndicator edge={closestEdge} />}
      </Td>
      {columns.map(({ key, render, cellProps }) => (
        <Td key={key} {...cellProps}>
          {render(props)}
        </Td>
      ))}
      <Td flush textAlign="center">
        <RemoveInline name={inlineName} index={index} min={min} />
      </Td>
    </Tr>
  );
}

type TableInlinesProps<T = any> = {
  name: string;
  initialValues?: T;
  children?: InlineRenderProps;
  columns: TableInlineColumn[];
  min?: number;
  actions?: ReactNode;
  tfoot?: ReactNode;
};

export function TableInlines<T = any>({
  name,
  columns,
  initialValues,
  min,
  actions,
  tfoot,
}: TableInlinesProps<T>): ReactElement {
  const { fields } = useFieldArray<T>(name, { subscription: {} });
  useDragMonitor((sourceData, targetData, edge) => {
    if (edge === 'bottom') {
      fields.move(sourceData.index, targetData.index + 1);
    } else if (edge === 'top') {
      fields.move(sourceData.index, targetData.index);
    }
  }, name);

  return (
    <Table
      marginBottom="md"
      size="sm"
      textSize="sm"
      before={<FormInlineError name={name} className="mb-1" />}
      fixed
      after={
        <Box border="light" padding="sm" style={{ borderTop: 0 }}>
          <Box display="flex">
            {initialValues && (
              <AddInline name={name} initialValues={initialValues}>
                Add rate
              </AddInline>
            )}
            {actions}
          </Box>
        </Box>
      }
    >
      <colgroup>
        <col />
        {columns.map(({ key, width, minWidth, maxWidth }) => (
          <col key={key} style={{ width, minWidth, maxWidth }} />
        ))}
        <col />
      </colgroup>
      <Thead variant="dark">
        <Tr>
          <Th className={controlCol} />
          {columns.map(({ key, content, cellProps, headerCellProps }) => (
            <Th key={key} {...cellProps} {...headerCellProps}>
              {content}
            </Th>
          ))}
          <Th className={controlCol} />
        </Tr>
      </Thead>
      <Tbody>
        <Inlines name={name}>
          {(props) => <TableInlineRow {...props} min={min} inlineName={name} columns={columns} />}
        </Inlines>
      </Tbody>
      {tfoot}
    </Table>
  );
}
