import { ReactElement } from 'react';
import cx from 'clsx';
import { Box, Sprinkles } from '@ff-it/ui';
import type { InvoiceDirection } from 'modules/invoicing/common/types';
import { wrap, variants, states } from './style.css';
import { IntersectionState } from 'types';

type PeriodIntersectionProps = {
  direction: InvoiceDirection;
  intersection: IntersectionState;
} & Sprinkles;

export function PeriodIntersection({ direction, intersection, ...sprinkles }: PeriodIntersectionProps): ReactElement {
  return (
    <Box className={wrap} {...sprinkles} title={intersection}>
      <Box className={cx(variants[direction], states[intersection])} />
    </Box>
  );
}
