import { ReactElement, useMemo } from 'react';
import { RequestArgs, RequestBody, RequestFailure } from '@ff-it/api';
import invariant from 'tiny-invariant';
import type { Action } from './Actions';
import { MaybePromiseAction, PomiseActionsProps, PromiseActions } from './PromiseActions';
import { actionErrorAndThrow } from 'utilities';
import { Fetcher, fetcher as defaultFetcher } from 'services';

// true for resolved, undefied for canceled
export type RequestDialogResult = RequestBody | true | undefined;
export type RequestDialog = () => Promise<RequestDialogResult>;

export interface RequestAction extends Action {
  request: RequestArgs;
  /** @deprecated remove me */
  onRetry?: (f: RequestFailure<any>, requestArgs: RequestArgs) => RequestArgs | undefined;
  /** @deprecated remove me */
  requestDialog?: RequestDialog;
  successMessage?: string;
}

export type MaybeRequestAction = MaybePromiseAction | RequestAction;

export function isRequestAction(action: MaybeRequestAction): action is RequestAction {
  return (action as RequestAction).request !== undefined;
}

// Binds request args to promises
export function useRequestActions(actions: Array<MaybeRequestAction>, fetcher: Fetcher): MaybePromiseAction[] {
  return useMemo(
    () =>
      actions.map((action) => {
        if (isRequestAction(action)) {
          const { request, requestDialog, onRetry, ...rest } = action;
          invariant(!action.button?.onClick, "onClick handler present, can't bind action");

          const promise = async (): Promise<any> => {
            let payload = undefined;
            if (requestDialog) {
              const res = await requestDialog();
              if (typeof res === 'undefined') {
                // bail
                return;
              }
              if (payload !== true) {
                payload = res;
              }
            }

            const requestArgs = {
              ...request,
              body: payload || request.body,
            };

            try {
              return await fetcher(requestArgs);
            } catch (error) {
              if (onRetry) {
                const retryArgs = onRetry(error as RequestFailure<unknown>, requestArgs);
                if (retryArgs) {
                  try {
                    return await fetcher(retryArgs);
                  } catch (error) {
                    actionErrorAndThrow(error as RequestFailure<unknown>);
                  }
                }
              }
              actionErrorAndThrow(error as RequestFailure<unknown>);
            }
          };
          return {
            lock: true,
            promise,
            ...rest,
          };
        }
        return action;
      }),
    [actions, fetcher],
  );
}

export type RequestActionsProps = Omit<PomiseActionsProps, 'actions'> & {
  actions: MaybeRequestAction[];
  fetcher?: Fetcher;
};

export function RequestActions({ actions, fetcher = defaultFetcher, ...props }: RequestActionsProps): ReactElement {
  const boundActions = useRequestActions(actions, fetcher);
  return <PromiseActions {...props} actions={boundActions} />;
}
