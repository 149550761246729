import { IsDisabledContext, useIsDisabled } from '@ff-it/form';
import { ButtonProps } from '@ff-it/ui';
import { AddButton, RemoveButton } from 'components/ui';
import { Fragment, ReactElement, ReactNode } from 'react';
import { useForm } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import invariant from 'tiny-invariant';

// @TODO inline member error

const subscription = {
  submitError: true,
  dirtySinceLastSubmit: true,
  error: true,
  dirty: true,
  touched: true,
};

export type InlineRenderProps<V = any> = {
  name: string;
  index: number;
  disabled: boolean;
  value: V;
};

type InlineProps = {
  name: string;
  //   initialValues?: T;
  //   min?: number;
  //   testId?: string;
  disabled?: boolean;
  children: (props: InlineRenderProps) => ReactNode;
};

const defaultValue: any[] = [];

export function Inlines({ name, disabled: localDisabled, children }: InlineProps): ReactElement {
  const { fields } = useFieldArray<any>(name, {
    // FIXME: this is busted but we need it for DND keys, and to pass down int ocolumns
    subscription: { value: true },
    /*
    TODO: should default to [] - does not actually work.
    For some reason field array does not handle parse and to fix remove we would have to overload whole remove mutator
    */
    defaultValue,
  });
  const disabled = useIsDisabled(localDisabled);
  return (
    <IsDisabledContext.Provider value={disabled}>
      {fields.map((name, index) => {
        const value = fields.value[index];
        const key = value.id || value.__NEW__;
        invariant(key, `failed to get key for ${name} index: ${index}`);
        return <Fragment key={key}>{children({ name, index, disabled, value })}</Fragment>;
      })}
    </IsDisabledContext.Provider>
  );
}

type AddInlineProps = {
  name: string;
  initialValues: any;
  testId?: string;
  children?: ReactNode;
  size?: ButtonProps['size'];
  className?: string;
};

export function AddInline({
  name,
  initialValues,
  testId = 'add-inline',
  children = 'Add',
  ...btnProps
}: AddInlineProps): ReactElement {
  const {
    mutators: { push },
  } = useForm();

  const isDisabled = useIsDisabled();
  const addClickHandler = () => {
    push(
      name,
      typeof initialValues === 'function'
        ? initialValues()
        : {
            ...initialValues,
            __NEW__: Date.now(),
          },
    );
  };

  return (
    <AddButton testId={testId} onClick={addClickHandler} disabled={isDisabled} {...btnProps}>
      {children}
    </AddButton>
  );
}

type RemoveInlineProps = {
  name: string;
  index: number;
  min?: number;
  testId?: string;
  children?: ReactNode;
  className?: string;
};

export function RemoveInline({
  name,
  index,
  min,
  testId = 'remove-inline',
  ...btnProps
}: RemoveInlineProps): ReactElement {
  const {
    mutators: { remove },
  } = useForm();

  const isDisabled = useIsDisabled();
  const removeClickHandler = () => {
    remove(name, index);
  };

  return (
    <RemoveButton
      testId={testId}
      onClick={removeClickHandler}
      disabled={isDisabled || Boolean(min && min > index)}
      {...btnProps}
    />
  );
}
