import { TitleContainer } from 'components/TitleContainer';
import { createColumn } from './createColumn';
import { fmt, sepFormat } from 'utilities';
import { right } from './style.css';

export const RevenueMarginColumn = createColumn(
  {
    key: 'revenue_margin',
    name: <TitleContainer title="Revenue" subtitle="Margin" />,
    width: 96,
    className: right,
  },
  {
    renderView: ({
      row: {
        sums: { revenue, margin },
      },
    }) => (
      <TitleContainer
        title={sepFormat(revenue)}
        subtitle={
          <>
            {sepFormat(margin)}
            {margin !== null && '%'}
          </>
        }
      />
    ),
  },
  {
    renderView: ({ row: { margin, revenue } }) => (
      <TitleContainer
        title={fmt(revenue)}
        subtitle={
          <>
            {fmt(margin)}
            {margin !== null && '%'}
          </>
        }
      />
    ),
  },
  {
    renderSummaryCell: ({ row: { margin, revenue } }) => (
      <TitleContainer
        title={fmt(revenue)}
        subtitle={
          <>
            {fmt(margin)}
            {margin !== null && '%'}
          </>
        }
      />
    ),
  },
);
