import type { ReactElement } from 'react';
import type { CampaignDetails } from 'modules/campaign/campaign/types';
import type { Report, Month } from './types';
import { Alert, LoadingContainer } from '@ff-it/ui';
import { useEntity, DownloadButton, BlockWidget, BoundStates } from 'components';
import { sepFormat } from 'utilities';
import { Table, HeaderRow, Row } from 'modules/invoicing/common/finance';
import { usePeriodMonths } from 'hooks';
import { SideProgress } from './SideProgress';
import useSWR from 'swr';

function MonthProgress({
  planned_income,
  target_income,
  income_bound,
  income_remainder,
  income_ratio,
  planned_expense,
  expense_bound,
  expense_remainder,
  expense_ratio,
}: Month): ReactElement {
  return (
    <>
      <SideProgress
        direction="income"
        planned={planned_income}
        actual={target_income}
        bound={income_bound}
        remainder={income_remainder}
        ratio={income_ratio}
      />
      <SideProgress
        direction="expense"
        planned={planned_expense}
        bound={expense_bound}
        remainder={expense_remainder}
        ratio={expense_ratio}
      />
    </>
  );
}

function Container({ date_from, date_to, blocks, campaign }: Report): ReactElement {
  const months = usePeriodMonths({
    start: date_from,
    end: date_to,
  });
  return (
    <Table
      header={
        <>
          <tr className="f__r f__r--sums">
            <th className="f__c--group text-right" colSpan={4}>
              Planned income:
            </th>
            {months.map(({ value }) => (
              <th key={value} className="f__c--month">
                {sepFormat(campaign.months[value]?.planned_income)}
              </th>
            ))}
          </tr>
          <tr className="f__r f__r--sums">
            <th className="f__c--group text-right" colSpan={4}>
              Invoiceable:
            </th>
            {months.map(({ value }) => (
              <th key={value} className="f__c--month">
                {sepFormat(campaign.months[value]?.planned_income)}
              </th>
            ))}
          </tr>
          <HeaderRow months={months} />
          <Row
            kind="subheader"
            group={
              <div className="d-flex">
                <BoundStates {...campaign.sums} vertical />
                <div>
                  <div>Income</div>
                  <div>Expense</div>
                </div>
              </div>
            }
            total={
              <>
                <div>
                  {sepFormat(campaign.sums.income_total, false)}
                  {campaign.sums.income_total !== campaign.totals.target_income && (
                    <span> ({sepFormat(campaign.totals.target_income, false)})</span>
                  )}
                </div>
                <div>
                  {sepFormat(campaign.sums.expense_total, false)}
                  {campaign.sums.expense_total !== campaign.totals.planned_expense && (
                    <span> ({sepFormat(campaign.totals.planned_expense, false)})</span>
                  )}
                </div>
              </>
            }
            bound={
              <>
                <div>{sepFormat(campaign.sums.income_bound, false)}</div>
                <div>{sepFormat(campaign.sums.expense_bound, false)}</div>
              </>
            }
            remainder={
              <>
                <div>{sepFormat(campaign.totals.income_remainder, false)}</div>
                <div>{sepFormat(campaign.totals.expense_remainder, false)}</div>
              </>
            }
          >
            {months.map(({ value }) => {
              const month = campaign.months[value];

              return (
                <td key={value} className="f__c--month">
                  {month && <MonthProgress {...month} />}
                </td>
              );
            })}
          </Row>
        </>
      }
    >
      {blocks.map(({ months: blockMonths, sums, totals, ...block }) => (
        <Row
          key={block.id}
          group={
            <div className="d-flex">
              <BoundStates {...sums} vertical />
              <BlockWidget {...block} link />
            </div>
          }
          total={
            <>
              <div>
                {sepFormat(sums.income_total, false)}
                {sums.income_total !== totals.target_income && <span> ({sepFormat(totals.target_income, false)})</span>}
              </div>
              <div>
                {sepFormat(sums.expense_total, false)}
                {sums.expense_total !== totals.planned_expense && (
                  <span> ({sepFormat(totals.planned_expense, false)})</span>
                )}
              </div>
            </>
          }
          bound={
            <>
              <div>{sepFormat(sums.income_bound, false)}</div>
              <div>{sepFormat(sums.expense_bound, false)}</div>
            </>
          }
          remainder={
            <>
              <div>{sepFormat(totals.income_remainder, false)}</div>
              <div>{sepFormat(totals.expense_remainder, false)}</div>
            </>
          }
        >
          {months.map(({ value }) => {
            const month = blockMonths[value];

            return (
              <td key={value} className="f__c--month">
                {month && <MonthProgress {...month} />}
              </td>
            );
          })}
        </Row>
      ))}
    </Table>
  );
}

export function Finance(): ReactElement {
  const { endpoint, item } = useEntity<CampaignDetails>();
  const { isLoading, data } = useSWR<Report>({
    method: 'GET',
    url: `${endpoint}finance/`,
  });
  return (
    <div className="container-fluid">
      <nav className="btn-toolbar ml-auto mb-1">
        <DownloadButton
          url={`${endpoint}export_links/`}
          variant="outline-primary"
          size="sm"
          icon="file-excel"
          title="Export"
        >
          Linked invoices
        </DownloadButton>
        <DownloadButton
          url={`invoicing/income/export_documents/?campaign=${item.id}`}
          variant="outline-primary"
          size="sm"
          icon="file-zip"
          title="Export"
          className="ml-1"
        >
          Income documents
        </DownloadButton>
        <DownloadButton
          url={`invoicing/expense/export_documents/?campaign=${item.id}`}
          variant="outline-primary"
          size="sm"
          icon="file-zip"
          title="Export"
          className="ml-1"
        >
          Expense documents
        </DownloadButton>
      </nav>
      <LoadingContainer loading={isLoading}>
        {data && (!data.date_from ? <Alert variant="info">Nothing here yet</Alert> : <Container {...data} />)}
      </LoadingContainer>
    </div>
  );
}
