import { ReactNode, useCallback, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CreateScene, ItemLoader, NotFound } from 'components';
import type { Ticket, TicketCategory } from 'modules/support/types';
import { toast } from 'react-toastify';
import { api } from 'services';

interface TicketFactoryContainerProps {
  children: ReactNode;
  ticket: any;
  resolveContext: (ticket: any) => [any, ReactNode | null];
  ticketUrl: string;
}

function TicketFactoryContainer({
  children,
  resolveContext,
  ticket,
  ticketUrl,
}: TicketFactoryContainerProps): ReactElement {
  const [initialValues, message] = resolveContext(ticket);
  const navigate = useNavigate();

  // biome-ignore lint/correctness/useExhaustiveDependencies: navigate is stable
  const onSuccess = useCallback(
    async (item: any) => {
      toast.success('Item has been created.', { toastId: 'create-scene-toast' });
      const res = await api.post(`${ticketUrl}close/`);
      if (res.ok) {
        toast.success('Ticket closed.', { toastId: 'ticket-toast' });
      } else {
        toast.error('Failed to close ticket.', { toastId: 'ticket-toast' });
      }
      navigate(`../${item.id}`);
    },
    [ticketUrl],
  );

  return (
    <CreateScene initialValues={initialValues} onSuccess={onSuccess}>
      {message}
      {children}
    </CreateScene>
  );
}

interface TicketFactoryProps {
  category: TicketCategory;
  children: ReactNode;
  resolveContext: (data: any) => [any, ReactNode | null];
}

export function TicketFactory({ category, children, resolveContext }: TicketFactoryProps): ReactElement {
  const { id } = useParams();
  const url = `support/tickets/${id}/`;

  return (
    <ItemLoader<Ticket> url={url}>
      {({ item }) => {
        if (item.state === 'OPEN' && item.category === category) {
          return (
            <TicketFactoryContainer ticket={item} resolveContext={resolveContext} ticketUrl={url}>
              {children}
            </TicketFactoryContainer>
          );
        }
        return <NotFound />;
      }}
    </ItemLoader>
  );
}
