import { Calendar } from './types';
import { RenderCellProps } from 'components/DataGrid';
import { GridRow } from '../types';
import { Scale } from './Scale';
import { Segments } from './Segments';
import { container, interactable } from './style.css';
export { useCalendar } from './useCalendar';
import { Selection } from './Selection';
import clsx from 'clsx';
import { useMouseDownHandler } from './useMouseDownHandler';
import { ReactNode } from 'react';

export function Edit(props: RenderCellProps<GridRow, any> & { calendar: Calendar }): ReactNode {
  const {
    calendar,
    row: { positions },
  } = props;

  const { selection, handleMouseDown, segments } = useMouseDownHandler(props);

  return (
    <div
      // dialogs don't return focus properly
      // tabIndex={tabIndex}
      className={clsx(container, interactable)}
      style={{ width: calendar.width }}
      onMouseDown={handleMouseDown}
      onClick={(event) => {
        // capture cell onClick, we handle selection onMouseDonw
        event.stopPropagation();
      }}
    >
      <Scale {...calendar} />
      <Segments
        segments={segments}
        positions={positions}
        intervalToBounds={calendar.intervalToBounds}
        rowSelection={selection && selection.type === 'segment' ? selection : null}
      />
      {selection && selection.type === 'selection' ? (
        <Selection selection={selection} intervalStart={calendar.interval.start} dayWidth={calendar.dayWidth} />
      ) : null}
    </div>
  );
}
