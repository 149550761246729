import { ReactElement } from 'react';
import { TextField, CheckField } from '@ff-it/form';
import { UserMembershipInline } from 'modules/core/UserMembershipInline';

export function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="row">
        <div className="col-md-6">
          <TextField name="email" label="E-mail" type="email" required />
          <div className="form-row">
            <TextField name="first_name" label="First name" className="col-md-6" />
            <TextField name="last_name" label="Last name" className="col-md-6" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-row pt-4">
            <CheckField name="is_active" fieldLabel="Active" help="Enables login" className="col" />
            <CheckField name="is_superuser" fieldLabel="Superuser" help="Superuser" className="col" />
            <CheckField name="manage_group" fieldLabel="Manage group" help="Sees group UI" className="col" />
          </div>
        </div>
      </div>
      <UserMembershipInline />
    </div>
  );
}
