import { LoadingContainer } from '@ff-it/ui';
import { ReactElement, ReactNode, useLayoutEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import { toast } from 'react-toastify';
import { PermissionDenied } from 'components/scenes/errors';
import { useSession } from './hooks';
import { auth, SessionScope } from 'services/auth';

interface SessionScopeResolverProps {
  children: (scope: SessionScope) => ReactNode;
}

export function SessionScopeResolver({ children }: SessionScopeResolverProps): ReactElement {
  const { slug } = useParams();
  const { user, impersonation, scope: currentScope } = useSession();
  const currentUser = impersonation || user;
  invariant(currentUser);

  const resolvedScope: SessionScope | undefined = useMemo(() => {
    const membership = currentUser.membership.find(({ slug: membershipSlug }) => membershipSlug === slug);
    if (membership) {
      return membership;
    }

    if (currentUser.agency_group && currentUser.agency_group.slug === slug) {
      return currentUser.agency_group;
    }
  }, [currentUser, slug]);

  useLayoutEffect(
    () => {
      if (resolvedScope && resolvedScope.slug !== currentScope?.slug) {
        toast.info(currentScope && resolvedScope.title);
        auth.scope = resolvedScope;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resolvedScope, currentScope],
  );
  if (!resolvedScope) {
    return <PermissionDenied>Failed to resolve enterprise</PermissionDenied>;
  }

  const notReady = resolvedScope?.slug !== currentScope?.slug;
  return <LoadingContainer loading={notReady}>{notReady ? null : children(resolvedScope)}</LoadingContainer>;
}
