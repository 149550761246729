import { ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import type { IncompleteSupplier } from 'modules/supplier/supplier/types';
import { ProductTitle } from 'modules/supplier/products';
import { TitleContainer } from 'components/TitleContainer';
import { BoxProps, Col, Row } from '@ff-it/ui';

type SupplierTitleProps = IncompleteSupplier & Omit<BoxProps, 'placeholder'> & { placeholder?: ReactNode };

export function SupplierTitle({
  product,
  provider,
  className,
  activity,
  placeholder = '—',
  ...rest
}: SupplierTitleProps): ReactElement {
  return (
    <Row
      display="flex"
      flexDirection="row"
      className={cx('supplier-title', className)}
      data-productid={product?.id}
      data-providerid={provider?.id}
      data-activityid={activity?.id}
      {...rest}
    >
      <Col width="1/2">{product ? <ProductTitle product={product} /> : <TitleContainer title={placeholder} />}</Col>
      <Col width="1/2">
        <TitleContainer
          title={provider ? provider.internal_name : placeholder}
          subtitle={activity ? activity.name : placeholder}
        />
      </Col>
    </Row>
  );
}
