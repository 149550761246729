import { ReactElement, ReactNode, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { ModelContext } from './context';
import { ModelPermissions } from './types';
import { Breadcrumb } from '../layout';
import { useDocumentTitle } from 'hooks';

export interface ModelSceneProps<T> {
  endpoint: string;
  permissions?: ModelPermissions;
  children?: ReactNode;
  title: ReactNode;
  pluralTitle: ReactNode;
  entityTitle: (item: T) => ReactNode;
}

export function ModelScene<T = any>({
  endpoint,
  permissions,
  children,
  title,
  pluralTitle,
  entityTitle,
}: ModelSceneProps<T>): ReactElement {
  useDocumentTitle(typeof title === 'string' ? title : '');
  const ctx = useMemo(
    () => ({
      endpoint: endpoint.replace(/\/?$/, '/'),
      permissions,
      title,
      pluralTitle,
      entityTitle,
    }),
    [endpoint, permissions, title, pluralTitle, entityTitle],
  );

  return (
    <ModelContext.Provider value={ctx}>
      <Breadcrumb>{pluralTitle}</Breadcrumb>
      {children}
      <Outlet />
    </ModelContext.Provider>
  );
}
