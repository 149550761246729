import { useEntity, bankFieldProps, Field, Fieldset, UserTitle } from 'components';
import { CompanyAccount } from 'modules/crm/types';
import { ReactElement } from 'react';
import { Company } from 'types';

export function ViewCompany(): ReactElement {
  const {
    item: { office_same_as_legal },
  } = useEntity<Company>();
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <Field name="title" label="Title" help="Full legal name" className="col-md-4" />
        <Field name="internal_name" label="Internal name" className="col-md-4" />
        <Field name="code" label="Code" className="col-md-4" />
      </div>
      <div className="form-row">
        <Field name="kind" label="Kind" className="col-md-2" />
        <Field name="country.name" label="Country" className="col-md-2" />
        <Field name="registration_number" label="Registration number" className="col-md-4" />
        <Field name="vat_number" label="VAT number" className="col-md-4" />
      </div>
      <div className="form-row">
        <Field name="legal_address" label="Legal address" className="col-md-6" />
        {!office_same_as_legal && <Field name="office_address" label="Office address" className="col-md-6" />}
      </div>
      <div className="form-row">
        {/* FIXME only CLIENT */}
        <Field name="managers" label="Client managers" className="col-md-6">
          {(managers: any) => managers.map(({ user }: any) => <UserTitle {...user} key={user.id} />)}
        </Field>
      </div>

      <Fieldset title="Documents">
        <div className="form-row">
          <Field name="invoice_template" label="Invoice template" className="col-md-6" />
          <Field name="acceptance_act_template" label="Acceptance act template" className="col-md-6" />
        </div>
      </Fieldset>

      <Fieldset title="Accounts">
        <Field name="accounts">
          {(accounts: CompanyAccount[]) =>
            accounts.map((account, idx) => (
              <div className="form-row" key={account.id}>
                <div className="col-md-6">
                  <small className="font-weight-bold">Bank</small>
                  <div className="form-control-plaintext py-0" data-testid-field={`accounts[${idx}].bank`}>
                    {bankFieldProps.getOptionLabel(account.bank)}
                  </div>
                </div>
                <div className="col-md-6">
                  <small className="font-weight-bold">Account number (IBAN)</small>
                  <div className="form-control-plaintext py-0" data-testid-field={`accounts[${idx}].account_number`}>
                    {account.account_number}
                  </div>
                </div>
              </div>
            ))
          }
        </Field>
      </Fieldset>

      <Fieldset title="Branches">
        <Field name="branches">
          {(branches: any[]) =>
            branches.map((branch, idx) => (
              <div className="form-row" key={branch.id}>
                <div className="col-md-6">
                  <small className="font-weight-bold">Internal name</small>
                  <div className="form-control-plaintext py-0" data-testid-field={`branches[${idx}].internal_name`}>
                    {branch.internal_name}
                  </div>
                </div>
                <div className="col-md-6">
                  <small className="font-weight-bold">Code</small>
                  <div className="form-control-plaintext py-0" data-testid-field={`branches[${idx}].code`}>
                    {branch.code}
                  </div>
                </div>
              </div>
            ))
          }
        </Field>
      </Fieldset>
    </div>
  );
}
