import { ReactElement } from 'react';
import { useRoutes, Link } from 'react-router-dom';
import {
  ClientTitle,
  CreateScene,
  DeleteScene,
  EntityScene,
  ModelScene,
  IndexScene,
  UpdateScene,
  useEntity,
} from 'components';
import permissions from 'modules/client/agreement/permissions';
import { date } from 'components/columns';
import { Nav } from './Nav';
import initialValues from './initialValues';
import { FromTicket } from './FromTicket';
import type { Client } from 'modules/client/types';
import { canViewClientAgreements } from 'modules/client';
import { ClientAgreement } from 'modules/client/agreement/types';
import { Form } from 'modules/client/agreement/Form';
import { Terms } from './Terms';
import { TableColumns } from '@ff-it/ui';

function RelatedClientLink({ id, number, client }: ClientAgreement): ReactElement {
  const {
    item: { id: clientId },
  } = useEntity<Client>();

  const to = client.id !== clientId ? `../../${client.id}/agreements/${id}` : `${id}`;
  return <Link to={to}>{number}</Link>;
}

const columns: TableColumns<ClientAgreement> = [
  {
    content: 'Number',
    key: 'number',
    width: 180,
    render: RelatedClientLink,
  },
  {
    content: 'Client',
    key: 'client',
    render: ClientTitle,
  },
  date('Date from', 'date_from', { sortable: true, width: undefined }),
  date('Date to', 'date_to', { sortable: true, width: undefined }),
];

export function Agreements(): ReactElement {
  const { item } = useEntity<Client>();

  const model = {
    endpoint: `client/clients/${item.id}/agreements/`,
    title: 'Client agreement',
    pluralTitle: 'Client agreements',
    entityTitle: (e: ClientAgreement) => e.number,
    permissions: {
      ...permissions,
      view: canViewClientAgreements(item),
    },
  };

  function copy(agreement: ClientAgreement): any {
    return {
      ...agreement,
      id: undefined,
      date_from: null,
      date_to: null,
      number: '',
      attachments: [],
      signature_persons: agreement.signature_persons.map((person) => ({ ...person, id: undefined })),
    };
  }

  const routes = useRoutes([
    {
      index: true,
      element: <IndexScene columns={columns} />,
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={{ ...initialValues, client: item }}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: 'from_ticket/:id',
      element: <FromTicket client={item} />,
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <Nav />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: (
            <UpdateScene viewDisabled={true} copy={copy}>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'terms/:type',
          element: <Terms />,
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ]);
  return <ModelScene {...model}>{routes}</ModelScene>;
}
