import type { Country } from 'types';
import { SelectField, SelectFieldProps } from '@ff-it/form';
import { useDefaultOptions } from './useOptions';
import { ReactElement } from 'react';

const optionProps = {
  getOptionLabel: (country: Country) => country.name,
  getOptionValue: (country: Country) => country.code,
};

export function CountryField(
  props: Omit<SelectFieldProps<Country>, 'options' | 'loadOptions' | 'getOptionLabel' | 'getOptionValue'>,
): ReactElement {
  const countries = useDefaultOptions<Country>('core/countries/');

  return <SelectField options={countries} {...props} {...optionProps} />;
}
