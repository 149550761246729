import { useContext } from 'react';
import { AuthState, SessionScope } from 'services/auth';
import invariant from 'tiny-invariant';
import type { ActivityType } from 'types';
import { SessionContext } from './context';
import type { AgencyGroup, SessionUser, UserMembership } from 'modules/core/types';

export function useSession(): AuthState {
  const sess = useContext(SessionContext);
  invariant(sess, 'Session has to be present');

  return sess;
}

export function useManagedTypes(): ActivityType[] {
  const scope = useSession().scope;
  if (scope?.kind === 'AGENCY') {
    return scope.managed_types;
  }
  return [];
}

export function useRealUser(): SessionUser {
  const session = useSession();
  invariant(session.user, 'User is not signed in');
  return session.user;
}

/**
 * Returns impersonation or user
 */
export function useUser(): SessionUser {
  const session = useSession();
  invariant(session.user, 'User is not signed in');
  return session.impersonation || session.user;
}

export function useSessionScope(): SessionScope {
  const sess = useSession();
  invariant(sess.scope, 'Session scope has to be present');
  return sess.scope;
}

export function useMembership(): UserMembership {
  const sess = useSession();
  invariant(sess.scope?.kind === 'AGENCY', 'Membership has to be present');
  return sess.scope;
}

export function useAgencyGroup(): AgencyGroup {
  const sess = useSession();
  invariant(sess.scope?.kind === 'AGENCY_GROUP', 'Agency group has to be present');
  return sess.scope;
}
