import cx from 'clsx';
import type { ReactElement, SVGAttributes } from 'react';
import type { ActivityType } from 'types';
import type { Transparency } from 'modules/core/types';
import * as style from './style.css';
import type { IconProp } from './types';

export type IconSize = keyof typeof style.size;

export type { IconProp } from './types';

export type IconProps = {
  icon: IconProp;
  className?: string;
  size?: IconSize;
  title?: string;
} & SVGAttributes<SVGElement>;

export function Icon({ icon, className, size = 'md', ...rest }: IconProps): ReactElement {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="regular"
      data-icon={icon}
      role="img"
      viewBox="0 0 512 512"
      className={cx(style.icon, style.size[size], className)}
      {...rest}
    >
      <use xlinkHref={`/sprites/regular.svg#${icon}`}></use>
    </svg>
  );
}

// @TODO factory

const typeIconMap: Record<ActivityType, IconProp> = {
  TV: 'tv',
  RADIO: 'radio',
  OOH: 'billboard',
  PRINT: 'newspaper',
  WEB: 'globe',
  SOCIAL: 'chart-network',
  CREATIVE: 'palette',
  RTB: 'gavel',
  OTHER: 'ellipsis-vertical',
  BTL: 'down-from-line',
  SEO: 'magnifying-glass',
};

export function ActivityIcon({
  type,
  className,
  size,
}: {
  type: ActivityType;
  className?: string;
  size?: IconSize;
}): ReactElement {
  return <Icon icon={typeIconMap[type]} className={className} size={size} title={type} />;
}

const transparencyIconMap: Record<Transparency, IconProp> = {
  PLANNED: 'paper-plane',
  DELIVERED: 'square-poll-vertical',
};

export function TransparencyIcon({
  transparency,
  className,
  size,
}: {
  transparency: Transparency;
  className?: string;
  size?: IconSize;
}): ReactElement {
  return <Icon icon={transparencyIconMap[transparency]} className={cx('transp-icon', className)} size={size} />;
}
