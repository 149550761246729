import { ReactElement } from 'react';
import { ExtendedUserField, GroupField } from 'components';
import { useField } from 'react-final-form';
import { UserOrInvite } from './UserOrInvite';

export function Form(): ReactElement {
  const {
    input: { value: id },
  } = useField('id', { subscription: { value: true } });
  return (
    <div className="row">
      <div className="col-md-6">
        {id ? <ExtendedUserField name="user" placeholder="User" required disabled /> : <UserOrInvite />}
        <GroupField name="groups" label="Roles" required isMulti help="Type to find a specific agency role" />
      </div>
    </div>
  );
}
