import type { Activity, ActivityKind, ActivityType } from 'types';
import { ActivityTitle } from 'modules/core/activity';
import { activityTypeOptions, Option } from 'options';
import { SelectField, SelectFieldProps } from '@ff-it/form';
import { useAgenecyTypeOptions } from 'modules/core/useEnabledTypeOptions';
import { ReactElement } from 'react';
import useSWR from 'swr/immutable';

export const activityFieldProps = {
  getOptionLabel: (a: Activity) => a.name,
  formatOptionLabel: ActivityTitle,
  getOptionValue: (a: Activity) => a.id.toString(),
};

type ActivityFieldProps = Omit<SelectFieldProps<Activity, any, any>, 'options' | 'loadOptions' | 'defaultOptions'> & {
  kind?: ActivityKind;
};

export function useActivites(): Activity[] {
  const { data: activities } = useSWR<Activity[]>({
    method: 'GET',
    url: 'core/activities/',
  });
  return activities || [];
}

// IMPORTANT: This is not constant list, but is practice it's short and stable. We also filter kind on client
export function ActivityField({ kind, ...props }: ActivityFieldProps): ReactElement {
  const activities = useActivites();
  const options = kind ? activities.filter((a) => a.kind === kind) : activities;
  return <SelectField options={options} {...activityFieldProps} {...props} />;
}

type ActivityTypeFieldProps = Omit<SelectFieldProps<Option<ActivityType>, any, any>, 'options' | 'simple'>;

export function AllActivityTypesField(props: ActivityTypeFieldProps): ReactElement {
  return <SelectField options={activityTypeOptions} simple {...props} />;
}

export function AgencyActivityTypesField(props: ActivityTypeFieldProps): ReactElement {
  const options = useAgenecyTypeOptions();
  return <SelectField options={options} simple {...props} />;
}
