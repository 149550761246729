import { ReactElement, useCallback } from 'react';
import { Select } from '@ff-it/ui';
import { useOptions } from 'components/fields';
import { useNavigate } from 'react-router';
import { Result } from './types';

const getOptionLabel = (a: Result): string => a.code;

export function GlobalSearch(): ReactElement {
  const navigate = useNavigate();

  const loadOptions = useOptions<any>('core/search/');

  const onChange = useCallback(
    (v: any) => {
      if (v) {
        if (v.kind === 'campaign') {
          navigate(`/${v.data.slug}/planning/campaigns/${v.data.id}/planning`);
        } else if (v.kind === 'block') {
          navigate(`/${v.data.campaign.slug}/planning/campaigns/${v.data.campaign.id}/planning/${v.data.id}`);
        }
      }
    },
    [navigate],
  );

  return (
    <div className="form-inline ml-1">
      <Select<Result>
        loadOptions={loadOptions}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        value={null}
        size="sm"
        name="global_search"
        id="global_search"
        placeholder="Code..."
        isClearable={false}
      />
    </div>
  );
}
