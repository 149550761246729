import type { PlanRow } from 'modules/campaign/row';
import { ReactElement, ReactNode, createContext, useContext, useMemo } from 'react';
import { SmartOption } from './types';
import invariant from 'tiny-invariant';

const SmartOptionsContext = createContext<SmartOption[] | undefined>(undefined);

type SmartOptionsProivderProps = {
  planRows: PlanRow[];
  children: ReactNode;
};

export function SmartOptionsProivder({ planRows, children }: SmartOptionsProivderProps): ReactElement {
  // @TODO use prev
  const smartOptions = useMemo(
    () =>
      planRows
        .filter(({ kind }) => kind === 'SMART')
        .map(({ id, direction, application, rate, apply_to }, index) => ({
          index,
          id,
          direction,
          application,
          rate,
          apply_to,
        })),
    [planRows],
  );

  return <SmartOptionsContext.Provider value={smartOptions}>{children}</SmartOptionsContext.Provider>;
}

export function useSmartOptions(): SmartOption[] {
  const options = useContext(SmartOptionsContext);
  invariant(options);
  return options;
}
