import { Icon } from 'components';
import { ReactElement } from 'react';
import type { IconProp, IconSize } from 'components';
import { InvoiceRowKind } from './types';

// @TODO: DRY with with other icons. Factory?

type InvoiceRowKindIconProps = {
  kind: InvoiceRowKind;
  className?: string;
  size?: IconSize;
};
const kindMap: Record<InvoiceRowKind, IconProp> = {
  AMOUNT: 'paper-plane',
  COMPENSATION: 'file-invoice-dollar',
};

const titleMap: Record<InvoiceRowKind, string> = {
  AMOUNT: 'Linkable amount',
  COMPENSATION: 'Compensation',
};

export function InvoiceRowKindIcon({ kind, ...rest }: InvoiceRowKindIconProps): ReactElement {
  return <Icon icon={kindMap[kind]} title={titleMap[kind]} {...rest} />;
}
