import { useTable } from 'components/ControlledTable';
import { DownloadButton } from 'components/ui';
import { ReactElement } from 'react';

export function XLSXDownloadButton({ url }: { url: string }): ReactElement {
  const { sort, filter } = useTable();
  return (
    <DownloadButton
      className="ml-auto mb-auto"
      method="POST"
      body={{
        // FIXME
        ordering: sort
          ? {
              order: sort.direction || 'DESC',
              key: sort.key,
            }
          : undefined,
        ...filter,
      }}
      url={url}
      icon="file-excel"
      title="Export"
      size="sm"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    >
      Export
    </DownloadButton>
  );
}
