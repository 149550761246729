import { EntityContextType } from 'components';
import { ReactElement, ReactNode, createContext, useCallback, useContext, useRef } from 'react';
import { Fetcher } from 'services/api';
import { BlockDetails } from './types';
import { RequestArgs, RequestFailure } from '@ff-it/api';
import { prompStale } from 'utilities';

const RequestHandlerContext = createContext<Fetcher | undefined>(undefined);

type RequestHandlerProviderProps = {
  blockFetcher: Fetcher;
  ctx: EntityContextType<BlockDetails>;
  children: ReactNode;
};

export function RequestHandlerProvider({
  blockFetcher,
  ctx: { endpoint, setSpinning },
  children,
}: RequestHandlerProviderProps): ReactElement {
  const requestRef = useRef(0);
  const requestHandler = useCallback(
    async <R,>(args: RequestArgs): Promise<R> => {
      const current = ++requestRef.current;
      setSpinning(true);

      return blockFetcher<R>({
        ...args,
        url: `${endpoint}${args.url}`,
      })
        .catch((e: RequestFailure<any>) => {
          // FIXME: hack to focus first invalid grid cell
          if (args.url === 'submit/' && e.status === 409 && e.data.detail === 'Plan is not complete') {
            window.__GRID__ && window.__GRID__.focusInvalid();
          }

          if (e.status === 412) {
            prompStale();
          }
          throw e;
        })
        .finally(() => {
          if (requestRef.current === current) {
            setSpinning(false);
          }
        });
    },
    [endpoint, blockFetcher, setSpinning],
  );

  return <RequestHandlerContext.Provider value={requestHandler}>{children}</RequestHandlerContext.Provider>;
}

export function useRequestHandler(): Fetcher {
  return useContext(RequestHandlerContext)!;
}
