import { DecimalEditor, RenderCellProps } from 'components/DataGrid';
import { createColumn } from './createColumn';
import { center, right } from './style.css';
import { fmt, parseBig, sepFormat } from 'utilities';
import { TitleContainer } from 'components/TitleContainer';
import { GridRow } from '../types';
import { ReactNode } from 'react';
import { Box } from '@ff-it/ui';

export const ReachImpressionsColumn = createColumn(
  {
    key: 'reach_impressions',
    name: <TitleContainer title="Reach impr." subtitle="000s" />,
    width: 96,
    className: right,
  },
  {
    renderView: (props) => (props.row.kind === 'MEDIA' ? sepFormat(props.row.reach_impressions) : null),
  },
  undefined,
  { renderSummaryCell: (props) => fmt(props.row.reachImpressions) },
);

export const ReachUniqueUsersColumn = createColumn(
  {
    key: 'reach_unique_users',
    name: 'Reach users',
    width: 96,
    className: right,
  },
  { renderView: (props) => (props.row.kind === 'MEDIA' ? sepFormat(props.row.reach_unique_users) : null) },
  undefined,
  { renderSummaryCell: (props) => fmt(props.row.reachUniqueUsers) },
);

export const ReachCompositionColumn = createColumn(
  {
    key: 'reach_composition',
    name: 'Comp.',
    width: 76,
    className: center,
  },
  {
    renderView: (props) => (props.row.kind === 'MEDIA' ? sepFormat(props.row.reach_composition) : null),
    renderEdit: (props) => <DecimalEditor {...props} precision={2} normalize />,
  },
);

export const ReachFrequencyColumn = createColumn(
  {
    key: 'reach_frequency',
    name: 'Frequenecy',
    width: 76,
    className: center,
  },
  {
    renderView: (props) => (props.row.kind === 'MEDIA' ? sepFormat(props.row.reach_frequency) : null),
    renderEdit: (props) => <DecimalEditor {...props} precision={2} normalize />,
  },
  undefined,
  { renderSummaryCell: (props) => fmt(props.row.reachFrequency) },
);

function ViewReach({ row }: RenderCellProps<GridRow, any>): ReactNode {
  if (row.kind !== 'MEDIA') {
    return null;
  }
  if (row.reach) {
    const reach = parseBig(row.reach);
    if (reach.gte(100)) {
      return <Box color="error-primary">{fmt(reach, true)}</Box>;
    }
    return fmt(reach, true);
  }
  return null;
}

export const ReachColumn = createColumn(
  {
    key: 'reach',
    name: 'Reach %',
    width: 68,
    className: center,
  },
  {
    renderView: (props) => <ViewReach {...props} />,
  },
  undefined,
  { renderSummaryCell: (props) => fmt(props.row.reach, true) },
);
