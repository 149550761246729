import { SelectField, SelectFieldProps } from '@ff-it/form';
import type { ReactElement } from 'react';
import type { Brand } from '../../types';
import { useDefaultOptions, createSelectField, useOptionLoader } from 'components';
import { useConcreteClientId } from 'modules/client/useFinalClientId';

const brandFieldProps = {
  getOptionLabel: ({ title }: Brand) => title,
  getOptionValue: (a: Brand) => a.id.toString(),
};

export const BrandField = createSelectField<Brand>('client/brands/', brandFieldProps);

export function ClientBrandField(
  props: Partial<SelectFieldProps<any, false>> & {
    name: string;
  },
): ReactElement {
  const clientId = useConcreteClientId();

  const defaultOptions: any = useDefaultOptions('client/brands/', { client: clientId }, clientId);
  const loadOptions: any = useOptionLoader('client/brands/', { client: clientId });
  return (
    <SelectField
      {...brandFieldProps}
      {...props}
      defaultOptions={defaultOptions}
      disabled={!clientId}
      loadOptions={loadOptions}
    />
  );
}
