import { Navigate, Outlet, RouteObject, useLocation } from 'react-router';
import { Invite, Login, Logout, PasswordReset, PasswordResetConfirm } from './Auth';
import { ReactElement } from 'react';
import { SessionScope, SessionScopeResolver, useSession, useUser } from 'services';
import { PermissionDenied } from 'components';
import { stringify } from 'qs';
import { Agency } from './Agency';
import { AgencyGroup } from './AgencyGroup';
import { Layout } from './Layout';

export * from './Auth';
export * from './Agency';
export * from './AgencyGroup';

function ErrorElement(): null {
  throw Error('Sample error');
}

function GuessScope(): ReactElement {
  const user = useUser();
  let s: SessionScope | undefined = user.last_enterprise
    ? user.membership.find((m) => m.id === user.last_enterprise)
    : user.membership[0];
  if (!s && user.agency_group) {
    s = user.agency_group;
  }
  if (s) {
    return <Navigate to={`/${s.slug}/`} replace />;
  }

  return <PermissionDenied>Failed to resolve enterprise</PermissionDenied>;
}

function ProtectedRoute(): ReactElement {
  const s = useSession();
  const { pathname } = useLocation();
  if (!s.isAuthenticated) {
    const to = pathname === '/' ? '/login' : `/login?${stringify({ next: pathname })}`;
    return <Navigate to={to} />;
  }

  return <Outlet />;
}

export const routes: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      { path: '/login', element: <Login /> },
      { path: '/logout', element: <Logout /> },
      { path: '/password_reset', element: <PasswordReset /> },
      { path: '/password_reset_confirm/:uidb64/:token', element: <PasswordResetConfirm /> },
      { path: '/invite/:token', element: <Invite /> },
      { path: '/trigger_error', element: <ErrorElement /> },
      {
        path: '/*',
        element: <ProtectedRoute />,
        children: [
          { index: true, element: <GuessScope /> },
          {
            path: ':slug/*',
            element: (
              <SessionScopeResolver>
                {({ kind }) => (kind === 'AGENCY' ? <Agency /> : <AgencyGroup />)}
              </SessionScopeResolver>
            ),
          },
        ],
      },
    ],
  },
];
