import type { SessionUser, UserMembership, AgencyGroup } from 'modules/core/types';
import { BaseAuthenticationService, AuthState as BaseAuthState } from './BaseAuthenticationService';

const TOKEN_KEY = '_init_token';

export type SessionScope = UserMembership | AgencyGroup;

export type SessionState = {
  impersonation: SessionUser | null;
  scope: SessionScope | null;
};

export type AuthState = BaseAuthState<SessionUser> & SessionState;

export class AuthenticationService extends BaseAuthenticationService<AuthState> {
  constructor() {
    super(
      {
        user: null,
        isAuthenticated: false,
        impersonation: null,
        scope: null,
      },
      TOKEN_KEY,
    );
  }

  public set impersonation(impersonation: SessionUser | null) {
    this.state = {
      ...this._state,
      impersonation,
      scope: null,
    };
  }

  public set scope(scope: SessionScope | null) {
    this.state = {
      ...this._state,
      scope,
    };
  }
}
