import { LoadingContainer, Table, Tbody, Td, Th, Thead, Tr } from '@ff-it/ui';
import { Breadcrumb, Heading, Page } from 'components';
import { ReactElement } from 'react';
import type { EmbeddedUser } from 'modules/core/types';
import { Card } from 'components/Card';
import useSWR from 'swr';

interface Common {
  received: number;
  planning: number;
  submitted: number;
  approved: number;
  live: number;
  ended: number;
  total: number;
}

interface Manager extends Common {
  manager: EmbeddedUser;
  new: number;

  missing_income: number;
  missing_expense: number;
}

interface Planner extends Common {
  planner: EmbeddedUser;
}

export interface SummaryProps {
  managers: Manager[];
  planners: Planner[];
}

function CommonCols({ received, planning, submitted, approved, live, ended, total }: Common): ReactElement {
  return (
    <>
      <Td textAlign="center">{received}</Td>
      <Td textAlign="center">{planning}</Td>
      <Td textAlign="center">{submitted}</Td>
      <Td textAlign="center">{approved}</Td>
      <Td textAlign="center">{live}</Td>
      <Td textAlign="center">{ended}</Td>
      <Td textAlign="center" fontWeight="bold">
        {total}
      </Td>
    </>
  );
}

function CommonHeader(): ReactElement {
  return (
    <>
      <Th textAlign="center">Received</Th>
      <Th textAlign="center">Planning</Th>
      <Th textAlign="center">Submitted</Th>
      <Th textAlign="center">Approved</Th>
      <Th textAlign="center">Live</Th>
      <Th textAlign="center">Ended</Th>
      <Th textAlign="center">Total</Th>
    </>
  );
}

function RenderSummary({ managers, planners }: SummaryProps): ReactElement {
  return (
    <>
      <Card title="Managers">
        <Table size="sm" variant="dark">
          <Thead>
            <Tr>
              <Th />
              <Th colSpan={8} textAlign="center">
                Campaign
              </Th>
              <Th
                colSpan={3}
                textAlign="center"
                style={{
                  borderLeft: '1px solid #EAECF0FF',
                }}
              >
                Invoices
              </Th>
            </Tr>
            <Tr>
              <Th>Manager</Th>
              <Th textAlign="center">New</Th>
              <CommonHeader />
              <Th
                textAlign="center"
                style={{
                  borderLeft: '1px solid #EAECF0FF',
                }}
              >
                Income
              </Th>
              <Th textAlign="center">Expense</Th>
              <Th textAlign="center">Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {managers.map(
              ({ manager: { first_name, last_name }, new: newCnt, missing_income, missing_expense, ...common }, i) => (
                <Tr key={i}>
                  <Td>
                    {first_name} {last_name}
                  </Td>
                  <Td textAlign="center">{newCnt}</Td>
                  <CommonCols {...common} />
                  <Td
                    textAlign="center"
                    style={{
                      borderLeft: '1px solid #EAECF0FF',
                    }}
                  >
                    {missing_income}
                  </Td>
                  <Td textAlign="center">{missing_expense}</Td>
                  <Td textAlign="center" fontWeight="bold">
                    {missing_income + missing_expense}
                  </Td>
                </Tr>
              ),
            )}
          </Tbody>
        </Table>
      </Card>
      <Card title="Planners">
        <Table size="sm" variant="dark">
          <Thead>
            <Tr>
              <Th>Planner</Th>
              <Th />
              <CommonHeader />
            </Tr>
          </Thead>
          <Tbody>
            {planners.map(({ planner: { first_name, last_name }, ...common }, i) => (
              <Tr key={i}>
                <Td>
                  {first_name} {last_name}
                </Td>
                <Td />
                <CommonCols {...common} />
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    </>
  );
}

export function Summary(): ReactElement {
  const { isLoading, data } = useSWR<SummaryProps>({
    method: 'GET',
    url: 'dashboard/summary/',
  });

  return (
    <Page narrow>
      <Breadcrumb>Summary</Breadcrumb>
      <Heading title="Summary" />
      <LoadingContainer loading={isLoading}>{data && <RenderSummary {...data} />}</LoadingContainer>
    </Page>
  );
}
