import { DecimalField } from '@ff-it/form';
import { ReactElement } from 'react';

interface RatesProps {
  name: string;
}
export function RateFields({ name }: RatesProps): ReactElement {
  return (
    <>
      <td className="rate">
        <DecimalField name={`${name}.current`} size="sm" required />
      </td>
      <td className="rate">
        <DecimalField name={`${name}.target`} size="sm" required />
      </td>
      <td className="rate">
        <DecimalField name={`${name}.final`} size="sm" required />
      </td>
    </>
  );
}
