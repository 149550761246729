import { ReactElement, useMemo } from 'react';
import { useRoutes } from 'react-router';
import { menu } from './menu';
import { routes } from './routes';
import { AppContainer, SideNav, AgencyBrand, EnterpriseLink, NotificationProvider } from 'components';
import { TopNav } from 'components/TopNav';
import { useMembership } from 'services';

export function Agency(): ReactElement {
  const { slug } = useMembership();
  const menuItems = useMemo(
    () =>
      menu.map(({ to, children, ...rest }) => ({
        to: `/${slug}${to}`,
        children: children ? children.map(({ to, ...rest }) => ({ to: `/${slug}${to}`, ...rest })) : undefined,
        ...rest,
      })),
    [slug],
  );
  const element = useRoutes(routes);

  const sideNav = (
    <SideNav branding={<AgencyBrand />} menu={menuItems}>
      <div className="text-center">
        <a href="mailto:support@niit.lv" className="text-muted">
          support@niit.lv
        </a>
      </div>
      <div className="text-center">
        <EnterpriseLink to="/support/ticket" className="text-muted">
          create a ticket
        </EnterpriseLink>
      </div>
    </SideNav>
  );
  return (
    <NotificationProvider>
      <AppContainer beforeMain={<TopNav />} beforeContent={sideNav}>
        {element}
      </AppContainer>
    </NotificationProvider>
  );
}
