import { IconBadge, IconBadgeBaseProps } from 'components/IconBadge/IconBadge';
import { ApplicationState } from 'core/types';
import { ReactElement } from 'react';

type IsLinkedStateProps = {
  linked_at: string | null;
  link_state: ApplicationState | null;
};

const linked: IconBadgeBaseProps = {
  icon: 'link',
  background: 'tertiary',
  color: 'success-primary',
  title: 'Linked',
  testId: 'is-linked',
};
const notLinked: IconBadgeBaseProps = {
  icon: 'link-slash',
  background: 'warning-solid',
  color: 'white',
  title: 'Not linked',
  testId: 'is-not-linked',
};

export function IsLinkedStateBadge({ linked_at, link_state }: IsLinkedStateProps): ReactElement {
  const props = link_state === null ? { icon: null } : linked_at ? linked : notLinked;
  return <IconBadge {...props} />;
}
