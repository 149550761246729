import { bool } from 'components/columns';
import { CreateScene, DeleteScene, EntityScene, ModelScene, IndexScene, UpdateScene } from 'components';
import { Activity } from 'types';
import { activityKindOptions } from 'options';
import { TextField, SelectField } from '@ff-it/form';
import permissions from 'modules/core/activity/permissions';
import { Link } from 'react-router-dom';
import { Form } from './Form';
import { NewListExport } from 'components/ListActions';
import { TableColumns } from '@ff-it/ui';

const columns: TableColumns<Activity> = [
  {
    content: 'Kind',
    key: 'kind',
    sortable: true,
    width: 100,
  },
  {
    content: 'Name',
    key: 'name',
    width: undefined,
    sortable: true,
    render: ({ id, name }: Activity) => <Link to={`${id}`}>{name}</Link>,
  },
  bool<Activity>('Non standard', 'non_standard', { sortable: true, width: 200 }),
  bool<Activity>('Is fee', 'is_fee', { sortable: true }),
];

const initialValues = {
  name: '',
  kind: null,
  non_standard: false,
  is_fee: false,
};

const model = {
  endpoint: 'core/activities/',
  permissions,
  title: 'Activity',
  pluralTitle: 'Activities',
  entityTitle: ({ name }: Activity) => name,
};

export const route = {
  path: 'activities',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene
          columns={columns}
          filterFields={
            <>
              <TextField name="search" placeholder="Search..." size="sm" />
              <SelectField
                name="kind"
                placeholder="Kind"
                size="sm"
                className="ml-1"
                options={activityKindOptions}
                simple
              />
            </>
          }
          toolbar={<NewListExport permission={permissions.export} />}
        />
      ),
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form update={false} />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <UpdateScene viewDisabled>
              <Form update={true} />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};
