import cx from 'clsx';
import { BoundState } from './BoundState';
import type { BoundState as BoundStateType } from 'modules/campaign/common/types';
import type { ReactElement } from 'react';

interface BoundStatesProps {
  income_state: BoundStateType;
  income_is_overspent: boolean;
  expense_state: BoundStateType;
  expense_is_overspent: boolean;
  row?: number;
  className?: string;
  vertical?: boolean;
}

export function BoundStates({
  income_state,
  income_is_overspent,
  expense_state,
  expense_is_overspent,
  row,
  className,
  vertical,
}: BoundStatesProps): ReactElement {
  return (
    <span className={cx('bound-states', vertical && 'bound-states--vertical', className)}>
      <BoundState variant="income" state={income_state} is_overspent={income_is_overspent} row={row} />
      <BoundState variant="expense" state={expense_state} is_overspent={expense_is_overspent} row={row} />
    </span>
  );
}
