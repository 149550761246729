import { Alert } from '@ff-it/ui';
import { Page } from 'components/layout';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

export function PermissionDenied({ children = 'Not allowed' }: Props): ReactElement {
  return (
    <Page>
      <Alert className="mt-3" variant="danger" heading="Permission denied">
        {children}
      </Alert>
    </Page>
  );
}

export function NotFound({ children = 'Item does not exist' }: Props): ReactElement {
  return (
    <Page>
      <Alert className="mt-3" variant="danger" heading="404: Not found">
        {children}
      </Alert>
    </Page>
  );
}
