import { ReactElement } from 'react';
import type { IncomeSums } from 'modules/invoicing/income/types';
import type { ApplicationState } from 'core/types';
import { isApplicable } from 'modules/applicator/isApplicable';
import { IconBadge, IconBadgeBaseProps } from 'components/IconBadge/IconBadge';
import type { ListInvoice } from 'modules/invoicing/types';
import type { ApplicationType } from 'modules/applicator/types';

type DocumentApplicationStateBadgeProps = {
  invoice: ListInvoice;
  type?: ApplicationType;
};

const complete: IconBadgeBaseProps = {
  icon: 'circle-check',
  background: 'tertiary',
  color: 'success-primary',
  title: 'Applied',
  testId: 'is-linked',
};
const notComplete: IconBadgeBaseProps = {
  icon: 'triangle-exclamation',
  background: 'warning-solid',
  color: 'white',
  title: 'Not applied',
  testId: 'is-not-linked',
};

export function DocumentApplicationStateBadge({
  invoice,
  type,
}: DocumentApplicationStateBadgeProps): ReactElement | null {
  let state: ApplicationState | null = null;

  switch (type || isApplicable(invoice)) {
    case 'advance':
      state = (invoice.sums as IncomeSums).adv_state;
      break;
    case 'credit':
      state = invoice.sums.cred_state;
      break;
    case 'compensation':
      state = (invoice.sums as IncomeSums).comp_state;
      break;
  }

  const props = state === null ? { icon: null } : state === 'FULL' ? complete : notComplete;
  return <IconBadge {...props} />;
}
