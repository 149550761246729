import { permissions } from './Campaigns/permissions';

export const menu = {
  to: '/planning',
  label: 'Planning',
  icon: 'paper-plane' as const,
  children: [
    { to: '/planning/campaigns', label: 'Campaigns', visible: permissions?.view },
    { to: '/planning/blocks', label: 'Blocks', visible: permissions?.view },
    { to: '/planning/rows', label: 'Rows', visible: permissions?.view },
  ],
};
