import { ReactElement, ReactNode } from 'react';
import { formatISO, startOfYear } from 'date-fns';
import { reportBlockStateOptions } from 'configuration';
import { Box, LoadingContainer } from '@ff-it/ui';
import { ReportContainer } from '../common';
import { ReportRenderer } from './ReportRenderer';
import { Options } from 'options';
import { ActivityType } from 'types';
import { ReportTable } from 'components/ReportTable';
import { TableFilter } from 'components/ControlledTable';
import { XLSXDownloadButton } from 'components/ReportTable/XLSXDownload';
import { Filter } from './Filter';

interface RevenueProps {
  types: Options<ActivityType>;
  url: string;
  filterFields: ReactNode;
  storageKey: string;
}

export function Revenue({ types, url, filterFields, storageKey }: RevenueProps): ReactElement {
  // FIXME: use ContolledTable with single page or at least storage and state management
  const defaultFilter = {
    year: formatISO(startOfYear(new Date()), { representation: 'date' }),
    state: reportBlockStateOptions.map(({ value }) => value).filter((value) => value !== 'CANCELED'),
    types: types.map(({ value }) => value),
  };

  return (
    <ReportContainer title="Revenue">
      <ReportTable
        defaultFilter={defaultFilter}
        storageKey={storageKey}
        columns={[]}
        url={url}
        queryParams={{ page: undefined, page_size: undefined }}
        renderTable={({ isLoading, data }) => (
          <LoadingContainer loading={isLoading}>
            <Box display="flex" marginTop="sm">
              <Box>
                <TableFilter>
                  <Filter types={types}>{filterFields}</Filter>
                </TableFilter>
              </Box>
              <XLSXDownloadButton url={url} />
            </Box>
            {data && <ReportRenderer data={data.rows as any} />}
          </LoadingContainer>
        )}
      />
    </ReportContainer>
  );
}
