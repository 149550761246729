import { ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import { Breadcrumb, Heading, Page } from 'components';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface ReportContainerProps {
  title: ReactNode;
  children: ReactNode;
  nav?: NavLinkProps[];
}

export function ReportContainer({ title, children, nav }: ReportContainerProps): ReactElement {
  return (
    <Page>
      <Breadcrumb to={null}>{title}</Breadcrumb>
      <Heading title={title} />
      {nav && nav.length > 0 ? (
        <ul className="nav nav-tabs  mr-auto">
          {nav.map(({ className, ...props }, idx) => (
            <li className="nav-item" key={idx}>
              <NavLink className={cx('nav-link', className)} {...props} />
            </li>
          ))}
        </ul>
      ) : null}

      {children}
    </Page>
  );
}
