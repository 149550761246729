import useSWR from 'swr';
import { ReactElement, ReactNode } from 'react';
import type { ApplicationType, ConsumedLink } from './types';
import { Card } from 'components/Card';
import { InvoiceWidget } from 'modules/invoicing/common/InvoiceWidget';
import { sepFormat } from 'utilities';

type ConsumedLinksProps = {
  endpoint: string;
  type: ApplicationType;
  title?: ReactNode;
};

export function ConsumedLinks({ title, endpoint, type }: ConsumedLinksProps): ReactElement {
  const { data } = useSWR<ConsumedLink[], any, { suspense: true }>(
    { url: `${endpoint}${type}_consumed/`, method: 'GET' },
    { suspense: true },
  );

  return (
    <Card title={title} testId={`consumed-links-${type}`}>
      <table className="form-table table mb-0">
        <tbody>
          {data.map(({ amount, source }) => (
            <tr key={source.id}>
              <td>
                <InvoiceWidget invoice={source} link />
              </td>
              <td className="text-right">{sepFormat(amount)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
}
