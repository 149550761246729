import type { ReactElement, ReactNode } from 'react';
import type { Language } from 'types';
import { RenderCellProps, RenderEditCellProps, SelectEditor } from 'components/DataGrid';
import { createColumn } from './createColumn';
import type { GridRow, GridSummaryRow } from '../types';
import { wrapContainer } from './style.css';
import { useLanguageOptions } from 'components';
import { CheckboxOption } from 'components/Select';

function renderValue(props: RenderCellProps<GridRow, GridSummaryRow>): ReactNode {
  const value = props.row.language;
  if (!value || value.length === 0) {
    return null;
  }
  const text = value.join(', ');
  return (
    <span className={wrapContainer} title={text}>
      {text}
    </span>
  );
}

function Edit(props: RenderEditCellProps<GridRow, any>): ReactElement {
  const allOptions = useLanguageOptions();
  const options = props.row.config.language.resolved_value as Language[];

  const availableOptions = options ? allOptions.filter((o) => options.includes(o.value)) : allOptions;

  return (
    <SelectEditor
      options={availableOptions}
      {...props}
      isSimple
      isSearchable
      isMulti
      Option={CheckboxOption}
      keepOpenOnSelect
    />
  );
}

export const LanguageColumn = createColumn(
  {
    key: 'language',
    name: 'Language',
    width: 76,
  },
  {
    renderView: (props) => (props.row.kind === 'MEDIA' ? renderValue(props) : null),
    renderEdit: (props) => <Edit {...props} />,
    displayCellContent: true,
  },
);
