import { ReactElement } from 'react';
import { sepFormat } from 'utilities';
import { header, oddRow } from 'styles/style.css';
import { AddButton } from 'components';
import type { ApplicationType, LinkCandidate } from 'modules/applicator/types';
import { InvoiceWidget } from 'modules/invoicing/common/InvoiceWidget';

type CandidateListProps = {
  items: LinkCandidate[];
  applicationType: ApplicationType;
  actionHandler?: (item: LinkCandidate) => void;
};

export function CandidateList({ items, applicationType, actionHandler }: CandidateListProps): ReactElement {
  return (
    <table className="form-table table" data-testid="candidates">
      <thead>
        <tr className={header.dark}>
          <th>Candidate invoice</th>
          <th className="text-right">Total amount</th>
          <th className="text-right">Applicable amount</th>
          <th className="tools"></th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, idx) => {
          const { destination, amount } = item;

          const totalAmount = destination[applicationType === 'advance' ? 'total' : 'sub_total'];

          return (
            <tr key={destination.id} className={idx % 2 === 0 ? oddRow : undefined}>
              <td>
                <InvoiceWidget invoice={destination} link />
              </td>
              <td className="text-right align-middle">{sepFormat(totalAmount)}</td>
              <td className="text-right align-middle">{sepFormat(amount)}</td>
              <td className="tools align-middle">
                {actionHandler && (
                  <AddButton testId={`candidates-action-${destination.id}`} onClick={() => actionHandler(item)} />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
