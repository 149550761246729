import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import equal from 'fast-deep-equal';
import { urlon } from '@ff-it/ui';
import { FetcherState } from './types';

export function parseQs(qs: string): FetcherState | undefined {
  const s = qs.replace(/^\?/, '');

  if (s.length !== 0) {
    try {
      const { p: pageIndex, s: pageSize, o: sort, f: filter } = urlon.parse(s);
      if (!pageIndex || !pageSize) {
        return undefined;
      }
      return { pageIndex, pageSize, sort, filter };
    } catch (_e) {
      return undefined;
    }
  }

  return undefined;
}

export function serializeQs({ pageIndex, pageSize, sort, filter }: FetcherState): string {
  const str =
    urlon.stringify({
      p: pageIndex,
      s: pageSize,
      o: sort,
      f: filter,
    }) || '';
  return str.length > 0 ? '?' + str : '';
}

export function useQueryState(
  initialState: FetcherState,
  onStateChange?: (state: FetcherState) => void,
): [FetcherState, Dispatch<SetStateAction<FetcherState>>] {
  // writing thorugh ref and decoupling state updates from qs seems better
  // but syncing external states vs internal gets very complicated unless we remount
  // if we could import history and listen to that might be better
  const location = useLocation();
  const navigate = useNavigate();

  const updateQuery = (newState: FetcherState, replace = false): void => {
    const qs = equal(newState, initialState) ? '' : serializeQs(newState);
    if (location.search !== qs) {
      const to = `${location.pathname}${qs}`;
      navigate(to, { replace });
    }
  };

  const [state, setInnerState] = useState<FetcherState>(() => {
    // reads qs on initialization
    return location.search ? { ...initialState, ...parseQs(location.search) } : initialState;
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: initialState should be in sync
  useEffect(() => {
    setInnerState(location.search ? { ...initialState, ...parseQs(location.search) } : initialState);
  }, [location]);

  const setState: Dispatch<SetStateAction<FetcherState>> = (value) => {
    const newState = typeof value === 'function' ? value(state) : value;
    updateQuery(newState, false);
    onStateChange && onStateChange(newState);
  };

  return [state, setState];
}
