import { eachMonthOfInterval, format, formatISO, parseISO } from 'date-fns';
import type { Options } from 'options';
import type { Period } from 'types';

export function usePeriodMonths(period: Period | null): Options {
  return period
    ? eachMonthOfInterval({
        start: parseISO(period.start),
        end: parseISO(period.end),
      }).map((m) => ({
        label: format(m, 'MMM, yy'),
        value: formatISO(m, { representation: 'date' }),
      }))
    : [];
}
