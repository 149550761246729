import { ReactElement } from 'react';
import { CompanyPersons } from 'modules/crm/CompanyPersons';
import { useEntity } from 'components';
import type { Person } from 'modules/crm/types';
import type { Client } from 'modules/client/types';
import permissions from 'modules/client/persons/permissions';
import { canViewClientPersons } from 'modules/client';

export function Persons(): ReactElement {
  const { item } = useEntity<Client>();

  const model = {
    endpoint: `client/clients/${item.id}/persons/`,
    title: 'Person',
    pluralTitle: 'Persons',
    entityTitle: (e: Person) => `${e.first_name} ${e.last_name}`,
    permissions: {
      ...permissions,
      view: canViewClientPersons(item),
    },
  };
  return <CompanyPersons {...model} />;
}
