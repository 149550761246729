import { useEffect, useRef, useMemo, ReactElement } from 'react';
import { useField } from 'react-final-form';
import type { Company } from 'types';
import type { BoundState } from 'modules/campaign/common/types';
import { DatePickerField, DatePickerFieldProps, SelectFieldProps, SelectField } from '@ff-it/form';
import { createSelectField } from './createSelectField';
import { boundStateOptions } from 'configuration';
import type { Option } from 'options';
import { formatISO, parseISO, addDays } from 'date-fns';
import { add, add_advance } from 'modules/invoicing/income/permissions';
import { useHasPerms } from 'core/permissions';
import { useAgencyVatOptions } from 'modules/core/useAgencyVAT';
import type { InvoiceState, InvoiceType } from 'modules/invoicing/common/types';
import { invoiceStateOptions, invoiceTypeOptions } from 'modules/invoicing/common/options';
import { api } from 'services';

export function IncomeTypeField(
  props: Omit<SelectFieldProps<Option<InvoiceType>>, 'simple' | 'options'>,
): ReactElement {
  const [canAdd, canAddAdvance] = useHasPerms(add, add_advance);

  const options = useMemo(
    () =>
      invoiceTypeOptions.map((opt) => {
        let isDisabled;
        if (canAdd) {
          // pass
          isDisabled = undefined;
        } else if (canAddAdvance) {
          isDisabled = opt.value === 'ADVANCE' ? undefined : true;
        }
        return { isDisabled, ...opt };
      }),
    [canAdd, canAddAdvance],
  );
  return <SelectField options={options} {...props} simple />;
}

export function ExpenseTypeField(
  props: Omit<SelectFieldProps<Option<InvoiceType>>, 'simple' | 'options'>,
): ReactElement {
  return <SelectField options={invoiceTypeOptions} {...props} simple />;
}

type VatRateFieldProps = Omit<SelectFieldProps<Option<number>>, 'simple' | 'options'>;
export function VatRateField(props: VatRateFieldProps): ReactElement {
  const options = useAgencyVatOptions();
  return <SelectField options={options} {...props} simple />;
}

export const BoundStateFilterField = createSelectField<Option<BoundState | 'OVERSPENT'>>(boundStateOptions, {
  simple: true,
});
export const InvoiceStateField = createSelectField<Option<InvoiceState>>(invoiceStateOptions, { simple: true });

export function PayerVatRateField(props: Omit<VatRateFieldProps, 'name'>): ReactElement {
  const {
    input: { value: payer },
    meta: { dirty },
  } = useField<Company | null>('payer', { subscription: { value: true, dirty: true } });

  const {
    input: { value, onChange },
  } = useField('vat_rate', { subscription: { value: true } });

  const payerId = payer?.parent || payer?.id;

  // biome-ignore lint/correctness/useExhaustiveDependencies: runs on payerId change
  useEffect(() => {
    if (payerId && (dirty || value === null || value === '')) {
      api
        .request<number, unknown>({
          url: `core/companies/${payerId}/resolve_vat/`,
          method: 'GET',
        })
        .then((res) => {
          if (res.ok) {
            const rate = res.data;
            if (rate !== value) {
              onChange(rate);
            }
          } else {
            throw res.error;
          }
        });
    }
  }, [payerId]);

  return <VatRateField name="vat_rate" {...props} />;
}

export function PaymentDateField(props: DatePickerFieldProps): ReactElement {
  const fetchIdRef = useRef(0);

  const {
    input: { value: source_campaign },
  } = useField<number | null>('source_campaign', { subscription: { value: true }, allowNull: true });

  const {
    input: { value: payer },
    meta: { dirty: payerDirty },
  } = useField<Company | null>('payer', { subscription: { value: true, dirty: true } });

  const {
    input: { value: checkout },
    meta: { dirty: checkoutDirty },
  } = useField<string | null>('checkout_date', { subscription: { value: true, dirty: true } });

  const {
    input: { value, onChange },
  } = useField<string | null>(props.name, { subscription: { value: true } });

  const dirty = payerDirty || checkoutDirty || value === '';

  // biome-ignore lint/correctness/useExhaustiveDependencies: form is stable
  useEffect(() => {
    const resolve = async (): Promise<void> => {
      const fetchId = ++fetchIdRef.current;

      if (dirty) {
        let payment_date = null;
        if (payer && checkout) {
          const res = await api.get<{ invoice_payment_days: number | null }, unknown>(
            'client/agreements/resolve_invoice_payment_days/',
            {
              queryParams: {
                source_campaign,
                payer: payer.id,
                checkout_date: checkout,
              },
            },
          );
          if (res.ok && res.data.invoice_payment_days) {
            payment_date = formatISO(addDays(parseISO(checkout), res.data.invoice_payment_days), {
              representation: 'date',
            });
          }
        }
        if (payment_date && fetchId === fetchIdRef.current) {
          onChange(payment_date);
        }
      }
    };

    resolve();
  }, [payer, source_campaign, checkout, dirty]);

  return <DatePickerField {...props} />;
}
