import { ReactElement } from 'react';
import cx from 'clsx';
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  useDialogController,
} from '@ff-it/ui';
import { Form, Submit, useSubmitHandler } from '@ff-it/form';
import { AttachmentField } from 'components';
import { Attachment } from 'types';
import useSWR, { KeyedMutator } from 'swr';

interface AttachmentFormProps {
  url: string;
  initialValues: any;
  mutate: KeyedMutator<any>;
}

function AttachmentForm({ url, initialValues, mutate }: AttachmentFormProps): ReactElement {
  const { setOpen } = useDialogController();
  const onSubmit = useSubmitHandler(
    { url, method: 'POST' },
    {
      onSuccess: (data) => {
        mutate(data, { revalidate: false });
        setOpen(false);
      },
    },
  );

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      <DialogBody>
        <AttachmentField name="plan_attachments" discriminator="plan" />
      </DialogBody>
      <DialogFooter>
        <Submit />
        <DialogClose className="ml-auto" />
      </DialogFooter>
    </Form>
  );
}

interface PlanAttachmentsProps {
  endpoint: string;
  className?: string;
}

export function PlanAttachments({ endpoint, className }: PlanAttachmentsProps): ReactElement {
  const url = `${endpoint}plan_attachments/`;
  const { data, mutate } = useSWR<{ plan_attachments: Attachment[] }>({ url, method: 'GET' });

  return (
    <Dialog>
      <DialogTrigger>
        <a role="button" className={cx('plan-attachments', className)}>
          Attachments {data && <span className="badge badge-light">{data.plan_attachments.length}</span>}
        </a>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader title="Plan attachments" />
        {data && <AttachmentForm initialValues={data} url={url} mutate={mutate} />}
      </DialogContent>
    </Dialog>
  );
}
