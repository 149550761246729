import { FieldValidator } from 'final-form';
import { useCallback } from 'react';
import { api } from 'services';

export function useNumberValidator(): FieldValidator<string> {
  const validateNumberUnique = useCallback(
    async (number: string, { id, partner }: any): Promise<undefined | string> => {
      if (number && partner) {
        const queryParams: Record<string, any> = {
          page_size: 1,
          number,
          partner: partner.id,
        };

        if (id) {
          queryParams['exclude'] = id;
        }

        const resp = await api.get<any, unknown>('invoicing/expense/', {
          queryParams,
        });
        if (resp.ok && resp.data.count) {
          return Promise.resolve('Invoice with this number already exists');
        }
      }

      return Promise.resolve(undefined);
    },
    [],
  );

  return validateNumberUnique;
}
