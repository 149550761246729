import { ReactElement } from 'react';
import { Alert, Box, Button, Col, Row, openModal } from '@ff-it/ui';
import { header } from 'styles/style.css';
import type { CorrectableInvoice, CorrectorData } from './types';
import { InvoiceWidget } from 'modules/invoicing/common/InvoiceWidget';
import { BlockWidget, BoundState, EnterpriseLink, Icon } from 'components';
import { ProductTitle } from 'modules/supplier/products';
import { TitleContainer } from 'components/TitleContainer';
import { fmtMonth } from 'utilities';
import { ConfirmDialog } from 'components/Actions/createConfirmDialog';

type ContainerProps = {
  data: CorrectorData;
  handleCorrection: (invoice: CorrectableInvoice) => void;
};

export function Container({ data: { linkable, entities, invoices }, handleCorrection }: ContainerProps): ReactElement {
  const linkableMap = linkable.reduce((acc: Record<string, number>, v, idx) => {
    acc[v.id] = idx;
    return acc;
  }, {});

  if (invoices.length === 0) {
    return <Alert variant="info">There is no linked income with overspent rows</Alert>;
  }

  return (
    <Box data-testid="correct-overspent-container">
      <Alert variant="info">Align linked income of overspent rows by reducing amount.</Alert>
      {invoices.map((invoice) => {
        return (
          <Box key={invoice.id} marginBottom="lg" border="default">
            <Row className={header.default} padding="sm">
              <InvoiceWidget invoice={invoice} link />
              <Box marginLeft="auto">
                <Button
                  testId={`correct-invoice-${invoice.id}`}
                  size="sm"
                  onClick={async () => {
                    const confirm = await openModal(
                      (render) => (
                        <ConfirmDialog {...render} title="Are you sure?">
                          This is going to modify invoice links <br /> and mark invoice as <em>not linked</em>.
                        </ConfirmDialog>
                      ),
                      { testId: 'confirm-reduce', canDismiss: false },
                    );
                    if (confirm) {
                      handleCorrection(invoice);
                    }
                  }}
                >
                  Reduce
                </Button>
              </Box>
            </Row>
            <Box>
              {invoice.rows.map(({ id, month, amount: _, overspent }) => {
                const { campaign_id, block_id, product_id, provider_id, activity_id } = linkable[linkableMap[id]];
                return (
                  <Row key={id} paddingY="sm" paddingX="lg">
                    <Col width="content" paddingRight="md" display="flex" alignItems="center">
                      <BoundState variant="income" state="FULL" is_overspent row={id} />
                    </Col>
                    <Col width="content" paddingRight="md" display="flex" alignItems="center">
                      <EnterpriseLink to={`/planning/campaigns/${campaign_id}/planning/${block_id}?rowId=${id}`}>
                        <Icon size="sm" icon="link" />
                      </EnterpriseLink>
                    </Col>
                    <Col>
                      <BlockWidget {...entities.block[block_id]} link />
                    </Col>
                    <Col>
                      <ProductTitle product={entities.product[product_id]} />
                    </Col>
                    <Col>
                      <TitleContainer
                        title={entities.company[provider_id].title}
                        subtitle={entities.activity[activity_id].name}
                      />
                    </Col>
                    <Col>
                      <Row>
                        <Col textAlign="right">{fmtMonth(month)}</Col>
                        <Col textAlign="right" fontWeight="bold">
                          {overspent}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
