import cx from 'clsx';
import { ReactNode } from 'react';
import { RenderCellProps } from 'components/DataGrid';
import type { GridRow, GridSummaryRow } from '../../types';
import { wrapContainer } from '../style.css';
import { Box } from '@ff-it/ui';

function renderDiscounts(discounts: string[] | null, currentDiscount: string | null, isEditable: boolean): ReactNode {
  if (!discounts && currentDiscount) {
    return <Box className={wrapContainer} color="quarterary">{`${currentDiscount}%`}</Box>;
  }

  return discounts ? (
    <Box className={cx(wrapContainer, 'triple')}>
      {discounts.map((value, idx) => (
        <div key={idx}>{value}%</div>
      ))}
    </Box>
  ) : !isEditable ? (
    '—'
  ) : null;
}

export function View({
  row,
  isCellEditable,
  direction,
}: RenderCellProps<GridRow, GridSummaryRow> & {
  direction: 'client' | 'provider';
}): ReactNode {
  const value = direction === 'client' ? row.client_discount : row.provider_discount;
  const currentDiscount = direction === 'client' ? row.sums.client_discount_rate : row.sums.provider_discount_rate;

  return renderDiscounts(value, currentDiscount, isCellEditable);
}
