import { ReactElement, ReactNode, forwardRef } from 'react';

import { handleBtnClassname } from './style.css';
import { Button } from '@ff-it/ui';
import { Icon } from 'components/ui';
export { handleBtnClassname };

type DragHandleProps = {
  testId?: string;
  disabled?: boolean;
  active?: boolean;
  children?: ReactNode;
};

export const DragHandle = forwardRef<HTMLButtonElement, DragHandleProps>(function DragHandle(
  { testId, children, active, ...props },
  ref,
): ReactElement {
  return (
    <Button variant={null} size={null} className={handleBtnClassname} {...props} ref={ref}>
      <Icon icon="grip-vertical" />
      {children}
    </Button>
  );
});
