import { ReactElement } from 'react';
import { GroupField, FormInline, FormInlineRow, EnterpriseField } from 'components';
import { useField } from 'react-final-form';
import { Alert } from '@ff-it/ui';

const initialMembershipValues = {
  enterprise: null,
  groups: [],
};

function MembershipRow({ name, remove }: { name: string; remove?: () => void }): ReactElement {
  return (
    <FormInlineRow name={name} remove={remove}>
      <div className="form-row">
        <EnterpriseField name={`${name}.enterprise`} placeholder="Agency" size="sm" className="col-md-6" required />
        <GroupField name={`${name}.groups`} placeholder="Groups" size="sm" className="col-md-6" required isMulti />
      </div>
    </FormInlineRow>
  );
}

export function UserMembershipInline(): ReactElement {
  const {
    input: { value: is_superuser },
  } = useField('is_superuser', { subscription: { value: true } });

  return (
    <>
      {is_superuser && (
        <Alert>
          For <strong>superuser</strong> membership of all agencies is implied
        </Alert>
      )}
      <FormInline
        title="Membership"
        name="membership"
        initialValues={initialMembershipValues}
        Row={MembershipRow}
        flush
      />
    </>
  );
}
