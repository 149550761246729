import React from 'react';
import { useField } from 'react-final-form';
import { Box, Button, ButtonProps } from '@ff-it/ui';
import { BaseFieldProps, FormGroup, fieldSubscription, useIsDisabled } from '@ff-it/form';

interface ButtonGroupFieldProps extends Omit<BaseFieldProps, 'placeholder' | 'id' | 'size'> {
  options: {
    value: any;
    label: React.ReactNode;
  }[];
  format?: (value: string, name: string) => any;
  parse?: (value: any, name: string) => any;
  size?: ButtonProps['size'];
  variant?: ButtonProps['variant'];
}

export function ButtonGroupField(props: ButtonGroupFieldProps): React.ReactElement {
  const { name, className, label, options, help, disabled: propDisabled, validate, size, variant, ...rest } = props;
  const disabled = useIsDisabled(propDisabled);

  const {
    input: { value, onChange },
    meta,
  } = useField<any, HTMLInputElement>(name, {
    subscription: fieldSubscription,
    validate,
  });

  return (
    <FormGroup className={className} label={label} help={help} meta={meta} required={rest.required}>
      <Box>
        <div className="btn-group">
          {options.map((option) => (
            <Button
              key={option.value}
              disabled={disabled}
              active={option.value === value}
              onClick={() => onChange(option.value)}
              size={size}
              variant={variant}
            >
              {option.label}
            </Button>
          ))}
        </div>
      </Box>
    </FormGroup>
  );
}
