import pivot from './Pivot';
import raw from './Raw';
import { GlobalRevenue } from 'modules/reporting/revenue';
import { GlobalClient } from 'modules/reporting/client';
import { GlobalProvider } from 'modules/reporting/provider';
import { GlobalCampaign } from 'modules/reporting/campaign';
import { GlobalType } from 'modules/reporting/type';
import { GlobalActivity } from 'modules/reporting/activity';

export const route = {
  path: 'reporting/*',
  children: [
    {
      path: 'revenue',
      element: <GlobalRevenue />,
    },
    {
      path: 'client',
      element: <GlobalClient />,
    },
    {
      path: 'provider',
      element: <GlobalProvider />,
    },
    {
      path: 'campaign',
      element: <GlobalCampaign />,
    },
    {
      path: 'type',
      element: <GlobalType />,
    },
    {
      path: 'activity',
      element: <GlobalActivity />,
    },
    pivot,
    raw,
  ],
};

export const menu = {
  to: '/reporting',
  label: 'Reporting',
  icon: 'chart-bar' as const,
  children: [
    {
      to: '/reporting/revenue',
      label: 'Revenue',
    },
    {
      to: '/reporting/client',
      label: 'Clients',
    },
    {
      to: '/reporting/provider',
      label: 'Providers',
    },
    {
      to: '/reporting/campaign',
      label: 'Campaigns',
    },
    {
      to: '/reporting/type',
      label: 'Types',
    },
    {
      to: '/reporting/activity',
      label: 'Activities',
    },
    {
      to: '/reporting/raw',
      label: 'Raw',
    },
    {
      to: '/reporting/pivot',
      label: 'Pivot',
    },
  ],
};
