import { ReactElement } from 'react';
import cx from 'clsx';
import { Box } from '@ff-it/ui';
import { container, wrap } from './style.css';
import { alignInvoices } from './alignInvoices';
import { MonthList } from './MonthList';
import { InvoiceList, InvoiceGroup } from './InvoiceList';
import type { Invoice, Invoices, Months } from './types';
import type { InvoiceDirection } from 'modules/invoicing/common/types';
import { fmt, parseBig } from 'utilities';
import Big from 'big.js';

export type RowLinkStateProps = {
  direction: InvoiceDirection;
  months: Months;
  invoices: Invoices;
  onInvoiceSelect?: (invoice: Invoice) => void;
  className?: string;
};

export function RowLinkState({
  direction,
  months,
  invoices,
  onInvoiceSelect,
  className,
}: RowLinkStateProps): ReactElement {
  const sizes = alignInvoices(months, invoices);
  const invoiceSum = invoices.reduce((acc, m) => {
    return acc.plus(parseBig(m.amount));
  }, Big(0));

  return (
    <Box className={cx(container, direction, className)}>
      <div className={wrap}>
        <MonthList months={months} />
        {Math.min(...sizes) < 0.49 ? (
          // invoices are too dense (or have negative invoices) to display in amount scale
          <InvoiceGroup
            variant={direction}
            invoices={invoices}
            progressRatio={sizes.reduce((prev, a) => prev + a, 0)}
            onInvoiceSelect={onInvoiceSelect}
          />
        ) : (
          <InvoiceList variant={direction} invoices={invoices} sizes={sizes} onInvoiceSelect={onInvoiceSelect} />
        )}
      </div>
      <Box textAlign="right" title="Invoiced" fontSize="xs" paddingX="xs" marginTop="xs">
        invoiced:{' '}
        <Box display="inline" fontWeight="bold">
          {fmt(invoiceSum)}
        </Box>
      </Box>
    </Box>
  );
}
