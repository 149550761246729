import { createSelectField } from 'components/fields/createSelectField';
import { DepartmentTitle } from './DepartmentTitle';
import { EmbeddedDepartment } from './types';

export const departmentFieldProps = {
  getOptionLabel: ({ name }: EmbeddedDepartment) => name,
  formatOptionLabel: DepartmentTitle,
  getOptionValue: (a: EmbeddedDepartment) => a.id.toString(),
};

export const DepartmentField = createSelectField<EmbeddedDepartment>('supplier/departments/', {
  ...departmentFieldProps,
  suggestion: true,
});
