import type { RenderHeaderCellProps } from './types';
import { ReactNode } from 'react';
import { headerSortCellClassname, headerSortNameClassname } from './style/cell.css';
import renderSortStatus from './sortStatus';

export default function renderHeaderCell<R, SR>({
  column,
  sortDirection,
  priority,
}: RenderHeaderCellProps<R, SR>): ReactNode {
  if (!column.sortable) return column.name;

  return (
    <SortableHeaderCell sortDirection={sortDirection} priority={priority}>
      {column.name}
    </SortableHeaderCell>
  );
}

type SharedHeaderCellProps<R, SR> = Pick<RenderHeaderCellProps<R, SR>, 'sortDirection' | 'priority'>;

interface SortableHeaderCellProps<R, SR> extends SharedHeaderCellProps<R, SR> {
  children: React.ReactNode;
}

function SortableHeaderCell<R, SR>({ sortDirection, priority, children }: SortableHeaderCellProps<R, SR>): ReactNode {
  return (
    <span className={headerSortCellClassname}>
      <span className={headerSortNameClassname}>{children}</span>
      <span>{renderSortStatus({ sortDirection, priority })}</span>
    </span>
  );
}
