import cx from 'clsx';
import { fieldSubscription, useField, useNumberParser } from '@ff-it/form';
import { Box } from '@ff-it/ui';
import { amountInput } from './style.css';

function format(value: any): any {
  return value === null || value === undefined ? '' : value.toString();
}

// this is here just because DecimalField is bound to FormGroup
export function AmountInput({ name }: { name: string }): React.ReactElement {
  const parse = useNumberParser(2);

  const { input, meta } = useField<string, any>(name, {
    parse,
    format,
    subscription: fieldSubscription,
    allowNull: true,
    // validate: positive, depends on the source sign
  });

  return (
    <Box
      component="input"
      className={cx(amountInput, meta.invalid && meta.touched ? 'is-invalid' : undefined)}
      type="text"
      {...input}
      autoComplete="off"
    />
  );
}
