import { RenderCellProps } from 'components/DataGrid';
import { createColumn } from './createColumn';
import { flush } from './style.css';
import { GridRow } from '../types';
import { ReactElement } from 'react';

import { RESERVATION_STATE_PROPS, ReservationStateBadge } from 'modules/campaign/common/ReservationStateBadge';
import { Button, MenuItem, MenuList, Popover } from '@ff-it/ui';
import { reserviationStates } from 'modules/campaign/row/media';
import { cellIsEditable } from '../util';
import { handleBtnClassname } from 'components/DND/style.css';
import { allowedPlacements } from 'components/Actions/DropdownActions';

export function RenderView({ row, ...props }: RenderCellProps<GridRow, any>): ReactElement {
  let content = (
    <ReservationStateBadge testId={`state-${row.reservation_state}`} reservation_state={row.reservation_state} />
  );

  const testId = `${row._key}-reservation-state`;
  // We don't provide renderEdit, could do props.column.editable
  const editable = cellIsEditable(row, props.column.key);

  if (editable) {
    content = (
      // FIXME: can't use ActionDropdonw since we are using Badges and not Icons
      <Popover
        interaction="click"
        placement="auto"
        placementOptions={{ allowedPlacements }}
        offsetOptions={6}
        content={
          <MenuList testId={`${testId}-menu`} fontSize="sm">
            {reserviationStates.map((state) => (
              <MenuItem
                active={state === row.reservation_state}
                key={state}
                testId={`action-${state}`}
                prefix={<ReservationStateBadge reservation_state={state} />}
                label={RESERVATION_STATE_PROPS[state].title}
                onClick={() => {
                  props.onRowChange({ ...row, [props.column.key]: state });
                }}
              />
            ))}
          </MenuList>
        }
      >
        <Button
          // FIXME
          className={handleBtnClassname}
          testId={testId}
          variant={null}
        >
          {content}
        </Button>
      </Popover>
    );
  }
  return content;
}

export const ReservationStateColumn = createColumn(
  {
    key: 'reservation_state',
    width: 30,
    frozen: true,
    className: flush,
  },
  {
    renderView: (props) => (props.row.kind === 'MEDIA' ? <RenderView {...props} /> : null),
  },
);
