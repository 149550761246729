import { Big } from 'big.js';
import type { ApplicationSummaryState } from './types';
import { parseBig } from 'utilities';
import type { AppliedLink } from 'modules/applicator/types';

export function useSummaryState(amount: Big, appliedInvoices: AppliedLink[]): ApplicationSummaryState {
  const applied = appliedInvoices.reduce((acc, v) => acc.plus(parseBig(v.amount)), Big(0));
  const balance = amount.minus(applied);
  return { amount: amount, applied, balance };
}
