import { TextField } from '@ff-it/form';
import { ReactElement } from 'react';
import { ActivityType } from 'types';
import { SmartRateGroups } from './SmartRateGroups';
import { SeviceRates } from './ServiceRates';
import { Box } from '@ff-it/ui';
import { MediaDiscountRates } from './MediaDiscountRates';

type TermsFormProps = {
  type: ActivityType;
};
export function TermsForm({ type }: TermsFormProps): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="terms" label="Terms" className="col-md-6" multiline />
      </div>
      <Box textSize="xs" color="quarterary" marginBottom="md">
        The first matching row in each group is applied from top to bottom.
      </Box>
      <SmartRateGroups type={type} />
      <SeviceRates type={type} />
      <MediaDiscountRates type={type} />
    </div>
  );
}
