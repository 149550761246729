import { ReactElement } from 'react';
import { DateRangePickerField } from '@ff-it/form';
import { PeriodPickerField } from 'components';
import { usePeriod } from '../usePeriod';
import { parseDate } from 'utilities';

function validate(_vaules: any, all: any) {
  const date_from = parseDate(all.date_from);
  const date_to = parseDate(all.date_to);
  if (!date_from || !date_to || date_to < date_from) {
    return 'Invalid period';
  }
  return undefined;
}

export function DateRangePeriodPicker(): ReactElement {
  const { date_from: minDate, date_to: maxDate } = usePeriod();

  return (
    <>
      <DateRangePickerField
        fromName="date_from"
        toName="date_to"
        minDate={minDate}
        maxDate={maxDate}
        validate={validate}
      />
      <div className="ml-1 d-flex align-items-center">
        <PeriodPickerField fromName="date_from" toName="date_to" minDate={minDate} maxDate={maxDate} />
      </div>
    </>
  );
}
