import clsx from 'clsx';

import type { RenderCheckboxProps } from '../types';
import type { ReactNode } from 'react';
import {
  checkboxClassname,
  checkboxInputClassname,
  checkboxLabelClassname,
  checkboxLabelDisabledClassname,
} from '../style/renderCheckBox.css';

export function renderCheckbox({ onChange, ...props }: RenderCheckboxProps): ReactNode {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    onChange(e.target.checked, (e.nativeEvent as MouseEvent).shiftKey);
  }

  return (
    <label
      className={clsx(checkboxLabelClassname, {
        [checkboxLabelDisabledClassname]: props.disabled,
      })}
    >
      <input type="checkbox" {...props} className={checkboxInputClassname} onChange={handleChange} />
      <div className={checkboxClassname} />
    </label>
  );
}
