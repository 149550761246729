import { ReactElement, ReactNode } from 'react';

export function AuthContainer({ children, wide }: { children: ReactNode; wide?: boolean }): ReactElement {
  return (
    <div className="bg-light">
      <div className="container">
        <div className="row justify-content-center align-items-center min-vh-100">
          <div className={`shadow-sm bg-light p-3 bg-white ${wide ? 'col-lg-6 col-md-8' : 'col-lg-4 col-md-6'}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
