import { Row } from './types';
import { sumColumns } from '../common';
import { TableColumns } from '@ff-it/ui';
import { ClientTitle } from 'components';

export const columns: TableColumns<Row> = [
  {
    content: 'Agency',
    key: 'enterprise_slug',
    width: 80,
  },
  {
    content: 'Client grp.',
    key: 'client_group_title',
    sortable: true,
  },
  {
    content: 'Client',
    key: 'client_internal_name',
    sortable: true,
    render: ({ client_internal_name, payer_internal_name }) => (
      <ClientTitle
        client={{
          internal_name: client_internal_name,
        }}
        payer={{
          internal_name: payer_internal_name,
        }}
      />
    ),
  },
  ...sumColumns,
];
