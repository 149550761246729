import { ReactElement } from 'react';
import { useEntity, TabNav } from 'components';
import { NavLinkProps } from 'react-router-dom';
import { any, useHasPerms } from 'core/permissions';
import { canViewClientAgreements, canViewClientPersons } from 'modules/client';
import type { Client } from 'modules/client/types';

export function Nav(): ReactElement {
  const { item } = useEntity<Client>();

  const [hasBrand, hasPersons, hasManagers, hasPlanners, canViewAgreements] = useHasPerms(
    any('client.view_clientbrand', 'client.change_clientbrand'),
    canViewClientPersons(item),
    'client.change_clientmanager',
    'client.change_clientplanner',
    canViewClientAgreements(item),
  );

  const nav: NavLinkProps[] = [
    {
      to: '',
      end: true,
      children: 'View',
    },
  ];
  if (hasManagers) {
    nav.push({
      to: 'managers',
      children: 'Managers',
    });
  }
  if (hasPlanners) {
    nav.push({
      to: 'planners',
      children: 'Planners',
    });
  }

  if (canViewAgreements) {
    nav.push({
      to: 'agreements',
      children: 'Agreements',
    });
  }

  if (hasBrand) {
    nav.push({
      to: 'brands',
      children: 'Brands',
    });
  }
  if (hasPersons) {
    nav.push({
      to: 'persons',
      children: 'Persons',
    });
  }

  return (
    <div className="container-fluid header header--client">
      <div className="d-flex flex-row align-items-center">
        <div className="mb-2">
          <h2>{item.title}</h2>
        </div>
      </div>
      <TabNav nav={nav} />
    </div>
  );
}
