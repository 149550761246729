import cx from 'clsx';
import { ReactElement } from 'react';
import type { GridRow } from '../Grid/types';
import { Box, Col, Popover, Row } from '@ff-it/ui';
import { Icon } from 'components';
import { applicationContainer, asteriskBadge } from './styles.css';
import { usePlanFactors } from '../useFactors';
import { sepFormat } from 'utilities';
import { expenseForeground, incomeForeground, wrapContainer } from '../Grid/columns/style.css';

function ApplicationDetails({
  row: {
    factors,
    gross_price,
    sums: { client_gross_price, provider_gross_price },
  },
}: { row: GridRow }): ReactElement {
  const planFactors = usePlanFactors();
  return (
    <Box fontSize="xs" padding="sm" className={applicationContainer}>
      <Box>
        <Box component="span" fontSize="sm" fontWeight="bold">
          {sepFormat(gross_price)}
        </Box>{' '}
        base rate
      </Box>
      <Row>
        <Col display="flex" flexDirection="column">
          <strong>Client</strong>
          {factors.client.map((factorId) => {
            const f = planFactors.find((v) => v.id === factorId)!;
            return (
              <Box key={factorId} marginBottom="xs" lineHeight={1}>
                {f.rate}% - {f.name}
              </Box>
            );
          })}
          <Box fontSize="sm" marginTop="auto" className={cx(incomeForeground, wrapContainer)} fontWeight="bold">
            {sepFormat(client_gross_price)}
          </Box>
        </Col>
        <Col display="flex" flexDirection="column">
          <strong>Provider</strong>
          {factors.provider.map((factorId) => {
            const f = planFactors.find((v) => v.id === factorId)!;
            return (
              <Box key={factorId} marginBottom="xs" lineHeight={1}>
                {f.rate}% - {f.name}
              </Box>
            );
          })}
          <Box fontSize="sm" marginTop="auto" className={cx(expenseForeground, wrapContainer)} fontWeight="bold">
            {sepFormat(provider_gross_price)}
          </Box>
        </Col>
      </Row>
    </Box>
  );
}

/**
 * Shows applied factors on hover
 */
export function AsteriskBadge({ row }: { row: GridRow }): ReactElement {
  return (
    <Popover interaction="hover" placement="left" portal content={<ApplicationDetails row={row} />}>
      <Box className={asteriskBadge} data-testid="application-details">
        <Icon icon="asterisk" />
      </Box>
    </Popover>
  );
}
