import Big from 'big.js';
import { cmpStrings, parseBig } from 'utilities';
import type { PlanRow } from 'modules/campaign/row';

// https://gitlab.ffit.lv/ff-it/init/-/blob/00b275db84dc54f36993d99c61f5ff8bfb8c439d/client/src/modules/campaign/block/Summary/Container.tsx

type Totals = {
  income: Big;
  expense: Big;
};

function reduceTotals(rows: Totals[]): Totals {
  let income = Big(0);
  let expense = Big(0);

  rows.forEach((row) => {
    income = income.plus(row.income);
    expense = expense.plus(row.expense);
  });
  return {
    income,
    expense,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type Result<C = void> = {
  title: string;
} & Totals &
  (C extends void
    ? unknown
    : {
        children: C[];
      });

type Month = Result;
type Activity = Result<Month>;
type Provider = Result<Activity>;

function sortResult(a: Result, b: Result): number {
  return cmpStrings(a.title, b.title);
}

type SummaryResult = {
  providers: Provider[];
  media: Totals;
  service: Totals;
  fees: Totals;
};

export function useSummary(rows: PlanRow[]): SummaryResult {
  const providerMap: Record<string, string> = {};
  const acitvityMap: Record<string, string> = {};
  const media: Totals = { income: Big(0), expense: Big(0) };
  const service: Totals = { income: Big(0), expense: Big(0) };
  const fees: Totals = { income: Big(0), expense: Big(0) };
  // provider.activity.month.sum
  const map: Record<string, Record<string, Record<string, Totals>>> = {};

  rows.forEach((row) => {
    if (!row.supplier) {
      return;
    }
    const { activity, provider } = row.supplier;

    const income = parseBig(row.sums.target_income);
    const expense = parseBig(row.sums.target_expense);

    if (activity.is_fee) {
      fees.income = fees.income.plus(income);
      fees.expense = fees.expense.plus(expense);
    } else {
      if (row.kind === 'MEDIA') {
        media.income = media.income.plus(income);
        media.expense = media.expense.plus(expense);
      } else {
        service.income = service.income.plus(income);
        service.expense = service.expense.plus(expense);
      }
    }

    if (!(activity.id in acitvityMap)) {
      acitvityMap[activity.id] = activity.name;
    }

    if (!(provider.id in providerMap)) {
      providerMap[provider.id] = provider.title;
    }

    Object.keys(row.months).map((month) => {
      const value = row.months[month];
      const income = parseBig(value.target_income);
      const expense = parseBig(value.target_expense);

      const amount = {
        income,
        expense,
      };

      // upsertIn?
      if (provider.id in map) {
        if (activity.id in map[provider.id]) {
          if (month in map[provider.id][activity.id]) {
            const c = map[provider.id][activity.id][month];
            map[provider.id][activity.id][month] = {
              income: income.plus(c.income),
              expense: expense.plus(c.expense),
            };
          } else {
            map[provider.id][activity.id][month] = amount;
          }
        } else {
          map[provider.id][activity.id] = {
            [month]: amount,
          };
        }
      } else {
        map[provider.id] = {
          [activity.id]: {
            [month]: amount,
          },
        };
      }
    });
  });

  const providers: Provider[] = Object.keys(map)
    .map((providerId) => {
      const children: Activity[] = Object.keys(map[providerId])
        .map((acitvityId) => {
          const children: Month[] = Object.keys(map[providerId][acitvityId])
            .map((month) => ({
              title: month,
              ...map[providerId][acitvityId][month],
            }))
            .sort(sortResult);

          return {
            title: acitvityMap[acitvityId] || 'Unknown',
            children,
            ...reduceTotals(children),
          };
        })
        .sort(sortResult);

      return {
        title: providerMap[providerId] || 'Unknown',
        children,
        ...reduceTotals(children),
      };
    })
    .sort(sortResult);

  return {
    providers,
    media,
    service,
    fees,
  };
}
