import { withHasPerm } from 'components';

import { RouteObject, Outlet } from 'react-router';
import { superuser } from 'core/permissions';

import * as users from './Users';
import * as agencies from './Agencies';

const System = withHasPerm(Outlet, superuser);

export const route: RouteObject = {
  path: 'system',
  element: <System />,
  children: [agencies.route, users.route],
};
