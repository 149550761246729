import { ReactElement } from 'react';
import { Button } from '@ff-it/ui';
import { useFieldArray } from 'react-final-form-arrays';
import { FormInlineError } from 'components';
import { Commission } from './Commission';
import { useField } from '@ff-it/form';
import { fmt, parseBig } from 'utilities';

export function Commissions(): ReactElement {
  const {
    input: { value: rawExecution },
  } = useField<string | undefined>('execution.actual', { subscription: { value: true } });

  const a = rawExecution ? parseBig(rawExecution) : undefined;

  const {
    fields: { map, value, remove, push, update, length = 0 },
  } = useFieldArray('commission', { subscription: { value: true } });
  function setCurrent(index: number): void {
    value.forEach((v, i) => {
      if (v.current && i !== index) {
        update(i, { ...v, current: false });
      } else if (i === index) {
        update(i, { ...v, current: true });
      }
    });
  }
  return (
    <div className="mb-3">
      <FormInlineError name="commission" />
      <table className="table form-table mb-0">
        <thead>
          <tr>
            <th className="text-center">Target</th>
            <th>From</th>
            <th className="text-right">To</th>
            <th className="text-center text-muted">Rate, %</th>
            <th className="text-right">Execution</th>
            <th className="tools" />
          </tr>
        </thead>
        <tbody>
          {map((_name, index) => {
            const to = index + 1 < length ? value[index + 1].target : undefined;

            let execution = undefined;
            if (a) {
              const f = parseBig(value[index].target);
              const t = to ? parseBig(to) : undefined;

              if (t) {
                if (a.gte(f) && a.lt(t)) {
                  execution = fmt(a);
                }
              } else if (a.gte(f)) {
                execution = fmt(a);
              }
            }

            return (
              <Commission
                prevTarget={index > 0 ? value[index - 1].target : undefined}
                nextTarget={to}
                current={value[index].current}
                setCurrent={setCurrent}
                key={index}
                index={index}
                remove={index !== 0 ? remove : undefined}
                execution={execution}
              />
            );
          })}
        </tbody>
      </table>
      <Button
        type="button"
        variant="outline-success"
        size="sm"
        testId="add-inline"
        onClick={() =>
          push({
            target: '',
            rate: '',
            current: false,
          })
        }
      >
        Add
      </Button>
    </div>
  );
}
