import type { ReactElement } from 'react';
import { Alert, Box, Button } from '@ff-it/ui';
import type { Income } from 'modules/invoicing/income/types';
import { InvoiceWidget } from 'modules/invoicing/common/InvoiceWidget';

type CreditSourceProps = {
  invoice: Income;
  clear: () => void;
};
export function CreditSource({ invoice, clear }: CreditSourceProps): ReactElement {
  return (
    <Alert>
      <Box display="flex" alignItems="center">
        <small className="mr-1">Credit source: </small> <InvoiceWidget invoice={invoice} flat />
        <Button className="ml-auto" variant="outline-secondary" size="sm" onClick={clear} testId="clear-credit-source">
          clear
        </Button>
      </Box>
    </Alert>
  );
}
