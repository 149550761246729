import {
  Alert,
  Button,
  Col,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Row,
  openModal,
} from '@ff-it/ui';
import { FactorDialogProps } from './types';
import { ReactElement } from 'react';
import { managerDialog } from './styles.css';
import cx from 'clsx';
import { DepartmentTitle } from 'modules/supplier/department/DepartmentTitle';
import { FactorOption } from './FactorOption';
import { DialogForm, Icon } from 'components';
import { FactorForm } from './FactorForm';
import { initialFactorValues } from '../initialValues';

export function FactorDialog({ factors, controller }: FactorDialogProps): ReactElement {
  const numFactors = factors.length;

  return (
    <DialogContent className={managerDialog} testId="factors-dialog">
      <DialogHeader title="Factors" />
      <DialogBody>
        {numFactors === 0 ? (
          <Alert variant="info" className="mb-0">
            This plan has no factors
          </Alert>
        ) : (
          factors.map((factor, idx) => (
            <Row
              data-testid={`factor-option-${factor.id}`}
              key={factor.id}
              display="flex"
              alignItems="flex-start"
              className={cx({ 'border-bottom pb-2 mb-2': numFactors !== idx + 1 })}
              fontSize="sm"
            >
              <Col width="1/4">
                <DepartmentTitle {...factor.department} />
              </Col>
              <FactorOption factor={factor} />
              {controller && (
                <Button
                  variant="outline-primary"
                  type="button"
                  className="border-0"
                  size="sm"
                  testId="update-option"
                  onClick={() =>
                    openModal(
                      (props) => (
                        <DialogForm
                          {...props}
                          dialogHeader="Update factor"
                          initialValues={factor}
                          submitHandler={(payload) => controller.updateFactor(factor.id, payload)}
                          onRemove={() => controller.removeFactor(factor.id)}
                        >
                          <FactorForm update />
                        </DialogForm>
                      ),
                      { testId: 'update-factor-dialog' },
                    )
                  }
                >
                  <Icon icon="pencil" />
                </Button>
              )}
            </Row>
          ))
        )}
      </DialogBody>
      <DialogFooter>
        {controller && (
          <Button
            variant="outline-primary"
            testId="add-factor"
            onClick={() =>
              openModal(
                (props) => (
                  <DialogForm
                    {...props}
                    dialogHeader="Create factor"
                    initialValues={initialFactorValues}
                    submitHandler={controller.addFactor}
                  >
                    <FactorForm />
                  </DialogForm>
                ),
                { testId: 'add-factor-dialog' },
              )
            }
          >
            Add factor
            <Icon icon="plus" className="ml-2" />
          </Button>
        )}

        <DialogClose className="ml-auto" />
      </DialogFooter>
    </DialogContent>
  );
}
