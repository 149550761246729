import Big from 'big.js';
import type { AmountFields, BuilderBlock, BuilderContext } from './types';
import { parseBig } from 'utilities';
import { NetLinkable } from '../types';

const initAmounts: AmountFields = {
  target: Big(0),
  invoiced: Big(0),
  invoiceable: Big(0),
};

function plus(f: AmountFields, target: Big, invoiced: Big, invoiceable: Big): void {
  f.target = f.target.plus(target);
  f.invoiced = f.invoiced.plus(invoiced);
  f.invoiceable = f.invoiceable.plus(invoiceable);
}

export function useBuilderContext(linkable: NetLinkable[]): BuilderContext {
  // group linkable into blocks

  const blockOrder: string[] = [];
  const blockMap: Record<string, BuilderBlock> = {};

  // month range
  let start;
  let end;

  for (let i = 0; i < linkable.length; i++) {
    const {
      id,
      block_id,
      state: { months },
    } = linkable[i];
    let block: BuilderBlock;

    if (block_id in blockMap) {
      block = blockMap[block_id];
    } else {
      blockOrder.push(block_id);

      block = {
        id: block_id,
        months: {},
        ...initAmounts,
      };
      blockMap[block_id] = block;
      // add block
    }

    for (let m = 0; m < months.length; m++) {
      const month = months[m];

      const target = parseBig(month.target);
      const invoiced = parseBig(month.invoiced);
      const invoiceable = target.minus(invoiced);

      let blockMonth;
      if (month.month in block.months) {
        blockMonth = block.months[month.month];
      } else {
        blockMonth = {
          amounts: {},
          ...initAmounts,
        };
        block.months[month.month] = blockMonth;
      }

      if (!invoiceable.eq(0)) {
        blockMonth.amounts[id] = {
          invoiceable,
          target,
          quantity: month.quantity,
        };
      }

      plus(blockMonth, target, invoiced, invoiceable);

      plus(block, target, invoiced, invoiceable);

      if (!start || start > month.month) {
        start = month.month;
      }
      if (!end || end < month.month) {
        end = month.month;
      }
    }
  }

  return {
    period:
      start && end
        ? {
            start,
            end,
          }
        : null,
    blocks: blockOrder.map((id) => blockMap[id]),
  };
}
