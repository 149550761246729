import { ReactElement, ReactNode } from 'react';

interface FieldsetProps {
  title?: ReactNode;
  children?: ReactNode;
  flush?: boolean;
  className?: string;
  testId?: string;
}

export function Fieldset({
  title,
  children,
  flush,
  className: providedClassName,
  testId,
}: FieldsetProps): ReactElement {
  const className = providedClassName || (flush ? 'mb-3 pb-2' : 'border mb-3 px-3 pb-2');
  return (
    <fieldset className={className} data-testid={testId}>
      {title && <legend className="w-auto pr-3">{title}</legend>}
      {children}
    </fieldset>
  );
}
