import { ReactElement } from 'react';
import { EntityActions, Field, Icon, useEntity } from 'components';
import { formatDistanceToNow, parseISO } from 'date-fns';
import type { Attachment } from 'types';
import type { Ticket } from 'modules/support/types';
import { AddCompany } from './AddCompany';
import { AddClient } from './AddClient';
import { AddDepartment } from './AddDepartment';
import { Header } from './Header';
import ticketActions from './ticketActions';

function Content(): ReactElement {
  const category = useEntity<Ticket>().item.category;

  switch (category) {
    case 'ADD_CLIENT':
      return <AddClient />;
    case 'ADD_COMPANY':
      return <AddCompany />;
    case 'ADD_DEPARTMENT':
      return <AddDepartment />;
    default:
      return <div>NOT IMPLEMENTED</div>;
  }
}

export function ViewTicket(): ReactElement {
  const { id, title } = useEntity<Ticket>().item;

  return (
    <>
      <Header />
      <div className="container px-0 ml-0">
        <div className="row">
          <Field name="author" label="Author" className="col-md-3">
            {({ first_name, last_name, email }) => (
              <span>
                {first_name} {last_name} (<a href={`mailto:${email}?subject=#${id} ${title}`}>{email}</a>)
              </span>
            )}
          </Field>
          <Field name="created_at" label="Created" className="col-md-3">
            {(created_at) => formatDistanceToNow(parseISO(created_at), { addSuffix: true })}
          </Field>
          <Field name="enterprise.company.title" label="Agency" className="col-md-3" />
        </div>
        <div className="row">
          <Field name="assignee.email" label="Assignee" className="col-md-3" />
          <Field name="closed_at" label="Closed" className="col-md-3">
            {(closed_at) => formatDistanceToNow(parseISO(closed_at), { addSuffix: true })}
          </Field>
          <Field name="canceled_at" label="Canceled" className="col-md-3">
            {(canceled_at) => formatDistanceToNow(parseISO(canceled_at), { addSuffix: true })}
          </Field>
        </div>
        <Content />
        <div className="row">
          <Field name="attachments" label="Attachments" className="col-md-12">
            {(attachments: Attachment[]) =>
              attachments.length === 0
                ? '—'
                : attachments.map(({ file, filename, id }: Attachment) => (
                    <a
                      key={id}
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="badge badge-light mr-1"
                    >
                      <Icon icon="file-arrow-down" /> {filename}
                    </a>
                  ))
            }
          </Field>
        </div>
        <EntityActions actions={ticketActions} />
      </div>
    </>
  );
}
