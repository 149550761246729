import { ReactElement } from 'react';
import { guaranteeTypeOptions } from 'configuration';
import { activityTypeOptions } from 'options';
import { TextField, SelectField, DateRangePickerField, CheckGroupField } from '@ff-it/form';
import {
  ActivityField,
  AgencyActivityTypesField,
  AllActivityTypesField,
  EnterpriseField,
  LookupOperatorField,
} from 'components';
import { Commissions } from './Commissions';
import { CompanyField } from 'modules/core/company/components';
import { DepartmentField } from 'modules/supplier/department/DepartmentField';

interface FormProps {
  global: boolean;
}
export function Form({ global }: FormProps): ReactElement {
  const ActivityTypeField = global ? AllActivityTypesField : AgencyActivityTypesField;
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="title" label="Title" required className="col-md-5" />
        <DateRangePickerField fromName="date_from" toName="date_to" label="Period" className="col-md-3" required />
        <SelectField name="type" label="Type" className="col-md-2" required simple options={guaranteeTypeOptions} />
      </div>
      <div className="form-row">
        <CompanyField name="partner" className="col-md-3" label="Partner" required includeBranches={true} />
      </div>
      <hr />
      <div className="form-row">
        <TextField
          name="comment"
          className="col-md-6"
          label="Terms & conditions"
          rows={2}
          autosize
          multiline
          help="General terms and conditions"
        />
      </div>
      <div className="row pt-2">
        <div className="col-6">
          <h5 className="border-bottom pb-1">Campaign</h5>
          {global && (
            <div className="form-row">
              <EnterpriseField name="agencies" label="Agencies" className="col" isMulti isClearable placeholder="All" />
            </div>
          )}
          <div className="form-row">
            <LookupOperatorField name="clients_op" className="col-4" />
            <CompanyField
              name="clients"
              label="Clients"
              className="col"
              isMulti
              isClearable
              placeholder="All"
              includeBranches={true}
            />
          </div>
          <div className="form-row">
            <LookupOperatorField name="activities_op" className="col-4" />
            <ActivityField name="activities" label="Activities" className="col" isMulti isClearable placeholder="All" />
          </div>
          <div className="form-row">
            <ActivityTypeField name="types" label="Types" className="col" isMulti isClearable placeholder="All" />
          </div>
        </div>
        <div className="col-6">
          <h5 className="border-bottom pb-1">Supplier</h5>
          <div className="form-row">
            <LookupOperatorField name="departments_op" className="col-4" />
            <DepartmentField
              name="departments"
              label="Departments"
              className="col"
              isMulti
              isClearable
              placeholder="All"
            />
          </div>
          <div className="form-row">
            <CompanyField
              name="providers"
              label="Providers"
              className="col"
              isMulti
              isClearable
              placeholder="All"
              includeBranches={true}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h5>Commission</h5>
          <Commissions />
        </div>
        <div className="col-6 pt-3">
          {!global && (
            <CheckGroupField
              name="managed_types"
              options={activityTypeOptions}
              inline
              label="Managed types"
              help="Make visible to managers of particular block type"
            />
          )}
        </div>
      </div>
    </div>
  );
}
