import { SelectField, TextField, useField } from '@ff-it/form';
import { Check } from '@ff-it/ui';
import { Fieldset, FormInlineError } from 'components';
import { resetPeriodOptions } from 'configuration';
import { ReactElement } from 'react';

export function CampaingSequenceFieldset(): ReactElement {
  const {
    input: { value, onChange },
  } = useField('campaign_sequence', { allowNull: true, subscription: { value: true } });

  const enabled = Boolean(value);

  return (
    <Fieldset title="Campaign sequence">
      <div className="form-row">
        <TextField name="campaign_block_format" label="Block code format" className="col-md-6" required />
        <div className="form-group ml-3 mb-0">
          <label htmlFor="campaign-sequence-toggle">Custom campaign sequence </label>
          <Check
            checked={enabled}
            onChange={() =>
              onChange(
                enabled
                  ? null
                  : {
                      format: '',
                      reset_period: null,
                    },
              )
            }
            variant="switch"
            label="Enable"
            id="campaign-sequence-toggle"
          />
        </div>
      </div>
      <FormInlineError name="campaign_sequence" />
      {enabled && (
        <div className="form-row">
          <TextField
            name="campaign_sequence.format"
            className="col-md-6 mb-0"
            placeholder="Format"
            help="{enterprise}-{code}-######"
            required
            size="sm"
          />
          <SelectField
            name="campaign_sequence.reset_period"
            placeholder="Reset period"
            className="col-md-6 mb-0"
            required
            options={resetPeriodOptions}
            simple
            size="sm"
          />
        </div>
      )}
    </Fieldset>
  );
}
