import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'clsx';
import { Badge, ClientTitle, Period, TabNav, useEntity } from 'components';
import { useHasPerms } from 'core/permissions';
import income from 'modules/invoicing/income/permissions';
import expense from 'modules/invoicing/expenses/permissions';
import { CampaignDetails } from 'modules/campaign/campaign/types';
import { fmtPeriodDistance } from 'utilities';

export function CampaignHeader(): ReactElement {
  const location = useParams();

  const plan = 'block_id' in location;

  const { item } = useEntity<CampaignDetails>();
  const {
    date_from,
    date_to,
    title,
    number,
    sums: { state },
    manager,
    brand,
  } = item;
  const [viewIncome, viewExpense] = useHasPerms(income.view, expense.view); // manage and change
  let nav = [
    {
      to: 'planning',
      children: 'Campaign',
    },
  ];

  if (viewIncome) {
    nav.push({
      to: 'income',
      children: 'Income',
    });
  }

  if (viewExpense) {
    nav.push({
      to: 'expense',
      children: 'Expense',
    });
  }

  nav = [
    ...nav,
    {
      to: 'finance',
      children: 'Finance',
    },
    {
      to: 'update',
      children: 'Update',
    },
    {
      to: 'history',
      children: 'History',
    },
  ];

  return (
    <div className={cx('container-fluid header header--campaign', { 'mb-0 bg-white': plan })}>
      <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-row align-items-center my-2">
          <h2 className="mt-0">
            <span>{title}</span>{' '}
            <span className="font-weight-bold px-1">
              <ClientTitle {...item} flat />
            </span>{' '}
            <span className="text-muted">
              <small>{number}</small>
            </span>
          </h2>
          <Badge state={state} dataTestId="campaign-state" className="ml-2" />
        </div>
        {plan && <TabNav className="ml-3 align-self-end" nav={nav} />}
        <dl className="ml-auto">
          <dt>
            <Period date_from={date_from} date_to={date_to} flat />
          </dt>
          <dd className="text-muted">{fmtPeriodDistance({ date_from, date_to })}</dd>
          <dt>managed by</dt>
          <dd>
            {manager.first_name} {manager.last_name.charAt(0)}
          </dd>
          {brand && (
            <>
              <dt>brand</dt>
              <dd>{brand?.title}</dd>
            </>
          )}
        </dl>
      </div>
      {!plan && <TabNav nav={nav} />}
    </div>
  );
}
