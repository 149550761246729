import * as groups from './ReportingGroups';
import raw from './Raw';
import pivot from './Pivot';
import { LocalRevenue } from 'modules/reporting/revenue';
import { LocalClient } from 'modules/reporting/client';
import { LocalProvider } from 'modules/reporting/provider';
import { LocalCampaign } from 'modules/reporting/campaign';
import { LocalType } from 'modules/reporting/type';
import { LocalActivity } from 'modules/reporting/activity';

export const route = {
  path: 'reporting/*',
  children: [
    groups.route,
    raw,
    pivot,
    {
      path: 'revenue',
      element: <LocalRevenue />,
    },
    {
      path: 'client/*',
      element: <LocalClient />,
    },
    {
      path: 'provider/*',
      element: <LocalProvider />,
    },
    {
      path: 'campaign/*',
      element: <LocalCampaign />,
    },
    {
      path: 'type/*',
      element: <LocalType />,
    },
    {
      path: 'activity/*',
      element: <LocalActivity />,
    },
  ],
};
