import { TableColumns } from '@ff-it/ui';
import { ModelScene, IndexScene } from 'components';
import { never } from 'core/permissions';

export interface Conflict extends Record<string, unknown> {
  id: number;
  identifier: string;
  message: string;
}

const model = {
  endpoint: 'invoicing/expense/conflicts',
  title: 'Conflict',
  pluralTitle: 'Synchronization Conflicts',
  entityTitle: (c: Conflict) => c.identifier,
};

const columns: TableColumns<Conflict> = [
  {
    content: 'Meta-invoice',
    key: 'identifier',
  },
  {
    content: 'Message',
    key: 'message',
  },
];

export const route = {
  path: 'conflicts',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: <IndexScene canCreate={never} columns={columns} />,
    },
  ],
};
