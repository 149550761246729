import { ReactElement } from 'react';
import { Th, Tr } from '@ff-it/ui';
import { useTable } from 'components/ControlledTable/context';

export function TotalsHeader(): ReactElement | null {
  const { columns, data } = useTable();
  if (data?.totals) {
    const totalColumns = columns.filter((c) => c.key in data.totals);
    const offset = columns.length - totalColumns.length;

    return (
      <Tr variant="light">
        <Th colSpan={offset} textAlign="end">
          Total:
        </Th>
        {totalColumns.map(({ key, cellProps, headerCellProps, render }, colIdx) => {
          const value = render ? render(data.totals, -1, offset + colIdx) : data.totals[key];
          return (
            <Th
              key={key}
              data-cellid={key}
              {...cellProps}
              {...headerCellProps}
              className={headerCellProps?.className || cellProps?.className}
            >
              {value as any}
            </Th>
          );
        })}
      </Tr>
    );
  }
  return null;
}
