import { useModel, ItemLoader, UpdateContainer, useEntity, withHasPerm, UserField, FormInline } from 'components';
import { Company } from 'types';
import { TextField } from '@ff-it/form';
import { ReactElement } from 'react';

export const permissions = {
  change: 'client.change_clientplanner',
};

const initialValues = {
  user: null,
  notes: '',
};

export const Planners = withHasPerm(function Planners(): ReactElement {
  const endpoint = useModel().endpoint;
  const entityId = useEntity<Company>().item.id;

  const url = `${endpoint}${entityId}/planners/`;
  return (
    <ItemLoader<any> url={url}>
      {({ setItem, item }) => (
        <UpdateContainer url={url} initialValues={item} updateItem={setItem} method="POST">
          <div className="container px-0 ml-0">
            <FormInline name="planners" title="Planners" initialValues={initialValues}>
              {(name) => (
                <div className="form-row">
                  <UserField
                    name={`${name}.user`}
                    placeholder="User"
                    className="col mb-0"
                    filter={{ plan_campaign_block: true }}
                  />
                  <TextField
                    name={`${name}.notes`}
                    placeholder="Note"
                    className="col mb-0"
                    help="Private notes for internal use"
                  />
                </div>
              )}
            </FormInline>
          </div>
        </UpdateContainer>
      )}
    </ItemLoader>
  );
}, permissions.change);
