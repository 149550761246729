import { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Form as FormHandler, useSubmitHandler } from '@ff-it/form';
import { Breadcrumb, Page, useEntity } from 'components';
import type { CVB } from 'modules/guarantee/types';
import { useAgencyVAT } from 'modules/core/useAgencyVAT';
import { useMembership } from 'services';
import type { Expense } from 'modules/invoicing/expenses/types';
import { DatePickerField, Submit, TextField } from '@ff-it/form';
import { AttachmentField, VatRateField } from 'components';
import { CurrencyRateField, InvoiceFields, TotalsFields } from 'routes/Agency/Invoices/Expense/Form/Fields';
import { useNumberValidator } from 'routes/Agency/Invoices/Expense/useNumberValidator';
import { CompanyField } from 'modules/core/company/components';

export function Form(): ReactElement {
  const validateNumberUnique = useNumberValidator();

  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <CompanyField name="partner" label="Provider" className="col-md-3" required includeBranches={true} />
        <TextField name="number" label="Number" className="col-md-3" required validate={validateNumberUnique} />
        <DatePickerField name="checkout_date" label="Invoice date" className="col-md-2" required />
        <DatePickerField name="payment_date" label="Payment date" className="col-md-2" required />
      </div>

      <div className="row">
        <div className="col-6">
          <TextField name="comment" label="Comment" multiline autosize rows={2} />
        </div>
        <div className="col-6">
          <InvoiceFields />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="form-row">
            <CurrencyRateField className="col-md-4" />
            <VatRateField name="vat_rate" label="VAT rate" className="col-md-4" required />
          </div>
          <TotalsFields />
          <div className="form-row">
            <AttachmentField name="attachments" label="Attachments" className="col-md-12" required />
          </div>
        </div>
        <div className="col-6"></div>
      </div>
      <Submit>Create</Submit>
    </div>
  );
}

/**
 * For CVB we don't use BonusLinker in builder, since invoice is issued externaly
 * and linker after (by different person)
 */
export function CreateExpense(): ReactElement {
  const {
    endpoint,
    item: { partner },
  } = useEntity<CVB>();
  const vat_rate = useAgencyVAT();

  const url = `${endpoint}builder/`;
  const { slug } = useMembership();
  const navigate = useNavigate();

  const onSubmit = useSubmitHandler(
    { method: 'POST', url },
    {
      onSuccess: ({ id }: Expense) => {
        toast.success(`Invoice has been created.`);
        navigate(`/${slug}/invoices/expense/${id}`);
      },
    },
  );

  const initialValues = {
    partner,
    state: 'DRAFT' as const,
    checkout_date: null,
    payment_date: null,
    comment: '',
    type: 'INVOICE' as const,
    // // @TODO enum
    currency: 'EUR',
    currency_rate: '1.00',
    vat_rate,
    attachments: [],
    alt_currency: null,
    alt_amount: null,
    alt_total: null,
    // sub_total: string;
    vat_amount: null,
    total: null,
    clients: [],
  };

  return (
    <Page className="scene flex-grow-1 d-flex flex-column">
      <Breadcrumb to={null}>Create</Breadcrumb>
      <FormHandler onSubmit={onSubmit} noValidate initialValues={initialValues}>
        <Form />
      </FormHandler>
    </Page>
  );
}
