import cx from 'clsx';
import { ReactElement } from 'react';
import { Sprinkles, sprinkles } from '@ff-it/ui';
import style from './style.css';
import { Icon, IconProp } from 'components';
import { InvoiceDirection } from '../../types';

type CheckState = 'success' | 'warn' | 'error';
type InvoiceCheckProps = {
  direction: InvoiceDirection;
  state: CheckState;
  testId?: string;
} & Sprinkles;

const iconMap: Record<CheckState, IconProp> = {
  success: 'circle-check',
  warn: 'triangle-exclamation',
  error: 'circle-exclamation',
};

export function InvoiceCheck({ direction, state, testId, ...sprinkes }: InvoiceCheckProps): ReactElement {
  return (
    <Icon
      data-testid={testId}
      data-direction={direction}
      data-state={state}
      icon={iconMap[state]}
      className={cx(style[direction][state], sprinkles({ ...sprinkes }))}
    />
  );
}
