export default {
  title: '',
  kind: 'company',
  country: window.__RUNTIME_CONFIG__?.DEFAULT_COUNTRY,
  code: null,
  registration_number: '',
  vat_number: '',
  legal_address: '',
  office_address: '',
  office_same_as_legal: false,
  invoice_template: window.__RUNTIME_CONFIG__?.DEFAULT_INVOICE_TEMPLATE,
  acceptance_act_template: window.__RUNTIME_CONFIG__?.DEFAULT_ACCEPTANCE_ACT_TEMPLATE,
  branches: [],
  accounts: [],
};

export const initialBranchValues = {
  internal_name: '',
  code: '',
};

export const initialAccountValues = {
  bank: null,
  account_number: '',
  primary: false,
};
