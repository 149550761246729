import { RenderCellProps } from 'components/DataGrid';
import { ReactNode } from 'react';
import { GridRow } from '../types';
import { Calendar } from './types';
import { container } from './style.css';
import { Scale } from './Scale';
import { Segments } from './Segments';
import invariant from 'tiny-invariant';
import { useRowSegments } from './useRowSegments';

export function View({
  row: { kind, positions },
  calendar,
}: RenderCellProps<GridRow, any> & { calendar: Calendar }): ReactNode {
  invariant(kind !== 'SMART');

  const segments = useRowSegments(positions, calendar.intervalToBounds);
  return (
    <div
      className={container}
      style={{ width: calendar.width }}
      onClick={(event) => {
        // capture cell onClick, we handle selection onMouseDonw
        event.stopPropagation();
      }}
    >
      <Scale {...calendar} />
      <Segments
        segments={segments}
        positions={positions}
        intervalToBounds={calendar.intervalToBounds}
        rowSelection={null}
        readonly
      />
    </div>
  );
}
