import Big from 'big.js';
import { parseBig } from 'utilities';
import { Invoices, Months } from './types';

export function alignInvoices(months: Months, invoices: Invoices): number[] {
  const monthAmounts = months
    .map((month) => ({
      target: parseBig(month.target),
      remainder: parseBig(month.target),
    }))
    .filter((m) => !m.target.eq(0));

  // const total = monthAmounts.reduce((acc, v) => acc.plus(v.target), Big(0));

  return invoices.map((invoice) => {
    let remainder = parseBig(invoice.amount);
    /*
     FIXME: negative invoices
     We just return negative ratios invoices get grouped
  */
    if (remainder.lt(0)) {
      return 0;
    }

    let monthRatio = Big(0);

    while (!remainder.eq(0)) {
      // overflow
      if (monthAmounts.length === 0) {
        // no positions for row but invoices bound
        return 1.0;
      }

      const month = monthAmounts[0];

      // assinged to month
      const assigned = month.remainder.lte(remainder) ? month.remainder : remainder;
      remainder = remainder.minus(assigned);

      monthRatio = monthRatio.plus(assigned.div(month.target));

      // proceed to next month if not last
      if (monthAmounts.length > 1) {
        const monthRemainder = month.remainder.minus(assigned);
        if (monthRemainder.eq(0)) {
          monthAmounts.shift();
        } else {
          monthAmounts[0].remainder = month.remainder.minus(assigned);
        }
      } else {
        break;
      }
    }

    return monthRatio.toNumber();
  });
}
