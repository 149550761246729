import { ReactElement, ReactNode } from 'react';
import { Form as FormHandler, Submit, useSubmitHandler } from '@ff-it/form';
import { Breadcrumb, Heading } from 'components';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import type { Ticket } from 'modules/support/types';

interface TicketFormProps {
  title: string;
  url: string;
  children: ReactNode;
  initialValues: Record<string, unknown>;
}

export function TicketForm({ title, url, children, initialValues }: TicketFormProps): ReactElement {
  const navigate = useNavigate();
  const submitHandler = useSubmitHandler(
    {
      method: 'POST',
      url,
    },
    {
      onSuccess: ({ id, enterprise: { slug } }: Ticket) => {
        toast.success('Your ticket has been submitted', { toastId: 'ticket-success' });
        navigate(`/${slug}/dashboard/tickets/${id}`);
      },
    },
  );
  return (
    <div>
      <Breadcrumb>{title}</Breadcrumb>
      <Heading title={title} />
      <FormHandler onSubmit={submitHandler} initialValues={initialValues} className="container px-0 ml-0" noValidate>
        {children}
        <Submit>Submit ticket</Submit>
      </FormHandler>
    </div>
  );
}
