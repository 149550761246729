import { ReactElement } from 'react';
import { RouterProvider } from 'react-router-dom';
import { APIProvider } from '@ff-it/api';
import { api, router, swrOptions } from 'services';
import { SWRConfig } from 'swr/_internal';

function App(): ReactElement {
  return (
    <SWRConfig value={swrOptions}>
      <APIProvider client={api}>
        <RouterProvider router={router} />
      </APIProvider>
    </SWRConfig>
  );
}

export default App;
