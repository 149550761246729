import { ReactElement } from 'react';
import { FormSpy } from 'react-final-form';
import { fmt } from 'utilities';
import { calculateTotals, InvoiceLike } from 'modules/invoicing/income/calculate';
import { Td, Tfoot, Th, Tr } from '@ff-it/ui';

function Totals({ preview = false }: { preview?: boolean }): ReactElement {
  const colSpan = preview ? 5 : 6;
  return (
    <FormSpy<InvoiceLike> subscription={{ values: true }}>
      {(formProps) => {
        const { totalInvoiced, vatRate, vatAmount, totalFinal } = calculateTotals(formProps.values);
        return (
          <Tfoot>
            <Tr>
              <Td colSpan={colSpan} className="text-right">
                Sub-total
              </Td>
              <Td className="text-right" data-testid="sub-total">
                {fmt(totalInvoiced)}
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td colSpan={colSpan} className="text-right">
                VAT {vatRate}%
              </Td>
              <Td className="text-right" data-testid="vat-amount">
                {fmt(vatAmount)}
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Th colSpan={colSpan} className="text-right">
                Total
              </Th>
              <Th className="text-right" data-testid="total">
                {fmt(totalFinal)}
              </Th>
              <Td />
            </Tr>
          </Tfoot>
        );
      }}
    </FormSpy>
  );
}

export default Totals;
