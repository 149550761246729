import { autoFocusAndSelect, RenderEditCellProps } from 'components/DataGrid';
import { GridRow, GridSummaryRow } from '../../types';
import { ReactNode, useRef, FocusEvent, KeyboardEvent } from 'react';
import { Box } from '@ff-it/ui';
import cx from 'clsx';
import { editorContainer, editorInput } from './style.css';
import invariant from 'tiny-invariant';
import { mergeRefs, parseDecimalString } from 'utilities';

export function renderEdit(props: RenderEditCellProps<GridRow, GridSummaryRow>): ReactNode {
  const { row, onClose, onRowChange } = props;

  const { direction, rate } = row;
  invariant(direction);
  const clientInputRef = useRef<HTMLInputElement>(null);
  const providerInputRef = useRef<HTMLInputElement>(null);

  function commit(client: string | null, provider: string | null): void {
    if (rate.client !== client || rate.provider !== provider) {
      onRowChange({ ...row, rate: { client, provider } }, true);
    }
    onClose(false);
  }

  function onKeyDown(event: KeyboardEvent<any>): void {
    if (event.key === 'Escape') {
      event.stopPropagation();
      onClose(false);
    } else if (event.key === 'Enter' || event.key === 'Tab') {
      if (direction === 'BOTH' && event.target === clientInputRef.current) {
        event.preventDefault();
        event.stopPropagation();
        autoFocusAndSelect(providerInputRef.current);
        return;
      } else {
        commit(
          parseDecimalString(clientInputRef.current?.value || null, 5, true),
          parseDecimalString(providerInputRef.current?.value || null, 5, true),
        );
      }
    }
  }

  function onBlur(event: FocusEvent): void {
    const currentTarget = event.currentTarget;

    const client = parseDecimalString(clientInputRef.current?.value || null, 5, true);
    const provider = parseDecimalString(providerInputRef.current?.value || null, 5, true);

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        commit(client, provider);
      }
    });
  }

  return (
    <Box className={cx(editorContainer, { both: direction === 'BOTH' })} onBlur={onBlur} onKeyDown={onKeyDown}>
      {direction !== 'AGENCY' && (
        <input
          ref={mergeRefs([clientInputRef, autoFocusAndSelect])}
          className={cx(editorInput, 'client')}
          defaultValue={rate.client || ''}
        />
      )}
      {direction !== 'CLIENT' && (
        <input
          ref={mergeRefs([providerInputRef, direction === 'AGENCY' ? autoFocusAndSelect : null])}
          className={cx(editorInput, 'provider')}
          defaultValue={rate.provider || ''}
        />
      )}
    </Box>
  );
}
