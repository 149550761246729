export function formatValue(options: any[], val: any, isMulti: boolean, getOptionValue: (a: any) => string): any {
  if (!val) {
    return null;
  }

  if (isMulti) {
    return options.filter((o) => val.includes(getOptionValue(o)));
  } else {
    return options.find((o) => getOptionValue(o) === val);
  }
}

export function parseValue(val: any, isMulti: any, getOptionValue: (a: any) => string): any {
  if (!val || (isMulti && val.length === 0)) {
    return null;
  }
  return isMulti ? val.map(getOptionValue) : getOptionValue(val);
}
