import { ReactElement } from 'react';
import { Field } from 'react-final-form';
import { Box, DialogBody, DialogClose, DialogFooter, DialogHeader } from '@ff-it/ui';
import { CheckField, Form, Submit, TextField } from '@ff-it/form';
import type { BlockDetails } from '../types';

import { BriefFields } from './BriefForm';
import { sticky } from './style.css';
import { SubmissionErrors } from 'final-form';
import type { CampaignDetails } from 'modules/campaign/campaign/types';

export type Action = 'reject' | 'replan';

const TITLES: Record<Action, string> = {
  reject: 'Reject plan',
  replan: 'Replan',
};

interface MaybeUpdateBlockDialogProps {
  block: BlockDetails;
  campaign: CampaignDetails;
  action: Action;
  submitHandler: (values: any) => Promise<SubmissionErrors | void>;
}

// FIXME: DialogForm (sticky nonsense?)
export function MaybeUpdateBlockDialog({
  block,
  campaign,
  action,
  submitHandler,
}: MaybeUpdateBlockDialogProps): ReactElement {
  return (
    <Form
      onSubmit={submitHandler}
      noValidate
      initialValues={block}
      style={{
        position: 'relative',
      }}
    >
      <DialogHeader title={TITLES[action]} />
      <DialogBody>
        <TextField name="message" label="Motivation" multiline autosize required />
        <Field name="update_brief" subscription={{ value: true }}>
          {({ input: { value } }) => (value ? <BriefFields type={block.type} campaign={campaign} /> : null)}
        </Field>
      </DialogBody>
      <Box className={sticky}>
        <DialogFooter>
          <Submit>Update</Submit>
          <Box marginLeft="md">
            <CheckField name="update_brief" fieldLabel="Update brief" variant="switch" />
          </Box>

          <DialogClose className="ml-auto" />
        </DialogFooter>
      </Box>
    </Form>
  );
}
