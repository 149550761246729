import { useState } from 'react';
import { openModal } from '@ff-it/ui';
import type { RenderCellProps } from 'components/DataGrid';
import type { Calendar, Segment, SelectionState } from './types';
import type { GridRow } from '../types';
import invariant from 'tiny-invariant';
import { calcRowSelection, getPositionError, getSegmentMouseHit, shiftInterval } from './calc';
import { PlanController, usePlanController } from '../../usePlanController';

import { DialogForm } from 'components';
import { goalSeekProps } from '../../goalSeekProps';
import { PositionForm } from './PositionForm';
import { differenceInDays, formatISO, max, min } from 'date-fns';
import { useRowSegments } from './useRowSegments';
import type { PlanRow } from 'modules/campaign/row';

type UseMouseDownHandlerProps = RenderCellProps<GridRow, any> & { calendar: Calendar };

// dialog form
// createEmpty, createFromSegemt, update

async function formDialog(controller: PlanController, row: PlanRow, payload: any): Promise<void> {
  const id: number | undefined = payload.id;

  // update
  if (id) {
    const url = `plan/rows/${row.id}/positions/${id}/`;
    await openModal(
      (props) => (
        <DialogForm
          dialogHeader="Update position"
          {...props}
          initialValues={payload}
          submitHandler={async (values) => controller.formRequest({ method: 'PUT', url, body: values })}
          onRemove={async () => {
            controller.formRequest({ method: 'DELETE', url });
          }}
        >
          <PositionForm goalSeek={goalSeekProps(row, payload.quantity)} />
        </DialogForm>
      ),
      { testId: 'position-edit' },
    );
  } else {
    await openModal(
      (props) => (
        <DialogForm
          dialogHeader="Create position"
          {...props}
          initialValues={payload}
          submitHandler={async (values) =>
            controller.formRequest({
              method: 'POST',
              url: `plan/rows/${row.id}/positions/`,
              body: values,
            })
          }
        >
          <PositionForm goalSeek={goalSeekProps(row)} />
        </DialogForm>
      ),
      { testId: 'position-create' },
    );
  }
}

export function useMouseDownHandler(props: UseMouseDownHandlerProps): {
  selection: SelectionState;
  handleMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void;
  segments: Segment[];
} {
  const {
    column: { key },
    row,
    calendar: { pixelToDate, intervalToBounds, interval },
  } = props;
  const { id, positions, kind } = row;
  invariant(kind !== 'SMART');
  const segments = useRowSegments(positions, intervalToBounds);
  const [selection, setSelection] = useState<SelectionState>(null);

  const controller = usePlanController();

  function handleMouseDown(event: React.MouseEvent<HTMLDivElement>): void {
    // keep the focus on the cell

    if (event.buttons !== 1) return;
    controller.gridRef.current?.selectCell(row._key, key, false, false);
    event.stopPropagation();
    event.preventDefault();

    // FIXME: create new reagardles of click,
    if (event.shiftKey) {
      formDialog(controller, row, {});
      return;
    }

    const rowLeft = event.currentTarget.getBoundingClientRect().left;
    const x = event.clientX - rowLeft;
    const day = pixelToDate(x);

    const segmentHit = getSegmentMouseHit(x, segments);

    let error = getPositionError(segments, { start: day, end: day }, segmentHit ? segmentHit[0].id : undefined);
    let delta = 0;
    let start = day;
    let end = day;

    if (segmentHit) {
      const [segment, handle] = segmentHit;
      setSelection({ type: 'segment', segmentId: segment.id, error, handle, delta });
    } else {
      setSelection({ type: 'selection', start, end, error });
    }

    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);

    let didMove = false;
    async function onMouseUp(event: MouseEvent): Promise<void> {
      event.preventDefault();
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);

      if (error) {
        didMove = false;
        setSelection(null);
        return;
      }
      // update or move
      if (segmentHit) {
        const [segment, handle] = segmentHit;
        const url = `plan/rows/${id}/positions/${segment.id}/`;
        if (didMove) {
          didMove = false;
          const selection = calcRowSelection(
            positions,
            {
              type: 'segment',
              segmentId: segment.id,
              error,
              handle,
              delta,
            },
            intervalToBounds,
          );
          invariant(selection);
          await controller.formRequest({ method: 'PUT', url, body: selection });
        } else {
          await formDialog(controller, row, segment);
        }
      } else {
        // create
        await formDialog(controller, row, {
          quantity: null,
          date_from: formatISO(start, { representation: 'date' }),
          date_to: formatISO(end, { representation: 'date' }),
        });
      }
      setSelection(null);
    }

    function onMouseMove(event: MouseEvent): void {
      const newX = event.clientX - rowLeft;
      const newDay = pixelToDate(newX);

      if (segmentHit) {
        const newDelta = differenceInDays(newDay, day);
        if (newDelta !== delta) {
          const [segment, handle] = segmentHit;

          const newInterval = shiftInterval({ start: segment.start, end: segment.end }, handle, newDelta);
          // calendar bounds
          if (newInterval.start < interval.start || newInterval.end > interval.end) {
            return;
          }

          didMove = true;
          delta = newDelta;
          error = getPositionError(segments, newInterval, segment.id);
          setSelection({
            type: 'segment',
            segmentId: segment.id,
            error,
            handle,
            delta,
          });
        }
      } else {
        const newStart = min([day, newDay]);
        const newEnd = max([day, newDay]);
        if (newStart.getTime() !== start.getTime() || newEnd.getTime() !== end.getTime()) {
          didMove = true;
          start = newStart;
          end = newEnd;
          error = getPositionError(segments, { start, end });
          setSelection({ type: 'selection', error, start, end });
        }
      }
    }
  }
  return {
    selection,
    handleMouseDown,
    segments,
  };
}
