import { useMemo, type ReactElement } from 'react';
import type { EmbeddedDepartment } from './types';
import { Fieldset } from 'components';
import { EmbeddedProduct, ProductTitle } from '../products';
import useSWR, { SWRResponse } from 'swr';
import { DateRangePickerField, DecimalField } from '@ff-it/form';
import { Box } from '@ff-it/ui';
import { ActivityType } from 'types';

export type ProductPrice = {
  id: number;
  product: EmbeddedProduct;
  gross_price: string;
};

/*
    this is a bit awkward since we want to group by departments
    and render all available products and final-form turns numeric keys into lists
    { prices: Record<ProductId(`_${id}`), Price(string)> }
*/

function MediaFieldset({ media }: { media: EmbeddedDepartment }): ReactElement {
  const { data: products }: SWRResponse<EmbeddedProduct[], any, { suspense: true }> = useSWR(
    {
      url: `supplier/products/`,
      method: 'GET',
      queryParams: {
        department: media.id,
      },
    },
    { suspense: true },
  );

  const groupedProducts: Record<ActivityType, EmbeddedProduct[]> = useMemo(() => {
    const res: any = {};
    for (const prod of products || []) {
      if (!(prod.type in res)) {
        res[prod.type] = [];
      }
      res[prod.type].push(prod);
    }
    return res;
  }, [products]);

  return (
    <Fieldset key={media.id} title={media.name} flush>
      {Object.keys(groupedProducts).map((type) => (
        <Box key={type} marginBottom="md" padding="md" border="default">
          <Box fontWeight="bold">{type}</Box>
          {groupedProducts[type as ActivityType].map((product) => (
            <Box key={product.id} display="flex">
              <Box flexGrow={1}>
                <ProductTitle product={product} />
              </Box>
              <Box marginRight="auto">
                <DecimalField
                  name={`prices._${product.id}`}
                  className="mb-2 text-right"
                  size="sm"
                  precision={16}
                  autoComplete="off"
                />
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Fieldset>
  );
}

type PriceListFormProps = {
  channels: EmbeddedDepartment[];
};

export function PriceListForm({ channels }: PriceListFormProps): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <DateRangePickerField label="Period" fromName="date_from" toName="date_to" className="col-md-3" required />
      </div>
      <Box maxWidth="sm">
        {channels.map((media) => (
          <MediaFieldset media={media} key={media.id} />
        ))}
      </Box>
    </div>
  );
}
