import { ReactElement } from 'react';
import { useField } from 'react-final-form';
import { EmbeddedCompany } from 'types';
import { Alert } from '@ff-it/ui';
import { optionLabel, invoiceTemplateOptions, acceptanceActTemplateOptions } from 'options';

export function TemplateNotice(): ReactElement | null {
  const {
    input: { value: payer },
  } = useField<EmbeddedCompany | null>('payer', { subscription: { value: true } });
  if (payer) {
    return (
      <Alert variant="secondary" className="border-0 px-2 py-1 mb-1">
        <small>
          Invoice template: <strong>{optionLabel(invoiceTemplateOptions, payer.invoice_template)}</strong>, Acceptance
          act template: <strong>{optionLabel(acceptanceActTemplateOptions, payer.acceptance_act_template)}</strong>
        </small>
      </Alert>
    );
  }
  return null;
}
