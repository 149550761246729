import { RouteObject } from 'react-router';
import { Form } from './Form';
import { ModelScene, CreateScene, UpdateScene, EntityScene, DeleteScene, EntityNav, IndexScene } from 'components';
import type { AVB, ListBonus } from 'modules/guarantee/types';

import { columns, initialValues } from 'modules/guarantee/common/bonus';
import permissions from 'modules/guarantee/avb/permissions';
import { Income } from './Income';
import { Filter } from 'modules/guarantee';

const model = {
  endpoint: 'guarantee/avb/',
  title: 'AVB',
  pluralTitle: 'AVB',
  entityTitle: (g: ListBonus) => g.number,
  permissions,
};

function copy(avb: AVB): any {
  return {
    ...avb,
    id: undefined,
    date_from: null,
    date_to: null,
    number: '',
    attachments: [],
    rates: avb.rates.map((rate) => ({ ...rate, id: undefined })),
  };
}

export const route: RouteObject = {
  path: 'avb',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: <IndexScene columns={columns} storageKey="guarantee_avb" filterFields={<Filter />} />,
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <EntityNav
            nav={[
              {
                to: '',
                end: true,
                children: 'Details',
              },
              {
                to: 'income',
                children: 'Income',
                perm: permissions.invoice,
              },
            ]}
          />
        </EntityScene>
      ),

      children: [
        {
          index: true,
          element: (
            <UpdateScene viewDisabled copy={copy}>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
        {
          path: 'income/*',
          element: <Income />,
        },
      ],
    },
  ],
};
