import { ReactElement } from 'react';

import type { PlanFactor } from 'modules/campaign/block/types';
import type { DepartmentFactor } from 'modules/supplier/factors';
import { Box, Row, Col } from '@ff-it/ui';
import { nl2br } from 'utilities';

type FactorOptionProps = {
  factor: PlanFactor | DepartmentFactor;
};

export function FactorOption({ factor: { name, rate, description } }: FactorOptionProps): ReactElement {
  return (
    <Box flexGrow={1}>
      <Row>
        <Col marginRight="md" paddingX="sm" data-testid="factor-name">
          {name}
        </Col>
        <Col marginLeft="auto" paddingX="sm" width="content">
          {rate}%
        </Col>
      </Row>
      <Box paddingX="sm" color="quarterary" fontSize="xs">
        {nl2br(description)}
      </Box>
    </Box>
  );
}
