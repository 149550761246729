import { ReactElement, ReactNode, useState, useId } from 'react';
import { useField } from 'react-final-form';
import { getControlClassName, Size } from '@ff-it/ui';
import { FormGroup, useIsDisabled, fieldSubscription } from '@ff-it/form';
import { FieldValidator } from 'final-form';
import invariant from 'tiny-invariant';
import cx from 'clsx';
import type { ActivityType, ProductKind } from 'types';
import { SupplierSelector, SupplierSelectordProps } from '../SupplierSelector';
import type { Supplier } from '../../types';
import { anyPlaceholderStyle, controlStyle } from './style.css';
import { SupplierTitle } from '../SupplierTitle';

type SupplierFieldProps = {
  name: string;
  type: ActivityType;
  kind?: ProductKind | ProductKind[];
  id?: string;
  label?: ReactNode;
  className?: string;
  disabled?: boolean;
  size?: Size;
  help?: ReactNode;
  placeholder?: string;
  validate?: FieldValidator<Supplier | null>;
} & Omit<SupplierSelectordProps, 'value' | 'onChange' | 'onRequestClose'>;

const anyPlaceholder = <span className={anyPlaceholderStyle}>Any</span>;

export function SupplierField({
  name,
  className,
  label,
  help,
  placeholder = 'Select supplier...',
  size,
  validate,
  id: outerId,
  disabled: outerDisabled,
  ...props
}: SupplierFieldProps): ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const _id = useId();
  const id = outerId || _id;
  const disabled = useIsDisabled(outerDisabled || !props.type);

  const {
    input: { value, onChange },
    meta,
  } = useField<Supplier | null, any>(name, {
    subscription: fieldSubscription,
    validate,
    allowNull: true,
  });

  // disabled, empty, focused, hocer
  let selector = null;
  if (isOpen) {
    invariant(props.type);
    selector = (
      <SupplierSelector
        onRequestClose={() => {
          setIsOpen(false);
        }}
        onChange={(v: Supplier | null) => {
          onChange(v);
          setIsOpen(false);
        }}
        value={value}
        {...props}
      />
    );
  }

  const content = value ? <SupplierTitle placeholder={anyPlaceholder} {...value} /> : placeholder;

  return (
    <FormGroup className={className} label={label} id={id} help={help} meta={meta} required={props.required}>
      <div
        id={id}
        className={getControlClassName(
          size,
          cx(controlStyle, 'form-control-supplier', {
            disabled,
            open: isOpen,
            empty: !value,
          }),
          meta.invalid && meta.touched ? false : undefined,
        )}
        onClick={
          !isOpen && !disabled
            ? () => {
                setIsOpen(true);
              }
            : undefined
        }
      >
        {selector}
        {content}
        {/* selectable by tests */}
        <input type="hidden" name={name} />
      </div>
    </FormGroup>
  );
}
