import { formatISO } from 'date-fns';
import { lazy, ReactElement } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

export const LazyCalendar = lazy(() => import('./Calendar'));

export function Calendar(): ReactElement {
  const today = new Date();
  return (
    <Routes>
      <Route path={`:date`} element={<LazyCalendar />} />
      <Route path="*" element={<Navigate to={`${formatISO(today, { representation: 'date' })}`} />} />
    </Routes>
  );
}
