import { useMemo } from 'react';
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  parseISO,
  max,
  min,
  endOfWeek,
  differenceInDays,
  eachMonthOfInterval,
  endOfMonth,
} from 'date-fns';
import type { PlanResolution } from 'modules/campaign/block/types';
import type { Bounds, Calendar, CalendarColumn } from './types';
import { calculateBounds } from './calc';

type UseCalendarArgs = {
  date_from: string;
  date_to: string;
  plan_resolution: PlanResolution;
};

const DAY_WIDTH: Record<PlanResolution, number> = {
  DAY: 28,
  WEEK: 16,
  MONTH: 12,
};

export function useCalendar({ date_from, date_to, plan_resolution }: UseCalendarArgs): Calendar {
  return useMemo(() => {
    const dayWidth = DAY_WIDTH[plan_resolution];
    const interval = {
      start: parseISO(date_from),
      end: parseISO(date_to),
    };

    const days: CalendarColumn[] = calculateBounds(
      eachDayOfInterval(interval).map((date) => {
        return {
          start: date,
          end: date,
          width: dayWidth,
        };
      }),
    );

    const weeks: CalendarColumn[] = calculateBounds(
      eachWeekOfInterval(interval, { weekStartsOn: 1 }).map((date) => {
        const start = max([interval.start, date]);
        const end = min([interval.end, endOfWeek(date, { weekStartsOn: 1 })]);
        const lengthInDays = differenceInDays(end, start) + 1;
        const width = lengthInDays * dayWidth + (lengthInDays - 1);
        return {
          start,
          end,
          width,
        };
      }),
    );

    const months: CalendarColumn[] = calculateBounds(
      eachMonthOfInterval(interval).map((date) => {
        const start = max([interval.start, date]);
        const end = min([interval.end, endOfMonth(date)]);
        const lengthInDays = differenceInDays(end, start) + 1;
        const width = lengthInDays * dayWidth + (lengthInDays - 1);
        return {
          start,
          end,
          width,
        };
      }),
    );

    const numDays = days.length - 1;

    function pixelToDate(x: number): Date {
      let dayIndex = Math.floor(x / (dayWidth + 1));
      if (dayIndex < 0) {
        dayIndex = 0;
      } else if (dayIndex > numDays) {
        dayIndex = numDays;
      }

      return days[dayIndex].start;
    }

    function intervalToBounds({ start, end }: Interval): Bounds {
      const left = differenceInDays(start, interval.start) * (dayWidth + 1);
      const width = (differenceInDays(end, start) + 1) * (dayWidth + 1);
      const right = left + width;
      return {
        left,
        width,
        right,
      };
    }

    return {
      // why do we care?
      resolution: plan_resolution,
      dayWidth,
      interval,
      days,
      weeks,
      months,
      width: days[numDays].right,
      pixelToDate,
      intervalToBounds,
    };
  }, [date_from, date_to, plan_resolution]);
}
