export default {
  client: null,
  payer: null,
  number: '',
  transparency: 'PLANNED',
  client_po_number: null,
  date_from: null,
  date_to: null,
  terms: '',
  type_terms: [],
  smart_rates: [],
  rates: [],
  signature_persons: [],
  client_discount: null,
  discounted_departments: [],
};
