import { ReactElement } from 'react';
import { Dialog, DialogContent } from '@ff-it/ui';
import type { IncompleteSupplier, Supplier } from '../../types';
import { Form as FormHandler } from '@ff-it/form';
import { Form } from './Form';
import { SupplierSelectordProps, isComplete } from './types';
import { initialState } from './const';
export type { SupplierSelectordProps };

export function SupplierSelector({ value, onChange, onRequestClose, ...rest }: SupplierSelectordProps): ReactElement {
  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        !open && onRequestClose();
      }}
    >
      <DialogContent testId="supplier-selector">
        <FormHandler<Supplier | IncompleteSupplier>
          initialValues={value || initialState}
          onSubmit={(value) => {
            if (isComplete(value) || rest.incomplete) {
              onChange(value as any);
            } else {
              onChange(null);
            }
          }}
        >
          <Form {...rest} />
        </FormHandler>
      </DialogContent>
    </Dialog>
  );
}
