import type { EmbeddedUser } from 'modules/core/types';
import { ReactElement } from 'react';
import { TitleContainer } from './TitleContainer';

export function UserTitle({ email, first_name, last_name, flat }: EmbeddedUser & { flat?: boolean }): ReactElement {
  return (
    <TitleContainer
      title={email}
      subtitle={first_name || last_name ? `${first_name} ${last_name}` : undefined}
      flat={flat}
    />
  );
}
