import { ActivityType, ProductKind } from 'types';
import { Supplier, IncompleteSupplier } from '../../types';
import { SubmissionErrors } from 'final-form';

export type Precedence = 'PRODUCT' | 'PROVIDER';

export type FormProps = {
  // filter fields
  type: ActivityType;
  kind?: ProductKind | ProductKind[];
  department?: number;
  // coearces empty to null
  nullable?: boolean; // even when field is not nullable it might be initialized to null
  // can select incomplete values
  incomplete?: boolean;
  // requires no empty Value
  required?: boolean;
};

export type FieldsProps = Omit<FormProps, 'incomplete' | 'kind'> & {
  kind: ProductKind;
};

type OnChangeHandler<V> = (value: V | null) => Promise<SubmissionErrors | void> | SubmissionErrors | void;

export type SupplierSelectordProps = FormProps & {
  value: Supplier | IncompleteSupplier | null;
  onChange: OnChangeHandler<Supplier> | OnChangeHandler<IncompleteSupplier>;
  onRequestClose: () => void;
};

export function isComplete(value: Supplier | IncompleteSupplier): value is Supplier {
  return value.product != null && value.provider !== null && value.activity !== null;
}
