import { ComponentType, ReactElement, useMemo } from 'react';
import { timestamp } from 'components/columns';
import { Entry } from './types';
import { IndexContainer } from 'components';
import { TableColumns } from '@ff-it/ui';

interface LogProps<T> {
  url: string;
  Description: ComponentType<T>;
}
export function Log<T extends Entry<any, any>>({ url, Description }: LogProps<T>): ReactElement {
  const columns: TableColumns<T> = useMemo(
    () => [
      { content: 'User', key: 'actor_repr', width: 200 },
      { content: 'Action', key: 'action', width: 200 },
      {
        content: 'Description',
        key: 'context',
        render: (entry: T) => <Description {...entry} />,
      },
      // FIXME: what is going on in here?
      timestamp<T>('Time', 'created_at' as any, { sortable: true }),
    ],
    [Description],
  );

  return <IndexContainer url={url} columns={columns} breadCrumb="History log" filterFields={null} />;
}
