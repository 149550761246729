import { activityTypeOptions, Options } from 'options';
import { useSession } from 'services';
import invariant from 'tiny-invariant';
import { ActivityType } from 'types';

export function useScopedActivityTypeOptions(global: boolean): Options<ActivityType> {
  const sess = useSession();
  if (global) {
    return activityTypeOptions;
  }
  invariant(sess.scope?.kind === 'AGENCY', 'Membership has to be present');

  const { planning_config } = sess.scope;
  return activityTypeOptions.filter(({ value }) => planning_config[value].enabled);
}
