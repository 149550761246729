import { Row } from './types';
import { sumColumns } from '../common';
import { TableColumns } from '@ff-it/ui';

export const columns: TableColumns<Row> = [
  {
    content: 'Agency',
    key: 'enterprise_slug',
    width: 80,
  },
  {
    content: 'Provider grp.',
    key: 'provider_group_title',
    sortable: true,
  },
  {
    content: 'Provider',
    key: 'provider_internal_name',
    sortable: true,
  },
  {
    content: 'Department',
    key: 'department_name',
    sortable: true,
  },
  ...sumColumns,
];
