import { ReactElement } from 'react';
import { SelectField } from '@ff-it/form';
import { paymentStateOptions } from './options';
import { applicationStateOptions } from 'core/types';

const bookedOptions = [
  {
    value: 'true',
    label: 'Booked',
  },
  {
    value: 'false',
    label: 'Not booked',
  },
];

const linkedOptions = [
  {
    value: 'true',
    label: 'Linked',
  },
  {
    value: 'false',
    label: 'Not linked',
  },
];

export function StateFilters(): ReactElement {
  return (
    <>
      <SelectField
        name="payment_state"
        placeholder="Payment"
        options={paymentStateOptions}
        isSearchable={false}
        className="ml-2 border-left pl-2"
        size="sm"
        simple
      />
      <SelectField
        name="booked"
        placeholder="Is booked"
        options={bookedOptions}
        isSearchable={false}
        className="ml-1"
        size="sm"
        simple
      />
      <SelectField
        name="linked"
        placeholder="Is linked"
        options={linkedOptions}
        isSearchable={false}
        className="ml-1"
        size="sm"
        simple
      />
      <SelectField
        name="sums__link_state"
        placeholder="Links"
        isSearchable={false}
        className="ml-1"
        simple
        isMulti
        size="sm"
        options={applicationStateOptions}
      />
    </>
  );
}
