import { Page } from 'components';
import { TextField, Form, Submit, useSubmitHandler } from '@ff-it/form';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { useSession, auth } from 'services';
import type { SessionUser } from 'modules/core/types';
import { ReactElement } from 'react';

export function Update(): ReactElement {
  const { user, impersonation } = useSession();
  const u = impersonation || user;
  invariant(u);

  const onSubmit = useSubmitHandler(
    {
      url: 'profile/',
      method: 'POST',
    },
    {
      onSuccess: (res: SessionUser) => {
        toast.success('Profile has been updated!');
        if (impersonation) {
          auth.impersonation = { ...impersonation, ...res };
        } else {
          auth.user = { ...user, ...res };
        }
      },
    },
  );
  return (
    <Page>
      <Form onSubmit={onSubmit} className="container px-0 ml-0" initialValues={u}>
        <div className="form-row">
          <TextField name="email" label="E-mail" className="col-md-6" type="email" disabled />
        </div>
        <div className="form-row">
          <TextField name="first_name" label="First name" className="col-md-3" required />
          <TextField name="last_name" label="Last name" className="col-md-3" required />
        </div>
        <Submit />
      </Form>
    </Page>
  );
}
