import { Action, ActionDropdown } from 'components';
import { ReactNode } from 'react';
import { PlanController } from '../usePlanController';

export function AddRowButton({ controller }: { controller: PlanController }): ReactNode {
  if (controller.locked) {
    return null;
  }

  const actions: (Action | '-')[] = [
    {
      action: 'add-media',
      button: {
        icon: 'rectangle-ad',
        children: 'Media',
        onClick: () => controller.addRow('MEDIA'),
        disabled: !controller.planning,
      },
    },
    {
      action: 'add-service',
      button: {
        icon: 'screwdriver-wrench',
        children: 'Service',
        onClick: () => controller.addRow('SERVICE'),
        disabled: !controller.planning,
      },
    },
    {
      action: 'add-smart',
      button: {
        icon: 'wand-magic-sparkles',
        children: 'Smart service',
        onClick: () => controller.addRow('SMART'),
        disabled: !controller.planning,
      },
    },
    '-',
    {
      action: 'add-group',
      button: {
        icon: 'folder',
        children: 'Add group',
        onClick: () => controller.addGroup(),
        disabled: false, // can add row unless locked
      },
    },
  ];
  return (
    <ActionDropdown actions={actions} variant="primary" className="ml-3" size="sm" icon="plus" testId="add-row">
      Add row
    </ActionDropdown>
  );
}
