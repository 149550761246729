import { ReactElement } from 'react';
import { useFilterFields } from '../filter';
import { EntityMap } from '../types';
import { RowContainer } from './RowContainer';
import { filterRow } from './style.css';
import { BonusSummaryState } from './types';
import { Box } from '@ff-it/ui';
import { AddButton, RemoveButton } from 'components';

type FilterRowProps = {
  summary: BonusSummaryState;
  entities: EntityMap;
  clients?: string[];
  link?: () => void;
  unlink?: () => void;
};

export function FilterRow({ summary, entities, clients, link, unlink }: FilterRowProps): ReactElement {
  const { client, department, provider, block } = useFilterFields(entities, clients);
  return (
    <RowContainer
      testId="filter-row"
      className={filterRow}
      client={client}
      product={department}
      provider={provider}
      block={block}
      balance={<Box textSize="xl">{summary.available.toFixed(2)}</Box>}
      balanceControl={link ? <AddButton icon="circle-plus" onClick={link} /> : undefined}
      linked={
        <Box textSize="xl" fontWeight="bold" data-testid="applied">
          {summary.applied.toFixed(2)}
        </Box>
      }
      linkedControl={unlink ? <RemoveButton icon="circle-xmark" onClick={unlink} /> : undefined}
    />
  );
}
