import { useRef, useState, useEffect, ReactElement, ReactNode, FC, ElementType } from 'react';
import { SelectField, SelectFieldProps } from '@ff-it/form';
import { useOptions, defaultRequestHandler } from '../useOptions';
import { useField } from 'react-final-form';
import { api } from 'services';

export type BoundProps = Omit<SelectFieldProps<any, boolean>, 'options' | 'loadOptions'> & {
  filter?: Record<string, unknown>;
};

interface DefaultOptionsProviderProps<T> {
  name: string;
  url: string;
  filter?: Record<string, unknown>;
  eager?: boolean;
  isMulti?: boolean;
  children: (defaultOptions: T[] | undefined) => ReactElement;
}

function InitialOptionLoader<T>({
  url,
  filter,
  name,
  eager,
  children,
  isMulti,
}: DefaultOptionsProviderProps<T>): ReactElement {
  const {
    input: { value },
  } = useField(name, { subscription: { value: true }, allowNull: true });

  const isMounted = useRef<boolean>(true);
  const [result, setResult] = useState(undefined);

  // biome-ignore lint/correctness/useExhaustiveDependencies: only runs once
  useEffect(() => {
    isMounted.current = true;
    // On mount loads default options if eager = true or if value is present
    if (eager || value) {
      const cond = value
        ? isMulti
          ? {
              id__in: value.join(','),
            }
          : {
              id: value,
            }
        : undefined;

      api
        .request<any, unknown>(
          defaultRequestHandler(url, undefined, {
            ...cond,
            ...filter,
          }),
        )
        .then((res) => {
          if (res.ok) {
            setResult(res.data.results);
          } else {
            throw res.error;
          }
        });
    }

    return () => {
      isMounted.current = false;
    };
  }, [eager]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: ony care about value change
  useEffect(() => {
    if (value && result) {
      // if value changes, and result is preset, we want to rest
      // else stale defaultOptions are used for resolution
      setResult(undefined);
    }
  }, [value]);

  return children(result);
}

function noOptionsMessage({ inputValue }: { inputValue: string }): ReactNode {
  return inputValue ? 'No matches' : 'Type to search';
}
function AsyncSelectField<T>({
  url,
  filter,
  suggestion,
  ...props
}: BoundProps & { url: string; suggestion?: boolean }): ReactElement {
  const loadOptions = useOptions<any>(url, filter, { suggestion });

  // @TODO this should not be bound to simple hand have it's own flat of even HOC
  if (props.simple && (!props.defaultOptions || props.defaultOptions === true)) {
    return (
      <InitialOptionLoader<T>
        name={props.name}
        url={url}
        filter={filter}
        eager={props.defaultOptions === true}
        isMulti={props.isMulti}
      >
        {(defaultOptions) => (
          <SelectField
            loadOptions={loadOptions}
            {...props}
            defaultOptions={defaultOptions}
            noOptionsMessage={noOptionsMessage}
          />
        )}
      </InitialOptionLoader>
    );
  }
  return <SelectField loadOptions={loadOptions} noOptionsMessage={noOptionsMessage} {...props} />;
}

export function createSelectField<T>(
  optionsOrUrl: string | readonly T[],
  extra?: Partial<SelectFieldProps<any, boolean>> & { filter?: Record<string, unknown>; suggestion?: boolean },
): FC<BoundProps> {
  const isAsync = typeof optionsOrUrl === 'string';
  const Component: ElementType = isAsync ? AsyncSelectField : SelectField;
  const boundProps = isAsync
    ? {
        url: optionsOrUrl,
      }
    : {
        options: optionsOrUrl,
      };

  function BoundSelect(props: BoundProps): ReactElement {
    return <Component {...boundProps} {...extra} {...props} />;
  }

  return BoundSelect;
}
