// Export for EnterpriseSwitcher
// FIXME: https://gitlab.ffit.lv/ff-it/init/-/issues/509
import { NavItem } from 'components/nav';
import { NotificationBadge } from 'components/ui';
import { menu as planning } from './Planning/menu';
import { menu as invoices } from './Invoices/menu';
import { menu as guarantee } from './Guarantee/menu';
import { menu as reporting } from './Reporting/menu';
import { menu as contacts } from './Contacts/menu';

import { menu as system } from '../_system/menu';
import { preferencesMenu } from './Preferences/menu';

export const menu: NavItem[] = [
  {
    to: '/dashboard',
    label: 'Dashboard',
    icon: 'house' as const,
    children: [
      { to: '/dashboard', label: 'Campaigns', end: true },
      { to: '/dashboard/summary', label: 'Summary' },
      {
        to: '/dashboard/tickets',
        label: 'Ticket',
        suffix: <NotificationBadge item="tickets" />,
      },
      {
        to: '/dashboard/calendar',
        label: 'Calendar',
        suffix: <NotificationBadge item="calendar" />,
      },
    ],
  },
  planning,
  invoices,
  guarantee,
  reporting,
  contacts,
  preferencesMenu,
  system,
];
