import { ReactElement } from 'react';
import { required } from 'utilities';
import { TextField } from '@ff-it/form';

export function Form({ create }: { create: boolean }): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="name" label="Name" className="col-md-6" required validate={required} />
      </div>
    </div>
  );
}
