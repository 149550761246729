import { ReactNode } from 'react';
import { createColumn } from './createColumn';
import { EditableChoiceEditor, type RenderEditCellProps } from 'components/DataGrid';
import type { GridRow, GridSummaryRow } from '../types';
import type { Placement } from 'modules/campaign/row';
import { wrapContainer } from './style.css';
import { usePlanController } from '../../usePlanController';
import { openModal } from '@ff-it/ui';
import { DialogForm } from 'components';
import { PlacementForm } from 'modules/supplier/products/rtb';

const getOptionLabel = (v: Placement): string => (v ? v.title : v);
const getOptionValue = getOptionLabel;

function Edit({ row, onClose }: RenderEditCellProps<GridRow, GridSummaryRow>): ReactNode {
  const controller = usePlanController();
  const options = row.config.placement?.resolved_value || [];
  const value = row.placement || [];

  function onSelect(v: Placement): void {
    controller.actionRequest({
      method: 'POST',
      url: `plan/rows/${row.id}/placement/`,
      body: v,
    });
  }

  function onDeselect(v: Placement): void {
    controller.actionRequest({
      method: 'DELETE',
      url: `plan/rows/${row.id}/placement/${value.findIndex((c) => getOptionValue(c) === getOptionValue(v))}/`,
      body: v,
    });
  }

  function onAdd(): void {
    openModal((props) => (
      <DialogForm
        {...props}
        dialogHeader="Add placement"
        initialValues={{ title: '' }}
        submitHandler={(v) =>
          controller.formRequest({ method: 'POST', url: `plan/rows/${row.id}/placement/`, body: v })
        }
      >
        <PlacementForm />
      </DialogForm>
    ));
  }

  function onUpdate(index: number, v: Placement): void {
    openModal((props) => (
      <DialogForm
        {...props}
        dialogHeader="Update placement"
        submitHandler={(v) =>
          controller.formRequest({ method: 'PUT', url: `plan/rows/${row.id}/placement/${index}/`, body: v })
        }
        initialValues={v}
        onRemove={() => controller.actionRequest({ method: 'DELETE', url: `plan/rows/${row.id}/placement/${index}/` })}
      >
        <PlacementForm />
      </DialogForm>
    ));
  }

  return (
    <EditableChoiceEditor
      options={options}
      onClose={onClose}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      value={value}
      onSelect={onSelect}
      onDeselect={onDeselect}
      onAdd={onAdd}
      onUpdate={onUpdate}
    />
  );
}

function renderValues(value: Placement[] | null): ReactNode {
  const text = value && value.length > 0 ? value.map(getOptionLabel).join(', ') : null;
  return text ? (
    <span className={wrapContainer} title={text}>
      {text}
    </span>
  ) : null;
}

export const PlacementColumn = createColumn(
  {
    key: 'placement',
    name: 'Placement',
    width: 116,
  },
  {
    renderView: (props) => (props.row.kind === 'MEDIA' ? renderValues(props.row.placement) : null),
    renderEdit: (props) => <Edit {...props} />,
    displayCellContent: true,
  },
);
