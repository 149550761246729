import { ReactElement, ReactNode, useState } from 'react';
import { Submit, TextField } from '@ff-it/form';
import { Alert, Button, DialogClose, RenderProps } from '@ff-it/ui';
import type { BlockDetails } from '../types';
import { BriefForm } from './BriefForm';
import { SubmissionErrors } from 'final-form';
import type { CampaignDetails } from 'modules/campaign/campaign/types';

interface UpdateBlockDialogProps extends RenderProps<BlockDetails> {
  block: BlockDetails;
  campaign: CampaignDetails;
  submitHandler: (values: any) => Promise<SubmissionErrors | void>;
  remove?: () => Promise<BlockDetails>;
}

export function UpdateBlockDialog({
  onDismiss,
  block,
  campaign,
  remove,
  submitHandler,
}: UpdateBlockDialogProps): ReactElement {
  const assigned = block.state !== 'NEW';

  const [locked, setLocked] = useState<boolean>(assigned);

  let before: ReactNode = undefined;

  if (assigned) {
    before = (
      <Alert variant="warning" className="mb-0 mt-2 rounded-0 px-3">
        {locked ? (
          <div className="row">
            <div className="col d-flex align-items-center">Brief has been assigned</div>
            <div className="col flex-grow-0">
              <Button variant="outline-danger" onClick={() => setLocked(false)} testId="unlock-brief-update">
                Update
              </Button>
            </div>
          </div>
        ) : (
          <TextField label="Update motivation" name="update_motivation" multiline autosize className="mb-0" />
        )}
      </Alert>
    );
  }

  return (
    <BriefForm
      title="Update brief"
      type={block.type}
      campaign={campaign}
      submitHandler={submitHandler}
      initialValues={block}
      before={before}
      disabled={locked}
    >
      <Submit>Update</Submit>
      <DialogClose className="ml-auto" />
      {remove && (
        <Button
          type="button"
          variant="outline-danger"
          className="ml-1"
          onClick={() => {
            remove().then(() => onDismiss());
          }}
          disabled={locked}
          testId="remove-block"
        >
          Delete
        </Button>
      )}
    </BriefForm>
  );
}
