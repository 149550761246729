import { ReactElement } from 'react';
import { ReportingGroup } from 'types';
import { CreateScene, DeleteScene, EntityScene, ModelScene, IndexScene, UpdateScene } from 'components';
import { SelectField, TextField } from '@ff-it/form';
import { Link } from 'react-router-dom';
import { permissions } from './permissions';
import { CompanyField } from 'modules/core/company/components';
import { TableColumns } from '@ff-it/ui';

const directionChoices = [
  {
    label: 'Client',
    value: 'CLIENT',
  },
  {
    label: 'Supplier',
    value: 'SUPPLIER',
  },
];

const columns: TableColumns<ReportingGroup> = [
  {
    content: 'Title',
    key: 'title',
    render: ({ id, title }: ReportingGroup): ReactElement => <Link to={`${id}`}>{title}</Link>,
  },
  {
    content: 'Direction',
    key: 'direction',
  },
  {
    content: 'Members',
    key: 'members',
    render: ({ members }) => members.map(({ title, internal_name }) => internal_name || title).join(', '),
  },
];

function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="title" label="Title" className="col-md-4" required />
        <SelectField
          name="direction"
          label="Direction"
          className="col-md-2"
          required
          simple
          options={directionChoices}
        />
      </div>
      <div className="form-row">
        <CompanyField
          name="members"
          className="col-md-6"
          label="Members"
          required
          isMulti
          help="Companies which are included in the group"
          includeBranches={true}
        />
      </div>
    </div>
  );
}

const initialValues = {
  title: '',
  direction: null,
  members: [],
};

const model = {
  endpoint: 'reporting/groups/',
  title: 'Reporting group',
  pluralTitle: 'Reporting groups',
  entityTitle: ({ title }: ReportingGroup) => title,
  permissions,
};

export const route = {
  path: 'groups',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene
          columns={columns}
          filterFields={
            <>
              <TextField name="search" placeholder="Search.." />
              <SelectField
                name="direction"
                placeholder="Direction"
                className="ml-1"
                simple
                options={directionChoices}
              />
            </>
          }
        />
      ),
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <UpdateScene>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};
