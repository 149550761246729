import type { RequestDialog } from './RequestActions';
import { Button, DialogBody, DialogFooter, DialogHeader, RenderProps } from '@ff-it/ui';
import { ReactElement, ReactNode } from 'react';

export function createConfirmDialog(msg = 'Are you sure?'): RequestDialog {
  return () =>
    new Promise(function (resolve, _reject) {
      const confirmed = window.confirm(msg);

      return confirmed ? resolve(true) : resolve(undefined);
    });
}

interface ConfirmDialogProps extends RenderProps<boolean> {
  title?: ReactNode;
  children?: ReactNode;
  confirm?: string;
  reject?: string;
}

export function ConfirmDialog({
  onSubmit,
  title,
  children,
  confirm = 'Yes',
  reject = 'No',
}: ConfirmDialogProps): ReactElement {
  return (
    <>
      {title && <DialogHeader title={title} />}
      <DialogBody>{children}</DialogBody>
      <DialogFooter>
        <Button type="button" variant="primary" onClick={() => onSubmit(true)} autoFocus testId="confirm-resolve">
          {confirm}
        </Button>
        <Button className="ml-auto" testId="confirm-reject" onClick={() => onSubmit(false)} variant="outline-secondary">
          {reject}
        </Button>
      </DialogFooter>
    </>
  );
}
