import { ReactElement } from 'react';
import { Heading, useEntity, SubNav } from 'components';
import { NavLinkProps } from 'react-router-dom';
import { Enterprise } from 'modules/core/types';

export function Nav(): ReactElement {
  const {
    item: {
      external,
      company: { title },
    },
  } = useEntity<Enterprise>();

  const nav: NavLinkProps[] = external
    ? []
    : [
        {
          to: '',
          end: true,
          children: 'Update',
        },
        {
          to: 'logos',
          children: 'Logos',
        },
        {
          to: 'planning',
          children: 'Planning',
        },
        {
          to: 'membership',
          children: 'Membership',
        },
        {
          to: 'membership_log',
          children: 'Membership log',
        },
        {
          to: 'onec',
          children: '1C Integration',
        },
      ];

  return (
    <SubNav nav={nav}>
      <Heading className="flex-column">
        <h1>{title}</h1>
      </Heading>
    </SubNav>
  );
}
