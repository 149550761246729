import { ReactElement } from 'react';
import { Col, DialogBody, DialogClose, DialogFooter, DialogHeader, Row } from '@ff-it/ui';
import { Form, Submit, useSubmitHandler } from '@ff-it/form';
import type { ApplicationResponse, AppliedLink } from 'modules/applicator/types';
import { InvoiceWidget } from 'modules/invoicing/common/InvoiceWidget';

type RemoveFormProps = {
  url: string;
  onSubmit: (data: ApplicationResponse) => void;
  applied: AppliedLink;
};

export function RemoveForm({
  url,
  applied: { id, destination, amount },
  onSubmit: onSuccess,
}: RemoveFormProps): ReactElement {
  const submitHandler = useSubmitHandler({ url, method: 'DELETE' }, { onSuccess });

  return (
    <Form initialValues={{ id }} onSubmit={submitHandler}>
      <DialogHeader title="Remove invoice" />
      <DialogBody>
        <Row paddingX="xs">
          <Col>
            <InvoiceWidget invoice={destination} />
          </Col>
          <Col>
            <div className="form-control text-right form-control-plaintext font-weight-bold">{amount}</div>
          </Col>
        </Row>
      </DialogBody>
      <DialogFooter>
        <Submit variant="danger">Remove</Submit>
        <DialogClose className="ml-auto" />
      </DialogFooter>
    </Form>
  );
}
