import { Box } from '@ff-it/ui';
import { ReactElement } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import type { EntityMap } from '../types';
import { Row } from './Row';
import { Linkable } from './types';

const ROW_HEIGHT = 36;

type GridProps = {
  rows: Linkable[];
  entities: EntityMap;
  disabled: boolean;
};
export function Grid(props: GridProps): ReactElement {
  return (
    <Box flexGrow={1}>
      <AutoSizer>
        {({ width, height }: any) => (
          <FixedSizeList
            style={{
              overflowY: 'scroll',
            }}
            height={height}
            itemCount={props.rows.length}
            itemSize={ROW_HEIGHT}
            width={width}
            itemData={props}
          >
            {Row}
          </FixedSizeList>
        )}
      </AutoSizer>
    </Box>
  );
}
