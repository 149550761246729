import { ReactElement, useCallback, useMemo } from 'react';
import { DecimalField } from '@ff-it/form';
import { Button, Check } from '@ff-it/ui';
import { parseBig, required, gt, fmt } from 'utilities';
import { Icon } from 'components';

interface CommissionProps {
  prevTarget?: string;
  nextTarget?: string;
  index: number;
  remove?: (index: number) => void;
  current: boolean;
  setCurrent: (index: number) => void;
  execution?: string;
}

export function Commission({
  execution,
  index,
  remove,
  nextTarget,
  prevTarget,
  current,
  setCurrent,
}: CommissionProps): ReactElement {
  const next = useMemo(() => (nextTarget ? fmt(parseBig(nextTarget).minus('0.01'), false, true) : '-'), [nextTarget]);
  const validateTarget = useCallback(
    (val?: string) => {
      return required(val) || prevTarget ? gt(parseBig(prevTarget))(val) : undefined;
    },
    [prevTarget],
  );

  const disable = !prevTarget && index !== 0;
  return (
    <tr className={current ? 'table-primary' : undefined}>
      <td className="align-middle text-center">
        <Check
          variant="radio"
          className="text-center"
          checked={current}
          onChange={(e) => {
            e.target.checked && setCurrent(index);
          }}
        />
      </td>
      <td>
        <DecimalField name={`commission[${index}].target`} validate={validateTarget} disabled={disable} />
      </td>
      <td className="align-middle text-right">{next}</td>
      <td>
        <DecimalField
          name={`commission[${index}].rate`}
          className="text-right"
          validate={required}
          disabled={disable}
        />
      </td>
      <td className="text-right align-middle ">
        <strong>{execution}</strong>
      </td>

      <td className="tools">
        {remove && (
          <Button
            data-test-id="remove-inline"
            variant="outline-danger"
            type="button"
            onClick={() => remove(index)}
            className="border-0"
            size="sm"
          >
            <Icon icon="xmark" />
          </Button>
        )}
      </td>
    </tr>
  );
}
