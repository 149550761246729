import { Dispatch, createContext, useContext, SetStateAction } from 'react';
import invariant from 'tiny-invariant';
import { RowKey } from '../types';

const RowExpansionContext = createContext<boolean | undefined>(undefined);
export const RowExpansionProvider = RowExpansionContext.Provider;

const ToggleExpandedContext = createContext<Dispatch<SetStateAction<ReadonlySet<RowKey>>> | undefined>(undefined);

export const ToggleExpandedProvider = ToggleExpandedContext.Provider;

export function useGroupToggle(): [isExpanded: boolean, toggleGroup: (groupKey: RowKey) => void] {
  const isExpanded = useContext(RowExpansionContext);
  const setExpanded = useContext(ToggleExpandedContext);

  invariant(isExpanded !== undefined && setExpanded !== undefined);

  const toggleGroup = (groupKey: RowKey): void => {
    setExpanded((current) => {
      const newValues = new Set(current);
      if (newValues.has(groupKey)) {
        newValues.delete(groupKey);
      } else {
        newValues.add(groupKey);
      }
      return newValues;
    });
  };
  return [isExpanded, toggleGroup];
}
