import { ReactElement, ReactNode } from 'react';
import { Box, DialogBody, DialogFooter, DialogHeader } from '@ff-it/ui';
import { Form, SubmitHandler } from '@ff-it/form';
import type { ActivityType } from 'types';
import { BriefFields } from './BriefFields';
export { BriefFields };
import type { BlockDetails } from 'modules/campaign/block/types';
import { sticky } from '../style.css';
import type { CampaignDetails } from 'modules/campaign/campaign/types';

interface BriefFormProps {
  title: string;
  initialValues: BlockDetails;
  before?: ReactNode;
  children: ReactNode;
  submitHandler: SubmitHandler<any>;
  disabled?: boolean;
  campaign: CampaignDetails;
  type: ActivityType;
}

export function BriefForm({
  title,
  initialValues,
  submitHandler,
  disabled,
  before,
  children,
  campaign,
  type,
}: BriefFormProps): ReactElement {
  return (
    <Form onSubmit={submitHandler} initialValues={initialValues} noValidate disabled={disabled}>
      <DialogHeader
        title={
          <>
            <strong>{title}</strong> {type}
          </>
        }
      />

      {before}
      <DialogBody>
        <BriefFields type={type} campaign={campaign} />
      </DialogBody>
      <Box className={sticky}>
        <DialogFooter>{children}</DialogFooter>
      </Box>
    </Form>
  );
}
