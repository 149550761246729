import { ReactElement } from 'react';
import { DecimalField, FormGroup, useFormState } from '@ff-it/form';
import { FormState } from './types';
// import { positive } from 'utilities';

export function Fields(): ReactElement {
  const { _calc, invoiceable, invoiced, balance, rate } = useFormState<FormState>({
    subscription: { values: true },
  }).values;

  return (
    <div className="form-row">
      <DecimalField
        name="target"
        label="Target"
        precision={6}
        required
        className="col"
        disabled={_calc !== 'TARGET'}
        // validate={positive}
      />
      <FormGroup label="Rate" className="px-3">
        <span className="form-control form-control-plaintext">{rate}%</span>
      </FormGroup>
      <FormGroup label="Invoiceable" className="px-3">
        <span className="form-control form-control-plaintext">{invoiceable}</span>
      </FormGroup>
      <FormGroup label="Invoiced" className="px-3">
        <span className="form-control form-control-plaintext">{invoiced}</span>
      </FormGroup>
      <FormGroup label="Balance" className="px-3">
        <span className="form-control form-control-plaintext">{balance}</span>
      </FormGroup>
      <DecimalField
        name="bound"
        label="Linked"
        precision={6}
        required
        className="col"
        disabled={_calc !== 'LINKED'}
        // validate={positive}
      />
    </div>
  );
}
