import type { ListExpense } from 'modules/invoicing/expenses/types';
import type { ListIncome } from 'modules/invoicing/income/types';
import type { LinkKind } from './types';

export function isLinkable(invoice: ListIncome | ListExpense): LinkKind | null {
  if (invoice.sums.link_state !== null) {
    return (invoice as ListIncome).source_avb || (invoice as ListExpense).source_cvb ? 'bonus' : 'net';
  }
  return null;
}
