import { ComponentType, ReactElement, ReactNode, useMemo } from 'react';
import { Perm } from 'core/permissions';
import { VisibilityProps } from './types';
import { useLocalVisiblityPerms, LocalVisibilityPerms } from './useLocalVisiblityPerms';
import { PermissionDenied } from 'components';
import { Navigate, RouteObject, useRoutes } from 'react-router';
import invariant from 'tiny-invariant';
import { NavLinkProps } from 'react-router-dom';
import { ReportContainer } from './ReportContainer';

function RenderReport({
  local,
  managedClients,
  managedTypes,
  Component,
  title,
}: LocalVisibilityPerms & {
  Component: ComponentType<VisibilityProps>;
  title: ReactNode;
}): ReactElement {
  const [routes, nav] = useMemo(() => {
    const routes: RouteObject[] = [];
    const nav: NavLinkProps[] = [];
    if (local) {
      routes.push({
        path: 'local',
        element: <Component visibility="LOCAL" />,
      });
      nav.push({
        children: 'Local',
        to: 'local',
      });
    }
    if (managedClients) {
      routes.push({
        path: 'local_managed',
        element: <Component visibility="LOCAL_MANAGED" />,
      });
      nav.push({
        children: 'Managed clients',
        to: 'local_managed',
      });
    }

    managedTypes.forEach((type) => {
      routes.push({
        path: `local_type/${type}`,
        element: <Component visibility="LOCAL_TYPE" type={type} />,
      });
      nav.push({
        to: `local_type/${type}`,
        children: type,
      });
    });

    invariant(routes[0].path);
    routes.push({
      index: true,
      element: <Navigate to={routes[0].path} />,
    });

    return [routes, nav];
  }, [local, managedClients, managedTypes, Component]);

  const element = useRoutes(routes);

  return (
    <ReportContainer title={title} nav={nav}>
      {element}
    </ReportContainer>
  );
}

interface LocalVisibilityReportProps {
  localPerm: Perm;
  managedPerm: Perm;
  Component: ComponentType<VisibilityProps>;
  title: ReactNode;
}

export function LocalVisibilityReport({
  localPerm,
  managedPerm,
  Component,
  title,
}: LocalVisibilityReportProps): ReactElement {
  const perms = useLocalVisiblityPerms(localPerm, managedPerm);
  if (!perms) {
    return <PermissionDenied />;
  }
  return <RenderReport {...perms} Component={Component} title={title} />;
}
