import { ReactElement, Fragment, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Td, Tr } from '@ff-it/ui';
import type { BlockDetails } from 'modules/campaign/block/types';
import {
  ActivityIcon,
  BoundStates,
  Period,
  useEntityActions,
  useRequestActions,
  usePromiseActions,
  ActionDropdown,
  EntityContextType,
} from 'components';
import type { CampaignDetails } from '../types';
import { useManagerActions } from 'modules/campaign/block/BlockActions/useManagerActions';
import { sepFormat } from 'utilities';
import { BlockNotice } from 'modules/campaign/block/BlockNotice';
import { fetcher as defaultFethcer, Fetcher } from 'services';
import { RequestArgs } from '@ff-it/api';

export interface BlockControlProps {
  itemContext: EntityContextType<BlockDetails>;
  campaign: CampaignDetails;
  remove: (block: BlockDetails) => void;
  add: (block: BlockDetails) => void;
}

function BlockControls({ itemContext, campaign, remove, add }: BlockControlProps): ReactElement | null {
  const fetcher: Fetcher = useCallback(
    ({ headers, ...args }: RequestArgs) =>
      defaultFethcer({
        headers: {
          ...headers,
          'If-Match': itemContext.item.etag,
        },
        ...args,
      }),
    [itemContext.item.etag],
  );
  const actions = usePromiseActions(
    useRequestActions(
      useEntityActions(
        itemContext,
        useManagerActions({
          fetcher,
          campaign,
          onAdd: add,
          onRemove: remove,
        }),
      ),
      fetcher,
    ),
    itemContext.setSpinning,
    itemContext.setItem,
  );
  if (actions.length === 0) {
    return null;
  }

  return (
    <ActionDropdown
      actions={actions}
      variant="outline-secondary"
      className="border-0"
      icon="ellipsis-vertical"
      testId="block-overview-actions"
    />
  );
}

export function Block(props: BlockControlProps): ReactElement {
  const block = props.itemContext.item;

  const {
    id,
    type,
    code,
    title,
    date_from,
    date_to,
    sums,
    planners,
    budget,
    role: { manage, plan },
    final_client_po_number,
  } = block;

  const notice = <BlockNotice {...block} overview />;
  return (
    <Fragment key={id}>
      <Tr className="block-overview" data-block-id={id} data-role-plan={plan} data-role-manage={manage}>
        <Td className="controls" rowSpan={2}>
          {manage && <BlockControls {...props} />}
        </Td>
        <Td className="state">{sums && <BoundStates {...sums} />}</Td>
        <Td className="type">
          <ActivityIcon type={type} /> <strong>{type}</strong>
        </Td>
        <Td className="code">
          <Link to={`${id}`}>{code}</Link>
        </Td>
        <Td className="title">
          {title}{' '}
          {final_client_po_number && (
            <small className="text-muted" data-testid="client-po-number">
              {final_client_po_number}
            </small>
          )}
        </Td>
        <Td className="period">
          <Period date_from={date_from} date_to={date_to} />
        </Td>
        <Td>{planners.map(({ first_name, last_name }) => `${first_name} ${last_name.charAt(0)}`).join(', ')}</Td>
        <Td textAlign="end">{sepFormat(budget)}</Td>
        <Td textAlign="end" className="client">
          {sepFormat(sums?.income_total)}
        </Td>
        <Td textAlign="end" className="agency">
          {sepFormat(sums?.expense_total)}
        </Td>
        <Td textAlign="end" className="revenue">
          {sepFormat(sums?.revenue)}
        </Td>
        <Td className="navigate">
          <Link to={`${id}`}>PLAN</Link>
        </Td>
      </Tr>
      <Tr className="block-notice">
        <Td flush colSpan={11}>
          {notice}
        </Td>
      </Tr>
    </Fragment>
  );
}
