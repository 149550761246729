import { ReactElement } from 'react';
import { ReportContainer } from 'modules/reporting/common';
import { Navigate, useRoutes } from 'react-router';
import { NavLinkProps } from 'react-router-dom';
import { IncomeLinks } from './IncomeLinks';
import { ExpenseLinks } from './ExpenseLinks';
import { useHasPerms } from 'core/permissions';
import links from 'modules/invoicing/links/permissions';
import { PermissionDenied } from 'components';

const nav: NavLinkProps[] = [
  {
    children: 'Income',
    to: 'income',
  },
  {
    children: 'Expense',
    to: 'expense',
  },
];

function Links(): ReactElement {
  const [income, expense] = useHasPerms(links.income, links.expense);
  const routes = [];
  income &&
    routes.push({
      path: 'income',
      element: <IncomeLinks />,
    });

  expense &&
    routes.push({
      path: 'expense',
      element: <ExpenseLinks />,
    });

  const hasRoutes = routes.length > 0;

  if (hasRoutes) {
    routes.push({
      index: true,
      element: <Navigate to={routes[0].path} />,
    });
  }

  const element = useRoutes(routes);

  if (!hasRoutes) {
    return <PermissionDenied />;
  }

  return (
    <ReportContainer title="Links" nav={nav}>
      {element}
    </ReportContainer>
  );
}

export const route = {
  path: 'links/*',
  element: <Links />,
};
