import { any } from 'core/permissions';

const headOfDepartment = any(
  'campaign.manage_tv',
  'campaign.manage_radio',
  'campaign.manage_ooh',
  'campaign.manage_press',
  'campaign.manage_internet',
  'campaign.manage_social',
  'campaign.manage_creative',
  'campaign.manage_rtb',
  'campaign.manage_other',
);

const view = any(
  'campaign.view_campaign',
  'campaign.manage_campaign',
  'campaign.plan_campaign_block',
  headOfDepartment,
);
const change = any('campaign.change_campaign', 'campaign.manage_campaign');

export const permissions = {
  view,
  add: any('campaign.add_campaign', 'campaign.manage_campaign'),
  change,
  delete: any('campaign.delete_campaign', 'campaign.manage_campaign'),
  export: 'campaign.export_rows',
};
