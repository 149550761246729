import { ReactElement } from 'react';
import { Icon, IconProp } from '../Icon';
import { Button, ButtonProps } from '@ff-it/ui';

export function RemoveButton({
  children,
  icon = 'xmark',
  ...props
}: Partial<ButtonProps> & { icon?: IconProp }): ReactElement {
  return (
    <Button variant="outline-danger" type="button" className="border-0" size="sm" {...props}>
      {children}
      <Icon icon={icon} className={children ? 'ml-2' : undefined} />
    </Button>
  );
}
