import { useEffect, createContext, useState, useCallback, useRef, ReactNode, ReactElement } from 'react';
import { Notifications } from './types';
import { api } from 'services';

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationContext = createContext<Notifications | undefined>(undefined);

// 60 sec
const TIMEOUT = 60 * 1000 * 3;

export function NotificationProvider({ children }: NotificationProviderProps): ReactElement {
  const isMounted = useRef(false);

  const [item, setItems] = useState<Notifications | undefined>(undefined);

  const fetchItem = useCallback(async () => {
    const res = await api.request<Notifications, unknown>({
      method: 'GET',
      url: 'notifications/notifications/',
    });
    if (res.ok) {
      isMounted.current && setItems(res.data);
    } else {
      // pass, this is not critical
    }
  }, []);

  useEffect(() => {
    fetchItem();
    const timer = setInterval(() => {
      fetchItem();
    }, TIMEOUT);
    return () => clearInterval(timer);
  }, [fetchItem]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return <NotificationContext.Provider value={item}>{children}</NotificationContext.Provider>;
}
