import { ReactElement, useState } from 'react';
import { Breadcrumb, Heading, Page, TailToggler } from 'components';
import { Card } from 'components/Card';
import useSWR from 'swr';
import { useSplitTail } from 'hooks';
import { Alert, Box, DataTable, LoadingContainer } from '@ff-it/ui';
import type { CampaignsData, LifecycleStageProps, ManagedBlock } from './types';

import {
  BOUND_STATES,
  BRIEF_SENT,
  DAYS_SINCE_END,
  DAYS_TO_PLAN,
  DAYS_TO_PLAN_LEFT,
  DAYS_UNTIL_END,
  DAYS_UNTIL_START,
  DEADLINE,
  END,
  PLANNERS,
  PREFIX,
  START,
} from './columns';

const getRowId = (block: ManagedBlock): string => `${block.campaign_id}_${block.block_id}`;

function LifecycleStage({ title, rows, columns, cap }: LifecycleStageProps): ReactElement {
  const [head, tail] = useSplitTail(rows, cap);
  const [showTail, setShow] = useState<boolean>(false);
  return (
    <Card
      title={
        // FIXME
        <Box display="flex" alignItems="center">
          {title} <div className="badge badge-pill ml-auto">{rows.length}</div>
        </Box>
      }
      className="mb-3"
    >
      <DataTable
        fixed
        size="sm"
        columns={columns}
        data={showTail ? rows : head}
        variant="dark"
        getRowId={getRowId}
        after={tail.length !== 0 && <TailToggler count={tail.length} show={showTail} setShow={setShow} />}
      />
    </Card>
  );
}

type StageKey = keyof CampaignsData;
type StageConfig = Omit<LifecycleStageProps, 'rows'> & { key: StageKey };

const cap = 5;

const stagesConfig: StageConfig[] = [
  {
    key: 'new',
    title: 'New',
    cap,
    columns: [...PREFIX, START, DEADLINE, DAYS_UNTIL_START, PLANNERS],
  },
  {
    key: 'received',
    title: 'Received',
    cap,
    columns: [...PREFIX, START, DEADLINE, DAYS_UNTIL_START, PLANNERS],
  },
  {
    key: 'planning',
    title: 'Planning',
    cap,
    columns: [...PREFIX, START, BRIEF_SENT, DEADLINE, DAYS_TO_PLAN, DAYS_TO_PLAN_LEFT, PLANNERS],
  },
  {
    key: 'submitted',
    title: 'Submitted',
    cap,
    columns: [...PREFIX, START, BRIEF_SENT, DEADLINE, DAYS_TO_PLAN, DAYS_TO_PLAN_LEFT, PLANNERS],
  },
  {
    key: 'approved',
    title: 'Approved',
    cap,
    columns: [...PREFIX, START, DAYS_UNTIL_START, PLANNERS],
  },
  {
    key: 'live',
    title: 'Live',
    cap,
    columns: [...PREFIX, START, END, DAYS_UNTIL_END, PLANNERS],
  },
  {
    key: 'ended',
    title: 'Ended',
    cap,
    columns: [...PREFIX, END, DAYS_SINCE_END, PLANNERS, BOUND_STATES],
  },
];

function CampaignsContainer(props: CampaignsData): ReactElement[] | ReactElement {
  if (!Object.keys(props).some((key) => props[key as keyof CampaignsData].length > 0)) {
    return <Alert variant="info">Nothing here yet.</Alert>;
  }

  return stagesConfig
    .filter(({ key }) => props[key].length > 0)
    .map(({ key, ...rest }) => <LifecycleStage key={key} rows={props[key]} {...rest} />);
}

export function Campaigns(): ReactElement {
  const { isLoading, data } = useSWR<CampaignsData>({
    method: 'GET',
    url: 'dashboard/campaigns/',
  });
  return (
    <Page narrow>
      <Breadcrumb>Campaigns</Breadcrumb>
      <Heading title="Campaigns" />
      <LoadingContainer loading={isLoading}>{data && <CampaignsContainer {...data} />}</LoadingContainer>
    </Page>
  );
}
