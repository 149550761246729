import { TitleContainer } from 'components/TitleContainer';
import { header } from './style.css';
import { RowContainer } from './RowContainer';
import { useId, type ReactElement } from 'react';
import { Icon } from 'components';
import { Tooltip } from 'react-tooltip';

function Invoiced(): ReactElement {
  const id = useId();

  return (
    <span className="text-left">
      Invoiced <Icon icon="circle-question" data-tooltip-id={id} />
      <Tooltip id={id}>
        Total invoiced for current agreement (regardless of the selected period). <br />
        Assumes period start matches agreement
      </Tooltip>
    </span>
  );
}

export function HeaderRow(): ReactElement {
  return (
    <RowContainer
      className={header}
      client={<TitleContainer title="Client" subtitle="Payer" />}
      product={<TitleContainer title="Department" subtitle="Product" />}
      provider={<TitleContainer title="Provider" subtitle="Activity" />}
      block={<TitleContainer title="Block" />}
      source={<TitleContainer title="Target" subtitle="Palnned" />}
      rate="Rate"
      invoiceable="Invoiceable"
      invoiced={<Invoiced />}
      balance="Balance"
      linked="Linked"
    />
  );
}
