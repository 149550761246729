import { ReactElement } from 'react';
import { TextField, CheckField, SelectField, useField } from '@ff-it/form';
import { required } from 'utilities';
import type { ProductKind } from 'types';
import { activityKindOptions } from 'options';

export function Form({ update }: { update?: boolean }): ReactElement {
  const {
    input: { value: kind },
  } = useField<ProductKind | null>('kind', { subscription: { value: true }, allowNull: true });

  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <SelectField
          name="kind"
          label="Kind"
          className="col-md-4"
          required
          options={activityKindOptions}
          simple
          validate={required}
          disabled={update}
        />
      </div>

      <div className="form-row">
        <TextField name="name" label="Name" className="col-md-4" required />
        <TextField
          name="name_alternative"
          label="Alternative name"
          help="Can be used in invoice builder"
          className="col-md-4"
        />
      </div>
      <div className="form-row col pl-4 mb-3">
        {kind === 'MEDIA' && (
          <CheckField
            name="non_standard"
            fieldLabel="Is non standart"
            variant="switch"
            className="col d-flex align-items-center"
            inline
          />
        )}
        {kind === 'SERVICE' && (
          <CheckField
            name="is_fee"
            fieldLabel="Is fee"
            variant="switch"
            className="col d-flex align-items-center"
            inline
          />
        )}
      </div>
    </div>
  );
}
