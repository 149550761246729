import { Breadcrumb, Page, Heading } from '../layout';
import { Perm, useHasPerms } from 'core/permissions';
import { useEntity, useModel } from './context';
import { PermissionDenied } from './errors';
import { ReactElement, ReactNode } from 'react';

interface DetailsSceneProps {
  perm?: Perm;
  children?: ReactNode;
  heading?: ReactNode | null;
}

export function DetailsScene(props: DetailsSceneProps): ReactElement {
  const { permissions, entityTitle } = useModel();
  const { item } = useEntity();

  const { children, perm, heading = <Heading title={entityTitle(item)}></Heading> } = props;

  const [hasView] = useHasPerms(perm || permissions?.view);

  if (!hasView) {
    return <PermissionDenied />;
  }

  return (
    <Page>
      <Breadcrumb to={null}>Details</Breadcrumb>
      {heading}
      {children}
    </Page>
  );
}
