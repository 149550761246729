import { useCallback, useState, ReactElement } from 'react';
import { useField, fieldSubscription, useFieldProps, FormGroup } from '@ff-it/form';
import { CreatableSelect } from '@ff-it/ui';
import type { Brand } from 'modules/client/types';
import { BoundProps, useOptions } from 'components';
import { api } from 'services';

const url = 'client/brands/';

const getOptionLabel = (b: any): string => (b.__isNew__ ? b.label : b.title);
const getOptionValue = (b: any): string => (b.__isNew__ ? b.value : b.id.toString());

export function CreatableBrandField(props: BoundProps): ReactElement {
  const loadOptions = useOptions<Brand>(url);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { name, className, label, help, isClearable, validate, disabled, ...rest } = useFieldProps(props);
  const { input, meta } = useField<Brand | Brand[], any>(name, {
    subscription: fieldSubscription,
    validate,
  });

  const onCreateOption = useCallback(
    (initialValue: string): void => {
      setIsLoading(true);
      api
        .post(url, {
          title: initialValue,
        })
        .then((res) => {
          if (res.ok) {
            input.onChange(props.isMulti ? [...(input.value as Brand[]), res.data] : res.data);
          } else {
            throw res.error;
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [input.onChange, input.value, props.isMulti],
  );

  const fieldProps = {
    ...input,
    valid: meta.invalid && meta.touched ? false : undefined,
    isClearable: typeof isClearable === 'undefined' ? !rest.required : isClearable,
    ...rest,
    loadOptions,
    getOptionLabel,
    getOptionValue,
    disabled: isLoading || disabled,
    isLoading,
    onCreateOption,
  };

  return (
    <FormGroup className={className} label={label} id={rest.id} help={help} meta={meta} required={fieldProps.required}>
      <CreatableSelect {...fieldProps} />
    </FormGroup>
  );
}
