import { useEntity } from 'components';
import type { Income } from 'modules/invoicing/income/types';
import type { Expense } from 'modules/invoicing/expenses/types';
import type { CampaignDetails } from 'modules/campaign/campaign/types';
import type { AVB } from 'modules/guarantee/types';
import { Container } from './Container';
import { useBuilder } from './useBuilder';
import { useLinker } from './useLinker';
import { CampaignBuilder } from './CampaignBuilder';
import { AVBBuilder } from './AVBBuilder';
import { NetLinker } from './NetLinker';
import { BonusLinker } from './BonusLinker';
import { LinkKind } from './types';
export { CampaignCorrector as CampaignInvoiceCorrector } from './CampaignCorrector';

// Public API

export function CampaignInvoiceBuilder(): React.ReactElement {
  const { endpoint } = useEntity<CampaignDetails>();
  const handler = useBuilder(endpoint);
  return (
    <Container title="Create" {...handler}>
      {CampaignBuilder}
    </Container>
  );
}

export function AVBInvoiceBuilder(): React.ReactElement {
  const { endpoint } = useEntity<AVB>();
  const handler = useBuilder(endpoint);
  return (
    <Container title="Create" {...handler}>
      {AVBBuilder}
    </Container>
  );
}

const linkComponents = {
  net: NetLinker,
  bonus: BonusLinker,
} as const;

export function IncomeLinker(): React.ReactElement {
  const { endpoint, item, setItem } = useEntity<Income>();
  const kind: LinkKind = item.source_avb ? 'bonus' : 'net';
  const handler = useLinker(endpoint, item, setItem);
  return (
    <Container title="Link" invoice={item} direction="income" {...handler}>
      {linkComponents[kind]}
    </Container>
  );
}

export function ExpenseLinker(): React.ReactElement {
  const { endpoint, item, setItem } = useEntity<Expense>();
  const handler = useLinker(endpoint, item, setItem);
  const kind: LinkKind = item.source_cvb ? 'bonus' : 'net';
  return (
    <Container title="Link" invoice={item} direction="expense" {...handler}>
      {linkComponents[kind]}
    </Container>
  );
}
