import { ReactElement, ReactNode } from 'react';
import { Variant } from '@ff-it/ui';
import cx from 'clsx';
import './Badge.scss';

interface BadgeProps<S extends string> {
  state: S;
  variant?: Variant;
  className?: string;
  children?: ReactNode;
  dataTestId?: string;
}

export function Badge<S extends string = any>({
  state,
  variant,
  children,
  className: outerClassName,
  dataTestId,
}: BadgeProps<S>): ReactElement {
  const className = cx('badge', variant && `badge-${variant}`, `badge-state-${state.toLowerCase()}`, outerClassName);

  return (
    <div className={className} data-test-id={dataTestId} data-state={state}>
      {state}
      {children}
    </div>
  );
}
