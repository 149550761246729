import { ReactElement } from 'react';
import { TicketFactory } from 'modules/support/TicketFactory';
import initialValues from './initialValues';
import { Form } from 'modules/client/agreement/Form';
import { Company } from 'types';

interface FromTicketProps {
  client: Company;
}

export function FromTicket({ client }: FromTicketProps): ReactElement {
  return (
    <TicketFactory
      category="ADD_CLIENT"
      resolveContext={({ data: { payer, invoice_payment_days, terms }, attachments }) => [
        {
          ...initialValues,
          client,
          payer,
          invoice_payment_days,
          terms,
          attachments,
        },
        null,
      ]}
    >
      <Form />
    </TicketFactory>
  );
}
