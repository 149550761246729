import { Link } from 'react-router-dom';
import type { ExternalSums } from 'modules/guarantee/types';
import { dateFrom, dateTo } from 'components/columns';
import { TableColumns } from '@ff-it/ui';

export const columns: TableColumns<ExternalSums> = [
  {
    content: 'Number',
    key: 'number',
    width: 90,
    render: ({ id, number }: ExternalSums) => <Link to={`${id}`}>{number}</Link>,
  },
  {
    content: 'Agency',
    key: 'agency.company.title',

    width: undefined,
  },
  dateFrom(),
  dateTo(),
];
