import { ReactElement, ReactNode } from 'react';
import { container, valueContainer, colors } from './style.css';
import type { SmartOption } from '../../../types';

const symbols = {
  RATE: '%',
  UNIT: '×',
} as const;

export function Label({ index, rate, application, direction }: Omit<SmartOption, 'apply_to'>): ReactElement {
  const symbol = application ? symbols[application] : '';

  let value: ReactNode = <span className={valueContainer}>—{symbol}</span>;
  switch (direction) {
    case 'CLIENT':
      value = (
        <span className={valueContainer} style={{ background: colors.CLIENT[index % 3] }}>
          {rate.client || '—'}
          {symbol}
        </span>
      );
      break;
    case 'AGENCY':
      value = (
        <span className={valueContainer} style={{ background: colors.AGENCY[index % 3] }}>
          {rate.provider || '—'}
          {symbol}
        </span>
      );
      break;
    case 'BOTH':
      value = (
        <>
          <span className={valueContainer} style={{ background: colors.CLIENT[index % 3] }}>
            {rate.client || '—'}
            {symbol}
          </span>
          <span className={valueContainer} style={{ background: colors.AGENCY[index % 3] }}>
            {rate.provider || '—'}
            {symbol}
          </span>
        </>
      );
      break;
  }

  return <span className={container}>{value}</span>;
}
