import { Field, useEntity, Fieldset } from 'components';
import invariant from 'tiny-invariant';
import type { Ticket } from 'modules/support/types';
import type { ReactElement } from 'react';

export function AddClient(): ReactElement {
  const {
    item: { data },
  } = useEntity<Ticket>();
  invariant(data, 'data present');

  return (
    <Fieldset title="Add client">
      <div className="form-row">
        <Field name="enterprise.company.title" label="Agency" className="col-md-4" />
        <Field name="data.account_manager" label="Account managers" className="col-md-4" />
      </div>
      <div className="form-row">
        <Field name="data.company.title" label="Company" className="col-md-4" />
        <Field name="data.payer.title" label="Payer" className="col-md-4" />
        <Field name="data.brand" label="Brand" className="col-md-4" />
      </div>
      <div className="form-row">
        <Field
          name="data.terms"
          className="col-6"
          label="Terms & conditions"
          help="Describe any terms & conditions this agreements contains"
        />
        <Field label="Invoice payment days" name="data.invoice_payment_days" className="col-3" />
      </div>

      <div className="form-row">
        <Field name="data.extra_info" label="Extra info" help="Any other info to know" className="col-md-4" />
      </div>
    </Fieldset>
  );
}
