import Big from 'big.js';
import type { Linkable, BonusSummaryState } from './types';
import type { Bound } from '../types';
import type { Invoice } from 'modules/invoicing/types';
import { calcSummaryState as calcNetSummaryState } from '../NetLinker/calcSummaryState';
import { LinkFields } from 'modules/invoicing/common/types';

const emptySums: LinkFields = {
  link_amount: '0.00',
  link_balance: '0.00',
  link_credited: '0.00',
  link_applied: '0.00',
  link_compensated: '0.00',
  link_state: null,
};

export function calcSummaryState(rows: Linkable[], bound: Bound, invoice?: Invoice): BonusSummaryState {
  const summary = calcNetSummaryState(invoice ? invoice.sums : emptySums, bound);

  let hasAvailable = false;
  const available = rows.reduce((acc, v) => {
    // not bound and has balance
    if (v.bound === null && v.balance.gt(0)) {
      hasAvailable = true;
    }
    return acc.plus(v.balance);
  }, Big(0));

  return {
    ...summary,
    available,
    hasBound: Object.keys(bound).length > 0,
    hasAvailable,
  };
}
