import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import type { AgencyMembership } from 'modules/core/types';
import { UserTitle } from 'components';
import { TableColumns } from '@ff-it/ui';

export const columns: TableColumns<AgencyMembership> = [
  {
    content: 'User',
    key: 'user',
    cellProps: {
      className: 'py-1',
    },
    render: ({ id, user }: AgencyMembership): ReactElement => (
      <Link to={`${id}`}>
        <UserTitle {...user} />
      </Link>
    ),
  },
  {
    content: 'Groups',
    key: 'groups',
    cellProps: {
      className: 'align-middle',
    },
    render: ({ groups }) => groups.map(({ name }) => name).join(', '),
  },
];
