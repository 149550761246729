/**
 * @FIXME move these to options or scoped modules
 */
import type { AgreementKind, CompanyKind, VATStrategy } from 'types';
import type { GuaranteeType, Regularity } from 'modules/guarantee/types';

import type { CampaignState } from 'modules/campaign/campaign/types';
import type { BlockState } from 'modules/campaign/block/types';
import type { BoundState } from 'modules/campaign/common/types';
import type { Option } from './options';

export const resetPeriodOptions = [
  { label: 'Reset never', value: 'never' },
  { label: 'Reset daily', value: 'daily' },
  { label: 'Reset monthly', value: 'monthly' },
  { label: 'Reset yearly', value: 'yearly' },
];

export const campaignStateOptions: Option<CampaignState>[] = [
  { label: 'New', value: 'NEW' },
  { label: 'In process', value: 'IN_PROCESS' },
  { label: 'Done', value: 'DONE' },
  { label: 'Canceled', value: 'CANCELED' },
];

export const blockStateOptions: Option<BlockState>[] = [
  { label: 'New', value: 'NEW' },
  { label: 'Planning', value: 'PLANNING' },
  { label: 'Submitted', value: 'SUBMITTED' },
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Done', value: 'DONE' },
  { label: 'Canceled', value: 'CANCELED' },
];

export const reportBlockStateOptions: Option<BlockState>[] = blockStateOptions.filter(({ value }) => value !== 'NEW');

export const companyKindOptions: Option<CompanyKind>[] = [
  { label: 'Individual', value: 'individual' },
  { label: 'Company', value: 'company' },
];

export const guaranteeTypeOptions: Option<GuaranteeType>[] = [
  { label: 'Goal', value: 'GOAL' },
  { label: 'Guarantee', value: 'GUARANTEE' },
];

export const boundStateOptions: Option<BoundState | 'OVERSPENT'>[] = [
  { label: 'None', value: 'NONE' },
  { label: 'Partial', value: 'PARTIAL' },
  { label: 'Full', value: 'FULL' },
  { label: 'Overspent', value: 'OVERSPENT' },
];

export const agreementKindOptions: Option<AgreementKind>[] = [
  { label: 'Contract', value: 'CONTRACT' },
  { label: 'Arrangement', value: 'ARRANGEMENT' },
];

export const regularityOptions: Option<Regularity>[] = [
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Quarterly', value: 'QUARTERLY' },
  { label: 'Half-Yearly', value: 'HALFYEARLY' },
  { label: 'Anually', value: 'ANNUALLY' },
];

export const vatStrategyOptions: Option<VATStrategy>[] = [
  { label: 'Line VAT', value: 'LINE_VAT' },
  { label: 'Group VAT', value: 'GROUP_VAT' },
];
