import { ReactElement } from 'react';
import { Button } from '@ff-it/ui';
import cx from 'clsx';
import { BlockWidget, InvoicingProgress } from 'components';
import { usePeriodMonths } from 'hooks';
import { fmt } from 'utilities';
import { Table, Row, HeaderRow } from 'modules/invoicing/common/finance';
import { EntityMap } from '../types';
import { BuilderState, BuilderContext } from './types';

type MonthSumsProps = {
  builderContext: BuilderContext;
  entities: EntityMap;
  state: BuilderState;
};

export function MonthSums(props: MonthSumsProps): ReactElement {
  const {
    state: { selection, select, clear },
    builderContext: { period, blocks },
    entities,
  } = props;
  const months = usePeriodMonths(period);
  return (
    <Table
      header={
        <HeaderRow
          months={months}
          group={
            clear ? (
              <Button onClick={clear} size="sm" variant="outline-danger" className="py-0">
                clear
              </Button>
            ) : undefined
          }
        />
      }
    >
      {blocks.map(({ id, target, invoiced, invoiceable, months: blockMonths }) => {
        const block = entities.block[id];
        return (
          <Row
            key={id}
            group={<BlockWidget {...block} link />}
            total={fmt(target)}
            bound={fmt(invoiced)}
            remainder={<strong>{fmt(invoiceable)}</strong>}
          >
            {months.map(({ value }) => {
              let ratio = null;
              let selectable = false;
              const month = blockMonths[value];
              if (month) {
                if (!month.target.eq(0)) {
                  ratio = month.invoiced.div(month.target).mul(100).toNumber();
                }

                selectable = Object.keys(month.amounts).length > 0;
              }

              const selected = selection[id]?.includes(value);

              return (
                <td
                  key={value}
                  className={cx({
                    'f__c--month': true,
                    'f__c--selected': selected,
                    'f__c--selectable': selectable,
                  })}
                  data-testid={`${id}_${value}`}
                  onClick={selectable ? () => select(id, value) : undefined}
                >
                  {month && <InvoicingProgress direction="income" ratio={ratio} />}
                </td>
              );
            })}
          </Row>
        );
      })}
    </Table>
  );
}
