export const initialValues = {
  title: '',
  date_from: null,
  date_to: null,
  // campaing
  agencies: [],
  clients_op: 'AND',
  clients: [],
  activities_op: 'AND',
  activities: [],
  types: [],
  // supplier
  providers: [],

  departments_op: 'AND',
  departments: [],
  // number: null,

  // type: null,
  // direction: null,

  managed_types: [],
  commission: [
    {
      target: '0.00',
      rate: '',
      current: true,
    },
  ],
};
