import { ReactElement, ReactNode, useCallback, useState } from 'react';
import { DownloadButton, Icon } from './ui';
import { Perm, useHasPerms } from 'core/permissions';
import { stringify } from 'qs';
import { useModel } from './scenes';
import { Button, DialogBody, DialogClose, DialogFooter, DialogHeader, RenderProps, openModal } from '@ff-it/ui';
import { SubmissionErrors } from 'final-form';
import { FileField, Form, Submit, getDRFFormError } from '@ff-it/form';
import { checkActionError, required } from 'utilities';
import { fetcher } from 'services';
import { toast } from 'react-toastify';
import { useTable } from './ControlledTable';

interface ListExportProps {
  permission?: Perm;
  filter?: Record<string, unknown>;
  endpoint?: string;
  children?: ReactNode;
}

export function NewListExport({
  permission,
  endpoint: providedEndpoint,
  filter: extraFilter,
  children = 'Export',
}: ListExportProps): ReactElement | null {
  const { endpoint } = useModel();
  const { filter, queryParams } = useTable();
  const [canExport] = useHasPerms(permission);
  if (!canExport) {
    return null;
  }

  const url = providedEndpoint || `${endpoint}export/`;

  return (
    <DownloadButton
      url={`${url}?${stringify({ ...extraFilter, ...queryParams, ...filter })}`}
      icon="file-excel"
      title="Export"
      size="sm"
      variant="outline-secondary"
      className="mr-1"
    >
      {children}
    </DownloadButton>
  );
}

type ImportSubmitHandler = (values: any) => Promise<SubmissionErrors | void>;

export function ImportDialog({
  onSubmit,
  submitHandler,
}: RenderProps & {
  submitHandler: ImportSubmitHandler;
}): ReactElement {
  return (
    <Form
      onSubmit={(v) =>
        submitHandler(v).then((v) => {
          if (!v) {
            onSubmit();
          }

          return v;
        })
      }
    >
      <DialogHeader title="Import" />
      <DialogBody>
        <FileField name="file" label="File" required validate={required} />
      </DialogBody>
      <DialogFooter>
        <Submit>Import</Submit>
        <DialogClose className="ml-auto" />
      </DialogFooter>
    </Form>
  );
}

function useImportSubmitHandler(endpoint: string): ImportSubmitHandler {
  const [err, setError] = useState();
  if (err) {
    throw err;
  }
  return useCallback(
    async (values) => {
      const fd = new FormData();
      for (const [key, value] of Object.entries(values)) {
        if (value) {
          fd.append(key, value as any);
        }
        try {
          const res = await fetcher<{ message: string }>({
            url: endpoint,
            method: 'POST',
            body: fd,
          });
          toast.success(res.message);
        } catch (error: any) {
          const formError = getDRFFormError(error);
          if (formError) {
            return formError;
          }
          if (!checkActionError(error)) {
            setError(error);
          }
        }
      }
    },
    [endpoint],
  );
}

interface ListImportProps {
  permission?: Perm;
  endpoint?: string;
  children?: ReactNode;
}

export function ListImport({
  permission,
  endpoint: providedEndpoint,
  children = 'Import',
}: ListImportProps): ReactElement | null {
  const { endpoint } = useModel();
  const url = providedEndpoint || `${endpoint}import/`;

  const submitHandler = useImportSubmitHandler(url);
  const [canImport] = useHasPerms(permission);
  if (!canImport) {
    return null;
  }

  const clickHandler = (): any => openModal((props) => <ImportDialog {...props} submitHandler={submitHandler} />);

  return (
    <Button title="Import" size="sm" variant="outline-secondary" className="mr-1" onClick={clickHandler}>
      {children} <Icon className="ml-1" icon="file-excel" />
    </Button>
  );
}
