import { listPerm, any } from 'core/permissions';
import { permissions as groups } from './ReportingGroups/permissions';
import revenue from 'modules/reporting/revenue/permissions';
import client from 'modules/reporting/client/permissions';
import provider from 'modules/reporting/provider/permissions';
import campaign from 'modules/reporting/campaign/permissions';
import type_ from 'modules/reporting/type/permissions';
import pivot from 'modules/reporting/pivot/permissions';
import raw from 'modules/reporting/raw/permissions';
import activity from 'modules/reporting/activity/permissions';
import { createViewLocal } from 'modules/reporting/common';

export const menu = {
  to: '/reporting',
  label: 'Reporting',
  icon: 'chart-simple' as const,
  children: [
    {
      to: '/reporting/revenue',
      label: 'Revenue',
      visible: revenue.local,
    },
    {
      to: '/reporting/client',
      label: 'Clients',
      visible: createViewLocal(client.local, client.local_managed),
    },
    {
      to: '/reporting/provider',
      label: 'Providers',
      visible: createViewLocal(provider.local, provider.local_managed),
    },
    {
      to: '/reporting/campaign',
      label: 'Campaigns',
      visible: createViewLocal(campaign.local, campaign.local_managed),
    },
    {
      to: '/reporting/type',
      label: 'Types',
      visible: createViewLocal(type_.local, type_.local_managed),
    },
    {
      to: '/reporting/activity',
      label: 'Activities',
      visible: createViewLocal(activity.local, activity.local_managed),
    },
    {
      to: '/reporting/raw',
      label: 'Raw',
      visible: any(raw.local, raw.local_managed),
    },
    {
      to: '/reporting/pivot',
      label: 'Pivot',
      visible: any(pivot.local, pivot.managed),
    },
    { to: '/reporting/groups', label: 'Reporting groups', visible: listPerm(groups) },
  ],
};
