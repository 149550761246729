import { createColumn } from '../createColumn';
import { center } from '../style.css';
import { View } from './View';
import { Edit } from './Edit';

export const ClientDiscountColumn = createColumn(
  { key: 'client_discount', name: 'Client disc.', width: 74, className: center },
  {
    renderView: (props) => (props.row.kind === 'SMART' ? null : <View {...props} direction="client" />),
    renderEdit: (props) => (props.row.kind === 'SMART' ? null : <Edit {...props} direction="client" />),
    displayCellContent: true,
  },
);

export const ProviderDiscountColumn = createColumn(
  { key: 'provider_discount', name: 'Prov. disc.', width: 74, className: center },
  {
    renderView: (props) => (props.row.kind === 'SMART' ? null : <View {...props} direction="provider" />),
    renderEdit: (props) => (props.row.kind === 'SMART' ? null : <Edit {...props} direction="provider" />),
    displayCellContent: true,
  },
);
