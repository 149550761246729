import { ReactElement } from 'react';
import { useEntity, TabNav } from 'components';
import { NavLinkProps } from 'react-router-dom';
import { DepartmentSet } from 'modules/supplier/department/types';

export function Nav(): ReactElement {
  const { item } = useEntity<DepartmentSet>();

  const nav: NavLinkProps[] = [
    {
      to: '',
      end: true,
      children: 'Update',
    },
    {
      to: 'price_lists',
      children: 'Price lists',
    },
  ];

  return (
    <div className="container-fluid header ">
      <div className="d-flex flex-row align-items-center">
        <div className="mb-2">
          <h2>{item.name}</h2>
        </div>
      </div>
      <TabNav nav={nav} />
    </div>
  );
}
