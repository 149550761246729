import { Box } from '@ff-it/ui';
import { ReactElement, ReactNode } from 'react';
import { cell as s } from './style.css';

type RowContainerProps = {
  className?: string;
  cell?: 'td' | 'th';
  rowLink?: ReactNode;
  supplier?: ReactNode;
  period?: ReactNode;
  invoiceable?: ReactNode;
  invoiced?: ReactNode;
  balance?: ReactNode;
  control?: ReactNode;
};

export function RowContainer({
  className,
  cell = 'td',
  rowLink,
  supplier,
  period,
  invoiceable,
  invoiced,
  balance,
  control,
}: RowContainerProps): ReactElement {
  return (
    <Box component="tr" className={className}>
      <Box component={cell} textAlign="center">
        {rowLink}
      </Box>
      <Box component={cell} className={s}>
        {supplier}
      </Box>
      <Box component={cell} className={s} textAlign="center">
        {period}
      </Box>
      <Box component={cell} className={s} textAlign="right">
        {invoiceable}
      </Box>
      <Box component={cell} className={s} textAlign="right">
        {invoiced}
      </Box>
      <Box component={cell} className={s} textAlign="right">
        {balance}
      </Box>
      <Box component={cell} textAlign="center">
        {control}
      </Box>
    </Box>
  );
}
