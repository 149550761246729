import { activityTypeOptions } from 'options';
import { Revenue } from './Revenue';
import permissions from './permissions';
import { EnterpriseField, withHasPerm } from 'components';

export const GlobalRevenue = withHasPerm(() => {
  return (
    <Revenue
      storageKey="reprot_revenue_global"
      types={activityTypeOptions}
      url="reporting/revenue/global/"
      filterFields={
        <EnterpriseField name="enterprises" isMulti className="ml-3" defaultOptions placeholder="All agencies" simple />
      }
    />
  );
}, permissions.global);
