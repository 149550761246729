import useSWR, { Key, SWRConfiguration } from 'swr';
import { ReactElement, ReactNode, SetStateAction, useCallback, useState } from 'react';
import { LoadingContainer } from '@ff-it/ui';
import { EntityContext } from './context';
import { useModel } from './context';
import { useParams, Outlet } from 'react-router-dom';
import { Breadcrumb } from '../layout';
import { EntityContextType } from './types';
import { NotFound, PermissionDenied } from './errors';
import { RequestFailure } from '@ff-it/api';

interface EntitySceneProps {
  children?: ReactNode;
  element?: ReactNode;
}

export function EntityScene<T extends object = any>({
  children,
  element = <Outlet />,
}: EntitySceneProps): ReactElement {
  const { endpoint, entityTitle } = useModel<T>();
  const params = useParams();
  const { id } = params;
  const url = `${endpoint}${id}/`;

  return (
    <ItemLoader<T> url={url}>
      {(ctx) => (
        <EntityContext.Provider value={ctx}>
          <Breadcrumb>{entityTitle(ctx.item)}</Breadcrumb>
          {children}
          {element}
        </EntityContext.Provider>
      )}
    </ItemLoader>
  );
}

interface ItemLoaderProps<T> {
  url: string;
  children: (ctx: EntityContextType<T>) => ReactNode;
  testId?: string;
  swrKey?: Key;
  swrOptions?: SWRConfiguration;
  notFoundElement?: ReactElement;
}

export function ItemLoader<T>({
  url,
  children,
  testId,
  swrOptions,
  swrKey,
  notFoundElement = <NotFound />,
}: ItemLoaderProps<T>): ReactElement {
  const [spinning, setSpinning] = useState<boolean>(false);
  const {
    data: item,
    isLoading,
    error,
    mutate,
  } = useSWR<T, RequestFailure<unknown>>(swrKey || { url, method: 'GET' }, swrOptions);

  // biome-ignore lint/correctness/useExhaustiveDependencies: mutate is stable
  const setItem = useCallback(
    (action: SetStateAction<T>) =>
      mutate(
        (currentState) => (typeof action === 'function' ? (action as (prev: T) => T)(currentState as T) : action),
        { revalidate: false },
      ),
    [],
  );

  if (error) {
    if (error.status === 404) {
      return notFoundElement;
    }
    if (error.status === 403) {
      return <PermissionDenied />;
    }
    throw error;
  }

  let content = null;

  if (item) {
    const context: EntityContextType<T> = {
      endpoint: url,
      item,
      setItem,
      setSpinning,
      mutate,
    };

    content = children(context);
  }
  return (
    <LoadingContainer loading={isLoading || spinning} className="flex-grow-1 flex-column d-flex " testId={testId}>
      {content}
    </LoadingContainer>
  );
}
