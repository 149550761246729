import { Breadcrumb } from 'components';
import { Outlet } from 'react-router';
import { Campaigns } from './Campaigns';
import { Summary } from './Summary';
import { Tickets } from './Tickets';
import { Calendar } from './Calendar';

export const route = {
  path: '/dashboard',
  element: (
    <>
      <Breadcrumb>Dashboard</Breadcrumb>
      <Outlet />
    </>
  ),
  children: [
    {
      index: true,
      element: <Campaigns />,
    },
    {
      path: 'summary',
      element: <Summary />,
    },
    {
      path: 'tickets/*',
      element: <Tickets />,
    },
    {
      path: 'calendar/*',
      element: <Calendar />,
    },
  ],
};
