import { createContext, useContext } from 'react';
import { ModelContextType, EntityContextType } from './types';

export const ModelContext = createContext<ModelContextType<any> | null>(null);

export function useModel<T>(): ModelContextType<T> {
  const ctx = useContext<ModelContextType<T> | null>(ModelContext);

  if (ctx === null) {
    throw new Error(`Model context is missing`);
  }

  return ctx;
}

export const EntityContext = createContext<EntityContextType<any> | null>(null);

export function useEntity<I = any>(): EntityContextType<I> {
  const ctx = useContext<EntityContextType<I> | null>(EntityContext);

  if (ctx === null) {
    throw new Error(`Entity context is missing`);
  }

  return ctx;
}
