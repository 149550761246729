import { ReactElement } from 'react';
import { TextField } from '@ff-it/form';
import { FIELD_MAP } from './Fields';
import { CommonFields } from './CommonFields';
import { AttachmentField } from 'components';
import { FieldsProps } from './types';

export function GenericFields(props: FieldsProps): ReactElement {
  const Fields = FIELD_MAP[props.type];

  return (
    <>
      <CommonFields {...props} />
      {props.type !== 'SEO' && <TextField name="suggested_media" label="Suggested media" />}
      <TextField name="comment" label="Comment" multiline autosize />

      {Fields && <Fields />}

      <AttachmentField name="brief_attachments" label="Attachements" />
    </>
  );
}
