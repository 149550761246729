import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { TextField } from '@ff-it/form';
import permissions from 'modules/client/brand/permissions';
import type { Brand } from 'modules/client/types';
import { CreateScene, DeleteScene, EntityScene, ModelScene, IndexScene, UpdateScene } from 'components';
import { TableColumns } from '@ff-it/ui';

const columns: TableColumns<Brand> = [
  {
    content: 'Title',
    key: 'title',
    render: ({ id, title }: Brand) => <Link to={`${id}`}>{title}</Link>,
  },
];

export function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="title" label="Title" className="col-md-6" required />
      </div>
    </div>
  );
}

const initialValues = {
  code: '',
  title: '',
};

const model = {
  endpoint: 'client/brands/',
  permissions,
  title: 'Brand',
  pluralTitle: 'Brands',
  entityTitle: ({ title }: Brand) => title,
};

export const route = {
  path: 'brands',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: <IndexScene columns={columns} />,
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <UpdateScene>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};
