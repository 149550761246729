import { CompanyPersons } from 'modules/crm/CompanyPersons';
import { useEntity } from 'components';
import type { Person } from 'modules/crm/types';
import type { Client } from 'modules/client/types';
import { personPermissions as permissions } from './permissions';
import { ReactElement } from 'react';

export function Persons(): ReactElement {
  const { item } = useEntity<Client>();

  const model = {
    endpoint: `supplier/providers/${item.id}/persons/`,
    title: 'Person',
    pluralTitle: 'Persons',
    entityTitle: (e: Person) => `${e.first_name} ${e.last_name}`,
    permissions,
  };
  return <CompanyPersons {...model} />;
}
