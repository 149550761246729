import { ReactElement } from 'react';
import { Link, NavLink, useLocation, useResolvedPath } from 'react-router-dom';
import { Icon } from '../../ui';

import { NavItem } from './types';
import { useCheck } from 'core/permissions';
import { Box, MenuItem, MenuList, Popover, useRenderTransition } from '@ff-it/ui';
import { AutoPlacementOptions, safePolygon } from '@floating-ui/react';
import { popMenuWrap, submenu, submenuItem } from './style.css';

export interface MenuProps {
  menu: NavItem[];
}

const sizeOptions: any = {
  apply({ availableHeight, elements }: any) {
    Object.assign(elements.floating.style, {
      maxHeight: `${availableHeight}px`,
    });
  },
  padding: 8,
};
const hoverOptions = {
  enabled: true,
  delay: { open: 75 },
  handleClose: safePolygon({
    blockPointerEvents: true,
    // buffer: 5,
    requireIntent: false,
  }),
  // move: false,
};
const placementOptions: AutoPlacementOptions = { allowedPlacements: ['right-start', 'right', 'right-end'] };

const clickOptions = {
  enabled: false,
};

function InlineMenu({ visible, visibleChildren }: { visible: boolean; visibleChildren: any[] }): ReactElement | null {
  const { shouldMount, stage } = useRenderTransition(visible, 150);
  if (!shouldMount) {
    return null;
  }

  return (
    <MenuList
      className={submenu}
      fontWeight="regular"
      textSize="sm"
      marginTop="-xs"
      data-stage={stage}
      style={{
        transition: 'opacity .15s linear',
        // maxHeight: stage === 'enter' ? '100vh' : '0px',
        opacity: stage === 'enter' ? 1 : 0,
        overflow: 'hidden',
      }}
    >
      {visibleChildren.map((item, idx) => (
        <MenuItem
          className={submenuItem}
          component={NavLink}
          to={item.to}
          end={item.end}
          label={item.label}
          key={idx}
          suffix={item.suffix}
        />
      ))}
    </MenuList>
  );
}

function PopMenu({
  collapsed,
  item,
  visibleChildren,
}: {
  collapsed: boolean;
  item: any;
  visibleChildren: any[];
}): ReactElement {
  const locationPathname = useLocation().pathname;
  const toPathname = useResolvedPath(item.to).pathname;
  const isActive = item.end ? locationPathname === toPathname : locationPathname.startsWith(toPathname);
  // @TODO figure out if we event want this
  // if (!collapsed) {
  // if (false) {
  if (!collapsed && isActive) {
    return (
      <MenuItem
        prefix={item.icon ? <Icon icon={item.icon} /> : undefined}
        component={Link}
        to={visibleChildren[0].to}
        label={!collapsed ? item.label : undefined}
        active={isActive}
        // suffix={item.suffix}
        suffix={<Icon icon="chevron-down" size="xs" />}
      >
        <InlineMenu visible={isActive} visibleChildren={visibleChildren} />
      </MenuItem>
    );
  }
  return (
    <Popover
      // re-render on navigation
      // key={locationPathname}
      placement="auto"
      offsetOptions={4}
      sizeOptions={sizeOptions}
      placementOptions={placementOptions}
      hoverOptions={hoverOptions}
      clickOptions={clickOptions}
      content={
        <Box className={popMenuWrap}>
          <MenuList className={submenu}>
            {visibleChildren.map((item, idx) => (
              <MenuItem
                component={NavLink}
                to={item.to}
                end={item.end}
                label={item.label}
                key={idx}
                suffix={item.suffix}
                className={submenuItem}
              />
            ))}
          </MenuList>
        </Box>
      }
    >
      <MenuItem
        prefix={item.icon ? <Icon icon={item.icon} /> : undefined}
        component={Link}
        to={visibleChildren[0].to}
        label={!collapsed ? item.label : undefined}
        active={isActive}
        suffix={collapsed ? item.suffix : <Icon size="xs" icon="chevron-right" />}
      />
    </Popover>
  );
}

export function Menu({ menu, collapsed }: MenuProps & { collapsed: boolean }): ReactElement {
  const permCheck = useCheck();

  return (
    <MenuList variant="dark" fontWeight="semibold">
      {menu.map(({ children, visible, ...item }, index) => {
        if (!permCheck(visible)) {
          return null;
        }

        if (children) {
          const visibleChildren = (children || []).filter(({ visible }) => permCheck(visible));

          if (visibleChildren.length) {
            return <PopMenu key={index} item={item} collapsed={collapsed} visibleChildren={visibleChildren} />;
          } else {
            return null;
          }
        }
        return (
          <MenuItem
            prefix={item.icon ? <Icon icon={item.icon} /> : undefined}
            component={NavLink}
            to={item.to}
            end={item.end}
            label={!collapsed ? item.label : undefined}
            key={index}
          />
        );
      })}
    </MenuList>
  );
}
