import { Field, useEntity, Fieldset } from 'components';
import type { ReactElement } from 'react';
import invariant from 'tiny-invariant';
import type { Ticket } from 'modules/support/types';

export function AddDepartment(): ReactElement {
  const {
    item: { data },
  } = useEntity<Ticket>();
  invariant(data, 'data present');

  return (
    <Fieldset title="Add department">
      <div className="form-row">
        <Field name="data.company" label="Company" className="col-md-4">
          {(c) => c.title}
        </Field>
      </div>
      <div className="form-row">
        <Field name="data.name" label="Department name" className="col-md-6" />
      </div>
      <div className="form-row">
        <Field name="data.service" label="Service" className="col-md-6" />
        <Field name="data.activity_type" label="Type" className="col-md-2" />
      </div>
      <div className="form-row">
        <Field name="data.extra_info" label="Extra info" help="Any other info to know" className="col-md-4" />
      </div>
    </Fieldset>
  );
}
