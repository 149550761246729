import type {
  Column,
  RenderCellProps,
  RenderEditCellProps,
  RenderHeaderCellProps,
  RenderSummaryCellProps,
} from 'components/DataGrid';
import type { ReactElement, ReactNode } from 'react';
import { GridSummaryRow, RowOrGroup, GridRow, GridGroup } from '../types';
import cx from 'clsx';
import { invalidCell } from '../style.css';
import { cellIsEditable, cellIsRequired, cellIsValid } from '../util';

type CreateColumnArgs = {
  key: string;
  name?: string | ReactElement;
  width: number | null;
  frozen?: boolean;
  renderView?: (props: RenderCellProps<RowOrGroup, GridSummaryRow>) => ReactNode;
  renderHeader?: (props: RenderHeaderCellProps<RowOrGroup, GridSummaryRow>) => ReactNode;
  renderSummary?: (props: RenderSummaryCellProps<GridSummaryRow, RowOrGroup>) => ReactNode;
  className?: string;
  headerClassName?: string;
  summaryClassName?: string;
};

type Args<RT extends RowOrGroup> = {
  renderView?: (props: RenderCellProps<RT, GridSummaryRow>) => ReactNode;
  renderEdit?: (props: RenderEditCellProps<RT, GridSummaryRow>) => ReactNode;
  displayCellContent?: boolean | ((row: RT) => boolean);
  className?: (row: RT) => string | undefined;
};

export function createColumn(
  {
    key,
    name = '',
    width,
    frozen,
    renderHeader: renderHeaderCell,
    renderSummary: renderSummaryCell,
    className,
    headerClassName,
    summaryClassName,
    renderView,
  }: CreateColumnArgs,
  // just row, just group, both different, bot same
  row?: Args<GridRow>,
  group?: Args<GridGroup>,
  rest?: Partial<Column<RowOrGroup, GridSummaryRow>>,
): Column<RowOrGroup, GridSummaryRow> {
  const renderCell = renderView
    ? renderView
    : (props: RenderCellProps<RowOrGroup, GridSummaryRow>): ReactNode => {
        if (!props.row._isGroup) {
          return row?.renderView?.(props as RenderCellProps<GridRow, GridSummaryRow>);
        }
        return group?.renderView?.(props as RenderCellProps<GridGroup, GridSummaryRow>);
      };

  const renderEditCell =
    row?.renderEdit || group?.renderEdit
      ? (props: RenderEditCellProps<RowOrGroup, GridSummaryRow>): ReactNode => {
          if (!props.row._isGroup) {
            return row?.renderEdit?.(props as RenderEditCellProps<GridRow, GridSummaryRow>);
          }
          return group?.renderEdit?.(props as RenderEditCellProps<GridGroup, GridSummaryRow>);
        }
      : undefined;

  const displayCellContent = (r: RowOrGroup): boolean => {
    const display = r._isGroup ? group?.displayCellContent : row?.displayCellContent;

    return typeof display === 'function' ? (display as any)(r) : Boolean(display);
  };

  const cellClass = (r: RowOrGroup): string => {
    if (r._isGroup) {
      return cx(className, group?.className?.(r));
    }
    return cx(className, !cellIsValid(r, key) && invalidCell, row?.className?.(r));
  };

  return {
    key,
    name,
    cellClass,
    headerCellClass: headerClassName || className,
    summaryCellClass: summaryClassName || className,
    width,
    minWidth: width,
    maxWidth: width,
    resizable: false,
    sortable: false,
    frozen,
    renderHeaderCell,
    renderSummaryCell,
    renderCell,
    renderEditCell,
    displayCellContent,
    editable: (row) => cellIsEditable(row, key),
    required: (row) => cellIsRequired(row, key),
    ...rest,
  };
}
