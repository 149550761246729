import { ReactElement } from 'react';
import { DateRangePickerField, DecimalField, useField } from '@ff-it/form';
import { fmt, required } from 'utilities';
import Big from 'big.js';
import { Box, Button } from '@ff-it/ui';

type PositionFormProps = {
  goalSeek?: {
    balance: Big;
    price: Big;
  };
};

export function PositionForm({ goalSeek }: PositionFormProps): ReactElement {
  const {
    input: { value: quantity, onChange },
  } = useField('quantity', { subscription: { value: true } });

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <DecimalField
            name="quantity"
            required
            validate={required}
            label="Quantity"
            autoFocus
            precision={4}
            size="sm"
          />
        </Box>
        {!quantity && goalSeek && (
          <Box display="flex" alignItems="flex-end" marginLeft="sm" className="mb-3">
            <Button
              testId="goal-seek"
              size="sm"
              variant="outline-primary"
              onClick={() => {
                // exact division
                const exactQuantity = goalSeek.balance.div(goalSeek.price).round(4, 1);
                if (exactQuantity.mul(goalSeek.price).round(2, 1).eq(goalSeek.balance)) {
                  onChange(exactQuantity.toFixed());
                  return;
                }

                // pessimistic round down, best we can do with 4 sings
                const startQuantity = goalSeek.balance.minus(Big('0.005')).div(goalSeek.price);

                let precision = 2;
                let currentQuantity = startQuantity.round(0, 1);
                while (precision <= 4) {
                  currentQuantity = startQuantity.round(precision, 1);
                  // if we match we bail
                  if (currentQuantity.mul(goalSeek.price).round(2, 1).eq(goalSeek.balance)) {
                    break;
                  }
                  precision++;
                }

                onChange(currentQuantity.toFixed());
              }}
            >
              Goal seek <strong>{fmt(goalSeek.balance)}</strong>
            </Button>
          </Box>
        )}
      </Box>
      <DateRangePickerField fromName="date_from" toName="date_to" required validate={required} label="Period" />
    </>
  );
}
