import { DialogEditor, RenderEditCellProps } from 'components/DataGrid';
import { GridRow } from '../types';

import { ReactNode } from 'react';

import { usePlanController } from '../../usePlanController';
import { FormFields } from './FormFields';

export function Edit(props: RenderEditCellProps<GridRow, any> & { month: string }): ReactNode {
  const controller = usePlanController();
  const { row, month } = props;
  const delivery = row.delivery[month];
  const monthSum = row.months[month];

  return (
    <DialogEditor
      {...props}
      submitHandler={async (values) =>
        await controller.formRequest(
          delivery
            ? {
                method: 'PUT',
                url: `plan/rows/${props.row.id}/delivery/${month}/`,
                body: values,
              }
            : {
                method: 'POST',
                url: `plan/rows/${props.row.id}/delivery/`,
                body: values,
              },
        )
      }
      dialogHeader={<strong>{props.column.name}</strong>}
      testId="month-sum-edit"
      initialValues={
        delivery
          ? delivery
          : {
              month,
              quantity: monthSum ? monthSum.target_expense_quantity : null,
              total: null,
              alt_prices: false,
              alt_quantity: monthSum ? monthSum.target_income_quantity : null,
              alt_total: null,
            }
      }
      onRemove={
        delivery
          ? async () => {
              await controller.actionRequest({
                method: 'DELETE',
                url: `plan/rows/${props.row.id}/delivery/${month}/`,
              });
            }
          : undefined
      }
    >
      <FormFields />
    </DialogEditor>
  );
}
