import { ReactNode, ReactElement } from 'react';
import './Table.scss';

type TableProps = {
  header?: ReactNode;
  children?: ReactNode;
};

export function Table({ header, children }: TableProps): ReactElement {
  return (
    <table className="table  table--finance">
      <thead>{header}</thead>
      <tbody>{children}</tbody>
    </table>
  );
}
