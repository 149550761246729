import { ReactElement } from 'react';
import { withHasPerm } from 'components';
import { Campaign } from './Campaign';
import permissions from './permissions';
import { LocalVisibilityReport, ReportContainer } from '../common';

export function LocalCampaign(): ReactElement {
  return (
    <LocalVisibilityReport
      localPerm={permissions.local}
      managedPerm={permissions.local_managed}
      Component={Campaign}
      title="Campaigns"
    />
  );
}

export const GlobalCampaign = withHasPerm(
  () => (
    <ReportContainer title="Campaigns">
      <Campaign visibility="GLOBAL" />
    </ReportContainer>
  ),
  permissions.global,
);
