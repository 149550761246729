import { ReactElement } from 'react';
import cx from 'clsx';
import { positionErrors } from './const';
import type { Calendar, PositionError, Segment, SegmentSelectionStateType } from './types';
import type { GridRow } from '../types';
import { calcRowSelection } from './calc';
import { segment as segmentClassName, selectionError } from './style.css';

export function Segments({
  segments,
  positions,
  intervalToBounds,
  rowSelection,
  readonly,
}: {
  segments: Segment[];
  positions: GridRow['positions'];
  intervalToBounds: Calendar['intervalToBounds'];
  readonly?: boolean;
  rowSelection: SegmentSelectionStateType | null;
}): ReactElement | null {
  if (segments.length === 0) {
    return null;
  }

  const selection = rowSelection ? calcRowSelection(positions, rowSelection, intervalToBounds) : undefined;

  return (
    <>
      {segments.map((s) => {
        const active = selection?.id === s.id;
        const segment: Segment & {
          error?: PositionError | null;
        } = selection?.id === s.id ? selection : s;
        const { id, left, width, quantity, error } = segment;

        return (
          <div
            key={id}
            className={cx(segmentClassName, { active, error, readonly })}
            title={quantity.toString()}
            style={{ left, width }}
          >
            <span className="contain">{quantity}</span>

            {error && <span className={selectionError}>{positionErrors[error]}</span>}
          </div>
        );
      })}
    </>
  );
}
