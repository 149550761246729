import { useMembership } from 'services';
import { formatISO } from 'date-fns';
import type { Income, InvoiceRow } from './types';
import { parseBig } from 'utilities';
import { initialValues as baseIntialValues } from './initialValues';

export function useInitialValues(credit_source?: Income): Record<string, unknown> {
  const { vat_strategy } = useMembership();
  const initialValues = {
    ...baseIntialValues,
    vat_strategy,
  };

  if (credit_source) {
    const { payer, alt_currency, vat_rate } = credit_source;

    const row: InvoiceRow & { __NEW__: number } = {
      // @FIXME #802
      kind: 'AMOUNT',
      title: ['LATVIAN_DEFAULT', 'LATVIAN_ENGLISH'].includes(payer.invoice_template)
        ? 'Par reklāmas izvietojumiem'
        : '',
      unit: 'UNIT',
      quantity: '1',
      unit_price: parseBig(credit_source.sums.link_balance).mul(-1).toFixed(2),
      compensated_to: null,
      __NEW__: Date.now(),
    };

    return {
      ...initialValues,
      credit_source,
      type: 'CREDIT',
      payer,
      checkout_date: formatISO(new Date(), { representation: 'date' }),
      vat_rate,
      alt_currency,
      rows: [row],
    };
  }
  return initialValues;
}
