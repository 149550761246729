import { SelectField, SelectFieldProps } from '@ff-it/form';
import { Language } from 'types';
import type { Option } from 'options';
import { ReactElement } from 'react';
import useSWR from 'swr/immutable';

export function useLanguageOptions(): Option<Language>[] {
  const { data } = useSWR<Option<Language>[]>({
    url: `core/languages/`,
    method: 'GET',
  });
  return data || [];
}

export function LanguageField(
  props: Omit<
    SelectFieldProps<Option<Language>, boolean>,
    'options' | 'loadOptions' | 'getOptionLabel' | 'getOptionValue' | 'simple'
  >,
): ReactElement {
  const languages = useLanguageOptions();

  return <SelectField options={languages} {...props} simple />;
}
