import useSWR from 'swr';
import { AgencyActivityTypesField, AttachmentField, FormInline, FormInlineMemberError, RemoveButton } from 'components';
import {
  TextField,
  DateRangePickerField,
  IntField,
  SelectField,
  DecimalField,
  useOnFieldChange,
  useForm,
} from '@ff-it/form';
import { agreementKindOptions } from 'configuration';
import { ReactElement } from 'react';
import { DepartmentField } from '../department/DepartmentField';
import { required } from 'utilities';
import { departmentProductFieldOptions } from '../products/ProductField';

import type { EmbeddedProduct } from '../products';

function ProvidedDepartmentProductRow({
  name,
  products,
}: {
  name: string;
  products: EmbeddedProduct[];
}): React.ReactElement {
  return (
    <div className="form-row">
      <div className="col-5" />
      <SelectField
        name={`${name}.product`}
        placeholder="Product"
        className="col-md-3 mb-1"
        required
        validate={required}
        options={products}
        {...departmentProductFieldOptions}
      />
      <DecimalField
        name={`${name}.agency_discount`}
        placeholder="Agency discount"
        className="col-md-2 mb-1"
        precision={2}
      />
    </div>
  );
}

function ProvidedDepartmentRow({ name, remove }: { name: string; remove?: () => void }): React.ReactElement {
  const form = useForm();

  const department = useOnFieldChange(`${name}.department`, () => {
    form.change(`${name}.provided_products`, []);
  });
  const type = useOnFieldChange(`${name}.type`, () => {
    form.change(`${name}.provided_products`, []);
  });

  const { data: products } = useSWR(
    department && type
      ? {
          url: 'supplier/products/',
          method: 'GET',
          queryParams: {
            type,
            department: department.id,
          },
        }
      : null,
  );

  return (
    <div className="bg-light px-3 pt-2 mb-1 border">
      <div className="d-flex flex-row border-bottom pb-1 mb-1">
        <div className="flex-grow-1">
          <FormInlineMemberError name={name} />
          <div className="form-row">
            <DepartmentField
              name={`${name}.department`}
              placeholder="Department"
              className="col-md-3 mb-0"
              required
              validate={required}
            />
            <AgencyActivityTypesField
              name={`${name}.type`}
              placeholder="Type"
              className="col-md-2 mb-0"
              required
              validate={required}
            />
            <div className="col-md-3" />
            <DecimalField
              name={`${name}.agency_discount`}
              placeholder="Agency discount"
              className="col-md-2 mb-0"
              precision={2}
            />
          </div>
        </div>
        <div className="form-group ml-2 d-flex align-items-start">
          <RemoveButton data-test-id="remove-inline" onClick={remove} disabled={!remove} />
        </div>
      </div>
      {products && (
        <FormInline
          flush
          name={`${name}.provided_products`}
          initialValues={{}}
          testId="provided_products"
          addLabel="Add product"
          className="mb-1"
        >
          {(name) => <ProvidedDepartmentProductRow name={name} products={products} />}
        </FormInline>
      )}
    </div>
  );
}

export function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="number" label="Number" className="col-md-3" help="Leave blank to assign" />
        <SelectField label="Kind" name="kind" options={agreementKindOptions} simple required className="col-md-3" />
        <DateRangePickerField label="Period" fromName="date_from" toName="date_to" className="col-md-3" required />
        <IntField label="Invoice payment days" name="invoice_payment_days" className="col-md-3" />
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <AttachmentField name="attachments" label="Attachments" />
        </div>
        <div className="col-md-6"></div>
      </div>

      <div className="form-row">
        <TextField
          name="terms"
          className="col-6"
          label="Terms and conditions"
          multiline
          autosize
          help="General terms and conditions"
        />
        <TextField
          name="notes"
          className="col-6"
          label="Notes"
          multiline
          autosize
          help="Private notes for internal use"
        />
      </div>
      <div className="form-row">
        <DecimalField name="agency_discount" label="Agency discount %" className="col-md-2" precision={2} />
      </div>
      <FormInline
        className="mb-3 pb-2"
        name="provided_departments"
        initialValues={{}}
        testId="provided_departments"
        flush
        addLabel="Add department"
        Row={ProvidedDepartmentRow}
        title="Provided"
        header={
          <div className="px-3 py-1 mb-1 border bg-light">
            <div className="form-row" style={{ paddingRight: '29px' }}>
              <div className="col-3">Department</div>
              <div className="col-2">Type</div>
              <div className="col-3">Product</div>
              <div className="col-2">Override discount</div>
            </div>
          </div>
        }
      />
    </div>
  );
}
