import { Link, useRoutes } from 'react-router-dom';
import type { AVB } from 'modules/guarantee/types';
import { useEntity, withHasPerm, Icon, Breadcrumb, IndexContainer } from 'components';
import { columns } from 'modules/invoicing/expenses';
import permissions from 'modules/guarantee/cvb/permissions';
import { Button } from '@ff-it/ui';
import { ReactElement } from 'react';
import { CreateExpense } from './CreateExpense';

export const Expense = withHasPerm(function Expense(): ReactElement {
  const { endpoint } = useEntity<AVB>();

  const routes = useRoutes([
    {
      path: '',
      element: (
        <IndexContainer
          url={`${endpoint}expense/`}
          columns={columns}
          title="Expense"
          toolbar={
            <Button variant="outline-primary" size="sm" to="create" component={Link}>
              Create Expense <Icon className="ml-1" icon="circle-plus" />
            </Button>
          }
          filterFields={null}
        />
      ),
    },
    {
      path: 'create',
      element: <CreateExpense />,
    },
  ]);
  return (
    <>
      <Breadcrumb>Expense</Breadcrumb>
      {routes}
    </>
  );
}, permissions.invoice);
