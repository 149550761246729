import type { Dispatch, SetStateAction, ReactElement, ReactNode } from 'react';
import eq from 'fast-deep-equal';
import { FinalForm, FormSpy, IsDisabledContext } from '@ff-it/form';
import { FilterState } from './types';

export type FilterFormProps = {
  filter: FilterState;
  setFilter: Dispatch<SetStateAction<FilterState>>;
  children: ReactNode;
  disabled?: boolean;
};
const noOpSumbit = (): void => undefined;

export function FilterForm({ filter, setFilter, children, disabled = false }: FilterFormProps): ReactElement {
  return (
    <FinalForm<FilterState> onSubmit={noOpSumbit} initialValues={filter} subscription={{}}>
      {() => (
        <IsDisabledContext.Provider value={disabled}>
          <FormSpy<FilterState>
            subscription={{ values: true }}
            onChange={({ values }) => {
              !eq(filter, values) && setFilter(values);
            }}
          />
          {children}
        </IsDisabledContext.Provider>
      )}
    </FinalForm>
  );
}
