import { CreateScene, DeleteScene, EntityScene, ModelScene, IndexScene, UpdateScene } from 'components';
import permissions from 'modules/core/membership/permissions';
import { columns, Form } from 'modules/core/membership';
import type { AgencyMembership } from 'modules/core/types';

const model = {
  endpoint: 'agency/membership/',
  title: 'Membership',
  pluralTitle: 'Membership',
  entityTitle: ({ user }: AgencyMembership) => user.email,
  permissions,
};

const initialValues = {
  user: null,
  groups: [],
};

export const route = {
  path: 'membership',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: <IndexScene columns={columns} />,
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <UpdateScene>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};
