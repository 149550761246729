import type { ReactNode } from 'react';
import type { GridRow, GridSummaryRow } from '../types';
import { RenderCellProps, textEditor } from 'components/DataGrid';
import { createColumn } from './createColumn';
import { wrapContainer } from './style.css';

function renderValue(props: RenderCellProps<GridRow, GridSummaryRow>): ReactNode {
  const value = props.row.tg;
  // dash for locked
  return value ? (
    <span className={wrapContainer} title={value}>
      {value}
    </span>
  ) : props.isCellEditable ? null : (
    '—'
  );
}

export const TgColumn = createColumn(
  {
    key: 'tg',
    name: 'TG',
    width: 105,
  },
  {
    renderView: (props) => (props.row.kind === 'MEDIA' ? renderValue(props) : null),
    renderEdit: textEditor,
  },
);
