import { ComponentType, ReactElement, ReactNode } from 'react';
import { ActionMeta, StylesConfig, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import type { EditorProps } from './types';
import { formatValue, parseValue } from 'components/Select';
import equal from 'fast-deep-equal';
import { CellDropdown } from './CellDropdown';

export const selectStyle: StylesConfig<any, any, any> = {
  container: () => ({
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'inherit',
  }),
  control: (base, props) =>
    props.selectProps.isSearchable
      ? {
          ...base,
          margin: '0.5rem 0.5rem 0.2rem 0.5rem',
          boxShadow: undefined,
          padding: '0.25rem 0.5rem',
        }
      : { maxHeight: '0px' }, // we have to hide this. since contols and evens are bound to children

  valueContainer: (base) => ({
    ...base,
    padding: undefined,
    fontSize: undefined,
    fontWeight: undefined,
    lineHeight: undefined,
    color: undefined,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: undefined,
  }),
  singleValue: (base) => ({
    ...base,
    whiteSpace: undefined,
    marginLeft: undefined,
    marginRight: undefined,
  }),
  menu: () => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 0,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: undefined,
  }),
};

export const selectProps = {
  styles: selectStyle,
  openMenuOnFocus: true,
  menuIsOpen: true,
  blurInputOnSelect: false,
  isClearable: false,
  hideSelectedOptions: false,
  controlShouldRenderValue: false,
  backspaceRemovesValue: false,
  tabSelectsValue: false,
  closeMenuOnSelect: false,
};

export const Dummy = (): ReactNode => null;

type SelectEditorProps<Row, TSummaryRow> = EditorProps<Row, TSummaryRow> & {
  options: Array<any>;
  getOptionLabel?: (a: any) => string;
  getOptionValue?: (a: any) => string;
  formatOptionLabel?: (a: any) => ReactNode;
  isSimple?: boolean;
  isSearchable?: boolean;
  isCreatable?: boolean;
  isMulti?: boolean;
  keepOpenOnSelect?: boolean;
  Option?: ComponentType<any>;
  placeholder?: string;
};

export function SelectEditor<Row, TSummaryRow>({
  row,
  column,
  field = column.key,
  onRowChange,
  onClose,
  options,
  getOptionLabel = (v) => (v ? v.label : v),
  getOptionValue = (v) => (v ? v.value : v),
  isSimple,
  keepOpenOnSelect,
  Option = components.Option,
  isSearchable,
  placeholder,
  isCreatable = false,
  ...rest
}: SelectEditorProps<Row, TSummaryRow>): ReactElement {
  const value = row[field as keyof Row];
  const currentValue = (isSimple ? formatValue(options, value, true, getOptionValue) : value) || [];

  function onChange(v: any, _action: ActionMeta<any>): void {
    const nevValue = (isSimple ? parseValue(v, rest.isMulti, getOptionValue) : v) || [];

    if (!equal(currentValue, nevValue)) {
      onRowChange({ ...row, [field]: nevValue }, true, keepOpenOnSelect);
    } else {
      onClose(false, false);
    }
  }
  const Component = isCreatable ? CreatableSelect : Select;
  return (
    <CellDropdown trapEnter onRequestClose={onClose}>
      <Component
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        {...selectProps}
        value={currentValue}
        // onKeyDown={onKeyDown}
        onChange={onChange}
        // does not foucs menu
        autoFocus
        // onBlur={onBlur}
        // autoFocus={!rest.isSearchable}
        isSearchable={isSearchable}
        placeholder={placeholder || (isSearchable ? 'Select...' : null)}
        components={{
          Option,
          IndicatorsContainer: Dummy,
        }}
        {...rest}
      />
    </CellDropdown>
  );
}
