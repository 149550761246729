import cx from 'clsx';
import type { RenderCellProps } from 'components/DataGrid';
import { useDraggable } from '../hooks';
import type { GridGroup, GridRow, GridSummaryRow } from '../types';
import { ReactElement } from 'react';
import { Action, ActionDropdown } from 'components';
import { createColumn } from './createColumn';
import { handleBtnClassname } from 'components/DND/style.css';
import { flush } from './style.css';
import { usePlanController } from '../../usePlanController';
import { useRowActions } from '../../useRowActions';

export function RowHandle({ row }: RenderCellProps<GridRow, GridSummaryRow>): ReactElement {
  const actions = useRowActions(row);

  const [handleRef, isDragging] = useDraggable(row);
  return (
    <ActionDropdown
      testId={`${row._key}-actions`}
      buttonRef={handleRef}
      actions={actions}
      icon="grip-vertical"
      variant={null}
      size={null}
      menuFontSize="sm"
      className={cx(handleBtnClassname, 'row-handle', isDragging && 'dragging')}
    />
  );
}

export function GroupHandle({ row }: RenderCellProps<GridGroup, GridSummaryRow>): ReactElement {
  const controller = usePlanController();
  const locked = row.config.locked;
  // dragable, vs has actions

  const actions: Action[] = [
    {
      action: 'remove',
      button: {
        children: 'Delete',
        disabled: locked || !row.empty,
        icon: 'xmark',
        variant: 'danger',
        onClick: () => controller.removeGroup(row.id),
      },
    },
  ];

  const [handleRef, isDragging] = useDraggable(row);
  return (
    <ActionDropdown
      testId={`${row._key}-actions`}
      buttonRef={handleRef}
      actions={actions}
      icon="grip-vertical"
      variant={null}
      size={null}
      menuFontSize="sm"
      className={cx(handleBtnClassname, 'row-handle', isDragging && 'dragging')}
    />
  );
}

export const HandleColumn = createColumn(
  {
    key: 'handle',
    width: 30,
    frozen: true,
    className: flush,
  },
  { renderView: (props) => <RowHandle {...props} /> },
  { renderView: (props) => (props.row.id === 0 ? null : <GroupHandle {...props} />) },
);
