import { ReactElement, useMemo } from 'react';
import { AttachmentField, EnterpriseField } from 'components';
import { IntField, TextField } from '@ff-it/form';
import { TicketForm } from './TicketForm';
import { useMembership } from 'services';
import { CompanyField } from 'modules/core/company/components';

export function NewClient(): ReactElement {
  const membership = useMembership();
  const initialValues = useMemo(
    () => ({
      enterprise: {
        id: membership.id,
        company: {
          id: membership.company,
          title: membership.title,
        },
      },
      account_manager: '',
      company: null,
      payer: null,
      brand: '',
      local_media_fee: '',
      terms: '',
      invoice_payment_days: null,

      attachments: [],
      extra_info: '',
    }),
    [membership],
  );

  return (
    <TicketForm title="New client" url="support/tickets/new_client/" initialValues={initialValues}>
      <div className="form-row">
        <EnterpriseField name="enterprise" label="Agency" className="col-4" required disabled />
        <TextField
          name="account_manager"
          label="Account managers"
          className="col-4"
          required
          help="Type manager/-s name and surname"
        />
      </div>
      <div className="form-row">
        <CompanyField
          name="company"
          label="Company"
          className="col-md-4"
          required
          help="Clients company name"
          includeBranches={false}
        />
        <CompanyField
          name="payer"
          label="Payer"
          className="col-md-4"
          help="Invoice recipient"
          includeBranches={false}
        />
        <TextField name="brand" label="Brand" className="col-md-4" help="Name all client brands" />
      </div>
      <div className="form-row">
        <TextField
          name="terms"
          className="col-6"
          label="Terms & conditions"
          help="Describe any terms & conditions this agreements contains"
          multiline
          autosize
          required
        />
        <IntField label="Invoice payment days" name="invoice_payment_days" className="col-3" required />
      </div>
      <div className="form-row">
        <AttachmentField name="attachments" label="Attachments" className="col-6" />
        <TextField
          name="extra_info"
          label="Extra info"
          help="Any other info to know"
          className="col-6"
          multiline
          autosize
        />
      </div>
    </TicketForm>
  );
}
