import { ReactElement } from 'react';
import { withHasPerm } from 'components';
import { Activity } from './Activity';
import permissions from './permissions';
import { LocalVisibilityReport, ReportContainer } from '../common';

export function LocalActivity(): ReactElement {
  return (
    <LocalVisibilityReport
      localPerm={permissions.local}
      managedPerm={permissions.local_managed}
      Component={Activity}
      title="Activities"
    />
  );
}

export const GlobalActivity = withHasPerm(
  () => (
    <ReportContainer title="Activities">
      <Activity visibility="GLOBAL" />
    </ReportContainer>
  ),
  permissions.global,
);
