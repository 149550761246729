import { ReactElement } from 'react';
import { toast } from 'react-toastify';
import { useSubmitHandler } from '@ff-it/form';
import { Button, DialogBody, DialogHeader, RenderProps } from '@ff-it/ui';
import { RequestFailure } from '@ff-it/api';
import { DeleteForm } from 'components';
import type { BlockDetails } from '../types';

interface RemoveBlockDialogProps extends RenderProps<BlockDetails> {
  block: BlockDetails;
}

export function RemoveBlockDialog({ onSubmit, onDismiss, block }: RemoveBlockDialogProps): ReactElement {
  const submitHandler = useSubmitHandler(
    {
      url: `campaign/blocks/${block.id}/`,
      method: 'DELETE',
      headers: {
        'If-Match': block.etag,
      },
    },
    {
      onSuccess: () => onSubmit(block),
      onFailure: (e: RequestFailure<any>) => {
        if (e.status === 403 && e.data.detail) {
          toast.error(e.data.detail);
        } else {
          throw e.error;
        }
      },
    },
  );

  return (
    <>
      <DialogHeader
        title={
          <>
            <strong>{block.title}</strong> {block.type}
          </>
        }
      />
      <DialogBody>
        <DeleteForm onSubmit={submitHandler}>
          <Button variant="outline-secondary" className="ml-auto" size="sm" onClick={onDismiss}>
            Cancel
          </Button>
        </DeleteForm>
      </DialogBody>
    </>
  );
}
