import { Link } from 'react-router-dom';
import { ButtonVariant } from '@ff-it/ui';
import { type EntityAction, type ResolvedEntityAction, createConfirmDialog } from 'components';
import permissions from './permissions';
import type { Income } from './types';
import { add } from './permissions';

export const credit: EntityAction<Income> = ({ item }, check) => {
  if (item.state !== 'CANCELED' && item.type !== 'INVOICE' && item.sums.link_balance === '0.00' && !check(add)) {
    return null;
  }

  return {
    action: 'credit',
    button: {
      children: 'Credit remainder',
      variant: 'outline-primary',
      size: 'sm',
      component: Link,
      to: '../create',
      state: {
        creditSource: item,
      },
    },
    permKey: null,
  };
};

export const actions: EntityAction<Income>[] = [
  (_i, check) => {
    return {
      action: 'confirm',
      button: {
        children: 'Confirm',
        icon: 'check',
        variant: 'outline-success',
      },
      visible: ({ state }) => state === 'DRAFT',
      // Force confirmation hack
      onRetry: check(permissions.force_confirm)
        ? (res, args) => {
            if (res.status === 400) {
              if (window.confirm(`${res.data}\nForce confirmation?`)) {
                return {
                  ...args,
                  body: {
                    force: true,
                  },
                };
              }
              return undefined;
            }
          }
        : undefined,
      successMessage: 'Invoice confirmed.',
    };
  },
  {
    action: 'cancel',
    button: {
      children: 'Cancel',
      icon: 'ban',
      variant: 'outline-danger',
    },
    requestDialog: createConfirmDialog(),
    visible: ({ state, linked_at, booking_state }) => state === 'CONFIRMED' && !linked_at && booking_state === 'NONE',
  },
  {
    action: 'draft',
    button: {
      children: 'Draft',
      icon: 'ban',
      variant: 'outline-secondary',
    },
    requestDialog: createConfirmDialog(),
    visible: ({ state }) => state === 'CANCELED',
  },
  {
    action: 'mark_linked',
    button: {
      children: 'Mark linked',
      variant: 'outline-success',
      icon: 'link',
    },
    visible: ({ state, type, linked_at }) => state === 'CONFIRMED' && type === 'INVOICE' && !linked_at,
  },
  {
    action: 'mark_not_linked',
    button: {
      children: 'Mark not linked',
      variant: 'outline-danger',
      icon: 'link-slash',
    },
    visible: ({ state, type, linked_at }) => state === 'CONFIRMED' && type === 'INVOICE' && !!linked_at,
  },
];

export const bookingActions: EntityAction<Income>[] = [
  {
    action: 'book_create',
    permKey: 'book_income',
    button: {
      children: 'Create',
      icon: 'book-open',
      variant: 'outline-secondary',
      size: 'sm',
    },
    visible: ({ state, booking_state }) => state !== 'CANCELED' && booking_state === 'NONE',
  },
  ({ item: { booking_state } }): ResolvedEntityAction<Income> => ({
    action: 'book_sync',
    permKey: 'book_income',
    button: {
      children: 'Sync',
      icon: 'book-open',
      size: 'sm',
      variant: {
        CREATED: 'outline-warning' as ButtonVariant,
        BOOKED: 'text-success' as ButtonVariant,
        NONE: undefined,
      }?.[booking_state],
    },
    visible: ({ state, booking_state }) => state !== 'CANCELED' && booking_state !== 'NONE',
  }),
];
