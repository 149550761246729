import { agencyScopeRequired, all, listPerm, superuser } from 'core/permissions';
import bank from 'modules/core/bank/permissions';
import activity from 'modules/core/activity/permissions';
import company from 'modules/core/company/permissions';
import department from 'modules/supplier/department/permissions';
import membership from 'modules/core/membership/permissions';
import brands from 'modules/client/brand/permissions';
import groups from 'modules/core/groups/permissions';

export const preferencesMenu = {
  to: '/preferences',
  label: 'Preferences',
  icon: 'gear' as const,
  children: [
    { to: '/preferences/activities', label: 'Activities', visible: activity.view },
    { to: '/preferences/agency_roles', label: 'Agency roles', visible: groups.view },
    { to: '/preferences/banks', label: 'Banks', visible: listPerm(bank) },
    { to: '/preferences/companies', label: 'Companies', visible: company.view },
    { to: '/preferences/brands', label: 'Brands', visible: brands.view },
    { to: '/preferences/categories', label: 'Media categories', visible: department.view },
    { to: '/preferences/department_sets', label: 'Department sets', visible: department.view },
    { to: '/preferences/departments', label: 'Departments', visible: department.view },
    { to: '/preferences/provided', label: 'Provided', visible: all(department.view, agencyScopeRequired) },
    // @TODO move to system
    { to: '/preferences/ticket_assignees', label: 'Ticket assignees', visible: superuser },
    { to: '/preferences/membership', label: 'Membership', visible: membership.view },
  ],
};
