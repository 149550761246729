import { ReactNode, useCallback } from 'react';
import { Calendar } from './types';
import { scaleCanvas } from './style.css';
import { Canvas, Draw } from './Canvas';
import { isWeekend } from 'date-fns';
import { INNER_ROW_HEIGHT } from './const';
export { useCalendar } from './useCalendar';

// @TODO render ofcreen and copy
export function Scale({ width, resolution, ...props }: Calendar): ReactNode {
  // biome-ignore lint/correctness/useExhaustiveDependencies: render on dimension change
  const drawSelection: Draw = useCallback(
    (ctx) => {
      const key = {
        DAY: 'days',
        WEEK: 'weeks',
        MONTH: 'months',
      }[resolution] as 'days' | 'weeks' | 'months';

      props[key].map((col) => {
        const { left, width, start } = col;

        if (key === 'days' && isWeekend(start)) {
          ctx.fillStyle = '#F2F4F7';
        } else {
          ctx.fillStyle = '#ffffff';
        }

        ctx.fillRect(left, 0, width, INNER_ROW_HEIGHT);
      });
    },
    [width, resolution],
  );

  return <Canvas className={scaleCanvas} width={width} height={INNER_ROW_HEIGHT} draw={drawSelection} />;
}
