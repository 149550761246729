import { TextField, useForm, useOnFieldChange } from '@ff-it/form';
import { EmbeddedClientAgreement } from 'modules/client/agreement/types';
import { ReactElement, ReactNode } from 'react';
import { coerceEmptyToNull } from 'utilities';

export function ClientPONumberField({
  name,
  ...props
}: { name: string; className?: string; label?: ReactNode; help?: ReactNode }): ReactElement {
  const form = useForm();
  useOnFieldChange<EmbeddedClientAgreement | null>(
    'agreement',
    (current) => {
      if (current && current.client_po_number) {
        form.change(name, current.client_po_number);
      }
    },
    false,
    true,
  );
  return <TextField name={name} {...props} {...coerceEmptyToNull} />;
}
