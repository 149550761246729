import cx from 'clsx';
import { ReactElement, ReactNode } from 'react';
import './Heading.scss';

interface HeadingProps {
  title?: ReactNode;
  children?: ReactNode;
  className?: string;
}

export function Heading({
  title,
  children,
  className = 'justify-content-between align-items-center',
}: HeadingProps): ReactElement {
  return (
    <div className={cx('d-flex  heading', className)}>
      {title && <h1>{title}</h1>}
      {children}
    </div>
  );
}
