import { Column } from 'components/DataGrid';
import type { GridSummaryRow, RowOrGroup } from '../types';
import { eachMonthOfInterval, format, formatISO, startOfMonth } from 'date-fns';
import { createColumn } from '../columns/createColumn';
import { View } from './View';
import { Edit } from './Edit';
import { Box } from '@ff-it/ui';
import { fmt } from 'utilities';
import { expenseForeground, incomeForeground, monthColumn } from '../columns/style.css';

export function createMonthColums(interval: Interval): Column<RowOrGroup, GridSummaryRow>[] {
  return eachMonthOfInterval(interval).map((date) => {
    const month = startOfMonth(date);

    const monthString = formatISO(month, { representation: 'date' });
    const key = `month_${monthString}`;

    return createColumn(
      {
        key: key,
        name: format(month, 'MMM. yyyy'),
        className: monthColumn,
        width: 140,
      },
      {
        renderView: (props) => <View {...props} month={monthString} />,
        renderEdit: (props) => <Edit {...props} month={monthString} />,
        displayCellContent: true,
      },
      undefined,
      {
        renderSummaryCell: ({ row }) => {
          const monthSum = row.months[monthString];
          if (monthSum) {
            return (
              <>
                <Box display="flex" alignItems="center" className={incomeForeground} data-testid="income">
                  {fmt(monthSum.income)}
                </Box>
                <Box display="flex" alignItems="center" className={expenseForeground} data-testid="expense">
                  {fmt(monthSum.expense)}
                </Box>
              </>
            );
          }
          return null;
        },
      },
    );
  });
}
