import { superuser } from 'core/permissions';

export const menu = {
  to: '/system/',
  label: 'System',
  icon: 'wrench' as const,
  children: [
    { to: '/system/agencies', label: 'Agencies' },
    { to: '/system/users', label: 'Users' },
  ],
  visible: superuser,
};
