import { useEntity, Heading, SubNav, ClientTitle } from 'components';
import { NavLinkProps } from 'react-router-dom';
import { ReactElement } from 'react';
import type { ClientAgreement } from 'modules/client/agreement/types';
import { activityTypeOptions } from 'options';

export function Nav(): ReactElement {
  const { item } = useEntity<ClientAgreement>();

  const nav: NavLinkProps[] = [
    {
      to: '',
      end: true,
      children: 'Update',
    },
    ...activityTypeOptions.map(({ value, label }) => ({
      to: `terms/${value}`,
      children: item.type_terms.includes(value) ? <strong>{label}</strong> : label,
      end: true,
    })),
  ];

  return (
    <SubNav nav={nav}>
      <Heading>
        <h2>
          <ClientTitle {...item} /> <small className="text-muted">#{item.number}</small>
        </h2>
      </Heading>
    </SubNav>
  );
}
