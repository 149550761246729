import * as agency from './Agency';
import * as client from './Client';
import * as externalSums from './ExternalSums';

export const route = {
  path: 'guarantee',
  children: [agency.route, client.route, externalSums.route],
};

export const menu = {
  to: '/guarantee',
  label: 'Guarantees',
  icon: 'handshake' as const,
  children: [
    { to: '/guarantee/agency', label: 'Agency' },
    { to: '/guarantee/client', label: 'Client' },
    { to: '/guarantee/external_sums', label: 'External sums' },
  ],
};
