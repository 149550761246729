import { ReactElement } from 'react';
import { TextField, SelectField, useField, FormGroup, useForm } from '@ff-it/form';
import { acceptanceActTemplateOptions, invoiceTemplateOptions } from 'options';
import { BankField, Fieldset, FormInline } from 'components';
import { CommonCompanyFields } from 'modules/core/company/components';
import { CompanyCodeField } from 'modules/core/company/components/CompanyCodeField';
import { required } from 'utilities';
import { initialAccountValues, initialBranchValues } from './initialValues';
import { Check } from '@ff-it/ui';

function PrimaryToggle({ index }: { index: number }): ReactElement {
  const name = `accounts[${index}].primary`;
  const {
    input: { value },
  } = useField(name, { subscription: { value: true } });
  const form = useForm();
  return (
    <FormGroup className="col-md-2 ml-3" label="&nbsp;">
      <Check
        variant="radio"
        name={name}
        className="text-center"
        label="Primary account"
        checked={value}
        onChange={(e) => {
          if (e.target.checked) {
            form.batch(() => {
              form.change(`accounts[${index}].primary`, true);
              const values = form.getFieldState('accounts')?.value || [];
              for (let i = 0; i < values.length; i++) {
                if (i !== index && values[i].primary) {
                  form.change(`accounts[${i}].primary`, false);
                }
              }
            });
          }
        }}
      />
    </FormGroup>
  );
}

export function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <CommonCompanyFields />
      <hr />
      <Fieldset title="Documents">
        <div className="form-row">
          <SelectField
            name="invoice_template"
            options={invoiceTemplateOptions}
            label="Invoice template"
            className="col-md-6"
            simple
          />
          <SelectField
            name="acceptance_act_template"
            options={acceptanceActTemplateOptions}
            label="Acceptance act template"
            className="col-md-6"
            simple
          />
        </div>
      </Fieldset>

      <FormInline name="accounts" title="Accounts" initialValues={initialAccountValues} testId="accounts">
        {(name, index) => (
          <div className="form-row">
            <BankField name={`${name}.bank`} label="Bank" className="col-md-4" required validate={required} />
            <TextField
              name={`${name}.account_number`}
              label="Account number (IBAN)"
              className="col-md-4"
              required
              validate={required}
            />
            <PrimaryToggle index={index} />
          </div>
        )}
      </FormInline>

      <FormInline
        name="branches"
        title="Branches"
        initialValues={initialBranchValues}
        help="If there are no separate credentials, branches can be used as campaign clients to allocate(split) amounts."
        testId="branches"
      >
        {(name) => (
          <div className="form-row">
            <TextField name={`${name}.internal_name`} label="Internal name" className="col-md-4" required size="sm" />
            <CompanyCodeField name={`${name}.code`} className="col-md-4" size="sm" />
          </div>
        )}
      </FormInline>
    </div>
  );
}
