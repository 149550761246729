import { Link } from 'react-router-dom';
import { date } from 'components/columns';
import type { ListBonus } from 'modules/guarantee/types';
import { TableColumns } from '@ff-it/ui';

export * from './BonusForm';

export const columns: TableColumns<ListBonus> = [
  {
    content: 'Number',
    key: 'number',
    width: 200,
    sortable: true,
    render: ({ id, number }: ListBonus) => <Link to={`${id}`}>{number}</Link>,
  },
  {
    content: 'Title',
    key: 'title',
    width: undefined,
    sortable: true,
  },
  { content: 'Partner', key: 'partner.title' as any, width: undefined, sortable: true },
  { content: 'Kind', key: 'kind', sortable: true },
  { content: 'Regularity', key: 'regularity', sortable: true },
  date('Date from', 'date_from', { sortable: true }),
  date('Date to', 'date_to', { sortable: true }),
];

export const initialValues = {
  title: '',
  number: '',
  partner: null,
  regularity: null,
  date_from: null,
  date_to: null,
  kind: null,
  terms: '',
  notes: '',
  signature_first_name: '',
  signature_last_name: '',
  attachments: [],
  rates: [],
};
