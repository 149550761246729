import { parseBig } from 'utilities';
import { Bound, LinkerContext, Parsed, NetLinkable } from '../types';
import { Linkable, LinkerState } from './types';
import { useMemo } from 'react';
import { type FilterState, filterRows } from '../filter';
import { useBound } from '../useBound';
import { parseLinkable } from '../parseLinkable';
import { calcSummaryState } from './calcSummaryState';
import type { Invoice } from 'modules/invoicing/types';

function partitionLinkable(
  linkable: Parsed<NetLinkable>[],
  bound: Bound,
): [linked: Parsed<NetLinkable>[], candidates: Parsed<NetLinkable>[]] {
  const linked: Parsed<NetLinkable>[] = [];
  const candidates: Parsed<NetLinkable>[] = [];
  linkable.forEach((row) => {
    const key = `_${row.id}`;
    if (key in bound) {
      const amount = parseBig(bound[key]);
      linked.push({
        ...row,
        invoiced: amount, // row.invoiced.plus(amount),
        balance: row.balance.minus(amount),
      });
    } else {
      candidates.push(row);
    }
  });
  return [linked, candidates];
}

const cond = (i: Linkable): boolean => !i.balance.eq(0);

export function useLinkerState(
  invoice: Invoice,
  { linkable, entities }: LinkerContext,
  filterState: FilterState,
): LinkerState {
  // const { reset, change } = useForm();
  const bound = useBound();
  const parsedLinkable = useMemo(() => linkable.map(parseLinkable), [linkable]);

  // memoing parsed does nothing for this
  const [linked, allCandidates] = partitionLinkable(parsedLinkable, bound);

  const candidates = useMemo(
    () => filterRows(allCandidates, filterState, entities, cond),
    [allCandidates, filterState, entities],
  );

  return {
    linked,
    candidates,
    summary: calcSummaryState(invoice.sums, bound),
  };
}
