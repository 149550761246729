import { ActivityField } from 'components';
import { FieldsProps } from './types';
import { CompanyField } from 'modules/core/company/components';
import { ProductField } from 'modules/supplier/products/ProductField';

/**
 * This actually has no precedence, but activity is currently the most common common condition and this is different from rest
 * We don't want validate provider - product or product - activity precedence either
 */
export function ActivityFirst({ type, kind, required: _, department }: FieldsProps): React.ReactElement {
  return (
    <>
      <ActivityField
        label="Activity"
        name="activity"
        menuPortalTarget={document.body}
        placeholder="Any"
        kind={kind === 'MEDIA' ? 'MEDIA' : 'SERVICE'}
      />
      <CompanyField
        label="Provider"
        name="provider"
        menuPortalTarget={document.body}
        placeholder="Any"
        includeBranches={true}
        defaultOptions
      />
      <ProductField
        label="Product"
        type={type}
        kind={kind}
        department={department}
        key={`${kind}-${department || 0}`} // reset defaultOptions
        name="product"
        menuPortalTarget={document.body}
        placeholder="Any"
        defaultOptions
        pageSize={50}
      />
    </>
  );
}
