import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { LoadingContainer } from '@ff-it/ui';
import { useAuthState, auth } from 'services/auth';
import { SessionContext } from './context';
import { api } from 'services/api';
import type { SessionUser } from 'modules/core/types';

export function SessionProvider({ children }: { children: ReactNode }): ReactElement {
  const authState = useAuthState();
  const didInitialise = useRef(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (didInitialise.current) {
      return;
    }

    didInitialise.current = true;
    const token = auth.token;

    if (token) {
      api
        .get<SessionUser, unknown>('auth/session/')
        .then((res) => {
          if (res.ok) {
            auth.user = res.data;
          } else {
            if (res.status === 401) {
              auth.token = null;
            } else {
              setLoading(() => {
                throw res.error;
              });
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LoadingContainer loading={loading}>
      {loading ? null : <SessionContext.Provider value={authState}>{children}</SessionContext.Provider>}
    </LoadingContainer>
  );
}
