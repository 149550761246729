import type { RowCommonFields } from './common';
import type { NaiveRowFields } from './naive';
import type { RTBRowFields } from './rtb';

type SpecializedFieldMap = {
  RTB: RTBRowFields;
  WEB: RTBRowFields;
};

export type MaybeSpec = keyof SpecializedFieldMap | void;

export const reserviationStates = ['NONE', 'BOOKED', 'ON_AIR', 'ON_HOLD', 'DONE'] as const;

export type ReservationState = (typeof reserviationStates)[number];

export type MediaRow<Spec extends MaybeSpec = MaybeSpec> = NaiveRowFields &
  RowCommonFields<'MEDIA'> & {
    reservation_state: ReservationState;
  } & (Spec extends keyof SpecializedFieldMap ? SpecializedFieldMap[Spec] : never);
