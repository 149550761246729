import { any } from 'core/permissions';

export default {
  // CRUD
  view: any('invoicing.view_expense', 'invoicing.view_campaign_expense'),
  add: 'invoicing.add_expense',
  change: 'invoicing.change_expense',
  delete: 'invoicing.delete_expense',
  // Views
  link: 'invoicing.link_expense',
  // Actions
  cancel: 'invoicing.cancel_expense',
  draft: 'invoicing.draft_expense',
  confirm: 'invoicing.confirm_expense',
  mark_linked: 'invoicing.mark_linked_expense',
  mark_not_linked: 'invoicing.mark_not_linked_expense',
  book_expense: 'invoicing.book_expense',
  // Export
  export: 'invoicing.export_expense',
};
