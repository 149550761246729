import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Icon, useEntity } from 'components';
import { InvoiceContext, InvoiceEntry, Log } from 'modules/audit';
import { parseBig } from 'utilities';
import { useMembership } from 'services';
import { Invoice } from '../types';

function InvoiceLink({ direction, id, number }: InvoiceContext): ReactElement {
  const { slug } = useMembership();
  return (
    <span>
      <Icon icon="file-invoice" className={`text-${direction}`} />{' '}
      <Link to={`/${slug}/invoices/${direction}/${id}`}>{number}</Link>{' '}
    </span>
  );
}

function Description(entry: InvoiceEntry): ReactElement | null {
  const { slug } = useMembership();
  switch (entry.action) {
    case 'CREATED':
      return <>created invoice</>;

    case 'UPDATED':
      return <>updated invoice</>;

    case 'DRAFT':
      return <>converted to draft</>;

    case 'CANCEL':
      return <>canceled invoice</>;

    case 'CONFIRM':
      return <>confirmed {entry.context.force && <strong>(force)</strong>} invoice</>;

    case 'LINKED':
      return <>invoice marked as linked</>;

    case 'NOT_LINKED':
      return <>invoice marked as not linked</>;

    case 'MANUAL_BOOK':
      return <>booking action</>;

    case 'MANUAL_SYNC':
      return <>sync action</>;

    case 'BOOK_CREATED':
      return <>booking request</>;

    case 'BOOK_BOOKED':
      return <>invoice booked</>;

    case 'BOOK_NONE':
      return <>booking canceled</>;

    case 'BOOK_PAID':
      return (
        <>
          balance updated {entry.context.paid[0]} → {entry.context.paid[1]}{' '}
        </>
      );

    case 'DELETED':
      return <>deleted invoice</>;

    case 'BOUND':
      return (
        <ul className="compact-list">
          {entry.context.map(({ amount, campaign: { id, number, title } }) => {
            const [prev, next] = amount;
            const diff = parseBig(next).minus(prev);
            const p = diff.s === 1;

            return (
              <li key={id}>
                {prev} → {next} (
                <span className={p ? 'text-success' : 'text-danger'}>
                  {p && '+'}
                  {diff.toFixed(2)}
                </span>
                )
                <span className="ml-2">
                  to {title} <Icon icon="paper-plane" />{' '}
                  <Link to={`/${slug}/planning/campaigns/${id}/planning`}>{number}</Link>
                </span>
              </li>
            );
          })}
        </ul>
      );

    case 'CREDIT_APPLIED_FROM':
    case 'CREDIT_APPLIED_TO':
      return (
        <>
          credit applied (<span className="text-success">+{entry.context.amount}</span>){' '}
          <InvoiceLink {...entry.context.invoice} />
        </>
      );
    case 'CREDIT_REMOVED_FROM':
    case 'CREDIT_REMOVED_TO':
      return (
        <>
          credit removed (<span className="text-danger">-{entry.context.amount}</span>){' '}
          <InvoiceLink {...entry.context.invoice} />
        </>
      );
    case 'ADVANCE_APPLIED_FROM':
    case 'ADVANCE_APPLIED_TO':
      return (
        <>
          advance applied (<span className="text-success">+{entry.context.amount}</span>){' '}
          <InvoiceLink {...entry.context.invoice} />
        </>
      );
    case 'ADVANCE_REMOVED_FROM':
    case 'ADVANCE_REMOVED_TO':
      return (
        <>
          advance removed (<span className="text-danger">-{entry.context.amount}</span>){' '}
          <InvoiceLink {...entry.context.invoice} />
        </>
      );

    case 'COMPENSATION_APPLIED_FROM':
    case 'COMPENSATION_APPLIED_TO':
      return (
        <>
          compensation applied (<span className="text-success">+{entry.context.amount}</span>){' '}
          <InvoiceLink {...entry.context.invoice} />
        </>
      );

    case 'COMPENSATION_REMOVED_FROM':
    case 'COMPENSATION_REMOVED_TO':
      return (
        <>
          compensation removed (<span className="text-danger">-{entry.context.amount}</span>){' '}
          <InvoiceLink {...entry.context.invoice} />
        </>
      );

    default:
      return null;
  }
}

export function History(): ReactElement {
  const { endpoint } = useEntity<Invoice>();

  return <Log<InvoiceEntry> url={`${endpoint}history/`} Description={Description} />;
}
