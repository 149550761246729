import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, auth } from 'services';

export function Logout(): ReactElement | null {
  const navigate = useNavigate();

  // biome-ignore lint/correctness/useExhaustiveDependencies: navigate is stable
  useEffect(() => {
    api.post('auth/logout/').then((res) => {
      if (res.ok) {
        auth.signout(() => {
          navigate('/');
        });
      } else {
        throw res.error;
      }
    });
  }, []);

  return null;
}
