import { DecimalField, TextField } from '@ff-it/form';
import { Card, CardBody } from 'components/Card';
import { AddInline, Inlines, RemoveInline } from 'components/form/Inlines';
import { CategoryField } from 'modules/supplier/category/MediaCategoryField';
import { SupplierField } from 'modules/supplier/supplier/components';
import { ReactElement } from 'react';
import { ActivityType } from 'types';
import { required } from 'utilities';
import { TableInlineColumn, TableInlines } from 'components';
import { SmartRate } from './types';
import { Box } from '@ff-it/ui';

const initialRateValues = {
  supplier: null,
  target_supplier: {
    provider: null,
    activity: null,
    product: null,
  },
  target_category: null,
  client_rate: null,
  provider_rate: null,
  terms: '',
};

export function SmartRateGroups({ type }: { type: ActivityType }): ReactElement {
  const rateColumns: TableInlineColumn<SmartRate>[] = [
    {
      key: 'supplier',
      content: 'Supplier',
      width: '260px',
      render: ({ name }) => (
        <SupplierField
          name={`${name}.supplier`}
          className="mb-0"
          type={type}
          required
          validate={required}
          kind="SMART"
        />
      ),
    },
    {
      key: 'client_rate',
      content: 'Cl. rate',
      render: ({ name, value }) => {
        const direction = value.supplier?.product?.direction;
        const enabled = direction === 'BOTH' || direction === 'CLIENT';
        return <DecimalField name={`${name}.client_rate`} className="mb-0" disabled={!enabled} required={enabled} />;
      },
      width: '90px',
    },
    {
      key: 'provider_rate',
      content: 'Prov. rate',
      render: ({ name, value }) => {
        const direction = value.supplier?.product?.direction;
        const enabled = direction === 'BOTH' || direction === 'AGENCY';
        return <DecimalField name={`${name}.provider_rate`} className="mb-0" disabled={!enabled} required={enabled} />;
      },
      width: '90px',
    },
    {
      key: 'target_category',
      content: 'Target category',
      render: ({ name }) => <CategoryField name={`${name}.target_category`} className="mb-0" />,
    },
    {
      key: 'target_supplier',
      content: 'Target supplier fields',
      render: ({ name }) => (
        <SupplierField
          name={`${name}.target_supplier`}
          className="mb-0"
          type={type}
          kind={['MEDIA', 'SERVICE']}
          incomplete
        />
      ),
      width: '260px',
    },
    {
      key: 'terms',
      content: 'Comment',
      render: ({ name }) => <TextField multiline autosize name={`${name}.terms`} className="mb-0" />,
    },
  ];

  return (
    <Card
      title={
        <Box display="flex" alignItems="center">
          Smart rates
          <Box marginLeft="auto" fontWeight="regular" fontSize="xs">
            Automatically add and apply smart rows when target conditions are met. One for each group.
          </Box>
        </Box>
      }
      testId="smart-rate-groups"
    >
      <CardBody>
        <Inlines name="smart_rate_groups">
          {({ name, index }) => (
            <TableInlines
              name={`${name}.rates`}
              initialValues={initialRateValues}
              columns={rateColumns}
              min={1}
              actions={
                <RemoveInline name="smart_rate_groups" index={index} className="ml-auto">
                  Remove group
                </RemoveInline>
              }
            />
          )}
        </Inlines>

        <AddInline
          name="smart_rate_groups"
          initialValues={() => ({ rates: [{ ...initialRateValues, __NEW__: Date.now() }], __NEW__: Date.now() })}
          className=""
        >
          Add group
        </AddInline>
      </CardBody>
    </Card>
  );
}
