import { ReactElement, useMemo } from 'react';
import { RouteObject, useRoutes, Navigate } from 'react-router';
import { AppContainer, SideNav, NavItem } from 'components';
import { TopNav } from 'components/TopNav';
import * as guarantee from './Guarantee';
import * as reporting from './Reporting';
import * as system from '../_system';
import { menu as systemMenu } from '../_system/menu';
import * as profile from '../Profile';

import { useAgencyGroup } from 'services';
import { route as preferncesRoute } from 'routes/Agency/Preferences';
import { preferencesMenu } from 'routes/Agency/Preferences/menu';
import { branding } from 'styles/style.css';

const menu: NavItem[] = [guarantee.menu, reporting.menu, preferencesMenu, systemMenu];

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="guarantee/agency" replace />,
  },
  guarantee.route,
  reporting.route,
  preferncesRoute,
  system.route,
  profile.route,
  { path: '*', element: <h1>404</h1> },
];

export function AgencyGroup(): ReactElement {
  const { slug, title } = useAgencyGroup();
  const menuItems = useMemo(
    () =>
      menu.map(({ to, children, ...rest }) => ({
        to: `/${slug}${to}`,
        children: children ? children.map(({ to, ...rest }) => ({ to: `/${slug}${to}`, ...rest })) : undefined,
        ...rest,
      })),
    [slug],
  );

  const element = useRoutes(routes);

  return (
    <AppContainer
      beforeMain={<TopNav />}
      beforeContent={<SideNav branding={<h1 className={branding}>{title}</h1>} menu={menuItems} />}
    >
      {element}
    </AppContainer>
  );
}
