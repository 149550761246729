import type { ReactElement } from 'react';
import { DialogBody, DialogClose, DialogFooter, DialogHeader, RenderProps } from '@ff-it/ui';
import type { LinkCandidate } from '../types';
import { CandidateList } from '../Applicator/CandidateList';

export function ApplicationCandidateDialog({
  canidates,
  onSubmit,
}: RenderProps<LinkCandidate> & {
  canidates: LinkCandidate[];
}): ReactElement {
  return (
    <>
      <DialogHeader title="Create compensation row from invoice link remainder" />
      <DialogBody>
        <CandidateList items={canidates} applicationType="compensation" actionHandler={onSubmit} />
      </DialogBody>
      <DialogFooter>
        <DialogClose className="ml-auto" />
      </DialogFooter>
    </>
  );
}
