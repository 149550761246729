import type { PlanStrategy } from './type';

export const NAIVE: PlanStrategy = {
  KEY: 'NAIVE',
  TITLE: 'Naive plan',
  copyRow: (row) => {
    // comment?
    switch (row.kind) {
      case 'SMART':
        return {
          kind: row.kind,
          supplier: row.supplier,
          rate: row.rate,
          // apply_to: []
        };

      case 'SERVICE':
      case 'MEDIA':
        return {
          kind: row.kind,
          supplier: row.supplier,
          unit: row.unit,
          gross_price: row.gross_price,
          client_price: row.client_price,
          provider_price: row.provider_price,
          applied_to: row.applied_to,
        };
      default:
        throw Error(`Unexpected kind: ${row.kind}`);
    }
  },
  async exportPlanOptions() {
    return undefined;
  },
  canExportMediaReservation: false,
  columns: [],
};
