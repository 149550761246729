import { ReactElement, ReactNode, useCallback } from 'react';
import cx from 'clsx';
import { Form, FormProps, Submit, useSubmitHandler, FormData, SubmitHandlerOptions } from '@ff-it/form';
import { useModel } from './context';
import { useLocation, useNavigate } from 'react-router-dom';
import { Perm, useHasPerms } from 'core/permissions';
import { Page, Heading, Breadcrumb } from '../layout';
import { PermissionDenied } from './errors';
import { toast } from 'react-toastify';

interface CreateSceneProps<F extends FormData, RV> extends SubmitHandlerOptions<F, RV>, Omit<FormProps<F>, 'onSubmit'> {
  successPath?: string | ((rv: RV) => string);
  perm?: Perm;
  successMessage?: ReactNode;
  url?: string;
  className?: string;
}

const FRAGMENT_REGEXP = /:([^\s/]+)/g;

export function CreateScene<F extends FormData = Record<string, unknown>, RV = F>(
  props: CreateSceneProps<F, RV>,
): ReactElement {
  const { state } = useLocation();
  const { endpoint, permissions, title } = useModel();
  const {
    perm,
    children,
    successPath = '../:id',
    mapValues,
    successMessage = 'Item has been created.',
    url = endpoint,
    className,
    onSuccess,
    initialValues: defaultInitialValues,
    ...formProps
  } = props;

  const initialValues = state?.initialValues || defaultInitialValues;

  const navigate = useNavigate();

  // biome-ignore lint/correctness/useExhaustiveDependencies: navigate is stable
  const defaultSuccess = useCallback(
    (result: any): void => {
      const path =
        typeof successPath === 'function'
          ? successPath(result)
          : successPath.replace(FRAGMENT_REGEXP, (_, field) => result[field]);
      toast.success(successMessage, { toastId: 'create-scene-toast' });
      navigate(path);
    },
    [successMessage, successPath],
  );

  const onSubmit = useSubmitHandler<F, RV>(
    { url, method: 'POST', queryParams: { copy_from_id: state?.copyFromId } },
    { mapValues, onSuccess: onSuccess || defaultSuccess },
  );

  const [canCreate] = useHasPerms(perm || permissions?.add);
  if (!canCreate) {
    return <PermissionDenied />;
  }
  return (
    <Page className={cx('scene scene-create', className)}>
      <Breadcrumb>Create</Breadcrumb>
      <Heading title={`Create ${title}`} />
      <Form onSubmit={onSubmit} noValidate {...formProps} initialValues={initialValues}>
        {children}
        <Submit>Create</Submit>
      </Form>
    </Page>
  );
}
