import cx from 'clsx';
import { RenderCellProps } from 'components/DataGrid';
import { createColumn } from './createColumn';
import { renderEdit } from './resettableDecimal';
import { expenseForeground, incomeForeground, right, wrapContainer } from './style.css';
import { GridRow, GridSummaryRow } from '../types';
import { ReactNode } from 'react';
import { DotBadge } from './DotBadge';
import { sepFormat } from 'utilities';
import { Box } from '@ff-it/ui';
import { AsteriskBadge } from '../../Factors';

function renderView({ row }: RenderCellProps<GridRow, GridSummaryRow>): ReactNode {
  const {
    gross_price,
    sums: { client_gross_price, provider_gross_price },
  } = row;
  const price = row.config['gross_price']?.resolved_value;

  if (gross_price) {
    const split = client_gross_price !== provider_gross_price;

    return (
      <Box lineHeight="xs" paddingLeft="sm">
        {(row.factors.client.length > 0 || row.factors.provider.length > 0) && <AsteriskBadge row={row} />}
        {price && price !== gross_price && <DotBadge />}
        <Box
          data-testid="client"
          className={cx(split ? incomeForeground : undefined, wrapContainer)}
          fontWeight={split ? 'bold' : undefined}
        >
          {sepFormat(client_gross_price)}
        </Box>
        {split && (
          <Box data-testid="provider" className={cx(expenseForeground, wrapContainer)}>
            {sepFormat(provider_gross_price)}
          </Box>
        )}
      </Box>
    );
  }
  return null;
}

export const GrossPriceColumn = createColumn(
  {
    key: 'gross_price',
    name: 'Gross p.u.',
    width: 96,
    className: right,
  },
  {
    renderView,
    renderEdit: (props) => renderEdit({ ...props, precision: 16, normalize: true }),
  },
);
