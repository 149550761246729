import { ReactElement } from 'react';
import { EmbeddedCompany } from 'types';
import { BoundState, Breadcrumb, ClientTitle, DatePartPickerField, UserField } from 'components';
import { SelectField } from '@ff-it/form';
import { usePeriod } from 'modules/reporting/usePeriod';
import { RowType, startColumns, endColumns, boundStateOptions } from './common';
import { CompanyField } from 'modules/core/company/components';
import { ReportTable } from 'components/ReportTable';
import { TableColumns } from '@ff-it/ui';

type IncomeRowType = RowType & {
  client: EmbeddedCompany;
  payer: EmbeddedCompany;
};

const columns: TableColumns<IncomeRowType> = [
  ...startColumns,
  {
    key: 'client',
    content: 'Client',
    render: ClientTitle,
  },
  ...endColumns,
  {
    key: 'state',
    width: 30,
    render: ({ state, is_overspent }) => <BoundState variant="income" state={state} is_overspent={is_overspent} />,
  },
];

export function IncomeLinks(): ReactElement {
  const { date_from: minDate, date_to: maxDate } = usePeriod();
  return (
    <ReportTable
      url="invoicing/links/income"
      columns={columns}
      filterFields={
        <>
          <Breadcrumb>Income</Breadcrumb>
          <UserField
            name="manager"
            placeholder="Manager"
            className="ml-1"
            size="sm"
            simple
            filter={{ manage_campaign: true }}
          />
          <CompanyField name="client" placeholder="Client" className="ml-2" size="sm" simple includeBranches={true} />
          <SelectField name="state" placeholder="State" options={boundStateOptions} className="ml-2" size="sm" simple />
          <DatePartPickerField part="month" name="month" className="ml-2" minDate={minDate} maxDate={maxDate} />
        </>
      }
    />
  );
}
