import { ReactElement, useContext } from 'react';
import { NotificationContext } from './NotificationProvider';
import { Notifications } from './types';

interface NotificationBadgeProps {
  item: keyof Notifications['badges'];
}

export function NotificationBadge({ item }: NotificationBadgeProps): ReactElement | null {
  const ctx = useContext(NotificationContext);
  const value = ctx?.badges[item];
  if (value) {
    return <span className="badge  badge-light  border-0">{value}</span>;
  }
  return null;
}
