import { ReactElement, useState } from 'react';
import { AuthContainer } from 'components';
import { Link } from 'react-router-dom';
import { Form, Submit, TextField, useSubmitHandler } from '@ff-it/form';

function ResetDone(): ReactElement {
  return (
    <AuthContainer wide>
      <h3 className="text-center">Reset Password</h3>
      <div className="px-2">
        <p className="pt-3">
          We have emailed you instructions for setting your password, if an account exists with the email you entered.
          <br />
          <br />
          You should receive them shortly.
        </p>
        <p>
          <small className="text-muted">
            If you don’t receive an email, please make sure you’ve entered the address you registered with, and check
            your spam folder
          </small>
        </p>
      </div>
    </AuthContainer>
  );
}

export function PasswordReset(): ReactElement {
  const [done, setDone] = useState<boolean>(false);

  const submitHandler = useSubmitHandler(
    {
      url: 'auth/password_reset/',
      method: 'POST',
    },
    {
      onSuccess: () => {
        setDone(true);
      },
    },
  );

  if (done) {
    return <ResetDone />;
  }
  return (
    <AuthContainer>
      <h3 className="text-center">Reset Password</h3>
      <Form onSubmit={submitHandler} autoComplete="off">
        <TextField name="email" label="Email" type="email" size="lg" required />
        <Submit variant="primary" size="lg" className="w-100" />
        <small className="mt-2 d-block">
          <Link to="/login">← Sign In</Link>
        </small>
      </Form>
    </AuthContainer>
  );
}
