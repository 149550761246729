import { ReactElement } from 'react';
import cx from 'clsx';
import { Sprinkles } from '@ff-it/ui';
import { TitleContainer, Title, SubTitle } from 'components/TitleContainer';
import { genericProduct } from './style.css';

export type ProductTitleProps = {
  product: {
    id: number;
    name: string;
    department: {
      id: number;
      name: string;
    };
  };
  className?: string;
} & Sprinkles;

export function ProductTitle({
  product: { id, name, department },
  className,
  ...rest
}: ProductTitleProps): ReactElement {
  return (
    <TitleContainer className={cx('product-title', className)} data-testid={`product-${id}`} {...rest}>
      <Title className="department">{department.name}</Title>
      <SubTitle className={cx('product', { [genericProduct]: !name })}>{name || 'Generic product'}</SubTitle>
    </TitleContainer>
  );
}
