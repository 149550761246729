import { ReactElement } from 'react';
import { DownloadButton } from 'components';
import { useFiancePeriod } from '../LinksProvider';

export function Export({ url }: { url: string }): ReactElement {
  const financePeriod = useFiancePeriod();

  return (
    <DownloadButton
      url={url}
      icon="file-excel"
      variant="outline-primary"
      size="sm"
      className="ml-3"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      queryParams={{ links: financePeriod ? `${financePeriod.start},${financePeriod.end}` : '' }}
    >
      Export linkable
    </DownloadButton>
  );
}
