import { ReactElement } from 'react';
import { TextField } from '@ff-it/form';
import { required } from 'utilities';

type PlacementFormProps = {
  name?: string;
};

export function PlacementForm({ name = '' }: PlacementFormProps): ReactElement {
  return <TextField label="Placement title" name={`${name}title`} required size="sm" validate={required} />;
}
